import { BehaviorIncident } from '../types/behavior';

const API_ENDPOINT = import.meta.env.VITE_API_DATATABLEDYNAMO_ENDPOINT;

export class BehaviorError extends Error {
  constructor(message: string, public statusCode?: number) {
    super(message);
    this.name = 'BehaviorError';
  }
}

export async function createBehaviorIncident(
  userId: string,
  incident: Omit<BehaviorIncident, 'id' | 'userId' | 'createdAt' | 'lastModified'>
): Promise<BehaviorIncident> {
  try {
    const response = await fetch(`${API_ENDPOINT}/behavior`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        userId,
        ...incident,
      }),
    });

    if (!response.ok) {
      throw new BehaviorError(`HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    if (!data?.Item) {
      throw new BehaviorError('Invalid response format from server');
    }

    return data.Item;
  } catch (error) {
    if (error instanceof BehaviorError) throw error;
    throw new BehaviorError('Failed to create behavior incident');
  }
}

export async function updateBehaviorIncident(
  userId: string,
  incident: BehaviorIncident
): Promise<BehaviorIncident> {
  try {
    const response = await fetch(`${API_ENDPOINT}/behavior`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        userId,
        ...incident,
        lastModified: new Date().toISOString(),
      }),
    });

    if (!response.ok) {
      throw new BehaviorError(`HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    if (!data?.Item) {
      throw new BehaviorError('Invalid response format from server');
    }

    return data.Item;
  } catch (error) {
    if (error instanceof BehaviorError) throw error;
    throw new BehaviorError('Failed to update behavior incident');
  }
}

export async function getStudentBehaviorIncidents(userId: string, studentId: string): Promise<BehaviorIncident[]> {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/behavior?userId=${userId}&studentId=${studentId}`,
      {
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    if (!response.ok) {
      throw new BehaviorError(`HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    return Array.isArray(data?.Items) ? data.Items : [];
  } catch (error) {
    if (error instanceof BehaviorError) throw error;
    throw new BehaviorError('Failed to fetch behavior incidents');
  }
}