import React, { useState, useCallback } from 'react';
import { uploadData, getUrl } from 'aws-amplify/storage';
import { Camera, X, Check } from 'lucide-react';
import Cropper from 'react-easy-crop';
import { useAuth } from '../../contexts/AuthContext';

interface Point { x: number; y: number }
interface Area { width: number; height: number; x: number; y: number }

interface ProfilePictureUploadProps {
  currentPicture?: string;
  onClose: () => void;
}

export default function ProfilePictureUpload({ currentPicture, onClose }: ProfilePictureUploadProps) {
  const [image, setImage] = useState<string | null>(null);
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { updateUserAttributes, state: { user } } = useAuth();

  const onFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size > 5 * 1024 * 1024) {
        setError('Image size must be less than 5MB');
        return;
      }
      
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setImage(reader.result as string);
        setError(null);
      });
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = (url: string): Promise<HTMLImageElement> =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', error => reject(error));
      image.src = url;
    });

  const getCroppedImg = async (
    imageSrc: string,
    pixelCrop: Area,
  ): Promise<Blob> => {
    const image = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      throw new Error('No 2d context');
    }

    const maxSize = 400;
    canvas.width = maxSize;
    canvas.height = maxSize;

    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      maxSize,
      maxSize
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Canvas to Blob conversion failed'));
          }
        },
        'image/jpeg',
        0.9
      );
    });
  };

  const handleSave = async () => {
    if (!image || !croppedAreaPixels || !user?.username) return;
    setError(null);
    setIsUploading(true);

    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      
      // Log blob details for debugging
      console.log('Blob size:', croppedImage.size);
      console.log('Blob type:', croppedImage.type);
      
      // Updated file path to match IAM policy
      const fileName = `profile-pictures/${user.username}-${Date.now()}.jpg`;
      
      console.log('Uploading file:', fileName);

      // Simplified upload configuration
      const uploadResult = await uploadData({
        key: fileName,
        data: croppedImage,
        options: {
          accessLevel: 'public',
          contentType: 'image/jpeg',
          metadata: {
            userId: user.username,
            purpose: 'profile-picture'
          }
        }
      }).result;

      console.log('Upload successful:', uploadResult);

      const { url } = await getUrl({
        key: uploadResult.key,
        options: {
          accessLevel: 'public',
          expires: 7200,
          validateObjectExistence: true
        }
      });

      const pictureUrl = url.toString();
      //this uses unsigned URL //  const pictureUrl = `https://ninjaed-user-content.s3.us-east-2.amazonaws.com/public/${uploadResult.key}`;
      console.log('Generated URL:', pictureUrl);

      await updateUserAttributes({
        picture: pictureUrl
      });

      console.log('Profile picture updated successfully');
      onClose();
    } catch (error) {
      console.error('Upload error:', error);
      let errorMessage = 'Failed to upload profile picture. ';
      
      if (error instanceof Error) {
        // Enhanced error logging
        console.error('Error details:', {
          message: error.message,
          name: error.name,
          stack: error.stack
        });
        
        if (error.message.includes('403')) {
          errorMessage += 'Access denied. Please check your permissions.';
        } else if (error.message.includes('NetworkError')) {
          errorMessage += 'Network error. Please check your connection.';
        } else if (error.message.includes('400')) {
          errorMessage += 'Invalid request. Please try again with a different image.';
        } else {
          errorMessage += error.message;
        }
      }
      
      setError(errorMessage);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-2xl w-full p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900">Update Profile Picture</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-5 w-5" />
          </button>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-50 rounded-lg">
            <p className="text-sm text-red-600">{error}</p>
          </div>
        )}

        {!image ? (
          <div className="text-center py-12">
            <div className="mb-6">
              <div className="mx-auto w-24 h-24 rounded-full bg-gray-100 flex items-center justify-center overflow-hidden">
                {currentPicture ? (
                  <img 
                    src={currentPicture} 
                    alt="Current profile" 
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <Camera className="h-8 w-8 text-gray-400" />
                )}
              </div>
            </div>
            <label className="cursor-pointer inline-block">
              <span className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700">
                Choose Photo
              </span>
              <input
                type="file"
                className="hidden"
                accept="image/jpeg,image/png,image/webp"
                onChange={onFileChange}
              />
            </label>
            <p className="mt-2 text-sm text-gray-500">Maximum file size: 5MB</p>
          </div>
        ) : (
          <>
            <div className="relative w-full h-96 bg-gray-100 rounded-lg overflow-hidden">
              <Cropper
                image={image}
                crop={crop}
                zoom={zoom}
                aspect={1}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
                cropShape="round"
                showGrid={false}
              />
            </div>

            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Zoom
              </label>
              <input
                type="range"
                min={1}
                max={3}
                step={0.1}
                value={zoom}
                onChange={(e) => setZoom(Number(e.target.value))}
                className="w-full"
              />
            </div>

            <div className="flex justify-end gap-3 mt-6">
              <button
                onClick={() => {
                  setImage(null);
                  setError(null);
                }}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                Choose Different Photo
              </button>
              <button
                onClick={handleSave}
                disabled={isUploading}
                className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
              >
                {isUploading ? (
                  <>
                    <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent" />
                    Saving...
                  </>
                ) : (
                  <>
                    <Check className="h-4 w-4" />
                    Save Photo
                  </>
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}