import React, { createContext, useContext, useReducer, useEffect } from 'react';
import type { Unit, SavedUnit, SavedUnitsState, SubjectType } from '../types';
import * as unitsApi from '../services/units';
import { useAuth } from './AuthContext';

type SavedUnitsAction = 
  | { type: 'SET_UNITS'; payload: SavedUnit[] }
  | { type: 'ADD_UNITS'; payload: SavedUnit[] }
  | { type: 'UPDATE_UNIT'; payload: SavedUnit }
  | { type: 'DELETE_UNIT'; payload: string }
  | { type: 'SET_SCHOOL_YEAR'; payload: string }
  | { type: 'SET_LOADING' }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'CLEAR_ERROR' };

interface SavedUnitsContextType {
  state: SavedUnitsState;
  addUnits: (unitsWithSubject: { units: Unit[]; subject: SubjectType }[]) => Promise<void>;
  updateUnit: (unit: SavedUnit) => Promise<void>;
  deleteUnit: (id: string) => Promise<void>;
  setSchoolYear: (year: string) => void;
  clearError: () => void;
  refreshUnits: () => Promise<void>;
  updateUnitSchedule: (unitId: string, scheduledStart: string | null, scheduledEnd: string | null) => Promise<void>;
}

const SavedUnitsContext = createContext<SavedUnitsContextType | undefined>(undefined);

const initialState: SavedUnitsState = {
  units: [],
  currentSchoolYear: '2023-2024',
  isLoading: false,
  error: null
};

function savedUnitsReducer(state: SavedUnitsState, action: SavedUnitsAction): SavedUnitsState {
  switch (action.type) {
    case 'SET_UNITS':
      return {
        ...state,
        units: action.payload,
        isLoading: false,
        error: null
      };
    case 'ADD_UNITS':
      return {
        ...state,
        units: [...state.units, ...action.payload],
        isLoading: false,
        error: null
      };
    case 'UPDATE_UNIT':
      return {
        ...state,
        units: state.units.map(unit => 
          unit.id === action.payload.id ? action.payload : unit
        ),
        isLoading: false,
        error: null
      };
    case 'DELETE_UNIT':
      return {
        ...state,
        units: state.units.filter(unit => unit.id !== action.payload),
        isLoading: false,
        error: null
      };
    case 'SET_SCHOOL_YEAR':
      return {
        ...state,
        currentSchoolYear: action.payload
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: true,
        error: null
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    case 'CLEAR_ERROR':
      return {
        ...state,
        error: null
      };
    default:
      return state;
  }
}

export function SavedUnitsProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(savedUnitsReducer, initialState);
  const { state: authState } = useAuth();

  const fetchUnits = async () => {
    if (!authState.user?.username) {
      console.log('No username available for fetching units');
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    console.log('Fetching units for user:', authState.user.username);
    dispatch({ type: 'SET_LOADING' });
    
    try {
      const units = await unitsApi.getUnits(authState.user.username);
      console.log('Units fetched successfully:', units);
      dispatch({ type: 'SET_UNITS', payload: units });
    } catch (error) {
      console.error('Error in fetchUnits:', error);
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error instanceof Error ? error.message : 'Failed to fetch units' 
      });
    }
  };

  useEffect(() => {
    console.log('SavedUnitsProvider useEffect - Auth state:', {
      isAuthenticated: authState.isAuthenticated,
      username: authState.user?.username
    });
    
    if (authState.isAuthenticated && authState.user?.username) {
      fetchUnits();
    }
  }, [authState.isAuthenticated, authState.user?.username]);

  const addUnits = async (unitsWithSubject: { units: Unit[]; subject: SubjectType }[]) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      const currentMaxNumber = Math.max(0, ...state.units.map(u => u.unitNumber));
      const newUnits: SavedUnit[] = [];

      for (const { units, subject } of unitsWithSubject) {
        for (let i = 0; i < units.length; i++) {
          const unit = units[i];
          const savedUnit: SavedUnit = {
            ...unit,
            id: Math.random().toString(36).substring(2, 9),
            userId: authState.user.username,
            modified: false,
            lastModified: new Date().toISOString(),
            // removing this line to fix cloning schoolYear: state.currentSchoolYear,
            originalUnit: unit,
            unitNumber: currentMaxNumber + newUnits.length + 1,
            subject
          };

          const createdUnit = await unitsApi.createUnit(authState.user.username, savedUnit);
          newUnits.push(createdUnit);
        }
      }

      dispatch({ type: 'ADD_UNITS', payload: newUnits });
    } catch (error) {
      console.error('Error adding units:', error);
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error instanceof Error ? error.message : 'Failed to add units' 
      });
      throw error;
    }
  };

  const updateUnit = async (unit: SavedUnit) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      const updatedUnit = await unitsApi.updateUnit(authState.user.username, unit);
      dispatch({ type: 'UPDATE_UNIT', payload: updatedUnit });
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error instanceof Error ? error.message : 'Failed to update unit' 
      });
      throw error;
    }
  };

  const updateUnitSchedule = async (unitId: string, scheduledStart: string | null, scheduledEnd: string | null) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      const unit = state.units.find(u => u.id === unitId);
      if (!unit) throw new Error('Unit not found');

      const updatedUnit = {
        ...unit,
        scheduledStart,
        scheduledEnd,
        lastModified: new Date().toISOString()
      };

      const savedUnit = await unitsApi.updateUnit(authState.user.username, updatedUnit);
      dispatch({ type: 'UPDATE_UNIT', payload: savedUnit });
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error instanceof Error ? error.message : 'Failed to update unit schedule' 
      });
      throw error;
    }
  };


  const deleteUnit = async (id: string) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      await unitsApi.deleteUnit(authState.user.username, id);
      dispatch({ type: 'DELETE_UNIT', payload: id });
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error instanceof Error ? error.message : 'Failed to delete unit' 
      });
      throw error;
    }
  };

  const setSchoolYear = (year: string) => {
    dispatch({ type: 'SET_SCHOOL_YEAR', payload: year });
  };

  const clearError = () => {
    dispatch({ type: 'CLEAR_ERROR' });
  };

  const refreshUnits = () => fetchUnits();

  return (
    <SavedUnitsContext.Provider 
      value={{ 
        state, 
        addUnits, 
        updateUnit, 
        deleteUnit,
        updateUnitSchedule,
        setSchoolYear,
        clearError,
        refreshUnits
      }}
    >
      {children}
    </SavedUnitsContext.Provider>
  );
}

export function useSavedUnits() {
  const context = useContext(SavedUnitsContext);
  if (context === undefined) {
    throw new Error('useSavedUnits must be used within a SavedUnitsProvider');
  }
  return context;
}