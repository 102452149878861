import { Lesson } from '../types';

const API_ENDPOINT = import.meta.env.VITE_API_DATATABLEDYNAMO_ENDPOINT;

export class LessonsError extends Error {
  constructor(message: string, public statusCode?: number) {
    super(message);
    this.name = 'LessonsError';
  }
}

export async function getLessons(userId: string, unitId?: string): Promise<Lesson[]> {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new LessonsError('Authentication token not found', 401);
    }

    let url = `${API_ENDPOINT}/lessons?userId=${userId}`;
    if (unitId) {
      url += `&unitId=${unitId}`;
    }

    const response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error' }));
      throw new LessonsError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    return Array.isArray(data?.Items) ? data.Items : [];
  } catch (error) {
    if (error instanceof LessonsError) throw error;
    throw new LessonsError('Failed to fetch lessons');
  }
}

export async function getLessonsByDate(userId: string, date: string): Promise<Lesson[]> {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new LessonsError('Authentication token not found', 401);
    }

    const response = await fetch(`${API_ENDPOINT}/lessons?userId=${userId}&date=${date}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error' }));
      throw new LessonsError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    return Array.isArray(data?.Items) ? data.Items : [];
  } catch (error) {
    if (error instanceof LessonsError) throw error;
    throw new LessonsError('Failed to fetch lessons');
  }
}

export async function createLesson(userId: string, lesson: Omit<Lesson, 'id' | 'createdAt'>): Promise<Lesson> {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new LessonsError('Authentication token not found', 401);
    }

    // Validate required fields
    if (!userId || !lesson.unitId || !lesson.title) {
      throw new LessonsError('userId, unitId, and title are required');
    }

    const response = await fetch(`${API_ENDPOINT}/lessons`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        ...lesson,
        standards: lesson.standards || [],
        learningTargets: lesson.learningTargets || [],
        resources: lesson.resources || '',
        description: lesson.description || '',
        order: lesson.order || 0,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error' }));
      throw new LessonsError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    if (!data?.Item) {
      throw new LessonsError('Invalid response format from server');
    }

    return data.Item;
  } catch (error) {
    if (error instanceof LessonsError) throw error;
    throw new LessonsError('Failed to create lesson');
  }
}

export async function updateLesson(userId: string, lesson: Lesson): Promise<Lesson> {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new LessonsError('Authentication token not found', 401);
    }

    // Validate required fields
    if (!userId || !lesson.id) {
      throw new LessonsError('userId and id are required');
    }

    const response = await fetch(`${API_ENDPOINT}/lessons`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        ...lesson,
        lastModified: new Date().toISOString(),
      }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error' }));
      throw new LessonsError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    if (!data?.Item) {
      throw new LessonsError('Invalid response format from server');
    }

    return data.Item;
  } catch (error) {
    if (error instanceof LessonsError) throw error;
    throw new LessonsError('Failed to update lesson');
  }
}

export async function deleteLesson(userId: string, lessonId: string): Promise<void> {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new LessonsError('Authentication token not found', 401);
    }

    const response = await fetch(`${API_ENDPOINT}/lessons?userId=${userId}&lessonId=${lessonId}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error' }));
      throw new LessonsError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
    }
  } catch (error) {
    if (error instanceof LessonsError) throw error;
    throw new LessonsError('Failed to delete lesson');
  }
}