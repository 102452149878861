import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { useAuth } from './AuthContext';
import * as stripeService from '../services/stripe';
import type { UserSubscription, SubscriptionPlan } from '../types/subscription';

// Interfaces and types
interface SubscriptionState {
  subscription: UserSubscription | null;
  plans: SubscriptionPlan[];
  isLoading: boolean;
  error: string | null;
}

type SubscriptionAction =
  | { type: 'SET_SUBSCRIPTION'; payload: UserSubscription }
  | { type: 'SET_PLANS'; payload: SubscriptionPlan[] }
  | { type: 'SET_LOADING' }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'CLEAR_ERROR' };

interface SubscriptionContextType {
  state: SubscriptionState;
  checkoutSubscription: (priceId: string) => Promise<void>;
  openCustomerPortal: () => Promise<void>;
  hasReachedLimit: () => boolean;
  clearError: () => void;
}

// Constants
const SUBSCRIPTION_PLANS: SubscriptionPlan[] = [
  {
    id: 'price_1QLp8zEysEiMJjmbcUAmBigd',
    name: 'Basic',
    description: 'Perfect for getting started',
    price: 9.99,
    interval: 'month',
    features: [
      'Up to 1,000 tokens per month',
      'Basic curriculum planning',
      'Standard support'
    ],
    tokenLimit: 1000
  },
  {
    id: 'price_1QMAavEysEiMJjmbtiFXoY3P',
    name: 'Professional',
    description: 'For growing educators',
    price: 19.99,
    interval: 'month',
    features: [
      'Up to 5,000 tokens per month',
      'Advanced curriculum planning',
      'Priority support',
      'Custom unit templates'
    ],
    tokenLimit: 5000
  },
  {
    id: 'price_1QMAbjEysEiMJjmbnwjn4XJX',
    name: 'Unlimited',
    description: 'For power users',
    price: 39.99,
    interval: 'month',
    features: [
      'Unlimited tokens',
      'All features included',
      '24/7 priority support',
      'Custom integrations',
      'Team collaboration'
    ],
    tokenLimit: Infinity
  }
];

const initialState: SubscriptionState = {
  subscription: null,
  plans: SUBSCRIPTION_PLANS,
  isLoading: false,
  error: null,
};

const SubscriptionContext = createContext<SubscriptionContextType | undefined>(undefined);

function subscriptionReducer(state: SubscriptionState, action: SubscriptionAction): SubscriptionState {
  switch (action.type) {
    case 'SET_SUBSCRIPTION':
      return {
        ...state,
        subscription: action.payload,
        isLoading: false,
        error: null,
      };
    case 'SET_PLANS':
      return {
        ...state,
        plans: action.payload,
        isLoading: false,
        error: null,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case 'SET_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'CLEAR_ERROR':
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}

export function SubscriptionProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(subscriptionReducer, initialState);
  const { state: authState } = useAuth();

  const fetchPlans = async () => {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_DATATABLEDYNAMO_ENDPOINT}/plan-configurations`, 
      {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    
    if (!response.ok) {
      throw new Error('Failed to fetch plan configurations');
    }

    const data = await response.json();
    console.log('Raw plan config data:', data); // Debug log

    const activePlans = data.Items?.filter((plan: any) => plan.status === 'active') || [];
    console.log('Active plan configs before mapping:', activePlans); // Debug log
    
    const mappedPlans = activePlans.map((plan: any) => {
      console.log('Mapping plan config:', plan); // Debug log for each plan
      return {
        id: plan.planId || plan.priceId, // Try both fields
        name: plan.name,
        description: plan.description,
        priceUSD: plan.priceUSD || plan.price, // Try both fields
        interval: plan.interval,
        features: plan.features || [],
        tokenLimit: plan.tokenLimit,
        displayOrder: plan.displayOrder || 0,
      };
    });

    console.log('Mapped plan configs:', mappedPlans); // Debug log

    dispatch({ 
      type: 'SET_PLANS', 
      payload: mappedPlans.sort((a, b) => a.displayOrder - b.displayOrder)
    });
  } catch (error) {
    console.error('Error fetching plan configurations:', error);
    dispatch({ 
      type: 'SET_ERROR', 
      payload: error instanceof Error ? error.message : 'Failed to fetch plan configs' 
    });
  }
};

  const fetchSubscription = async () => {
    dispatch({ type: 'SET_LOADING' });
    try {
      const userId = authState.user?.userId;
      
      if (!userId) {
        throw new Error('User ID not found');
      }

      const response = await fetch(
        `${import.meta.env.VITE_API_DATATABLEDYNAMO_ENDPOINT}/subscription?userId=${userId}`, 
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch subscription');
      }

      const data = await response.json();
      dispatch({ type: 'SET_SUBSCRIPTION', payload: data.Item });
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error instanceof Error ? error.message : 'Failed to fetch subscription'
      });
    }
  };

  const checkoutSubscription = async (priceId: string) => {
    dispatch({ type: 'SET_LOADING' });
    try {
      const { url } = await stripeService.createCheckoutSession(priceId);
      window.location.href = url;
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error instanceof Error ? error.message : 'Failed to start checkout'
      });
      throw error;
    }
  };

  const openCustomerPortal = async () => {
    dispatch({ type: 'SET_LOADING' });
    try {
      const response = await fetch(`${import.meta.env.VITE_API_URL}/create-portal-session`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to create portal session');
      }

      const { url } = await response.json();
      window.location.href = url;
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error instanceof Error ? error.message : 'Failed to open customer portal'
      });
      throw error;
    }
  };

  const hasReachedLimit = () => {
    if (!state.subscription) return false;
    const plan = state.plans.find(p => p.id === state.subscription?.priceId);  // Changed from planId
    if (!plan) return false;
    return state.subscription.tokenUsage >= plan.tokenLimit;
  };

  const clearError = () => {
    dispatch({ type: 'CLEAR_ERROR' });
  };

  useEffect(() => {
    if (authState.isAuthenticated) {
      fetchSubscription();
      fetchPlans();
    }
  }, [authState.isAuthenticated]);

  return (
    <SubscriptionContext.Provider
      value={{
        state,
        checkoutSubscription,
        openCustomerPortal,
        hasReachedLimit,
        clearError,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}

export function useSubscription() {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error('useSubscription must be used within a SubscriptionProvider');
  }
  return context;
}