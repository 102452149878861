
import React from 'react';
import { MessageCircle } from 'lucide-react';
import { format } from 'date-fns';
import type { Communication } from '../../types/communication';

interface CommunicationHistoryProps {
  communications: Communication[];
}

export default function CommunicationHistory({ communications }: CommunicationHistoryProps) {
  // Sort communications by date in descending order (newest first)
  const sortedCommunications = [...communications]
    .filter(comm => comm.status === 'sent')
    .sort((a, b) => new Date(b.sentDate).getTime() - new Date(a.sentDate).getTime());

  if (sortedCommunications.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Communications</h2>
        <p className="text-center text-gray-500 py-4">No communications found</p>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <h2 className="text-lg font-semibold text-gray-900 mb-4">Communications</h2>
      <div className="space-y-4">
        {sortedCommunications.map((communication) => (
          <div
            key={communication.id}
            className="border-b border-gray-200 last:border-0 pb-4 last:pb-0"
          >
            <div className="flex items-start gap-3">
              <div className="p-2 bg-indigo-50 rounded-lg">
                <MessageCircle className="h-5 w-5 text-indigo-600" />
              </div>
              
              <div className="flex-1 min-w-0">
                <div className="mt-2 flex flex-wrap gap-2">
                  {communication.recipients.map((recipient, index) => (
                    <span
                      key={index}
                      className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                    >
                      {recipient}
                    </span>
                  ))}
                </div>
                <div className="flex items-center justify-between mb-1">
                  
                  <h3 className="text-sm font-medium text-gray-900">
                    {communication.subject}
                  </h3>
                  <span className="text-xs text-gray-500">
                    {format(new Date(communication.sentDate), 'MMM d, yyyy h:mm a')}
                  </span>
                </div>
                <div className="text-sm text-gray-600 whitespace-pre-wrap">
                  {communication.content}
                </div>
                
                {communication.templateId && (
                  <p className="mt-2 text-xs text-gray-500">
                    Sent using template
                  </p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
