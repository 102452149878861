import React from 'react';

interface MessageFormProps {
  subject: string;
  message: string;
  additionalInstructions: string;
  isGenerating: boolean;
  generationProgress: number;
  onSubjectChange: (value: string) => void;
  onMessageChange: (value: string) => void;
  onInstructionsChange: (value: string) => void;
  onSubmit: () => void;
}

export default function MessageForm({
  subject,
  message,
  additionalInstructions,
  isGenerating,
  generationProgress,
  onSubjectChange,
  onMessageChange,
  onInstructionsChange,
  onSubmit,
}: MessageFormProps) {
  
  return (
    <div className="space-y-6">
      <div>
        <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
          Subject
        </label>
        <input
          type="text"
          id="subject"
          value={subject}
          onChange={(e) => onSubjectChange(e.target.value)}
          className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder="Enter message subject"
          required
        />
      </div>

      <div>
        <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
          Message
        </label>
        <textarea
          id="message"
          value={message}
          onChange={(e) => onMessageChange(e.target.value)}
          rows={6}
          className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder="Type your message here..."
          required
        />
      </div>

      <div>
        <label htmlFor="instructions" className="block text-sm font-medium text-gray-700 mb-1">
          Additional Instructions (Optional)
        </label>
        <textarea
          id="instructions"
          value={additionalInstructions}
          onChange={(e) => onInstructionsChange(e.target.value)}
          rows={3}
          className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder="Add any special instructions for email generation..."
        />
      </div>

      <div className="flex justify-end">
        <button
          type="button"
          onClick={onSubmit}
          disabled={isGenerating || !subject || !message}
          className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          {isGenerating ? (
            <>
              <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent" />
              {generationProgress > 0 ? `Generating (${generationProgress}%)...` : 'Generating...'}
            </>
          ) : (
            <>
              <span className="text-lg">⚔️</span>
              Ninja It!
            </>
          )}
        </button>
      </div>
    </div>
  );
}