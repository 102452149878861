import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { useAuth } from './AuthContext';
import * as api from '../services/api';
import type { Task, RecurrencePattern } from '../types';
import { addDays, addWeeks, addMonths, isWeekend, format } from 'date-fns';

interface TaskState {
  tasks: Task[];
  isLoading: boolean;
  error: string | null;
}

type TaskAction =
  | { type: 'SET_LOADING' }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'CLEAR_ERROR' }
  | { type: 'SET_TASKS'; payload: Task[] }
  | { type: 'ADD_TASK'; payload: Task }
  | { type: 'UPDATE_TASK'; payload: Task }
  | { type: 'DELETE_TASK'; payload: string };

interface TaskContextType {
  state: TaskState;
  addTask: (task: Omit<Task, 'id'>) => Promise<void>;
  updateTask: (task: Task) => Promise<void>;
  deleteTask: (id: string) => Promise<void>;
  refreshTasks: () => Promise<void>;
  clearError: () => void;
}

const TaskContext = createContext<TaskContextType | undefined>(undefined);

const initialState: TaskState = {
  tasks: [],
  isLoading: false,
  error: null,
};

function getNextDueDate(currentDate: Date, pattern: RecurrencePattern): Date {
  switch (pattern) {
    case 'daily':
      return addDays(currentDate, 1);
    case 'weekdays': {
      let nextDate = addDays(currentDate, 1);
      while (isWeekend(nextDate)) {
        nextDate = addDays(nextDate, 1);
      }
      return nextDate;
    }
    case 'weekly':
      return addWeeks(currentDate, 1);
    case 'monthly':
      return addMonths(currentDate, 1);
    default:
      return currentDate;
  }
}

function taskReducer(state: TaskState, action: TaskAction): TaskState {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case 'SET_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case 'CLEAR_ERROR':
      return {
        ...state,
        error: null,
      };
    case 'SET_TASKS':
      return {
        ...state,
        tasks: action.payload,
        isLoading: false,
        error: null,
      };
    case 'ADD_TASK':
      return {
        ...state,
        tasks: [action.payload, ...state.tasks],
        isLoading: false,
        error: null,
      };
    case 'UPDATE_TASK':
      return {
        ...state,
        tasks: state.tasks.map((task) =>
          task.id === action.payload.id ? action.payload : task
        ),
        isLoading: false,
        error: null,
      };
    case 'DELETE_TASK':
      return {
        ...state,
        tasks: state.tasks.filter((task) => task.id !== action.payload),
        isLoading: false,
        error: null,
      };
    default:
      return state;
  }
}

export function TaskProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(taskReducer, {
    tasks: [],
    isLoading: false,
    error: null,
  });
  const { state: authState } = useAuth();

  const clearError = () => {
    dispatch({ type: 'CLEAR_ERROR' });
  };

  const refreshTasks = async () => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_TASKS', payload: [] });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      const tasks = await api.getTasks(authState.user.username);
      dispatch({ type: 'SET_TASKS', payload: tasks });
    } catch (error) {
      const message = error instanceof api.APIError ? error.message : 'Failed to fetch tasks';
      dispatch({ type: 'SET_ERROR', payload: message });
    }
  };

  useEffect(() => {
    refreshTasks();
  }, [authState.user?.username]);

  const addTask = async (task: Omit<Task, 'id'>) => {
    if (!authState.user?.username) return;

    dispatch({ type: 'SET_LOADING' });
    try {
      const newTask = await api.createTask(authState.user.username, task);
      dispatch({ type: 'ADD_TASK', payload: newTask });
    } catch (error) {
      const message = error instanceof api.APIError ? error.message : 'Failed to create task';
      dispatch({ type: 'SET_ERROR', payload: message });
      throw error;
    }
  };

  const updateTask = async (task: Task) => {
    if (!authState.user?.username) return;

    dispatch({ type: 'SET_LOADING' });
    try {
      const updatedTask = await api.updateTask(authState.user.username, task);
      dispatch({ type: 'UPDATE_TASK', payload: updatedTask });

      // Handle recurring task completion
      if (task.completed && task.recurrence && !task.parentTaskId) {
        const currentDueDate = new Date(task.dueDate);
        const nextDueDate = getNextDueDate(currentDueDate, task.recurrence.pattern);
        
        // Check if we've reached the end date
        if (!task.recurrence.endDate || new Date(task.recurrence.endDate) >= nextDueDate) {
          const newTask = {
            ...task,
            id: undefined as any, // Will be generated by the API
            dueDate: format(nextDueDate, 'yyyy-MM-dd'),
            completed: false,
            parentTaskId: task.id,
            createdAt: new Date().toISOString()
          };
          
          await addTask(newTask);
        }
      }
    } catch (error) {
      const message = error instanceof api.APIError ? error.message : 'Failed to update task';
      dispatch({ type: 'SET_ERROR', payload: message });
      throw error;
    }
  };

  const deleteTask = async (id: string) => {
    if (!authState.user?.username) return;

    dispatch({ type: 'SET_LOADING' });
    try {
      await api.deleteTask(authState.user.username, id);
      dispatch({ type: 'DELETE_TASK', payload: id });
    } catch (error) {
      const message = error instanceof api.APIError ? error.message : 'Failed to delete task';
      dispatch({ type: 'SET_ERROR', payload: message });
      throw error;
    }
  };

  return (
    <TaskContext.Provider
      value={{ state, addTask, updateTask, deleteTask, refreshTasks, clearError }}
    >
      {children}
    </TaskContext.Provider>
  );
}

export function useTasks() {
  const context = useContext(TaskContext);
  if (context === undefined) {
    throw new Error('useTasks must be used within a TaskProvider');
  }
  return context;
}