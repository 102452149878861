import React, { useState } from 'react';
import { DndContext, closestCenter, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import type { Lesson, SavedUnit, CalendarEvent } from '../../../types';
import LessonItem from './LessonItem';
import { useLessons } from '../../../contexts/LessonContext';
import { format, parseISO, startOfDay } from 'date-fns';
import DeleteConfirmationModal from './DeleteConfirmationModal';

interface LessonListProps {
  lessons: Lesson[];
  unit: SavedUnit;
  schoolDays: Date[];
  event: CalendarEvent;
}

export default function LessonList({ lessons, unit, schoolDays, event }: LessonListProps) {
  const { reorderLessons, deleteLesson, setBatchOptimisticUpdates, clearOptimisticUpdates, state: lessonState } = useLessons();
  const [deletingLesson, setDeletingLesson] = useState<Lesson | null>(null);
  const [optimisticOrdering, setOptimisticOrdering] = useState<Record<string, Lesson>>({});

const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over.id) {
      // Get the sorted dates first
      const sortedDates = [...lessons]
        .sort((a, b) => a.order - b.order)
        .map(lesson => lesson.date);

      // Reorder the lessons
      const oldIndex = lessons.findIndex((lesson) => lesson.id === active.id);
      const newIndex = lessons.findIndex((lesson) => lesson.id === over.id);
      const newLessons = [...lessons];
      const [movedLesson] = newLessons.splice(oldIndex, 1);
      newLessons.splice(newIndex, 0, movedLesson);

      // Create ordered lesson map immediately for optimistic update
      const orderedLessons = {};
      newLessons.forEach((lesson, index) => {
        orderedLessons[lesson.id] = {
          ...lesson,
          order: index,
          date: sortedDates[index]
        };
      });

      // Set optimistic state immediately
      setOptimisticOrdering(orderedLessons);
      
      // Create reordered lessons array for API
      const reorderedLessons = Object.values(orderedLessons);

      // Handle the actual reorder
      reorderLessons(unit.id, reorderedLessons)
        .then(() => {
          setOptimisticOrdering({});
        })
        .catch(() => {
          setOptimisticOrdering({});
        });
    }
};

  const handleDeleteConfirm = async () => {
    if (deletingLesson) {
      await deleteLesson(unit.id, deletingLesson.id);
      setDeletingLesson(null);
    }
  };

  return (
    <>
      <div className="space-y-4">
        {lessons.length === 0 ? (
          <div className="text-center py-8">
            <p className="text-gray-500">No lessons planned yet</p>
            <p className="text-sm text-gray-400 mt-1">
              Click "Add Lesson" to start planning
            </p>
          </div>
        ) : (
          <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                        <SortableContext 
              items={lessons.map(l => l.id)} 
              strategy={verticalListSortingStrategy}
            >
              <div className="space-y-4">
                {lessons
                  .map((lesson) => ({
                    ...lesson,
                    ...optimisticOrdering[lesson.id]
                  }))
                  .sort((a, b) => a.order - b.order)
                  .map((lesson) => (
                    <LessonItem
                      key={lesson.id}
                      lesson={lesson}
                      unit={unit}
                      schoolDays={schoolDays}
                      event={event}
                      onDelete={() => setDeletingLesson(lesson)}
                    />
                    ))}
               
              </div>
            </SortableContext>
          </DndContext>
        )}
      </div>
      {deletingLesson && (
        <DeleteConfirmationModal
          lessonTitle={deletingLesson.title}
          onConfirm={handleDeleteConfirm}
          onCancel={() => setDeletingLesson(null)}
        />
      )}
    </>
);
}