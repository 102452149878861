import React from 'react';
import { format } from 'date-fns';
import { Clock, CalendarDays, Printer } from 'lucide-react';
import type { Lesson } from '../../types';
import { useSavedUnits } from '../../contexts/SavedUnitsContext';
import { subjectColors } from '../../utils/constants';

interface DayDetailsProps {
  date: Date;
  lessons: Lesson[];
  onEditLesson: (lesson: Lesson) => void;
}

export default function DayDetails({ date, lessons, onEditLesson }: DayDetailsProps) {
  const { state: unitsState } = useSavedUnits();

  const sortedLessons = [...lessons].sort((a, b) => {
    if (a.order !== b.order) return a.order - b.order;
    
    const aTime = a.description?.match(/Time: (.*?)\n/)?.[1] || '';
    const bTime = b.description?.match(/Time: (.*?)\n/)?.[1] || '';
    return aTime.localeCompare(bTime);
  });

  const handlePrint = () => {
    const printWindow = window.open('', '_blank');
    if (!printWindow) return;

    const printContent = `
      <!DOCTYPE html>
      <html>
        <head>
          <title>Daily Schedule - ${format(date, 'MMMM d, yyyy')}</title>
          <style>
            body {
              font-family: system-ui, -apple-system, sans-serif;
              line-height: 1.5;
              margin: 2rem;
            }
            .header {
              text-align: center;
              margin-bottom: 2rem;
              border-bottom: 2px solid #e5e7eb;
              padding-bottom: 1rem;
            }
            .header h1 {
              font-size: 24px;
              font-weight: bold;
              margin: 0 0 0.5rem 0;
            }
            .header p {
              font-size: 16px;
              color: #6b7280;
              margin: 0;
            }
            .lesson {
              margin-bottom: 2rem;
              page-break-inside: avoid;
              border: 1px solid #e5e7eb;
              border-radius: 8px;
              padding: 1rem;
            }
            .lesson-header {
              font-size: 18px;
              font-weight: bold;
              margin-bottom: 0.5rem;
              color: #1f2937;
            }
            .lesson-meta {
              color: #6b7280;
              font-size: 14px;
              margin-bottom: 0.5rem;
              display: flex;
              gap: 1rem;
            }
            .section {
              margin-top: 1rem;
              padding-top: 1rem;
              border-top: 1px solid #e5e7eb;
            }
            .section-title {
              font-weight: 600;
              margin-bottom: 0.5rem;
              color: #4b5563;
            }
            .section-content {
              margin: 0;
              color: #4b5563;
            }
            ul {
              margin: 0;
              padding-left: 1.5rem;
            }
            li {
              margin-bottom: 0.25rem;
            }
            @media print {
              body {
                margin: 1in;
              }
              .lesson {
                border: none;
                border-bottom: 1px solid #e5e7eb;
                border-radius: 0;
                padding: 1rem 0;
              }
              .lesson:last-child {
                border-bottom: none;
              }
            }
          </style>
        </head>
        <body>
          <div class="header">
            <h1>Daily Schedule</h1>
            <p>${format(date, 'EEEE, MMMM d, yyyy')}</p>
          </div>
          ${sortedLessons.map(lesson => {
            const unit = lesson.unitId !== 'other' 
              ? unitsState.units?.find(u => u.id === lesson.unitId)
              : null;
            
            const isOtherActivity = lesson.unitId === 'other';
            const activityType = isOtherActivity 
              ? lesson.description?.match(/^Type: (.*?)\n/)?.[1] || 'Other' 
              : null;
            const activityTime = isOtherActivity 
              ? lesson.description?.match(/Time: (.*?)\n/)?.[1] 
              : null;
            const description = isOtherActivity 
              ? lesson.description?.replace(/^Type: .*\n/, '').replace(/^Time: .*\n/, '')
              : lesson.description;

            return `
              <div class="lesson">
                <div class="lesson-header">${lesson.title}</div>
                <div class="lesson-meta">
                  ${unit ? `
                    <span>Unit ${unit.unitNumber} - ${unit.subject}</span>
                  ` : ''}
                  ${activityTime ? `
                    <span>Time: ${activityTime}</span>
                  ` : ''}
                </div>
                ${description ? `
                  <div class="section">
                    <div class="section-title">Description</div>
                    <p class="section-content">${description}</p>
                  </div>
                ` : ''}
                ${lesson.resources ? `
                  <div class="section">
                    <div class="section-title">Resources</div>
                    <p class="section-content">${lesson.resources}</p>
                  </div>
                ` : ''}
                ${!isOtherActivity && unit && lesson.standards?.length > 0 ? `
                  <div class="section">
                    <div class="section-title">Standards</div>
                    <ul>
                      ${lesson.standards.map(standardId => {
                        const standard = unit.standardsCovered?.find(s => s.standard === standardId);
                        return standard ? `
                          <li>${standard.standard}: ${standard.description}</li>
                        ` : '';
                      }).join('')}
                    </ul>
                  </div>
                ` : ''}
                ${!isOtherActivity && unit && lesson.learningTargets?.length > 0 ? `
                  <div class="section">
                    <div class="section-title">Learning Targets</div>
                    <ul>
                      ${lesson.learningTargets.map(target => `
                        <li>${target}</li>
                      `).join('')}
                    </ul>
                  </div>
                ` : ''}
              </div>
            `;
          }).join('')}
          <script>
            window.onload = function() {
              window.print();
              setTimeout(function() {
                window.close();
              }, 500);
            };
          </script>
        </body>
      </html>
    `;

    printWindow.document.open();
    printWindow.document.write(printContent);
    printWindow.document.close();
  };

  return (
    <div id="day-details" className="mt-8 bg-white rounded-xl shadow-sm p-6">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-3">
          <CalendarDays className="h-6 w-6 text-indigo-600" />
          <h2 className="text-xl font-semibold text-gray-900">
            {format(date, 'EEEE, MMMM d, yyyy')}
          </h2>
        </div>
        <div className="flex items-center gap-4">
          <span className="text-sm text-gray-500">
            {sortedLessons.length} {sortedLessons.length === 1 ? 'activity' : 'activities'} scheduled
          </span>
          <button
            onClick={handlePrint}
            className="flex items-center gap-2 px-3 py-1.5 text-sm text-indigo-600 hover:text-indigo-800 hover:bg-indigo-50 rounded-lg transition-colors"
          >
            <Printer className="h-4 w-4" />
            Print Schedule
          </button>
        </div>
      </div>

      <div className="space-y-4">
        {sortedLessons.map((lesson) => {
          const unit = lesson.unitId !== 'other' 
            ? unitsState.units?.find(u => u.id === lesson.unitId)
            : null;
          
          const isOtherActivity = lesson.unitId === 'other';
          const colors = unit 
            ? subjectColors[unit.subject] || subjectColors.Other 
            : subjectColors.Other;
          
          const activityType = isOtherActivity 
            ? lesson.description?.match(/^Type: (.*?)\n/)?.[1] || 'Other' 
            : null;
          const activityTime = isOtherActivity 
            ? lesson.description?.match(/Time: (.*?)\n/)?.[1] 
            : null;
          const description = isOtherActivity 
            ? lesson.description?.replace(/^Type: .*\n/, '').replace(/^Time: .*\n/, '')
            : lesson.description;

          return (
            <div 
              key={lesson.id}
              className="flex gap-6 p-4 rounded-lg hover:bg-gray-50 transition-colors group"
            >
              {activityTime && (
                <div className="flex items-center gap-2 text-gray-500">
                  <Clock className="h-4 w-4" />
                  <span className="text-sm whitespace-nowrap">{activityTime}</span>
                </div>
              )}
              
              <div className="flex-1 min-w-0">
                <div className="flex items-center gap-2 mb-1">
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${colors.bg} ${colors.text}`}>
                    {isOtherActivity ? activityType : unit?.subject || 'Other'}
                  </span>
                  {!isOtherActivity && unit?.unitNumber && (
                    <span className="text-sm text-gray-500">
                      Unit {unit.unitNumber}
                    </span>
                  )}
                </div>
                
                <h3 className="text-lg font-medium text-gray-900">{lesson.title}</h3>
                {!isOtherActivity && unit?.unitName && (
                  <p className="text-sm text-gray-600 mb-2">{unit.unitName}</p>
                )}
                
                {description && (
                  <div className="mt-4">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">Description</h4>
                    <p className="text-sm text-gray-600 whitespace-pre-wrap">{description}</p>
                  </div>
                )}

                {lesson.resources && (
                  <div className="mt-4">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">Resources</h4>
                    <p className="text-sm text-gray-600 whitespace-pre-wrap">{lesson.resources}</p>
                  </div>
                )}

                {!isOtherActivity && unit && lesson.standards?.length > 0 && (
                  <div className="mt-4">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">Standards</h4>
                    <ul className="space-y-1">
                      {lesson.standards.map((standardId) => {
                        const standard = unit.standardsCovered?.find(s => s.standard === standardId);
                        return standard && (
                          <li key={standardId} className="text-sm text-gray-600">
                            <span className="font-medium">{standard.standard}:</span>{' '}
                            {standard.description}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}

                {!isOtherActivity && unit && lesson.learningTargets?.length > 0 && (
                  <div className="mt-4">
                    <h4 className="text-sm font-medium text-gray-700 mb-2">Learning Targets</h4>
                    <ul className="space-y-1">
                      {lesson.learningTargets.map((target, idx) => (
                        <li key={`${lesson.id}-target-${idx}`} className="text-sm text-gray-600">
                          {target}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              <button
                onClick={() => onEditLesson(lesson)}
                className="opacity-0 group-hover:opacity-100 px-3 py-1 text-sm text-indigo-600 hover:text-indigo-800 hover:bg-indigo-50 rounded transition-all"
              >
                Edit
              </button>
            </div>
          );
        })}

        {sortedLessons.length === 0 && (
          <div className="text-center py-12 text-gray-500">
            No activities scheduled for this day
          </div>
        )}
      </div>
    </div>
  );
}