
import React, { useState, useEffect } from 'react';

const loadingMessages = [
  "Analyzing curriculum standards...",
  "Structuring learning objectives...",
  "Organizing units and lessons...",
  "This takes a bit of time...",
  "This is planning for a full year, OK?",
  "Feel free to leave and receive alert when complete",
  "Finalizing your year-long plan...",
];

interface PlanFormProps {
  input: string;
  isLoading: boolean;
  onInputChange: (value: string) => void;
  onSubmit: (e: React.FormEvent) => void;
  isExistingPlan?: boolean;
  originalInput?: string;
}

export default function PlanForm({ 
  input, 
  isLoading, 
  onInputChange, 
  onSubmit,
  isExistingPlan,
  originalInput 
}: PlanFormProps) {
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  const isInputUnchanged = originalInput !== undefined && input === originalInput;

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isLoading) {
      interval = setInterval(() => {
        setLoadingMessageIndex((prev) => 
          prev < loadingMessages.length - 1 ? prev + 1 : prev
        );
      }, 3000);
    } else {
      setLoadingMessageIndex(0);
    }
    return () => clearInterval(interval);
  }, [isLoading]);
  
  return (
    <form onSubmit={onSubmit} className="mb-8">
      <div className="space-y-4">
        <div>
          <label htmlFor="planInput" className="block text-sm font-medium text-gray-700">
            Describe your curriculum needs
          </label>
          <textarea
            id="planInput"
            rows={4}
            value={input}
            onChange={(e) => onInputChange(e.target.value)}
            placeholder="Example: 5th grade common core math"
            className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            required
          />
        </div>
        <div className="space-y-2">
          <button
            type="submit"
            disabled={isLoading || isInputUnchanged}
            className="w-full sm:w-auto px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center gap-2"
          >
            {isLoading ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent" />
                Generating Plan...
              </>
            ) : isInputUnchanged ? (
              'Plan Already Generated'
            ) : (
              <>
                <span className="text-lg">⚔️</span>
                Ninja It!
              </>
            )}
          </button>
          {isLoading && (
            <p className="text-sm text-gray-500 animate-pulse">
              {loadingMessages[loadingMessageIndex]}
            </p>
          )}
        </div>
      </div>
    </form>
  );
}
