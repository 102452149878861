import { AttendanceRecord, AttendanceStatus, MissedLesson } from '../types/attendance';

const API_ENDPOINT = import.meta.env.VITE_API_DATATABLEDYNAMO_ENDPOINT;

export class AttendanceError extends Error {
  constructor(message: string, public statusCode?: number) {
    super(message);
    this.name = 'AttendanceError';
  }
}

export async function createAttendanceRecord(
  userId: string,
  data: {
    studentId: string;
    date: string;
    status: AttendanceStatus;
    missedLessons?: MissedLesson[];
    notes?: string;
  }
): Promise<AttendanceRecord> {
  try {
    const response = await fetch(`${API_ENDPOINT}/attendance`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        userId,
        ...data,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', {
        status: response.status,
        statusText: response.statusText,
        body: errorText
      });
      throw new AttendanceError(`HTTP error! status: ${response.status}`, response.status);
    }

    const responseData = await response.json();
    return responseData.Item;
  } catch (error) {
    console.error('Create attendance error:', error);
    throw new AttendanceError(error instanceof Error ? error.message : 'Failed to create attendance record');
  }
}

export async function updateAttendanceRecord(
  userId: string,
  record: AttendanceRecord
): Promise<AttendanceRecord> {
  try {
    // If status is present, delete the record instead
    if (record.status === 'present') {
      await deleteAttendanceRecord(userId, record.id);
      return record;
    }

    const response = await fetch(`${API_ENDPOINT}/attendance`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        userId,
        ...record,
        lastModified: new Date().toISOString(),
      }),
    });

    if (!response.ok) {
      throw new AttendanceError(`HTTP error! status: ${response.status}`, response.status);
    }

    const responseData = await response.json();
    return responseData.Item;
  } catch (error) {
    if (error instanceof AttendanceError) throw error;
    throw new AttendanceError('Failed to update attendance record');
  }
}

export async function deleteAttendanceRecord(userId: string, recordId: string): Promise<void> {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/attendance?userId=${userId}&recordId=${recordId}`,
      {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    if (!response.ok) {
      throw new AttendanceError(`HTTP error! status: ${response.status}`, response.status);
    }
  } catch (error) {
    if (error instanceof AttendanceError) throw error;
    throw new AttendanceError('Failed to delete attendance record');
  }
}

export async function getStudentAttendanceRecords(
  userId: string,
  studentId: string,
  startDate?: string,
  endDate?: string
): Promise<AttendanceRecord[]> {
  try {
    let url = `${API_ENDPOINT}/attendance?userId=${userId}&studentId=${studentId}`;
    if (startDate) url += `&startDate=${startDate}`;
    if (endDate) url += `&endDate=${endDate}`;
    
    console.log('Requesting URL:', url);
    console.log('Token:', localStorage.getItem('token')?.substring(0, 10) + '...'); // Log partial token for debugging

    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Accept': 'application/json',
      },
    });

    // Log the full response details
    console.log('Response status:', response.status);
    console.log('Response headers:', Object.fromEntries(response.headers.entries()));

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response body:', errorText);
      
      // Try to parse error as JSON if possible
      let errorDetails;
      try {
        errorDetails = JSON.parse(errorText);
      } catch {
        errorDetails = errorText;
      }
      
      throw new AttendanceError(
        `HTTP error! status: ${response.status}, details: ${JSON.stringify(errorDetails)}`,
        response.status
      );
    }

    const data = await response.json();
    console.log('Response data:', data); // Log successful response
    
    return data.Items || [];
  } catch (error) {
    console.error('Full error details:', error);
    
    // Check if it's a network error
    if (error instanceof TypeError && error.message === 'Failed to fetch') {
      throw new AttendanceError('Network error - Unable to connect to attendance service');
    }
    
    if (error instanceof AttendanceError) throw error;
    throw new AttendanceError(`Failed to fetch attendance records: ${error.message}`);
  }
}

export async function exportAttendanceReport(
  userId: string,
  startDate: string,
  endDate: string,
  studentId?: string
): Promise<Blob> {
  try {
    let url = `${API_ENDPOINT}/attendance/export?userId=${userId}&startDate=${startDate}&endDate=${endDate}`;
    if (studentId) url += `&studentId=${studentId}`;

    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      throw new AttendanceError(`HTTP error! status: ${response.status}`, response.status);
    }

    return await response.blob();
  } catch (error) {
    if (error instanceof AttendanceError) throw error;
    throw new AttendanceError('Failed to export attendance report');
  }
}