import React from 'react';
import { format, parseISO } from 'date-fns';
import { X, Calendar } from 'lucide-react';

interface SkipDaysListProps {
  skipDays: string[];
  onDelete: (date: string) => void;
  disabled?: boolean;
}

export default function SkipDaysList({ skipDays, onDelete, disabled }: SkipDaysListProps) {
  if (!skipDays?.length) return null;

  const sortedSkipDays = [...skipDays].sort();

  return (
    <div>
      <h4 className="text-sm font-medium text-gray-700 mb-2">Skip Days</h4>
      <div className="space-y-1">
        {sortedSkipDays.map((date) => (
          <div 
            key={date} 
            className="flex items-center justify-between text-sm text-gray-600"
          >
            <div className="flex items-center gap-2">
              <Calendar className="h-4 w-4 text-indigo-400" />
              {format(parseISO(date), 'MMMM d, yyyy')}
            </div>
            <button
              onClick={() => !disabled && onDelete(date)}
              disabled={disabled}
              className={`
                p-1 rounded-full transition-colors
                ${disabled 
                  ? 'text-gray-400 cursor-not-allowed' 
                  : 'text-gray-400 hover:text-red-500 hover:bg-gray-100'
                }
              `}
              title="Remove skip day"
            >
              <X className="h-4 w-4" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}