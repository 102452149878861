export interface Quote {
  text: string;
  author: string;
}

export const quotes: Quote[] = [
  {
    text: "Education is not the filling of a pail, but the lighting of a fire.",
    author: "William Butler Yeats"
  },
  {
    text: "A teacher affects eternity; they can never tell where their influence stops.",
    author: "Henry Adams"
  },
  {
    text: "The art of teaching is the art of assisting discovery.",
    author: "Mark Van Doren"
  },
  {
    text: "It is the supreme art of the teacher to awaken joy in creative expression and knowledge.",
    author: "Albert Einstein"
  },
  {
    text: "Teaching kids to count is fine, but teaching them what counts is best.",
    author: "Bob Talbert"
  },
  {
    text: "Just combine 'Ninja' and 'Ed' into one smooth word: Nin-Judd.",
    author: "The NinjaED Team"
  },
  {
    text: "Ninjaed - verb: past tense of Ninja. 'I just ninjaed that!'",
    author: "The NinjaED Team"
  }
];

export function getDailyQuote(): Quote {
  // Get the current date in YYYY-MM-DD format to ensure it changes only once per day
  const today = new Date().toISOString().split('T')[0];
  
  // Create a simple hash of the date string
  const hash = today.split('').reduce((acc, char) => {
    return acc + char.charCodeAt(0);
  }, 0);
  
  // Use the hash to select a quote
  const index = hash % quotes.length;
  
  return quotes[index];
}