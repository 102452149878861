import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';
import type { Student } from '../../types/student';
import type { BehaviorIncident } from '../../types/behavior';
import { format } from 'date-fns';

interface BehaviorIncidentModalProps {
  students: Student[];
  onClose: () => void;
  onSubmit: (incident: Omit<BehaviorIncident, 'id' | 'userId' | 'createdAt' | 'lastModified'>) => Promise<void>;
  editingIncident?: BehaviorIncident;
}

export default function BehaviorIncidentModal({ 
  students, 
  onClose, 
  onSubmit,
  editingIncident 
}: BehaviorIncidentModalProps) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  
  // Initialize form data with default values or editing values
  const [formData, setFormData] = useState(() => {
    if (editingIncident) {
      return {
        date: editingIncident.date,
        time: editingIncident.time,
        location: editingIncident.location,
        description: editingIncident.description,
        antecedent: editingIncident.antecedent,
        behavior: editingIncident.behavior,
        consequence: editingIncident.consequence,
        interventionTried: editingIncident.interventionTried,
        nextSteps: editingIncident.nextSteps,
        witnesses: editingIncident.witnesses?.join(', ') || '',
        severity: editingIncident.severity,
        status: editingIncident.status
      };
    }
    return {
      date: format(new Date(), 'yyyy-MM-dd'),
      time: format(new Date(), 'HH:mm'),
      location: '',
      description: '',
      antecedent: '',
      behavior: '',
      consequence: '',
      interventionTried: '',
      nextSteps: '',
      witnesses: '',
      severity: 'minor' as BehaviorIncident['severity'],
      status: 'open' as BehaviorIncident['status']
    };
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      await onSubmit({
        ...formData,
        studentIds: editingIncident ? editingIncident.studentIds : students.map(s => s.id),
        witnesses: formData.witnesses.split(',').map(w => w.trim()).filter(Boolean)
      });
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save incident report');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-xl font-semibold text-gray-900">
                {editingIncident ? 'Edit Behavior Incident' : 'Create Behavior Incident'}
              </h2>
              <p className="text-sm text-gray-500 mt-1">
                {editingIncident 
                  ? 'Update the behavior incident details'
                  : `Document a behavior incident for ${students.length} student${students.length !== 1 ? 's' : ''}`
                }
              </p>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="p-6 overflow-y-auto max-h-[calc(90vh-129px)]">
          {error && (
            <div className="mb-6 p-4 bg-red-50 rounded-lg">
              <p className="text-sm text-red-600">{error}</p>
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                  Date
                </label>
                <input
                  type="date"
                  id="date"
                  value={formData.date}
                  onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  required
                />
              </div>
              <div>
                <label htmlFor="time" className="block text-sm font-medium text-gray-700">
                  Time
                </label>
                <input
                  type="time"
                  id="time"
                  value={formData.time}
                  onChange={(e) => setFormData({ ...formData, time: e.target.value })}
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  required
                />
              </div>
            </div>

            <div>
              <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                Location
              </label>
              <input
                type="text"
                id="location"
                value={formData.location}
                onChange={(e) => setFormData({ ...formData, location: e.target.value })}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>

            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <textarea
                id="description"
                value={formData.description}
                onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                rows={3}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>

            <div>
              <label htmlFor="antecedent" className="block text-sm font-medium text-gray-700">
                Antecedent (What happened before?)
              </label>
              <textarea
                id="antecedent"
                value={formData.antecedent}
                onChange={(e) => setFormData({ ...formData, antecedent: e.target.value })}
                rows={2}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>

            <div>
              <label htmlFor="behavior" className="block text-sm font-medium text-gray-700">
                Behavior (What happened?)
              </label>
              <textarea
                id="behavior"
                value={formData.behavior}
                onChange={(e) => setFormData({ ...formData, behavior: e.target.value })}
                rows={2}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>

            <div>
              <label htmlFor="consequence" className="block text-sm font-medium text-gray-700">
                Consequence (What happened after?)
              </label>
              <textarea
                id="consequence"
                value={formData.consequence}
                onChange={(e) => setFormData({ ...formData, consequence: e.target.value })}
                rows={2}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>

            <div>
              <label htmlFor="interventionTried" className="block text-sm font-medium text-gray-700">
                Intervention(s) Tried
              </label>
              <textarea
                id="interventionTried"
                value={formData.interventionTried}
                onChange={(e) => setFormData({ ...formData, interventionTried: e.target.value })}
                rows={2}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>

            <div>
              <label htmlFor="nextSteps" className="block text-sm font-medium text-gray-700">
                Next Steps
              </label>
              <textarea
                id="nextSteps"
                value={formData.nextSteps}
                onChange={(e) => setFormData({ ...formData, nextSteps: e.target.value })}
                rows={2}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>

            <div>
              <label htmlFor="witnesses" className="block text-sm font-medium text-gray-700">
                Witnesses (comma-separated)
              </label>
              <input
                type="text"
                id="witnesses"
                value={formData.witnesses}
                onChange={(e) => setFormData({ ...formData, witnesses: e.target.value })}
                className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                placeholder="e.g., John Smith, Jane Doe"
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="severity" className="block text-sm font-medium text-gray-700">
                  Severity
                </label>
                <select
                  id="severity"
                  value={formData.severity}
                  onChange={(e) => setFormData({ ...formData, severity: e.target.value as BehaviorIncident['severity'] })}
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  <option value="minor">Minor</option>
                  <option value="moderate">Moderate</option>
                  <option value="major">Major</option>
                </select>
              </div>
              <div>
                <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                  Status
                </label>
                <select
                  id="status"
                  value={formData.status}
                  onChange={(e) => setFormData({ ...formData, status: e.target.value as BehaviorIncident['status'] })}
                  className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  <option value="open">Open</option>
                  <option value="resolved">Resolved</option>
                </select>
              </div>
            </div>

            <div className="flex justify-end gap-3">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
              >
                {isSubmitting 
                  ? (editingIncident ? 'Updating...' : 'Creating...') 
                  : (editingIncident ? 'Update Incident' : 'Create Incident Report')
                }
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}