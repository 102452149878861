import React from 'react';
import { Calendar, Clock, AlertTriangle, User, Cpu } from 'lucide-react';

export type FilterType = 'all' | 'today' | 'overdue' | 'upcoming';
export type SourceType = 'all' | 'mine' | 'system';

interface TaskFiltersProps {
  activeFilter: FilterType;
  activeSource: SourceType;
  onFilterChange: (filter: FilterType) => void;
  onSourceChange: (source: SourceType) => void;
  counts: {
    today: number;
    overdue: number;
    upcoming: number;
    mine: number;
    system: number;
  };
}

export default function TaskFilters({ 
  activeFilter, 
  activeSource,
  onFilterChange, 
  onSourceChange,
  counts 
}: TaskFiltersProps) {
  return (
    <div className="space-y-4">
      <div className="flex flex-wrap gap-2">
        <button
          onClick={() => onFilterChange('all')}
          className={`flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm ${
            activeFilter === 'all'
              ? 'bg-gray-100 text-gray-900'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
        >
          All
        </button>
        <button
          onClick={() => onFilterChange('today')}
          className={`flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm ${
            activeFilter === 'today'
              ? 'bg-blue-100 text-blue-900'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
        >
          <Calendar className="h-4 w-4" />
          Due Today
          {counts.today > 0 && (
            <span className="ml-1 px-1.5 py-0.5 text-xs bg-blue-200 text-blue-800 rounded-full">
              {counts.today}
            </span>
          )}
        </button>
        <button
          onClick={() => onFilterChange('overdue')}
          className={`flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm ${
            activeFilter === 'overdue'
              ? 'bg-red-100 text-red-900'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
        >
          <AlertTriangle className="h-4 w-4" />
          Overdue
          {counts.overdue > 0 && (
            <span className="ml-1 px-1.5 py-0.5 text-xs bg-red-200 text-red-800 rounded-full">
              {counts.overdue}
            </span>
          )}
        </button>
        <button
          onClick={() => onFilterChange('upcoming')}
          className={`flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm ${
            activeFilter === 'upcoming'
              ? 'bg-green-100 text-green-900'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
        >
          <Clock className="h-4 w-4" />
          Upcoming
          {counts.upcoming > 0 && (
            <span className="ml-1 px-1.5 py-0.5 text-xs bg-green-200 text-green-800 rounded-full">
              {counts.upcoming}
            </span>
          )}
        </button>
      </div>

      <div className="flex flex-wrap gap-2 border-t pt-4">
        <button
          onClick={() => onSourceChange('all')}
          className={`flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm ${
            activeSource === 'all'
              ? 'bg-gray-100 text-gray-900'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
        >
          All Sources
        </button>
        <button
          onClick={() => onSourceChange('mine')}
          className={`flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm ${
            activeSource === 'mine'
              ? 'bg-purple-100 text-purple-900'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
        >
          <User className="h-4 w-4" />
          Mine
          {counts.mine > 0 && (
            <span className="ml-1 px-1.5 py-0.5 text-xs bg-purple-200 text-purple-800 rounded-full">
              {counts.mine}
            </span>
          )}
        </button>
        <button
          onClick={() => onSourceChange('system')}
          className={`flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm ${
            activeSource === 'system'
              ? 'bg-orange-100 text-orange-900'
              : 'text-gray-600 hover:bg-gray-50'
          }`}
        >
          <Cpu className="h-4 w-4" />
          System
          {counts.system > 0 && (
            <span className="ml-1 px-1.5 py-0.5 text-xs bg-orange-200 text-orange-800 rounded-full">
              {counts.system}
            </span>
          )}
        </button>
      </div>
    </div>
  );
}