import React, { useState } from 'react';
import { Calendar, Download } from 'lucide-react';
import { format, startOfMonth, endOfMonth, parseISO } from 'date-fns';
import { useStudents } from '../../contexts/StudentContext';
import { useAttendance } from '../../contexts/AttendanceContext';
import AttendanceReport from '../../components/Students/AttendanceReport';
import { exportAttendanceReport } from '../../services/attendance';

export default function Attendance() {
  const { state: studentState } = useStudents();
  const [selectedMonth, setSelectedMonth] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [isExporting, setIsExporting] = useState(false);

  const handleExport = async () => {
    try {
      setIsExporting(true);
      const monthDate = parseISO(selectedMonth);
      const startDate = format(startOfMonth(monthDate), 'yyyy-MM-dd');
      const endDate = format(endOfMonth(monthDate), 'yyyy-MM-dd');
      
      const blob = await exportAttendanceReport(
        localStorage.getItem('userId') || '',
        startDate,
        endDate
      );

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `attendance-report-${format(parseISO(selectedMonth), 'yyyy-MM')}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to export attendance report:', error);
    } finally {
      setIsExporting(false);
    }
  };

return (
  <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
    
    <div className="mb-8">
      <div className="flex items-center justify-between">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Attendance</h1>
          <p className="text-gray-500">Track and manage student attendance</p>
        </div>
      </div>
    </div>
    <AttendanceReport 
      students={studentState.students}
      initialDate={parseISO(selectedMonth)}
    />
  </div>
  );
}


    {/* Comment out this entire section
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="p-4 bg-gray-50 rounded-lg">
            <div className="flex items-center gap-3">
              <Calendar className="h-5 w-5 text-indigo-600" />
              <div>
                <p className="text-sm text-gray-500">Today's Attendance</p>
                <p className="text-2xl font-semibold text-gray-900">
                  {studentState.students.length} Students
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    */}