import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Eye } from 'lucide-react';
import { usePlanRequests } from '../../contexts/PlanRequestContext';
import { useAlerts } from '../../contexts/AlertContext';
import { format, parseISO, isValid } from 'date-fns';

const formatDate = (dateString: string | number | Date | null | undefined): string => {
  if (!dateString) return 'Unknown date';
  
  try {
    const date = typeof dateString === 'number' 
      ? new Date(dateString) 
      : typeof dateString === 'string' 
        ? parseISO(dateString)
        : dateString;

    if (!isValid(date)) {
      return 'Invalid date';
    }

    return format(date, 'MMM d, yyyy h:mm a');
  } catch (error) {
    return 'Invalid date';
  }
};

export default function HiddenPlans() {
  const navigate = useNavigate();
  const { state: { hiddenRequests }, unhidePlan, loadPlans } = usePlanRequests();
  const { addAlert } = useAlerts();

  // Load plans when component mounts
  React.useEffect(() => {
    loadPlans();
  }, []);

  const handleUnhide = async (requestId: string) => {
    try {
      await unhidePlan(requestId);
      await loadPlans(); // Reload plans after unhiding
      addAlert({
        type: 'info',
        message: 'Plan restored to active plans',
        date: new Date().toISOString(),
      });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An error occurred';
      console.error('Error unhiding plan:', err);
      addAlert({
        type: 'error',
        message: `Failed to restore plan: ${errorMessage}`,
        date: new Date().toISOString(),
      });
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <button
        onClick={() => navigate('/planning/full-year')}
        className="flex items-center gap-2 text-gray-600 hover:text-gray-900 mb-6"
      >
        <ArrowLeft className="h-4 w-4" />
        Back to Year-Long Plan
      </button>

      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Hidden Plans</h1>
        <p className="text-gray-500">View and restore your hidden curriculum plans</p>
      </div>

      {hiddenRequests.length === 0 ? (
        <div className="bg-white rounded-lg shadow-sm p-8 text-center">
          <p className="text-gray-500">No hidden plans found</p>
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow-sm divide-y divide-gray-200">
          {hiddenRequests.map((request) => (
            <div
              key={request.id}
              className="p-4 flex items-center justify-between hover:bg-gray-50"
            >
              <div>
                <h3 className="text-sm font-medium text-gray-900">
                  {request.input || 'Untitled Plan'}
                </h3>
                <p className="text-xs text-gray-500 mt-1">
                  Hidden on {formatDate(request.timestamp)}
                </p>
              </div>
              <button
                onClick={() => handleUnhide(request.id)}
                className="flex items-center gap-2 px-3 py-2 text-sm font-medium text-indigo-600 hover:text-indigo-800 rounded-lg hover:bg-indigo-50"
              >
                <Eye className="h-4 w-4" />
                Restore Plan
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}