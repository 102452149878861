import React from 'react';
import { Pause } from 'lucide-react';

interface SkipDayButtonProps {
  date: string;
  isSkipDay: boolean;
  onToggle: () => void;
  disabled?: boolean;
  impact?: {
    daysExtended: number;
    newEndDate: string;
  };
}

export default function SkipDayButton({ 
  date, 
  isSkipDay, 
  onToggle, 
  disabled,
  impact 
}: SkipDayButtonProps) {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (!disabled) {
      onToggle();
    }
  };

  return (
    <button
      onClick={handleClick}
      disabled={disabled}
      className={`
        flex items-center gap-2 px-3 py-1.5 rounded-lg text-sm font-medium
        transition-colors
        ${isSkipDay
          ? 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200'
          : 'text-gray-700 hover:bg-gray-100'
        }
        ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}
      `}
      title={isSkipDay ? 'Remove skip day' : 'Add skip day'}
    >
      <Pause className="h-4 w-4" />
      <span>
        {isSkipDay ? 'Skip Day' : 'Add Skip'}
      </span>
      {impact && !isSkipDay && (
        <span className="text-xs text-gray-500">
          (+{impact.daysExtended} days)
        </span>
      )}
    </button>
  );
}