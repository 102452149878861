import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import AlertsWidget from '../components/Dashboard/AlertsWidget';
import TasksWidget from '../components/Dashboard/TasksWidget';
import { useAuth } from '../contexts/AuthContext';

export default function Dashboard() {
  const [searchParams] = useSearchParams();
  const { state: authState } = useAuth();
  const userName = authState.user?.attributes?.name?.split(' ')[0] || 'Teacher';

  useEffect(() => {
    const sessionId = searchParams.get('session_id');
    
    if (sessionId) {
      const checkSession = async () => {
        try {
          const response = await fetch(`${import.meta.env.VITE_API_URL}/verify-session?session_id=${sessionId}`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Content-Type': 'application/json'
            }
          });
          
          if (response.ok) {
            const data = await response.json();
            console.log('Subscription verified:', data);
          } else {
            console.error('Failed to verify subscription');
          }
        } catch (error) {
          console.error('Error verifying session:', error);
        }
      };
      
      checkSession();
    }
  }, [searchParams]);

  return (
    <div className="space-y-6">
      <div>
        <h1 className="text-2xl font-bold text-gray-900">Dashboard</h1>
        <p className="text-gray-500">Welcome back, {userName}! Here's your overview.</p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <AlertsWidget />
        <TasksWidget />
      </div>
    </div>
  );
}