import { Alert } from '../types';

const API_ENDPOINT = import.meta.env.VITE_API_DATATABLEDYNAMO_ENDPOINT;

export class AlertsError extends Error {
  constructor(message: string, public statusCode?: number) {
    super(message);
    this.name = 'AlertsError';
  }
}

export async function getAlerts(userId: string): Promise<Alert[]> {
  try {
    const response = await fetch(`${API_ENDPOINT}/alerts?userId=${userId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      credentials: 'omit'  // Add this to ensure we're not sending credentials automatically
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const data = await response.json();
    return data.Items || [];
  } catch (error) {
    console.error('Error fetching alerts:', error);
    throw new AlertsError('Failed to fetch alerts');
  }
}

export async function createAlert(userId: string, alert: Omit<Alert, 'id'>): Promise<Alert> {
  try {
    const response = await fetch(`${API_ENDPOINT}/alerts`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        userId,
        ...alert,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.Item;
  } catch (error) {
    console.error('Error creating alert:', error);
    throw new AlertsError('Failed to create alert');
  }
}

export async function deleteAlert(userId: string, alertId: string): Promise<void> {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/alerts?userId=${userId}&alertId=${alertId}`,
      {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error deleting alert:', error);
    throw new AlertsError('Failed to delete alert');
  }
}