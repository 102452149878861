import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useSubscription } from '../../contexts/SubscriptionContext';
import { CreditCard, School, MapPin, Mail, User, AlertCircle, Pencil, Camera } from 'lucide-react';
import { format } from 'date-fns';
import UpgradeModal from '../../components/Subscription/UpgradeModal';
import EditProfileModal from '../../components/Account/EditProfileModal';
import ProfilePictureUpload from '../../components/Account/ProfilePictureUpload';
import { getProfilePictureUrl } from '../../utils/imageUtils';
import { fetchAuthSession } from 'aws-amplify/auth';

export default function Account() {
  const { state: authState } = useAuth();
  const { state: subscriptionState, openCustomerPortal, clearError } = useSubscription();
  const { subscription, plans, error, isLoading } = subscriptionState;
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [showProfilePictureModal, setShowProfilePictureModal] = useState(false);
  const [profilePicUrl, setProfilePicUrl] = useState<string | null>(null);

  console.log('Auth State:', authState);
  console.log('Auth User:', authState.user);
  console.log('User ID:', authState.user?.sub || authState.user?.id || authState.user?.username);
  console.log('Subscription State:', subscriptionState);


  

  const [tokenUsageData, setTokenUsageData] = useState<{
    tokens: number;
    inputTokens: number;
    outputTokens: number;
    model: string | null;
    lastUpdated: string | null;
    planLimit: number;
    currentPeriod: string;
  } | null>(null);

  // Add fetch function
  const fetchTokenUsage = async () => {
  try {
    const { tokens } = await fetchAuthSession();
    const idToken = tokens.idToken.toString();
    const userId = authState.user?.sub || authState.user?.id || authState.user?.username;

    console.log('Making token usage request with userId:', userId);
    const url = `${import.meta.env.VITE_API_DATATABLEDYNAMO_ENDPOINT}/tokenUsage?userId=${userId}`;
    console.log('Request URL:', url);

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${idToken}`
      }
    });

    if (!response.ok) {
      console.error('Response not OK:', response.status, response.statusText);
      const errorText = await response.text();
      console.error('Error response:', errorText);
      throw new Error('Failed to fetch token usage');
    }

    const data = await response.json();
    console.log('Token usage API response:', data);
    setTokenUsageData(data);
    return data;
  } catch (error) {
    console.error('Error in fetchTokenUsage:', error);
  }
};

  // Add useEffect to fetch token usage
  useEffect(() => {
    if (authState.user) {
      console.log('Fetching token usage...');
      fetchTokenUsage()
        .then(data => {
          console.log('Token usage data received:', data);
          setTokenUsageData(data);
        });
    }
  }, [authState.user]);



  console.log('Subscription details:', subscription);
  console.log('Available plans:', plans);
  console.log('Subscription priceId:', subscription?.priceId);

  console.log('Plan structure:', plans[0]); // Log the full structure of a plan object
  console.log('Plans with planId:', plans.map(p => ({ name: p.name, planId: p.planId })));
  
  const currentPlan = subscription ? plans.find(p => p.id === subscription.priceId) : null;

    // Update usage percentage calculation
  const usagePercentage = currentPlan && tokenUsageData && typeof tokenUsageData.tokens === 'number'
      ? !currentPlan.tokenLimit
        ? 0
        : (tokenUsageData.tokens / currentPlan.tokenLimit) * 100 
      : 0;

  console.log('Current plan:', currentPlan);
  console.log('Token usage data:', tokenUsageData);

  useEffect(() => {
    const refreshProfilePicture = async () => {
      if (authState.user?.attributes?.picture) {
        try {
          const url = await getProfilePictureUrl(authState.user.attributes.picture);
          if (url) {
            console.log('Account page - Refreshed profile URL:', url);
            setProfilePicUrl(url);
          }
        } catch (error) {
          console.error('Account page - Failed to refresh profile picture:', error);
        }
      }
    };

    refreshProfilePicture();
    const interval = setInterval(refreshProfilePicture, 30 * 60 * 1000);
    
    return () => clearInterval(interval);
  }, [authState.user?.attributes?.picture]);

  const handleManageSubscription = async () => {
    if (!subscription) {
      setShowUpgradeModal(true);
      return;
    }

    try {
      await openCustomerPortal();
    } catch (error) {
      console.error('Portal error:', error);
    }
  };


  // Get user profile data from Cognito attributes
  const userProfile = authState.user?.attributes?.profile 
    ? JSON.parse(authState.user.attributes.profile)
    : { schoolName: 'Not set', zipCode: 'Not set' };
  
  
  return (
    <div className="max-w-4xl mx-auto">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Account Settings</h1>
        <p className="text-gray-500">Manage your account and subscription</p>
      </div>

      {error && (
        <div className="mb-6 rounded-md bg-red-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3 flex-1">
              <p className="text-sm font-medium text-red-800">{error}</p>
            </div>
            <div className="ml-auto pl-3">
              <button
                onClick={clearError}
                className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100"
              >
                <span className="sr-only">Dismiss</span>
                <AlertCircle className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="space-y-6">
        {/* Profile Information */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-semibold text-gray-900">Profile Information</h2>
            <button
              onClick={() => setShowEditProfileModal(true)}
              className="flex items-center gap-2 px-3 py-2 text-sm font-medium text-indigo-600 hover:text-indigo-800 rounded-lg hover:bg-indigo-50"
            >
              <Pencil className="h-4 w-4" />
              Edit Profile
            </button>
          </div>

          <div className="flex items-start gap-6">
            <div className="relative group">
              <div className="w-24 h-24 rounded-full bg-gray-100 flex items-center justify-center overflow-hidden">
                {authState.user?.attributes?.picture ? (
                  <img 
                    src={profilePicUrl || authState.user.attributes.picture} 
                    alt="Profile" 
                    className="w-full h-full object-cover"
                    onError={(e) => {
                      console.log('Account page - Image load failed, attempting refresh');
                      const target = e.target as HTMLImageElement;
                      target.onerror = null; // Prevent infinite loop
                      if (authState.user?.attributes?.picture) {
                        getProfilePictureUrl(authState.user.attributes.picture)
                          .then(url => {
                            if (url) {
                              setProfilePicUrl(url);
                              target.src = url;
                            }
                          });
                      }
                    }}
                  />
                ) : (
                  <User className="h-12 w-12 text-gray-400" />
                )}
              </div>
              <button
                onClick={() => setShowProfilePictureModal(true)}
                className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
              >
                <Camera className="h-6 w-6 text-white" />
              </button>
            </div>


            <div className="flex-1 grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="flex items-center gap-3">
                <div className="p-2 bg-gray-100 rounded-lg">
                  <User className="h-5 w-5 text-gray-600" />
                </div>
                <div>
                  <p className="text-sm text-gray-500">Name</p>
                  <p className="font-medium text-gray-900">{authState.user?.attributes?.name}</p>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div className="p-2 bg-gray-100 rounded-lg">
                  <Mail className="h-5 w-5 text-gray-600" />
                </div>
                <div>
                  <p className="text-sm text-gray-500">Email</p>
                  <p className="font-medium text-gray-900">{authState.user?.attributes?.email}</p>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div className="p-2 bg-gray-100 rounded-lg">
                  <School className="h-5 w-5 text-gray-600" />
                </div>
                <div>
                  <p className="text-sm text-gray-500">School</p>
                  <p className="font-medium text-gray-900">{userProfile.schoolName}</p>
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div className="p-2 bg-gray-100 rounded-lg">
                  <MapPin className="h-5 w-5 text-gray-600" />
                </div>
                <div>
                  <p className="text-sm text-gray-500">ZIP Code</p>
                  <p className="font-medium text-gray-900">{userProfile.zipCode}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Subscription Information */}
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex items-center justify-between mb-6">
            <div>
              <h2 className="text-lg font-semibold text-gray-900">Subscription</h2>
              {isLoading ? (
                <p className="text-gray-500">Loading subscription details...</p>
              ) : currentPlan ? (
                <p className="text-gray-500">
                  You're currently on the {currentPlan.name} plan
                </p>
              ) : (
                <p className="text-gray-500">No active subscription</p>
              )}
            </div>
            <button
        onClick={handleManageSubscription}
        disabled={isLoading}
        className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
      >
        <CreditCard className="h-4 w-4" />
        {isLoading ? 'Loading...' : 'Manage or Upgrade Plan'}
      </button>
                </div>

          {currentPlan && subscription && (
  <>
    <div className="border-t border-b border-gray-200 py-4 mb-4">
      <div className="flex items-center justify-between mb-2">
        <span className="text-sm text-gray-600">Ninja Star Usage This Month</span>
          <span className="text-sm font-medium text-gray-900">
        {tokenUsageData && !currentPlan?.tokenLimit ? (
          `${(tokenUsageData.tokens / 1000).toLocaleString()} / Unlimited ninja stars`
        ) : tokenUsageData && typeof tokenUsageData.tokens === 'number' ? (
          `${(tokenUsageData.tokens / 1000).toLocaleString()} / ${(currentPlan?.tokenLimit / 1000).toLocaleString() || 0} ninja stars`
        ) : (
          'Loading...'
        )}
</span>
      </div>
      <div className="w-full bg-gray-200 rounded-full h-2">
  {!currentPlan?.tokenLimit ? (
    <div className="h-2 rounded-full bg-green-500" style={{ width: '100%', opacity: '0.5' }} />
  ) : (
    <div
      className={`h-2 rounded-full ${
        usagePercentage > 90 ? 'bg-red-500' :
        usagePercentage > 75 ? 'bg-yellow-500' :
        'bg-green-500'
      }`}
      style={{ width: `${Math.min(usagePercentage, 100)}%` }}
    />
  )}
</div>
      {usagePercentage > 75 && currentPlan?.tokenLimit && (
        <p className="text-sm text-yellow-600 mt-2">
          You're approaching your monthly token limit. Click "Manage or Upgrade Plan" above to upgrade to a higher tier plan with more ninja stars.
        </p>
      )}
      {tokenUsageData?.lastUpdated && (
        <p className="text-xs text-gray-500 mt-2">
          Last updated: {format(new Date(tokenUsageData.lastUpdated), 'MMM d, yyyy h:mm a')}
        </p>
      )}
    </div>

    <div className="space-y-3 text-sm">
    
      {/* Keep existing subscription details */}
      <div className="flex justify-between">
        <span className="text-gray-600">Billing Period</span>
        <span className="text-gray-900">
          {format(new Date(), 'MMMM d, yyyy')} - {' '}
          {format(new Date(subscription.currentPeriodEnd), 'MMMM d, yyyy')}
        </span>
      </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">Plan</span>
                  <span className="text-gray-900">
                    {currentPlan.name}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600">Status</span>
                  <span className={`capitalize ${
                    subscription.status === 'active' ? 'text-green-600' :
                    subscription.status === 'past_due' ? 'text-red-600' :
                    'text-gray-900'
                  }`}>
                    {subscription.status}
                  </span>
                </div>
                {subscription.cancelAtPeriodEnd && (
                  <div className="bg-yellow-50 border border-yellow-100 rounded-lg p-3 mt-4">
                    <p className="text-yellow-800">
                      Your subscription will end on {format(new Date(subscription.currentPeriodEnd), 'MMMM d, yyyy')}.
                      You can reactivate your subscription from the customer portal.
                    </p>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {showUpgradeModal && (
        <UpgradeModal onClose={() => setShowUpgradeModal(false)} />
      )}

      {showEditProfileModal && (
        <EditProfileModal
          onClose={() => setShowEditProfileModal(false)}
          currentName={authState.user?.attributes?.name || ''}
          currentSchool={userProfile.schoolName}
          currentZipCode={userProfile.zipCode}
        />
      )}

      {showProfilePictureModal && (
        <ProfilePictureUpload
          currentPicture={authState.user?.attributes?.picture}
          onClose={() => setShowProfilePictureModal(false)}
        />
      )}
    </div>
  );
}