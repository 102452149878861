import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import type { SavedUnit } from '../../types';
import { useLessons } from '../../contexts/LessonContext';
import { useAlerts } from '../../contexts/AlertContext';
import { fetchAuthSession } from 'aws-amplify/auth';

interface NinjaLessonsModalProps {
  unit: SavedUnit;
  onClose: () => void;
}

interface LessonResponse {
  title: string;
  description: string;
  standards: string[];
  learningTargets: string[];
  resources?: string;
  activities?: string[];
  duration?: string;
  objectives?: string[];
  assessment?: string;
}

interface WebhookResponse {
  success: boolean;
  lessons: LessonResponse[];
  message?: string;
  remainingTokens?: number;
}

export default function NinjaLessonsModal({ unit, onClose }: NinjaLessonsModalProps) {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { addLesson, loadLessons, state: lessonState } = useLessons();
  const { addAlert } = useAlerts();
  const navigate = useNavigate();

  const handleGenerate = async () => {
    setIsGenerating(true);
    setError(null);

    try {
      await loadLessons(unit.id);
      const existingLessons = lessonState.lessons[unit.id] || [];

      const maxScheduledDate = existingLessons.reduce((latest, lesson) => {
        const lessonDate = lesson.date ? new Date(lesson.date) : null;
        return lessonDate && (!latest || lessonDate > latest) ? lessonDate : latest;
      }, null);

      const maxOrder = existingLessons.reduce((max, lesson) => 
        Math.max(max, lesson.order || 0), 
        -1
      );

      // Get auth token
      const { tokens } = await fetchAuthSession();
      const idToken = tokens.idToken.toString();

      const response = await fetch('https://mm7vbxs5o2ukkhtmvbb7p2tsk40oplcg.lambda-url.us-east-2.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({
          unit: {
            title: unit.unitName,
            subject: unit.subject,
            standards: unit.standardsCovered.map((s) => ({
              notation: s.standard,
              description: s.description,
              duration: s.duration,
            })),
            learningTargets: unit.learningTargets,
            duration: unit.duration,
          },
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || `Failed to generate lessons: ${response.statusText}`);
      }

      const data: WebhookResponse = await response.json();

      if (!data.success || !Array.isArray(data.lessons)) {
        throw new Error(data.message || 'Invalid response format from lesson generation service');
      }

      let successCount = 0;
      const errors: string[] = [];

      let currentDate = maxScheduledDate
        ? new Date(maxScheduledDate.getTime() + 86400000)
        : unit.scheduledStart
        ? new Date(unit.scheduledStart)
        : new Date(2024, 0, 1);

      const isUnitUnscheduled = !unit.scheduledStart;

      for (let i = 0; i < data.lessons.length; i++) {
        const lesson = data.lessons[i];

        try {
          if (!lesson.title || !Array.isArray(lesson.standards) || !Array.isArray(lesson.learningTargets)) {
            console.error('Invalid lesson format:', lesson);
            errors.push(`Lesson ${i + 1}: Missing required fields`);
            continue;
          }

          if (!isUnitUnscheduled) {
            while (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
              currentDate.setDate(currentDate.getDate() + 1);
            }
          }

          const lessonDate = isUnitUnscheduled 
            ? '2024-01-01'
            : currentDate.toISOString().split('T')[0];

          await addLesson(unit.id, {
            title: lesson.title,
            description: `${lesson.description}\n
                        Activities:
                        ${lesson.activities?.join('\n') || 'None provided'}\n
                        Duration:
                        ${lesson.duration || 'Not specified'}\n
                        Objectives:
                        ${lesson.objectives?.join('\n') || 'None provided'}\n
                        Assessment:
                        ${lesson.assessment || 'None provided'}\n
                        Notes:
                        ${lesson.notes || 'None provided'}\n
                        Materials:
                        ${lesson.materials || 'None provided'}`,
            standards: lesson.standards,
            learningTargets: lesson.learningTargets,
            resources: lesson.resources || '',
            date: lessonDate,
            order: maxOrder + i + 1,
            unitId: unit.id,
            activities: [],
            duration: '',
            assessments: [],
            materials: [],
            notes: '',
          });

          successCount++;
          if (!isUnitUnscheduled) {
            currentDate.setDate(currentDate.getDate() + 1);
          }
        } catch (err) {
          console.error(`Error creating lesson ${i + 1}:`, err);
          errors.push(`Lesson ${i + 1}: ${err instanceof Error ? err.message : 'Unknown error'}`);
        }
      }

      addAlert({
        type: successCount > 0 ? 'info' : 'warning',
        message: `Generated ${successCount} lessons for ${unit.unitName}${errors.length > 0 ? ` (${errors.length} failed)` : ''}`,
        date: new Date().toISOString(),
        metadata: {
          type: 'plan_ready',
          path: `/planning/lessons/${unit.id}`,
        },
      });

      if (successCount > 0) {
        onClose();
        navigate(`/planning/lessons/${unit.id}`);
      } else {
        throw new Error('Failed to create any lessons');
      }
    } catch (error) {
      console.error('Error generating lessons:', error);
      setError(error instanceof Error ? error.message : 'Failed to generate lessons');
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-md w-full p-6">
        <div className="text-center">
          <div className="mx-auto w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center mb-4">
            <span className="text-2xl">⚔️</span>
          </div>
          <h2 className="text-xl font-semibold text-gray-900 mb-2">Generate Lessons</h2>
          <p className="text-gray-500 mb-6">
            Ready to Ninja It? ⚔️ Let us take some work off your plate & create detailed lesson plans for "{unit.unitName}" based on your standards and learning targets.
          </p>

          {error && (
            <div className="mb-6 p-4 bg-red-50 rounded-lg">
              <p className="text-sm text-red-600">{error}</p>
            </div>
          )}

          <div className="flex justify-end gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={handleGenerate}
              disabled={isGenerating}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
            >
              {isGenerating ? (
                <>
                  <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent" />
                  Generating...
                </>
              ) : (
                <>
                  <span className="text-lg">⚔️</span>
                  Ninja It!
                </>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}