
import React from 'react';
import { useStudents } from '../../contexts/StudentContext';
import StudentCard from './StudentCard';

interface StudentListProps {
  selectedStudents: Set<string>;
  onSelectionChange: (selectedIds: Set<string>) => void;
}

export default function StudentList({ selectedStudents, onSelectionChange }: StudentListProps) {
  const { state } = useStudents();
  const { students, isLoading, error } = state;

  const handleSelectAll = () => {
    if (selectedStudents.size === students.length) {
      onSelectionChange(new Set());
    } else {
      onSelectionChange(new Set(students.map(s => s.id)));
    }
  };

  const handleStudentClick = (studentId: string, e: React.MouseEvent) => {
    // Don't trigger selection if clicking the view button
    if ((e.target as HTMLElement).closest('.edit-button')) {
      return;
    }

    // Handle Shift + Click
    if (e.shiftKey && selectedStudents.size > 0) {
      const lastSelected = Array.from(selectedStudents).pop();
      if (lastSelected) {
        const currentIndex = students.findIndex(s => s.id === studentId);
        const lastIndex = students.findIndex(s => s.id === lastSelected);
        const [start, end] = [currentIndex, lastIndex].sort((a, b) => a - b);
        const rangeIds = students
          .slice(start, end + 1)
          .map(student => student.id);
        
        const newSelection = new Set([...selectedStudents, ...rangeIds]);
        onSelectionChange(newSelection);
        return;
      }
    }

    // Handle Ctrl/Cmd + Click
    if (e.ctrlKey || e.metaKey) {
      const newSelection = new Set(selectedStudents);
      if (newSelection.has(studentId)) {
        newSelection.delete(studentId);
      } else {
        newSelection.add(studentId);
      }
      onSelectionChange(newSelection);
      return;
    }

    // Regular click - toggle selection
    const newSelection = new Set(selectedStudents);
    if (newSelection.has(studentId)) {
      newSelection.delete(studentId);
    } else {
      newSelection.add(studentId);
    }
    onSelectionChange(newSelection);
  };

  if (isLoading) {
    return (
      <div className="text-center py-12">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600 mx-auto"></div>
        <p className="mt-4 text-gray-500">Loading students...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12 bg-red-50 rounded-lg">
        <p className="text-red-600">{error}</p>
      </div>
    );
  }

  if (!students.length) {
    return (
      <div className="text-center py-12 bg-white rounded-lg shadow-sm">
        <p className="text-gray-500">No students added yet</p>
      </div>
    );
  }

  return (
    <div>
      <div className="mb-4">
        <label className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={selectedStudents.size === students.length}
            onChange={handleSelectAll}
            className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          />
          <span className="text-sm text-gray-700">
            {selectedStudents.size === students.length ? 'Deselect All' : 'Select All'}
          </span>
        </label>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {students.map((student) => (
          <StudentCard 
            key={student.id} 
            student={student}
            isSelected={selectedStudents.has(student.id)}
            onClick={(e) => handleStudentClick(student.id, e)}
          />
        ))}
      </div>
    </div>
  );
}
