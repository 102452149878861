import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Calendar, Archive, CalendarDays, BookOpen } from 'lucide-react';
import { useSavedUnits } from '../../contexts/SavedUnitsContext';
import { useCalendar } from '../../contexts/CalendarContext';
import { useLessons } from '../../contexts/LessonContext';
import { useAuth } from '../../contexts/AuthContext';
import { subjectColors } from '../../utils/constants';

const planningTools = [
  {
    title: 'Year-Long Plan',
    description: 'Generate a comprehensive year-long curriculum plan based on standards',
    icon: Calendar,
    path: '/planning/full-year',
  },
  {
    title: 'Unit Plans',
    description: 'Create and manage your curriculum units',
    icon: Archive,
    path: '/planning/saved-units',
  },
  {
    title: 'Calendar',
    description: 'Schedule and organize your units across the school year',
    icon: CalendarDays,
    path: '/planning/calendar',
  },
  {
    title: 'Lesson Planning',
    description: 'Create and manage daily lessons for your units',
    icon: BookOpen,
    path: '/planning/lessons',
  },
];

export default function Planning() {
  const { state: unitsState, refreshUnits } = useSavedUnits();
  const { state: calendarState, loadEvents } = useCalendar();
  const { state: lessonState } = useLessons();
  const { state: authState } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  // Load initial data
  useEffect(() => {
    const loadData = async () => {
      if (authState.user?.username) {
        try {
          await refreshUnits();
          
          // Create calendar events from scheduled units
          const scheduledUnits = unitsState.units.filter(unit => unit.scheduledStart && unit.scheduledEnd);
          const events = scheduledUnits.map(unit => ({
            id: unit.id,
            unitId: unit.id,
            title: `Unit ${unit.unitNumber}: ${unit.unitName}`,
            start: unit.scheduledStart!,
            end: unit.scheduledEnd!,
            duration: parseInt(unit.duration),
            backgroundColor: subjectColors[unit.subject]?.bg || subjectColors.Other.bg,
            textColor: subjectColors[unit.subject]?.text || subjectColors.Other.text,
            subject: unit.subject,
          }));
          
          loadEvents(events);
        } finally {
          setIsLoading(false);
        }
      }
    };
    loadData();
  }, [authState.user?.username]);

  // Calculate stats
  const scheduledUnits = calendarState.events;
  const totalLessons = Object.values(lessonState.lessons).reduce(
    (sum, unitLessons) => sum + unitLessons.length,
    0
  );

  if (isLoading) {
    return (
      <div className="max-w-4xl mx-auto">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900">Planning Tools</h1>
          <p className="text-gray-500">Loading your planning tools...</p>
        </div>
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Planning Tools</h1>
        <p className="text-gray-500">Select a planning tool to get started</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {planningTools.map(({ title, description, icon: Icon, path }) => (
          <Link
            key={title}
            to={path}
            className="block p-6 bg-white rounded-xl shadow-sm hover:shadow-md transition-shadow"
          >
            <div className="flex items-start gap-4">
              <div className="p-3 bg-indigo-50 rounded-lg">
                <Icon className="h-6 w-6 text-indigo-600" />
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
                  {title === 'Unit Plans' && unitsState.units.length > 0 && (
                    <span className="px-2 py-0.5 text-xs bg-indigo-100 text-indigo-800 rounded-full">
                      {unitsState.units.length} units
                    </span>
                  )}
                  {title === 'Calendar' && scheduledUnits.length > 0 && (
                    <span className="px-2 py-0.5 text-xs bg-indigo-100 text-indigo-800 rounded-full">
                      {scheduledUnits.length} scheduled
                    </span>
                  )}
                  {title === 'Lesson Planning' && totalLessons > 0 && (
                    <span className="px-2 py-0.5 text-xs bg-indigo-100 text-indigo-800 rounded-full">
                      {totalLessons} lessons
                    </span>
                  )}
                </div>
                <p className="mt-1 text-gray-500">{description}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}