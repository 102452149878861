import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Plus } from 'lucide-react';
import WeekView from './WeekView';
import DayDetails from './DayDetails';
import { startOfWeek, addWeeks, subWeeks, format, parseISO, isSameDay } from 'date-fns';
import { getNextBusinessWeek } from '../../utils/dateHelpers';
import { subjectColors } from '../../utils/constants';
import { useSubjects } from '../../contexts/SubjectsContext';
import { useLessons } from '../../contexts/LessonContext';
import CreateLessonModal from './CreateLessonModal';
import LessonDetailsModal from './LessonDetailsModal';

export default function Schedule() {
  const [currentWeek, setCurrentWeek] = useState(getNextBusinessWeek());
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [editingLesson, setEditingLesson] = useState<any>(null);
  const { state: subjectsState } = useSubjects();
  const { state: lessonState } = useLessons();

  const handlePreviousWeek = () => {
    setCurrentWeek(subWeeks(currentWeek, 1));
  };

  const handleNextWeek = () => {
    setCurrentWeek(addWeeks(currentWeek, 1));
  };

  // Get custom subject colors
  const customColorKeys = ['Custom1', 'Custom2', 'Custom3', 'Custom4', 'Custom5'];
  const customSubjectColors = subjectsState.customSubjects.reduce((acc, subject, index) => {
    acc[subject.name] = subjectColors[customColorKeys[index % customColorKeys.length]];
    return acc;
  }, {} as Record<string, { bg: string; text: string }>);

  // Combine all subjects and their colors
  const allSubjectsWithColors = [
    ...subjectsState.defaultSubjects.map(subject => ({
      name: subject,
      colors: subjectColors[subject] || subjectColors.Other
    })),
    ...subjectsState.customSubjects.map(subject => ({
      name: subject.name,
      colors: customSubjectColors[subject.name] || subjectColors.Other
    }))
  ];

  // Get lessons for selected date
  const selectedDateLessons = selectedDate
    ? Object.values(lessonState.lessons)
        .flat()
        .filter(lesson => isSameDay(parseISO(lesson.date), selectedDate))
    : [];

  return (
    <div className="max-w-7xl mx-auto px-4 pb-12">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Weekly Schedule</h1>
        <p className="text-gray-500">Manage and organize your weekly lessons</p>
      </div>

      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-4">
          <button
            onClick={handlePreviousWeek}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-900"
          >
            <ChevronLeft className="h-4 w-4" />
            Previous Week
          </button>
          <h2 className="text-lg font-semibold text-gray-900">
            Week of {format(startOfWeek(currentWeek, { weekStartsOn: 1 }), 'MMMM d, yyyy')}
          </h2>
          <button
            onClick={handleNextWeek}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-900"
          >
            Next Week
            <ChevronRight className="h-4 w-4" />
          </button>
        </div>
        <button
          onClick={() => setShowCreateModal(true)}
          className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
        >
          <Plus className="h-4 w-4" />
          Add Lesson
        </button>
      </div>

      <div className="flex justify-end mb-4">
        <div className="bg-white rounded-lg shadow-sm p-3 flex flex-wrap items-center gap-4">
          <span className="text-sm font-medium text-gray-700">Subjects:</span>
          {allSubjectsWithColors.map(({ name, colors }) => (
            <div key={name} className="flex items-center gap-2">
              <div className={`w-3 h-3 rounded ${colors.bg}`} />
              <span className="text-sm text-gray-600">{name}</span>
            </div>
          ))}
        </div>
      </div>

      <WeekView 
        currentWeek={currentWeek} 
        selectedDate={selectedDate}
        onDaySelect={setSelectedDate}
      />

      {selectedDate && (
        <DayDetails 
          date={selectedDate}
          lessons={selectedDateLessons}
          onEditLesson={setEditingLesson}
        />
      )}

      {showCreateModal && (
        <CreateLessonModal
          weekStart={startOfWeek(currentWeek, { weekStartsOn: 1 })}
          onClose={() => setShowCreateModal(false)}
        />
      )}

      {editingLesson && (
        <LessonDetailsModal
          lesson={editingLesson}
          onClose={() => setEditingLesson(null)}
        />
      )}
    </div>
  );
}