import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useTasks } from './TaskContext';
import * as attendanceApi from '../services/attendance';
import type { AttendanceRecord, AttendanceStatus, MissedLesson } from '../types/attendance';

interface AttendanceState {
  records: AttendanceRecord[];
  isLoading: boolean;
  error: string | null;
}

type AttendanceAction =
  | { type: 'SET_LOADING' }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'CLEAR_ERROR' }
  | { type: 'SET_RECORDS'; payload: AttendanceRecord[] }
  | { type: 'ADD_RECORD'; payload: AttendanceRecord }
  | { type: 'UPDATE_RECORD'; payload: AttendanceRecord }
  | { type: 'DELETE_RECORD'; payload: string }
  | { type: 'ADD_RECORDS'; payload: AttendanceRecord[] };

interface AttendanceContextType {
  state: AttendanceState;
  dispatch: React.Dispatch<AttendanceAction>;
  markAttendance: (
    studentIds: string[],
    date: string,
    status: AttendanceStatus,
    missedLessons?: MissedLesson[],
    notes?: string
  ) => Promise<void>;
  updateAttendance: (record: AttendanceRecord) => Promise<void>;
  deleteAttendance: (recordId: string) => Promise<void>;
  getStudentAttendance: (studentId: string, startDate?: string, endDate?: string) => Promise<AttendanceRecord[]>;
  clearError: () => void;
}

const AttendanceContext = createContext<AttendanceContextType | undefined>(undefined);

const initialState: AttendanceState = {
  records: [],
  isLoading: false,
  error: null,
};

function attendanceReducer(state: AttendanceState, action: AttendanceAction): AttendanceState {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, isLoading: true, error: null };
    case 'SET_ERROR':
      return { ...state, error: action.payload, isLoading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: null };
    case 'SET_RECORDS':
      console.log('SET_RECORDS payload:', action.payload);
      return { ...state, records: action.payload, isLoading: false, error: null };
    case 'ADD_RECORD':
      console.log('ADD_RECORD payload:', action.payload);
      console.log('Current records:', state.records);
      // Only add if record doesn't already exist
      const exists = state.records.some(r => 
        r.studentId === action.payload.studentId && r.date === action.payload.date
      );
      if (exists) {
        console.log('Record already exists, not adding');
        return state;
      }
      return {
        ...state,
        records: [...state.records, action.payload],
        isLoading: false,
        error: null,
      };
    case 'ADD_RECORDS':
      return {
        ...state,
        records: [...state.records, ...action.payload],
        isLoading: false,
        error: null,
      };
    case 'UPDATE_RECORD':
      return {
        ...state,
        records: state.records.map((record) =>
          record.id === action.payload.id ? action.payload : record
        ),
        isLoading: false,
        error: null,
      };
    case 'DELETE_RECORD':
      return {
        ...state,
        records: state.records.filter((record) => record.id !== action.payload),
        isLoading: false,
        error: null,
      };
    default:
      return state;
  }
}

export function AttendanceProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(attendanceReducer, initialState);
  const { state: authState } = useAuth();
  const { addTask } = useTasks();

  const createCatchUpTask = async (studentId: string, missedLessons: MissedLesson[]) => {
    if (!authState.user?.username) return;

    const lessonTitles = missedLessons.map(lesson => lesson.title).join(', ');
    await addTask({
      title: `Catch up student on missed lessons: ${lessonTitles}`,
      dueDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toISOString().split('T')[0],
      completed: false,
      priority: 'high',
      source: 'system',
    });
  };

  const markAttendance = async (
  studentIds: string[],
  date: string,
  status: AttendanceStatus,
  missedLessons?: MissedLesson[],
  notes?: string
) => {
  if (!authState.user?.username) {
    dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
    return;
  }

  dispatch({ type: 'SET_LOADING' });
  try {
    // Check for existing records first
    const existingRecords = await Promise.all(
      studentIds.map(studentId => 
        attendanceApi.getStudentAttendanceRecords(
          authState.user!.username,
          studentId,
          date,
          date
        )
      )
    );

    // Filter out students that already have records
    const studentsToProcess = studentIds.filter((studentId, index) => 
      existingRecords[index].length === 0
    );

    if (studentsToProcess.length === 0) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: 'Attendance already marked for selected student(s) on this date' 
      });
      return;
    }

    // Process only students without existing records
    const records = await Promise.all(
      studentsToProcess.map(async (studentId) => {
        const record = await attendanceApi.createAttendanceRecord(
          authState.user!.username,
          {
            studentId,
            date,
            status,
            missedLessons,
            notes,
          }
        );

        if (missedLessons?.length) {
          await createCatchUpTask(studentId, missedLessons);
        }

        return record;
      })
    );

    records.forEach(record => {
      dispatch({ type: 'ADD_RECORD', payload: record });
    });
  } catch (error) {
    dispatch({
      type: 'SET_ERROR',
      payload: error instanceof Error ? error.message : 'Failed to mark attendance',
    });
    throw error;
  }
};

  const updateAttendance = async (record: AttendanceRecord) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      const updatedRecord = await attendanceApi.updateAttendanceRecord(
        authState.user.username,
        record
      );
      dispatch({ type: 'UPDATE_RECORD', payload: updatedRecord });
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: error instanceof Error ? error.message : 'Failed to update attendance',
      });
      throw error;
    }
  };

  const deleteAttendance = async (recordId: string) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      await attendanceApi.deleteAttendanceRecord(authState.user.username, recordId);
      dispatch({ type: 'DELETE_RECORD', payload: recordId });
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: error instanceof Error ? error.message : 'Failed to delete attendance',
      });
      throw error;
    }
  };

  const getStudentAttendance = async (
  studentId: string, 
  startDate?: string, 
  endDate?: string,
  accumulate: boolean = false // new parameter
) => {
  if (!authState.user?.username) {
    dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
    return [];
  }

  try {
    const records = await attendanceApi.getStudentAttendanceRecords(
      authState.user.username,
      studentId,
      startDate,
      endDate
    );
    // Use SET_RECORDS for profile view, ADD_RECORDS for report view
    dispatch({ 
      type: accumulate ? 'ADD_RECORDS' : 'SET_RECORDS', 
      payload: records 
    });
    return records;
  } catch (error) {
    dispatch({
      type: 'SET_ERROR',
      payload: error instanceof Error ? error.message : 'Failed to fetch attendance'
    });
    throw error;
  }
};

  const clearError = () => {
    dispatch({ type: 'CLEAR_ERROR' });
  };

  return (
    <AttendanceContext.Provider
      value={{
        state,
        dispatch,
        markAttendance,
        updateAttendance,
        deleteAttendance,
        getStudentAttendance,
        clearError,
      }}
    >
      {children}
    </AttendanceContext.Provider>
  );
}

export function useAttendance() {
  const context = useContext(AttendanceContext);
  if (context === undefined) {
    throw new Error('useAttendance must be used within an AttendanceProvider');
  }
  return context;
}
