
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Plus, ChevronDown, ChevronUp } from 'lucide-react';
import { useSavedUnits } from '../../../contexts/SavedUnitsContext';
import { subjectColors } from '../../../utils/constants';
import NinjaLessonsModal from '../../../components/Planning/NinjaLessonsModal';

export default function LessonPlanning() {
  const { state: unitsState } = useSavedUnits();
  const navigate = useNavigate();
  const [selectedSubject, setSelectedSubject] = useState<string | 'All'>('All');
  const [expandedUnits, setExpandedUnits] = useState<Set<string>>(new Set());
  const [ninjaUnit, setNinjaUnit] = useState<SavedUnit | null>(null);

  const filteredUnits = selectedSubject === 'All' 
    ? unitsState.units 
    : unitsState.units.filter(unit => unit.subject === selectedSubject);

  const subjects = ['All', ...new Set(unitsState.units.map(unit => unit.subject))];

  const toggleExpand = (unitId: string) => {
    const newExpanded = new Set(expandedUnits);
    if (expandedUnits.has(unitId)) {
      newExpanded.delete(unitId);
    } else {
      newExpanded.add(unitId);
    }
    setExpandedUnits(newExpanded);
  };

  return (
    <div className="max-w-4xl mx-auto">
      <button
        onClick={() => navigate('/planning')}
        className="flex items-center gap-2 text-gray-600 hover:text-gray-900 mb-6"
      >
        <ArrowLeft className="h-4 w-4" />
        Back to Planning Tools
      </button>

      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Lesson Planning</h1>
        <p className="text-gray-500">Create and manage lessons for your curriculum units</p>
      </div>

      <div className="mb-6">
        <label htmlFor="subjectFilter" className="block text-sm font-medium text-gray-700 mb-1">
          Filter by Subject
        </label>
        <select
          id="subjectFilter"
          value={selectedSubject}
          onChange={(e) => setSelectedSubject(e.target.value)}
          className="w-48 rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        >
          {subjects.map(subject => (
            <option key={subject} value={subject}>{subject}</option>
          ))}
        </select>
      </div>

      <div className="space-y-4">
        {filteredUnits.length === 0 ? (
          <div className="text-center py-12 bg-white rounded-lg shadow-sm">
            <p className="text-gray-500">No units found</p>
          </div>
        ) : (
          filteredUnits.map((unit) => {
            const colors = subjectColors[unit.subject] || subjectColors.Other;
            return (
              <div
                key={unit.id}
                className="bg-white rounded-xl shadow-sm p-6"
              >
                <div className="flex items-start justify-between">
                  <div>
                    <div className="flex items-center gap-3 mb-2">
                      <h3 className="text-lg font-semibold text-gray-900">
                        Unit {unit.unitNumber}: {unit.unitName}
                      </h3>
                      <span className={`px-2 py-1 rounded-full text-xs font-medium ${colors.bg} ${colors.text}`}>
                        {unit.subject}
                      </span>
                    </div>
                    <p className="text-sm text-gray-500">{unit.duration}</p>
                  </div>
                  <div className="flex gap-2">
                    <button
                      onClick={() => setNinjaUnit(unit)}
                      className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
                    >
                      <span className="text-lg">⚔️</span>
                      Ninja It!
                    </button>
                    <button
                      onClick={() => navigate(`/planning/lessons/${unit.id}`)}
                      className="px-4 py-2 text-sm font-medium text-indigo-600 hover:text-indigo-800 border border-indigo-600 rounded-lg"
                    >
                      View Lessons
                    </button>
                    <button
                      onClick={() => toggleExpand(unit.id)}
                      className="p-2 text-gray-400 hover:text-gray-600"
                    >
                      {expandedUnits.has(unit.id) ? (
                        <ChevronUp className="h-4 w-4" />
                      ) : (
                        <ChevronDown className="h-4 w-4" />
                      )}
                    </button>
                  </div>
                </div>

                {expandedUnits.has(unit.id) && (
                  <div className="mt-4 space-y-4">
                    <div>
                      <h4 className="font-medium text-gray-900">Standards Covered</h4>
                      <div className="mt-2 space-y-2">
                        {unit.standardsCovered?.map((standard, index) => (
                          <div key={index} className="flex items-baseline justify-between">
                            <div className="flex-1">
                              <span className="font-medium">{standard.standard}:</span>
                              <span className="ml-2 text-gray-600">{standard.description}</span>
                            </div>
                            <span className="ml-4 text-sm text-gray-500">{standard.duration}</span>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div>
                      <h4 className="font-medium text-gray-900">Learning Targets</h4>
                      <ul className="mt-2 space-y-1 list-disc list-inside">
                        {unit.learningTargets?.map((target, index) => (
                          <li key={index} className="text-gray-600">{target}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>

      {ninjaUnit && (
        <NinjaLessonsModal
          unit={ninjaUnit}
          onClose={() => setNinjaUnit(null)}
        />
      )}
    </div>
  );
}
