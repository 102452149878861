import React, { useState, useEffect } from 'react';
import { Calendar, Download, ChevronDown, ChevronUp, Clock } from 'lucide-react';
import { DatePicker } from '../Common/DatePicker';
import { 
  format, 
  parseISO, 
  startOfMonth, 
  endOfMonth, 
  isToday,
  startOfWeek,
  endOfWeek 
} from 'date-fns';
import type { Student } from '../../types';
import type { AttendanceRecord } from '../../types/attendance';
import { useAttendance } from '../../contexts/AttendanceContext';

interface AttendanceReportProps {
  students: Student[];
  initialDate?: Date;
}

type ViewMode = 'day' | 'week' | 'month';

export default function AttendanceReport({ students, initialDate = new Date() }: AttendanceReportProps) {
  const { state, getStudentAttendance, dispatch } = useAttendance();
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [viewMode, setViewMode] = useState<ViewMode>('day');
  const [expandedStudents, setExpandedStudents] = useState<Set<string>>(new Set());
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isExporting, setIsExporting] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(true);

  const getDateRange = () => {
  const date = new Date(selectedDate); // Create a new date to avoid mutations
  switch (viewMode) {
    case 'day':
      return {
        start: format(date, 'yyyy-MM-dd'),
        end: format(date, 'yyyy-MM-dd')
      };
    case 'week':
      return {
        start: format(startOfWeek(date), 'yyyy-MM-dd'),
        end: format(endOfWeek(date), 'yyyy-MM-dd')
      };
    case 'month':
      return {
        start: format(startOfMonth(date), 'yyyy-MM-dd'),
        end: format(endOfMonth(date), 'yyyy-MM-dd')
      };
  }
};

  // Fetch data when date or view mode changes
  useEffect(() => {
    setShouldRefresh(true);
  }, [selectedDate, viewMode]);

  useEffect(() => {
  if (shouldRefresh && students.length > 0) {
    const timeoutId = setTimeout(() => {
      loadAttendanceData();
      setShouldRefresh(false);
    }, 0);
    return () => clearTimeout(timeoutId);
  }
}, [shouldRefresh, students.length, selectedDate, viewMode]); // Add selectedDate and viewMode as dependencies


  const loadAttendanceData = async () => {
  setIsLoading(true);
  setError(null);
  
  try {
    const { start, end } = getDateRange();
    console.log('Loading attendance for date range:', { start, end });
    dispatch({ type: 'SET_RECORDS', payload: [] }); // Clear records

    const results = await Promise.all(
      students.map(async student => {
        try {
          return await getStudentAttendance(student.id, start, end, true);  // accumulate = true
        } catch (err) {
          console.error(`Error fetching attendance for student ${student.id}:`, err);
          throw err;
        }
      })
    );
    console.log('All attendance results:', results);
  } catch (error) {
    console.error('Full error details:', error); // Log the full error
    setError(error instanceof Error ? error.message : 'Failed to load attendance records.');
  } finally {
    setIsLoading(false);
  }
};


  const getDatePickerType = () => {
    switch (viewMode) {
      case 'day':
        return 'date';
      case 'week':
        return 'week';
      case 'month':
        return 'month';
    }
  };

  const getDateFormat = () => {
  switch (viewMode) {
    case 'day':
      return 'yyyy-MM-dd';
    case 'week':
      // For week view, we'll show the start of the week
      return 'yyyy-\'W\'II'; // format for ISO week numbers
    case 'month':
      return 'yyyy-MM';
  }
};

const getFormattedDate = () => {
  switch (viewMode) {
    case 'day':
      return format(selectedDate, 'yyyy-MM-dd');
    case 'week': {
      const year = format(selectedDate, 'yyyy');
      const week = format(selectedDate, 'II'); // II gives ISO week number
      return `${year}-W${week}`;
    }
    case 'month':
      return format(selectedDate, 'yyyy-MM');
  }
};

const handleDateSelection = (newDate: string) => {
  try {
    let date;
    if (viewMode === 'week') {
      const [year, week] = newDate.split('-W');
      date = startOfWeek(new Date(parseInt(year), 0, 1 + (parseInt(week) - 1) * 7));
    } else {
      date = parseISO(newDate);
    }
    setSelectedDate(date);
    setShouldRefresh(true); // Explicitly trigger refresh when date changes
  } catch (error) {
    console.error('Error parsing date:', error);
    setError('Invalid date selection');
  }
};

  const handleExport = async () => {
    try {
      setIsExporting(true);
      const { start, end } = getDateRange();
      
      // Create CSV content
      const headers = ['Student Name', 'Date', 'Status', 'Missed Lessons', 'Notes'];
      const rows = students.flatMap(student => {
        const records = getStudentRecords(student.id);
        return records.map(record => [
          `${student.firstName} ${student.lastName}`,
          format(parseISO(record.date), 'yyyy-MM-dd'),
          record.status,
          record.missedLessons?.map(l => l.title).join('; ') || '',
          record.notes || ''
        ]);
      });

      const csvContent = [
        headers.join(','),
        ...rows.map(row => row.map(cell => `"${cell}"`).join(','))
      ].join('\n');

      // Create and download the file
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `attendance-report-${format(selectedDate, 'yyyy-MM')}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to export attendance report:', error);
      setError('Failed to export report. Please try again.');
    } finally {
      setIsExporting(false);
    }
  };

  const toggleStudentExpand = (studentId: string) => {
    const newExpanded = new Set(expandedStudents);
    if (newExpanded.has(studentId)) {
      newExpanded.delete(studentId);
    } else {
      newExpanded.add(studentId);
    }
    setExpandedStudents(newExpanded);
  };

  const getStudentRecords = (studentId: string): AttendanceRecord[] => {
    return state.records.filter(record => record.studentId === studentId);
  };

  const calculateAttendanceRate = (studentId: string): string => {
    const records = getStudentRecords(studentId);
    if (records.length === 0) return '100%';

    const present = records.filter(r => r.status === 'present').length;
    const partial = records.filter(r => r.status === 'partial').length;
    const rate = ((present + (partial * 0.5)) / records.length) * 100;
    return `${rate.toFixed(1)}%`;
  };

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="text-center text-gray-500">Loading attendance records...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <div className="text-center text-red-500">{error}</div>
      </div>
    );
  }

   return (
  <div className="bg-white rounded-lg shadow-sm">
    <div className="p-6 border-b border-gray-200">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              View Mode
            </label>
            <select
              id="viewMode"
              value={viewMode}
              onChange={(e) => setViewMode(e.target.value as ViewMode)}
              className="block w-[120px] rounded-lg border border-gray-300 bg-white px-4 py-2 text-base shadow-sm hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            >
              <option value="day">Daily</option>
              <option value="week">Weekly</option>
              <option value="month">Monthly</option>
            </select>
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Select {viewMode === 'day' ? 'Date' : viewMode === 'week' ? 'Week' : 'Month'}
            </label>
            <DatePicker 
              selectedDate={selectedDate}
              onChange={setSelectedDate}
              viewMode={viewMode}
            />
          </div>
        </div>
        <button
          onClick={handleExport}
          disabled={isExporting || isLoading}
          className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          <Download className="h-4 w-4" />
          {isExporting ? 'Exporting...' : 'Export Report'}
        </button>
      </div>
    </div>

      <div className="divide-y divide-gray-200">
        {students.map(student => {
          const records = getStudentRecords(student.id);
          const isExpanded = expandedStudents.has(student.id);
          const attendanceRate = calculateAttendanceRate(student.id);

          return (
            <div key={student.id} className="p-6">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <div>
                    <h3 className="text-sm font-medium text-gray-900">
                      {student.firstName} {student.lastName}
                    </h3>
                    <p className="text-sm text-gray-500">
                      Attendance Rate: {attendanceRate}
                    </p>
                  </div>
                </div>
                <button
                  onClick={() => toggleStudentExpand(student.id)}
                  className="p-2 text-gray-400 hover:text-gray-600"
                >
                  {isExpanded ? (
                    <ChevronUp className="h-5 w-5" />
                  ) : (
                    <ChevronDown className="h-5 w-5" />
                  )}
                </button>
              </div>

              {isExpanded && (
                <div className="mt-4 space-y-4">
                  {records.length > 0 ? (
                    records.map(record => (
                      <div
                        key={record.id}
                        className="flex items-start gap-4 p-4 bg-gray-50 rounded-lg"
                      >
                        <Calendar className="h-5 w-5 text-gray-400" />
                        <div>
                          <div className="flex items-center gap-2">
                            <span className="text-sm font-medium text-gray-900">
                              {format(parseISO(record.date), 'MMMM d, yyyy')}
                            </span>
                            <span className={`px-2 py-0.5 text-xs font-medium rounded-full ${
                              record.status === 'absent' 
                                ? 'bg-red-100 text-red-800'
                                : record.status === 'partial'
                                ? 'bg-yellow-100 text-yellow-800'
                                : 'bg-green-100 text-green-800'
                            }`}>
                              {record.status === 'absent' 
                                ? 'Absent'
                                : record.status === 'partial'
                                ? 'Partial Day'
                                : 'Present'}
                            </span>
                            {isToday(parseISO(record.date)) && (
                              <span className="px-2 py-0.5 text-xs font-medium bg-blue-100 text-blue-800 rounded-full">
                                Today
                              </span>
                            )}
                          </div>

                          {record.missedLessons && record.missedLessons.length > 0 && (
                            <div className="mt-2">
                              <p className="text-sm text-gray-600">Missed Lessons:</p>
                              <ul className="mt-1 space-y-1">
                                {record.missedLessons.map((lesson, index) => (
                                  <li key={index} className="flex items-center gap-2 text-sm text-gray-600">
                                    <Clock className="h-4 w-4 text-gray-400" />
                                    <span>{lesson.title}</span>
                                    <span className="text-xs text-gray-500">({lesson.period})</span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}

                          {record.notes && (
                            <p className="mt-2 text-sm text-gray-600">
                              Notes: {record.notes}
                            </p>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm text-gray-500 text-center py-4">
                      No attendance records found
                    </p>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}