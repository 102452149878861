import React, { useState, useEffect } from 'react';
import { User, ArrowRight, MessageCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import type { Student } from '../../types/student';
import { format } from 'date-fns';
import { useCommunication } from '../../contexts/CommunicationContext';

interface StudentCardProps {
  student: Student;
  isSelected: boolean;
  onClick: (e: React.MouseEvent) => void;
}

export default function StudentCard({ student, isSelected, onClick }: StudentCardProps) {
  const navigate = useNavigate();
  const { getStudentCommunications } = useCommunication();
  const [studentCommunications, setStudentCommunications] = useState<Communication[]>([]);

  useEffect(() => {
    const loadCommunications = async () => {
      try {
        const comms = await getStudentCommunications(student.id);
        const sentComms = comms.filter(comm => comm.status === 'sent');
        setStudentCommunications(sentComms);
      } catch (error) {
        console.error('Error loading communications for student:', student.id, error);
      }
    };
    
    loadCommunications();
  }, [student.id, getStudentCommunications]);

  const handleViewProfile = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/students/${student.id}`);
  };

  // Ensure arrays exist with default empty arrays
  const medicalAlerts = student.medicalAlerts || [];

  return (
    <div 
      onClick={onClick}
      className={`bg-white rounded-lg shadow-sm p-6 cursor-pointer hover:shadow-md transition-all ${
        isSelected ? 'ring-2 ring-indigo-500 bg-indigo-50' : ''
      }`}
    >
      <div className="flex items-start justify-between">
        <div className="flex items-center gap-4">
          <div className="w-16 h-16 rounded-full bg-gray-100 flex items-center justify-center overflow-hidden">
            {student.profilePicture ? (
              <img
                src={student.profilePicture}
                alt={`${student.firstName} ${student.lastName}`}
                className="w-full h-full object-cover"
              />
            ) : (
              <User className="w-8 h-8 text-gray-400" />
            )}
          </div>
          <div>
            <div className="flex items-center gap-2">
              <h3 className="text-lg font-medium text-gray-900">
                {student.firstName} {student.lastName}
              </h3>
              {studentCommunications.length > 0 && (
                <span className="inline-flex items-center gap-1 px-2 py-1 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">
                  <MessageCircle className="h-3 w-3" />
                  {studentCommunications.length}
                </span>
              )}
            </div>
            <p className="text-sm text-gray-500">Grade {student.grade}</p>
          </div>
        </div>
        <button
          onClick={handleViewProfile}
          className="p-2 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100 edit-button"
          title="View student details"
        >
          <ArrowRight className="w-4 h-4" />
        </button>
      </div>

      <div className="mt-4 space-y-2">
        <div className="flex items-center justify-between text-sm">
          <span className="text-gray-500">Class:</span>
          <span className="text-gray-900">{student.class}</span>
        </div>
        {medicalAlerts.length > 0 && (
          <div className="mt-2">
            <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800">
              Medical Alert
            </span>
          </div>
        )}
        {(student.hasIEP || student.has504) && (
          <div className="mt-2">
            {student.hasIEP && (
              <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800 mr-2">
                IEP
              </span>
            )}
            {student.has504 && (
              <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                504
              </span>
            )}
          </div>
        )}
      </div>

      <div className="mt-4 pt-4 border-t border-gray-200">
        <p className="text-xs text-gray-500">
          Last modified: {format(new Date(student.lastModified), 'MMM d, yyyy')}
        </p>
      </div>
    </div>
  );
}