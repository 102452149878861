import React, { useState } from 'react';
import { X } from 'lucide-react';
import type { SavedUnit } from '../../types';

interface CloneUnitModalProps {
  unit: SavedUnit;
  onClose: () => void;
  onClone: (schoolYear: string) => Promise<void>;
  currentSchoolYear: string;
}

const SCHOOL_YEARS = [
  '2023-2024',
  '2024-2025',
  '2025-2026',
  '2026-2027',
  '2027-2028'
];

export default function CloneUnitModal({ unit, onClose, onClone, currentSchoolYear }: CloneUnitModalProps) {
  // Initialize with the unit's current school year instead of the current school year
  const [selectedYear, setSelectedYear] = useState(unit.schoolYear || currentSchoolYear);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Don't allow cloning to the same school year
    if (selectedYear === unit.schoolYear) {
      setError('Please select a different school year');
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      await onClone(selectedYear);
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to clone unit');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-md w-full p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900">Clone Unit</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-5 w-5" />
          </button>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-50 rounded-lg">
            <p className="text-sm text-red-600">{error}</p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="schoolYear" className="block text-sm font-medium text-gray-700 mb-1">
              Select School Year
            </label>
            <select
              id="schoolYear"
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              {SCHOOL_YEARS.map((year) => (
                <option 
                  key={year} 
                  value={year}
                  disabled={year === unit.schoolYear}
                >
                  {year} {year === unit.schoolYear ? '(Current)' : ''}
                </option>
              ))}
            </select>
            <p className="mt-2 text-sm text-gray-500">
              This will create a copy of "{unit.unitName}" in the selected school year.
              {unit.schoolYear && (
                <span className="block mt-1 text-gray-400">
                  Currently in {unit.schoolYear} school year
                </span>
              )}
            </p>
          </div>

          <div className="flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting || selectedYear === unit.schoolYear}
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
            >
              {isSubmitting ? 'Cloning...' : 'Clone Unit'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}