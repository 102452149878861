import React, { useState } from 'react';
import { X } from 'lucide-react';
import type { Unit, SubjectType } from '../../types';
import SubjectSelect from './SubjectSelect';

interface CreateUnitModalProps {
  onClose: () => void;
  onSave: (unit: Unit) => void;
}

export default function CreateUnitModal({ onClose, onSave }: CreateUnitModalProps) {
  const [unit, setUnit] = useState<Partial<Unit>>({
    unitName: '', // Changed from unit_name to unitName
    subject: 'Math',
    standardsCovered: [{ standard: '', description: '', duration: '0 days' }],
    learningTargets: [''],
    duration: '0 days',
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (unit.unitName && unit.standardsCovered && unit.learningTargets) { // Changed from unit_name to unitName
      // Calculate total duration from standards
      const totalDays = unit.standardsCovered.reduce(
        (sum, standard) => sum + (parseInt(standard.duration) || 0),
        0
      );

      const newUnit: Unit = {
        unitName: unit.unitName, // Changed from unit_name to unitName
        subject: unit.subject || 'Math',
        standardsCovered: unit.standardsCovered.map(standard => ({
          standard: standard.standard,
          description: standard.description,
          duration: `${parseInt(standard.duration)} days`
        })),
        learningTargets: unit.learningTargets.filter(target => target.trim() !== ''),
        duration: `${totalDays} days`,
      };

      onSave(newUnit);
      onClose();
    }
  };

  const addStandard = () => {
    setUnit({
      ...unit,
      standardsCovered: [
        ...(unit.standardsCovered || []),
        { standard: '', description: '', duration: '0 days' },
      ],
    });
  };

  const updateStandard = (index: number, field: string, value: string) => {
    const newStandards = [...(unit.standardsCovered || [])];
    newStandards[index] = { ...newStandards[index], [field]: value };
    
    // Update total duration
    const totalDays = newStandards.reduce(
      (sum, standard) => sum + (parseInt(standard.duration) || 0),
      0
    );

    setUnit({
      ...unit,
      standardsCovered: newStandards,
      duration: `${totalDays} days`,
    });
  };

  const removeStandard = (index: number) => {
    const newStandards = [...(unit.standardsCovered || [])];
    newStandards.splice(index, 1);
    
    // Update total duration
    const totalDays = newStandards.reduce(
      (sum, standard) => sum + (parseInt(standard.duration) || 0),
      0
    );

    setUnit({
      ...unit,
      standardsCovered: newStandards,
      duration: `${totalDays} days`,
    });
  };

  const addLearningTarget = () => {
    setUnit({
      ...unit,
      learningTargets: [...(unit.learningTargets || []), ''],
    });
  };

  const updateLearningTarget = (index: number, value: string) => {
    const newTargets = [...(unit.learningTargets || [])];
    newTargets[index] = value;
    setUnit({
      ...unit,
      learningTargets: newTargets,
    });
  };

  const removeLearningTarget = (index: number) => {
    const newTargets = [...(unit.learningTargets || [])];
    newTargets.splice(index, 1);
    setUnit({
      ...unit,
      learningTargets: newTargets,
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-900">Create New Unit</h2>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6 overflow-y-auto max-h-[calc(90vh-129px)]">
          <div className="space-y-6">
            <div>
              <label htmlFor="unitName" className="block text-sm font-medium text-gray-700 mb-1">
                Unit Name
              </label>
              <input
                type="text"
                id="unitName"
                value={unit.unitName || ''} // Changed from unit_name to unitName
                onChange={(e) => setUnit({ ...unit, unitName: e.target.value })} // Changed from unit_name to unitName
                className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>

            <div>
              <label htmlFor="subject" className="block text-sm font-medium text-gray-700 mb-1">
                Subject
              </label>
              <SubjectSelect
                value={unit.subject || 'Math'}
                onChange={(value) => setUnit({ ...unit, subject: value })}
              />
            </div>

            <div>
              <div className="flex items-center justify-between mb-2">
                <label className="block text-sm font-medium text-gray-700">
                  Standards Covered
                </label>
                <span className="text-sm text-gray-500">
                  Total Duration: {unit.duration}
                </span>
              </div>
              <div className="space-y-4">
                {unit.standardsCovered?.map((standard, index) => (
                  <div key={index} className="flex gap-4">
                    <div className="flex-1">
                      <input
                        type="text"
                        value={standard.standard}
                        onChange={(e) =>
                          updateStandard(index, 'standard', e.target.value)
                        }
                        placeholder="Standard (e.g., MATH.5.NBT.1)"
                        className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        required
                      />
                    </div>
                    <div className="flex-[2]">
                      <input
                        type="text"
                        value={standard.description}
                        onChange={(e) =>
                          updateStandard(index, 'description', e.target.value)
                        }
                        placeholder="Description"
                        className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        required
                      />
                    </div>
                    <div className="w-24">
                      <input
                        type="number"
                        value={parseInt(standard.duration) || 0}
                        onChange={(e) =>
                          updateStandard(index, 'duration', e.target.value)
                        }
                        placeholder="Days"
                        className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        required
                        min="0"
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => removeStandard(index)}
                      className="text-red-600 hover:text-red-800"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addStandard}
                  className="text-sm text-indigo-600 hover:text-indigo-800"
                >
                  + Add Standard
                </button>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Learning Targets
              </label>
              <div className="space-y-4">
                {unit.learningTargets?.map((target, index) => (
                  <div key={index} className="flex gap-4">
                    <div className="flex-1">
                      <input
                        type="text"
                        value={target}
                        onChange={(e) =>
                          updateLearningTarget(index, e.target.value)
                        }
                        placeholder="I can..."
                        className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        required
                      />
                    </div>
                    <button
                      type="button"
                      onClick={() => removeLearningTarget(index)}
                      className="text-red-600 hover:text-red-800"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={addLearningTarget}
                  className="text-sm text-indigo-600 hover:text-indigo-800"
                >
                  + Add Learning Target
                </button>
              </div>
            </div>
          </div>

          <div className="mt-6 flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
            >
              Create Unit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}