import React, { useState } from 'react';
import { format } from 'date-fns';
import { useLessons } from '../../contexts/LessonContext';

interface OtherScheduleFormProps {
  weekDays: Date[];
  onClose: () => void;
  editingLesson?: any;
}

export default function OtherScheduleForm({ weekDays, onClose, editingLesson }: OtherScheduleFormProps) {
  const { addLesson, updateLesson } = useLessons();
  
  // Parse existing data if editing
  const initialData = editingLesson ? {
    title: editingLesson.title,
    description: editingLesson.description.replace(/^Type: .*\n/, '').replace(/^Time: .*\n/, ''),
    date: editingLesson.date,
    type: editingLesson.description.match(/^Type: (.*?)\n/)?.[1] || 'routine',
    time: editingLesson.description.match(/Time: (.*?)\n/)?.[1] || '',
  } : {
    title: '',
    description: '',
    date: format(weekDays[0], 'yyyy-MM-dd'),
    type: 'routine' as 'routine' | 'event' | 'other',
    time: '',
  };

  const [formData, setFormData] = useState(initialData);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    
    const lessonData = {
      title: formData.title,
      description: `Type: ${formData.type}\n${formData.time ? `Time: ${formData.time}\n` : ''}${formData.description}`,
      date: formData.date,
      order: editingLesson?.order || 0,
      standards: [],
      learningTargets: [],
      resources: '',
      unitId: 'other',
    };

    if (editingLesson) {
      updateLesson({
        ...editingLesson,
        ...lessonData,
      });
    } else {
      addLesson('other', lessonData);
    }
    onClose();
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label htmlFor="type" className="block text-sm font-medium text-gray-700 mb-1">
          Type
        </label>
        <select
          id="type"
          value={formData.type}
          onChange={(e) => setFormData({ ...formData, type: e.target.value as typeof formData.type })}
          className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        >
          <option value="routine">Daily Routine</option>
          <option value="event">Special Event</option>
          <option value="other">Other</option>
        </select>
      </div>

      <div>
        <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">
          Date
        </label>
        <select
          id="date"
          value={formData.date}
          onChange={(e) => setFormData({ ...formData, date: e.target.value })}
          className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          required
        >
          {weekDays.map((day) => (
            <option key={format(day, 'yyyy-MM-dd')} value={format(day, 'yyyy-MM-dd')}>
              {format(day, 'EEEE, MMMM d')}
            </option>
          ))}
        </select>
      </div>

      <div>
        <label htmlFor="time" className="block text-sm font-medium text-gray-700 mb-1">
          Time (optional)
        </label>
        <input
          type="time"
          id="time"
          value={formData.time}
          onChange={(e) => setFormData({ ...formData, time: e.target.value })}
          className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        />
      </div>

      <div>
        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
          Title
        </label>
        <input
          type="text"
          id="title"
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          placeholder={
            formData.type === 'routine' ? 'e.g., Morning Assembly, Attendance' :
            formData.type === 'event' ? 'e.g., Field Trip, Guest Speaker' :
            'e.g., Parent Meeting, Team Planning'
          }
          className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          required
        />
      </div>

      <div>
        <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
          Description
        </label>
        <textarea
          id="description"
          value={formData.description}
          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          rows={3}
          className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder="Add any additional details or notes"
        />
      </div>

      <div className="flex justify-end gap-3">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
        >
          {editingLesson ? 'Save Changes' : 'Add to Schedule'}
        </button>
      </div>
    </form>
  );
}