import React from 'react';
import { Check, ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface SaveSuccessModalProps {
  unitCount: number;
  onClose: () => void;
}

export default function SaveSuccessModal({ unitCount, onClose }: SaveSuccessModalProps) {
  const navigate = useNavigate();

  const handleViewUnits = () => {
    navigate('/planning/saved-units');
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-md w-full p-6">
        <div className="flex flex-col items-center text-center">
          <div className="w-12 h-12 bg-green-100 rounded-full flex items-center justify-center mb-4">
            <Check className="h-6 w-6 text-green-600" />
          </div>
          <h2 className="text-xl font-semibold text-gray-900 mb-2">Units Saved Successfully!</h2>
          <p className="text-gray-600 mb-6">
            {unitCount} unit{unitCount !== 1 ? 's' : ''} {unitCount === 1 ? 'has' : 'have'} been added to your saved units.
          </p>
          <div className="flex gap-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Close
            </button>
            <button
              onClick={handleViewUnits}
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 flex items-center gap-2"
            >
              View Saved Units
              <ArrowRight className="h-4 w-4" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}