import React from 'react';
import {
  LayoutDashboard,
  Users,
  Calendar,
  BookOpen,
  Bell,
  Settings,
  Swords,
  MessageSquare,
  CheckSquare,
} from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { useAlerts } from '../contexts/AlertContext';
import FeedbackModal from './Feedback/FeedbackModal';

const navItems = [
  { icon: LayoutDashboard, label: 'Dashboard', path: '/' },
  { icon: Users, label: 'Students', path: '/students' },
  { icon: BookOpen, label: 'Planning', path: '/planning' },
  { icon: Calendar, label: 'Schedule', path: '/schedule' },
  { icon: CheckSquare, label: 'Attendance', path: '/attendance' },
  { icon: Bell, label: 'Notifications', path: '/notifications' },
  { icon: Settings, label: 'Settings', path: '/settings' },
];

const colors = [
  'bg-indigo-900',
  'bg-blue-900',
  'bg-purple-900',
  'bg-pink-900',
  'bg-red-900',
  'bg-orange-900',
  'bg-green-900',
  'bg-teal-900',
];

const hoverColors = {
  'bg-indigo-900': 'hover:bg-indigo-800/50',
  'bg-blue-900': 'hover:bg-blue-800/50',
  'bg-purple-900': 'hover:bg-purple-800/50',
  'bg-pink-900': 'hover:bg-pink-800/50',
  'bg-red-900': 'hover:bg-red-800/50',
  'bg-orange-900': 'hover:bg-orange-800/50',
  'bg-green-900': 'hover:bg-green-800/50',
  'bg-teal-900': 'hover:bg-teal-800/50',
};

const activeColors = {
  'bg-indigo-900': 'bg-indigo-800',
  'bg-blue-900': 'bg-blue-800',
  'bg-purple-900': 'bg-purple-800',
  'bg-pink-900': 'bg-pink-800',
  'bg-red-900': 'bg-red-800',
  'bg-orange-900': 'bg-orange-800',
  'bg-green-900': 'bg-green-800',
  'bg-teal-900': 'bg-teal-800',
};

export default function Sidebar() {
  const location = useLocation();
  const { state } = useAlerts();
  const unreadCount = state.alerts.length;
  const [showFeedback, setShowFeedback] = React.useState(false);
  const [bgColor, setBgColor] = React.useState('bg-indigo-900');

  const handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      const currentIndex = colors.indexOf(bgColor);
      const nextIndex = Math.floor(Math.random() * colors.length);
      const newColor = colors[nextIndex === currentIndex ? (nextIndex + 1) % colors.length : nextIndex];
      setBgColor(newColor);
    }
  };

  return (
    <>
      <aside 
        className={`${bgColor} text-white w-64 min-h-screen p-4 flex flex-col transition-colors duration-300`}
        onClick={handleBackgroundClick}
      >
<div 
  className="flex items-center gap-2 mb-8 px-2 cursor-pointer"
  onClick={handleBackgroundClick}
>
  <Swords className="w-8 h-8 pointer-events-none" />
  <span className="text-xl font-bold pointer-events-none select-none">NinjaED</span>
</div>

        
        <nav className="flex flex-col">
  {navItems.map(({ icon: Icon, label, path }) => (
    <Link
      key={path}
      to={path}
      className={`flex items-center gap-3 px-4 py-3 rounded-lg transition-colors
        ${location.pathname === path 
          ? activeColors[bgColor] + ' text-white' 
          : 'text-white/80 ' + hoverColors[bgColor]}`}
    >
      <div className="relative">
        <Icon className="w-5 h-5" />
        {label === 'Notifications' && unreadCount > 0 && (
          <span className="absolute -top-1 -right-1 h-4 w-4 bg-red-500 rounded-full text-xs flex items-center justify-center">
            {unreadCount}
          </span>
        )}
      </div>
      <span className="select-none">{label}</span> {/* Prevent text selection */}
    </Link>
  ))}
</nav>

        <div className="mt-[200px]">
          <button
            onClick={() => setShowFeedback(true)}
            className={`w-full flex items-center gap-3 px-4 py-3 rounded-lg transition-colors text-white/80 ${hoverColors[bgColor]}`}
          >
            <MessageSquare className="w-5 h-5" />
            <span className="select-none">Feedback</span> {/* Prevent text selection */}
          </button>
        </div>

      </aside>
      {showFeedback && (
        <FeedbackModal onClose={() => setShowFeedback(false)} />
      )}
    </>
  );
}


