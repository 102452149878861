import React, { useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft, AlertCircle } from 'lucide-react';
import { useStudents } from '../../contexts/StudentContext';
import { useCommunication } from '../../contexts/CommunicationContext';
import { useAuth } from '../../contexts/AuthContext';
import { useTeacherInfo } from '../../utils/emailUtils';
import RecipientList from '../../components/Communication/RecipientList';
import MessageForm from '../../components/Communication/MessageForm';
import EmailGenerator from '../../components/Communication/EmailGenerator';
import { fetchAuthSession } from 'aws-amplify/auth';

interface GeneratedEmail {
  to: string[];
  subject: string;
  body: string;
  copied: boolean;
  studentId?: string;
  communicationId?: string;
}

export default function Reply() {
  const location = useLocation();
  const navigate = useNavigate();
  const { state: { students } } = useStudents();
  const { state: commState, createCommunication, updateCommunication } = useCommunication();
  const { state: authState } = useAuth();
  const teacherInfo = useTeacherInfo();
  const emailContainerRef = useRef<HTMLDivElement>(null);
  
  const studentId = location.state?.studentId;
  const student = students.find(s => s.id === studentId);
  
  const [originalMessage, setOriginalMessage] = useState('');
  const [subject, setSubject] = useState('Re: ');
  const [message, setMessage] = useState('');
  const [additionalInstructions, setAdditionalInstructions] = useState('');
  const [generatedEmails, setGeneratedEmails] = useState<GeneratedEmail[]>([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [generationProgress, setGenerationProgress] = useState(0);

  if (!student) {
    return (
      <div className="max-w-4xl mx-auto">
        <div className="bg-red-50 p-4 rounded-lg">
          <p className="text-red-600">Student not found</p>
          <button
            onClick={() => navigate('/students')}
            className="mt-4 text-sm text-red-700 hover:text-red-800"
          >
            Return to Students
          </button>
        </div>
      </div>
    );
  }

  const handleGenerateEmails = async () => {
    if (!authState.user?.username) {
      setError('User not authenticated');
      return;
    }

    setIsGenerating(true);
    setError(null);
    setGeneratedEmails([]);
    setGenerationProgress(0);

    try {
      const { tokens } = await fetchAuthSession();
      const idToken = tokens.idToken.toString();

      let allEmails: GeneratedEmail[] = [];
      let hasScrolled = false;

      try {
        const response = await fetch('https://hwnfbvblhfnvvegbq5xyrwwxgu0hlgqw.lambda-url.us-east-2.on.aws/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}`
          },
          body: JSON.stringify({
            students: [{
              name: `${student.firstName} ${student.lastName}`,
              parents: student.parents.map(p => ({
                name: `${p.firstName} ${p.lastName}`,
                email: p.email,
                preferredLanguage: p.preferredLanguage
              }))
            }],
            originalMessage,
            subject,
            message,
            additionalInstructions,
            teacher: teacherInfo,
            type: 'reply'
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || `Failed to generate email for ${student.firstName} ${student.lastName}`);
        }

        const data = await response.json();
        
        const processedEmails = data.emails.map((email: any) => ({
          to: email.to || student.parents.map(p => p.email),
          subject: email.subject || subject,
          body: email.body || email.message || '',
          copied: false,
          studentId: student.id
        }));
        
        allEmails = [...allEmails, ...processedEmails];
        setGeneratedEmails(allEmails);

        if (!hasScrolled && allEmails.length > 0) {
          setTimeout(() => {
            emailContainerRef.current?.scrollIntoView({ 
              behavior: 'smooth',
              block: 'start'
            });
          }, 100);
          hasScrolled = true;
        }

      } catch (error) {
        console.error('Error generating email:', error);
        throw error;
      }

      // Create communications
      for (const email of allEmails) {
        try {
          const communication = await createCommunication({
            studentId: student.id,
            type: 'email',
            subject: email.subject,
            content: email.body,
            recipients: student.parents.map(p => p.email),
            sentDate: new Date().toISOString(),
            status: 'draft',
          });
          
          email.communicationId = communication?.id;
        } catch (commError) {
          console.error(`Failed to create communication:`, commError);
        }
      }

      setGeneratedEmails([...allEmails]);

    } catch (error) {
      console.error('Error generating emails:', error);
      setError(
        error instanceof Error 
          ? error.message 
          : 'Failed to generate emails. Please try again.'
      );
    } finally {
      setIsGenerating(false);
      setGenerationProgress(0);
    }
  };

  const handleCopyEmail = async (index: number, studentId: string, communicationId: string) => {
    if (!authState.user?.username) {
      setError('User not authenticated');
      return;
    }

    const email = generatedEmails[index];
    const text = `To: ${email.to.join(', ')}\nSubject: ${email.subject}\n\n${email.body}`;
    
    try {
      await navigator.clipboard.writeText(text);
      
      if (communicationId) {
        try {
          await updateCommunication({
            id: communicationId,
            studentId,
            status: 'sent',
            sentDate: new Date().toISOString()
          });
        } catch (updateError) {
          console.error('Failed to update communication status:', updateError);
        }
      }

      const newEmails = [...generatedEmails];
      newEmails[index] = { ...email, copied: true };
      setGeneratedEmails(newEmails);

      setTimeout(() => {
        const resetEmails = [...generatedEmails];
        resetEmails[index] = { ...email, copied: false };
        setGeneratedEmails(resetEmails);
      }, 2000);

    } catch (err) {
      console.error('Failed to copy to clipboard:', err);
      setError('Failed to copy email to clipboard. Please try selecting and copying manually.');
    }
  };
  
  return (
    <div className="max-w-4xl mx-auto px-4 pb-12">
      <button
        onClick={() => navigate(`/students/${student.id}`)}
        className="flex items-center gap-2 text-gray-600 hover:text-gray-900 mb-6"
      >
        <ArrowLeft className="h-4 w-4" />
        Back to Student Profile
      </button>

      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Reply to Communication</h1>
        <p className="text-gray-500">
          Generate a response for {student.firstName} {student.lastName}'s parent/guardian
        </p>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-50 rounded-lg flex items-start gap-3">
          <AlertCircle className="h-5 w-5 text-red-400 flex-shrink-0 mt-0.5" />
          <p className="text-sm font-medium text-red-800">{error}</p>
        </div>
      )}

      <div className="grid grid-cols-1 gap-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <RecipientList students={[student]} />
          
          <div className="mb-6">
            <label htmlFor="originalMessage" className="block text-sm font-medium text-gray-700 mb-1">
              Original Message
            </label>
            <textarea
              id="originalMessage"
              value={originalMessage}
              onChange={(e) => setOriginalMessage(e.target.value)}
              rows={4}
              className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              placeholder="Paste the original message here..."
              required
            />
          </div>

          <MessageForm
            subject={subject}
            message={message}
            additionalInstructions={additionalInstructions}
            isGenerating={isGenerating}
            generationProgress={generationProgress}
            onSubjectChange={setSubject}
            onMessageChange={setMessage}
            onInstructionsChange={setAdditionalInstructions}
            onSubmit={handleGenerateEmails}
          />
        </div>

        <EmailGenerator 
          ref={emailContainerRef}
          emails={generatedEmails}
          onCopy={handleCopyEmail}
        />
      </div>
    </div>
  );
}