import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ArrowLeft, ChevronDown, ChevronUp, Save, X, Archive } from 'lucide-react';
import { format } from 'date-fns';
import { usePlanRequests } from '../../contexts/PlanRequestContext';
import { useAlerts } from '../../contexts/AlertContext';
import PlanForm from '../../components/Planning/PlanForm';
import PlanUnit from '../../components/Planning/PlanUnit';
import UnitSelectionModal from '../../components/Planning/UnitSelectionModal';
import type { Plan } from '../../types';
import { useAuth } from '../../contexts/AuthContext';
import { fetchAuthSession } from 'aws-amplify/auth';

export default function FullYearPlan() {
  const { state: authState } = useAuth();
  const [input, setInput] = useState('');
  const [plan, setPlan] = useState<Plan | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const [expandedHistory, setExpandedHistory] = useState(false);
  const [originalInput, setOriginalInput] = useState('');
  
  const { addRequest, hidePlan, state: { requests }, loadPlans } = usePlanRequests();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { addAlert } = useAlerts();
  const generatedUnitsRef = useRef<HTMLDivElement>(null);

  // Load plans when component mounts
  useEffect(() => {
    loadPlans().catch(console.error);
  }, []);

  useEffect(() => {
    const requestId = searchParams.get('requestId');
    if (requestId) {
      const savedRequest = requests.find(req => req.id === requestId);
      if (savedRequest) {
        try {
          if (!savedRequest.output) {
            throw new Error('No plan data found');
          }
          const planData = JSON.parse(savedRequest.output);
          if (planData && Array.isArray(planData.units)) {
            setPlan(planData);
            setInput(savedRequest.input || '');
            setOriginalInput(savedRequest.input || '');
            setTimeout(() => {
              generatedUnitsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 100);
          } else {
            throw new Error('Invalid plan data format');
          }
        } catch (err) {
          setError('Failed to load saved plan');
          console.error('Error loading plan:', err);
          navigate('/planning/full-year', { replace: true });
        }
      } else {
        setPlan(null);
        setInput('');
        setOriginalInput('');
        navigate('/planning/full-year', { replace: true });
      }
    } else {
      setOriginalInput('');
    }
  }, [searchParams, requests]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;

    setIsLoading(true);
    setError(null);
    setPlan(null);

    try {
      const { tokens } = await fetchAuthSession();
      const idToken = tokens.idToken.toString();
      const sanitizedInput = input
        .replace(/[\u0000-\u001F\u007F-\u009F]/g, '')
        .replace(/\\/g, '\\\\')
        .replace(/"/g, '\\"')
        .trim();

      // Replace the fetch call in handleSubmit with:
      const response = await fetch('https://pdiun7qok2ijf45mtcz7szj5ga0xlqif.lambda-url.us-east-2.on.aws/', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${idToken}` // Using the Amplify token
          },
          body: JSON.stringify({
              messageRequest: sanitizedInput,
              requestingUser: 'user123',
              requestURL: window.location.origin + location.pathname,
          }),
      });

      if (!response.ok) {
        throw new Error(`Failed to generate plan: ${response.statusText}`);
      }

      const data = await response.json();
      
      if (!data) {
        throw new Error('No data received from server');
      }
      
      if (!Array.isArray(data.units)) {
        throw new Error('Invalid response format: missing units array');
      }
      
      setPlan(data);
      setOriginalInput(input);

      const sanitizedData = {
        ...data,
        units: data.units.map(unit => ({
          ...unit,
        }))
      };

      const savedRequest = await addRequest({
        input: sanitizedInput,
        output: JSON.stringify(sanitizedData),
        url: `${window.location.origin}${location.pathname}`,
        sonnetInput: data.sonnetInput || null,
        sonnetOutput: data.sonnetOutput || null
      });

      if (!savedRequest) {
        throw new Error('Failed to save plan to backend');
      }

      // Update URL without navigating
      window.history.replaceState(null, '', `?requestId=${savedRequest.id}`);

      // Add alert for later navigation
      addAlert({
        type: 'info',
        message: `Your curriculum plan for "${sanitizedInput}" is ready to view!`,
        date: new Date().toISOString(),
        metadata: {
          type: 'plan_ready',
          path: `/planning/full-year?requestId=${savedRequest.id}`
        }
      });

      // Scroll to Generated Units section
      setTimeout(() => {
        generatedUnitsRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 100);

    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An error occurred';
      setError(errorMessage);
      console.error('Error generating plan:', err);
      addAlert({
        type: 'urgent',
        message: `Failed to generate curriculum plan: ${errorMessage}`,
        date: new Date().toISOString(),
        metadata: {
          type: 'plan_ready',
          path: location.pathname
        }
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemovePlan = async (e: React.MouseEvent, requestId: string) => {
    e.preventDefault();
    e.stopPropagation();
    
    try {
      const currentRequestId = searchParams.get('requestId');
      if (currentRequestId === requestId) {
        setPlan(null);
        setInput('');
        setOriginalInput('');
        navigate('/planning/full-year', { replace: true });
      }
      
      await hidePlan(requestId);
      
      addAlert({
        type: 'info',
        message: 'Plan moved to hidden plans',
        date: new Date().toISOString(),
      });
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An error occurred';
      console.error('Error removing plan:', err);
      addAlert({
        type: 'error',
        message: `Failed to hide plan: ${errorMessage}`,
        date: new Date().toISOString(),
      });
    }
  };

  const loadPreviousPlan = (requestId: string) => {
    setError(null);
    navigate(`?requestId=${requestId}`);
  };

  const renderPreviousPlan = (request: PlanRequest) => {
    const isSelected = searchParams.get('requestId') === request.id;
    
    return (
      <div className="flex items-center justify-between hover:bg-gray-50 px-4 py-3">
        <button
          onClick={() => loadPreviousPlan(request.id)}
          className="flex-grow text-left flex items-center justify-between"
        >
          <div>
            <p className="text-sm font-medium text-gray-900">
              {request.input || 'Untitled Plan'}
            </p>
            <p className="text-xs text-gray-500">
              Generated on {format(new Date(request.timestamp), 'MMMM d, yyyy')}
            </p>
          </div>
          <ArrowLeft className="h-4 w-4 text-gray-400 rotate-180" />
        </button>
        <button
          onClick={(e) => handleRemovePlan(e, request.id)}
          className="ml-4 p-1 hover:bg-gray-100 rounded-full text-gray-400 hover:text-gray-600"
          title="Move to hidden plans"
        >
          <Archive className="h-4 w-4" />
        </button>
      </div>
    );
  };

  const uniqueRequests = Array.from(new Map(requests.map(req => [req.id, req])).values());

  return (
    <div className="max-w-4xl mx-auto">
      <button
        onClick={() => navigate('/planning')}
        className="flex items-center gap-2 text-gray-600 hover:text-gray-900 mb-6"
      >
        <ArrowLeft className="h-4 w-4" />
        Back to Planning Tools
      </button>

      {error && (
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-8">
          <p className="text-red-800">{error}</p>
        </div>
      )}

      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Year-Long Plan Generator</h1>
        <p className="text-gray-500">Create a comprehensive year-long curriculum plan</p>
      </div>

      <PlanForm
        input={input}
        isLoading={isLoading}
        onInputChange={setInput}
        onSubmit={handleSubmit}
        originalInput={originalInput}
      />

      {uniqueRequests.length > 0 && (
        <div className="mt-8 bg-white rounded-lg shadow-sm overflow-hidden">
          <div className="flex items-center justify-between px-4 py-3 border-b border-gray-200">
            <button
              onClick={() => setExpandedHistory(!expandedHistory)}
              className="flex items-center gap-2 text-gray-900 font-medium"
            >
              Previous Plans
              {expandedHistory ? (
                <ChevronUp className="h-5 w-5 text-gray-500" />
              ) : (
                <ChevronDown className="h-5 w-5 text-gray-500" />
              )}
            </button>
            <button
              onClick={() => navigate('/planning/hidden-plans')}
              className="flex items-center gap-2 text-sm text-gray-600 hover:text-gray-900"
            >
              <Archive className="h-4 w-4" />
              View Hidden Plans
            </button>
          </div>
          
          {expandedHistory && (
            <div className="divide-y divide-gray-200">
              {uniqueRequests.map((request) => (
                <React.Fragment key={request.id}>
                  {renderPreviousPlan(request)}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      )}

      {plan && plan.units && (
        <>
          <div ref={generatedUnitsRef} className="flex justify-between items-center mb-6 mt-12">
            <h2 className="text-xl font-semibold text-gray-900">Generated Units</h2>
            <button
              onClick={() => setShowSelectionModal(true)}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
              disabled={isLoading}
            >
              <Save className="h-4 w-4" />
              Save Units to My Plan
            </button>
          </div>

          <div className="space-y-8">
            {plan.units.map((unit, index) => (
              <PlanUnit key={index} unit={unit} index={index} />
            ))}
          </div>

          {showSelectionModal && (
            <UnitSelectionModal
              units={plan.units}
              onClose={() => setShowSelectionModal(false)}
            />
          )}
        </>
      )}
    </div>
  );
}