import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useCalendar } from '../../../contexts/CalendarContext';
import { format, parseISO, eachDayOfInterval } from 'date-fns'; 

interface NonSchoolDayModalProps {
  onClose: () => void;
}

// COMPONENTS_PLANNING_CALENDAR_NonSchoolDayModal.tsx

export default function NonSchoolDayModal({ onClose }: NonSchoolDayModalProps) {
  const [mode, setMode] = useState<'single' | 'range'>('single');
  const [formData, setFormData] = useState({
    startDate: format(new Date(), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
    description: '',
    type: 'holiday' as 'holiday' | 'break' | 'other'
  });
  const { addNonSchoolDay, addMultipleNonSchoolDays } = useCalendar();

  const handleSubmit = async (e: React.FormEvent) => {
  e.preventDefault();
  
  if (mode === 'single') {
    // Add single non-school day
    await addNonSchoolDay({
      id: Math.random().toString(36).substring(2, 9),
      date: formData.startDate,
      description: formData.description,
      type: formData.type,
    });
  } else {
    // Add range of non-school days
    const start = parseISO(formData.startDate);
    const end = parseISO(formData.endDate);
    
    // Create an array of dates between start and end
    const dates = eachDayOfInterval({ start, end });
    
    // Create array of non-school days
    const daysToAdd = dates.map(date => ({
      date: format(date, 'yyyy-MM-dd'),
      description: formData.description,
      type: formData.type,
    }));

    // Add all days at once
    await addMultipleNonSchoolDays(daysToAdd);
  }
  
  onClose();
};

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-md w-full p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900">Add Non-School Day(s)</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex gap-4 mb-4">
            <button
              type="button"
              onClick={() => setMode('single')}
              className={`flex-1 py-2 px-4 rounded-lg ${
                mode === 'single' 
                  ? 'bg-indigo-100 text-indigo-700' 
                  : 'bg-gray-100 text-gray-700'
              }`}
            >
              Single Day
            </button>
            <button
              type="button"
              onClick={() => setMode('range')}
              className={`flex-1 py-2 px-4 rounded-lg ${
                mode === 'range' 
                  ? 'bg-indigo-100 text-indigo-700' 
                  : 'bg-gray-100 text-gray-700'
              }`}
            >
              Date Range
            </button>
          </div>

          {mode === 'single' ? (
            <div>
              <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">
                Date
              </label>
              <input
                type="date"
                id="date"
                value={formData.startDate}
                onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
                className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="startDate" className="block text-sm font-medium text-gray-700 mb-1">
                  Start Date
                </label>
                <input
                  type="date"
                  id="startDate"
                  value={formData.startDate}
                  onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
                  className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  required
                />
              </div>
              <div>
                <label htmlFor="endDate" className="block text-sm font-medium text-gray-700 mb-1">
                  End Date
                </label>
                <input
                  type="date"
                  id="endDate"
                  value={formData.endDate}
                  onChange={(e) => setFormData({ ...formData, endDate: e.target.value })}
                  className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  required
                />
              </div>
            </div>
          )}

          {/* Type selection field */}
<div>
  <label htmlFor="type" className="block text-sm font-medium text-gray-700 mb-1">
    Type
  </label>
  <select
    id="type"
    value={formData.type}
    onChange={(e) => setFormData({ ...formData, type: e.target.value as 'holiday' | 'break' | 'other' })}
    className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
    required
  >
    <option value="holiday">Holiday</option>
    <option value="break">Break</option>
    <option value="other">Other</option>
  </select>
</div>

{/* Description field */}
<div>
  <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
    Description
  </label>
  <input
    type="text"
    id="description"
    value={formData.description}
    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
    placeholder={
      formData.type === 'holiday' ? 'e.g., Christmas Day, Thanksgiving' :
      formData.type === 'break' ? 'e.g., Winter Break, Spring Break' :
      'e.g., Teacher In-Service Day'
    }
    className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
    required
  />
</div>

{/* Submit buttons */}
<div className="flex justify-end gap-3 pt-4">
  <button
    type="button"
    onClick={onClose}
    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
  >
    Cancel
  </button>
  <button
    type="submit"
    className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
  >
    {mode === 'single' ? 'Add Non-School Day' : 'Add Non-School Days'}
  </button>
</div>
        </form>
      </div>
    </div>
  );
}