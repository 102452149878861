import React, { useState, useMemo } from 'react';
import { CheckCircle, Circle, Plus, X, AlertCircle, Pencil, Repeat } from 'lucide-react';
import { useTasks } from '../../contexts/TaskContext';
import { formatDate } from '../../utils/dateFormatter';
import type { Task, RecurrencePattern } from '../../types';
import TaskSort, { SortField, SortDirection } from './TaskSort';
import TaskFilters, { FilterType, SourceType } from './TaskFilters';
import EditTaskModal from './EditTaskModal';
import { startOfDay, isToday, isPast, isFuture, parseISO, addHours } from 'date-fns';

const priorityOrder = {
  high: 0,
  medium: 1,
  low: 2,
};

export default function TasksWidget() {
  const { state, addTask, updateTask, deleteTask, clearError } = useTasks();
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [editingTask, setEditingTask] = useState<Task | null>(null);
  const [sortField, setSortField] = useState<SortField>('dueDate');
  const [sortDirection, setSortDirection] = useState<SortDirection>('asc');
  const [activeFilter, setActiveFilter] = useState<FilterType>('all');
  const [activeSource, setActiveSource] = useState<SourceType>('all');
  const [newTask, setNewTask] = useState({
    title: '',
    dueDate: '',
    priority: 'medium' as const,
    recurrence: {
      pattern: 'none' as RecurrencePattern,
      endDate: '',
    },
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (newTask.title && newTask.dueDate) {
      try {
        await addTask({
          ...newTask,
          completed: false,
          recurrence: newTask.recurrence.pattern === 'none' 
            ? undefined 
            : {
                pattern: newTask.recurrence.pattern,
                endDate: newTask.recurrence.endDate || undefined
              }
        });
        setNewTask({ 
          title: '', 
          dueDate: '', 
          priority: 'medium',
          recurrence: { pattern: 'none', endDate: '' }
        });
        setIsAddingTask(false);
      } catch (error) {
        // Error is handled by the context
      }
    }
  };

  const handleToggleTask = async (task: Task) => {
    try {
      await updateTask({
        ...task,
        completed: !task.completed,
      });
    } catch (error) {
      // Error is handled by the context
    }
  };

  const handleDeleteTask = async (id: string) => {
    try {
      await deleteTask(id);
    } catch (error) {
      // Error is handled by the context
    }
  };

  const handleSort = (field: SortField, direction: SortDirection) => {
    setSortField(field);
    setSortDirection(direction);
  };

  const handleEditTask = async (updatedTask: Task) => {
    try {
      await updateTask(updatedTask);
    } catch (error) {
      throw error;
    }
  };

  const filterTasks = (tasks: Task[]): Task[] => {
    // First apply source filter
    let filtered = tasks;
    if (activeSource !== 'all') {
      filtered = tasks.filter(task => 
        activeSource === 'mine' ? task.source === 'user' : task.source === 'system'
      );
    }
    
    // Then apply date filter
    switch (activeFilter) {
      case 'today':
        return filtered.filter(task => isToday(parseISO(task.dueDate)));
      case 'overdue':
        return filtered.filter(task => 
          !task.completed && 
          isPast(new Date(task.dueDate)) && 
          !isToday(parseISO(task.dueDate))
        );
      case 'upcoming':
        return filtered.filter(task => 
          isFuture(new Date(task.dueDate)) || 
          isToday(parseISO(task.dueDate))
        );
      default:
        return filtered;
    }
  };

  const sortTasks = (tasks: Task[]) => {
    return [...tasks].sort((a, b) => {
      let comparison = 0;

      switch (sortField) {
        case 'priority':
          comparison = priorityOrder[a.priority] - priorityOrder[b.priority];
          break;
        case 'dueDate':
          comparison = parseISO(a.dueDate).getTime() - parseISO(b.dueDate).getTime();
          break;
        case 'createdAt':
          comparison = new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
          break;
      }

      return sortDirection === 'asc' ? comparison : -comparison;
    });
  };

  const filteredAndSortedTasks = useMemo(() => {
    const filtered = filterTasks(state.tasks);
    return sortTasks(filtered);
  }, [state.tasks, activeFilter, activeSource, sortField, sortDirection]);

  const taskCounts = useMemo(() => {
    return {
      today: state.tasks.filter(task => isToday(parseISO(task.dueDate))).length,
      overdue: state.tasks.filter(task => 
        !task.completed && 
        isPast(new Date(task.dueDate)) && 
        !isToday(parseISO(task.dueDate))
      ).length,
      upcoming: state.tasks.filter(task => 
        isFuture(new Date(task.dueDate)) || 
        isToday(parseISO(task.dueDate))
      ).length,
      mine: state.tasks.filter(task => task.source === 'user').length,
      system: state.tasks.filter(task => task.source === 'system').length,
    };
  }, [state.tasks]);

  return (
    <div className="bg-white rounded-xl shadow-sm p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-lg font-semibold text-gray-900">Tasks</h2>
        <button
          onClick={() => setIsAddingTask(true)}
          className="text-sm text-indigo-600 hover:text-indigo-800 flex items-center gap-1"
        >
          <Plus className="h-4 w-4" />
          Add Task
        </button>
      </div>

      {state.error && (
        <div className="mb-4 p-4 bg-red-50 rounded-lg flex items-center justify-between">
          <div className="flex items-center gap-2 text-red-800">
            <AlertCircle className="h-5 w-5" />
            <p className="text-sm">{state.error}</p>
          </div>
          <button
            onClick={clearError}
            className="text-red-600 hover:text-red-800"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
      )}

      {isAddingTask && (
        <form onSubmit={handleSubmit} className="mb-6 p-4 bg-gray-50 rounded-lg">
          <div className="space-y-4">
            <div>
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                Task Title
              </label>
              <input
                type="text"
                id="title"
                value={newTask.title}
                onChange={(e) => setNewTask({ ...newTask, title: e.target.value })}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="dueDate" className="block text-sm font-medium text-gray-700">
                  Due Date
                </label>
                <input
                  type="date"
                  id="dueDate"
                  value={newTask.dueDate}
                  onChange={(e) => setNewTask({ ...newTask, dueDate: e.target.value })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  required
                />
              </div>
              <div>
                <label htmlFor="priority" className="block text-sm font-medium text-gray-700">
                  Priority
                </label>
                <select
                  id="priority"
                  value={newTask.priority}
                  onChange={(e) => setNewTask({ ...newTask, priority: e.target.value as Task['priority'] })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  <option value="low">Low</option>
                  <option value="medium">Medium</option>
                  <option value="high">High</option>
                </select>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="recurrence" className="block text-sm font-medium text-gray-700">
                  <div className="flex items-center gap-2">
                    <Repeat className="h-4 w-4" />
                    Recurrence
                  </div>
                </label>
                <select
                  id="recurrence"
                  value={newTask.recurrence.pattern}
                  onChange={(e) => setNewTask({
                    ...newTask,
                    recurrence: {
                      ...newTask.recurrence,
                      pattern: e.target.value as RecurrencePattern
                    }
                  })}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                >
                  <option value="none">No Recurrence</option>
                  <option value="daily">Daily</option>
                  <option value="weekdays">Every Weekday</option>
                  <option value="weekly">Weekly</option>
                  <option value="monthly">Monthly</option>
                </select>
              </div>

              {newTask.recurrence.pattern !== 'none' && (
                <div>
                  <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">
                    End Date (Optional)
                  </label>
                  <input
                    type="date"
                    id="endDate"
                    value={newTask.recurrence.endDate}
                    onChange={(e) => setNewTask({
                      ...newTask,
                      recurrence: {
                        ...newTask.recurrence,
                        endDate: e.target.value
                      }
                    })}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                    min={newTask.dueDate}
                  />
                </div>
              )}
            </div>

            <div className="flex justify-end gap-2">
              <button
                type="button"
                onClick={() => setIsAddingTask(false)}
                className="px-3 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={state.isLoading}
                className="px-3 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700 disabled:opacity-50"
              >
                {state.isLoading ? 'Adding...' : 'Add Task'}
              </button>
            </div>
          </div>
        </form>
      )}

      <div className="mb-6">
        <TaskFilters
          activeFilter={activeFilter}
          activeSource={activeSource}
          onFilterChange={setActiveFilter}
          onSourceChange={setActiveSource}
          counts={taskCounts}
        />
      </div>

      {filteredAndSortedTasks.length > 0 && (
        <div className="mb-4">
          <TaskSort
            field={sortField}
            direction={sortDirection}
            onSort={handleSort}
          />
        </div>
      )}

      <div className="space-y-4">
        {state.isLoading && !isAddingTask ? (
          <div className="text-center py-4">
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600 mx-auto"></div>
          </div>
        ) : filteredAndSortedTasks.length === 0 ? (
          <p className="text-center text-gray-500 py-4">No tasks found</p>
        ) : (
          filteredAndSortedTasks.map((task) => (
            <div
              key={task.id}
              className={`flex items-center gap-4 p-4 rounded-lg ${
                task.completed ? 'bg-gray-50' : 'bg-white'
              }`}
            >
              <button 
                className="flex-shrink-0"
                onClick={() => handleToggleTask(task)}
                disabled={state.isLoading}
              >
                {task.completed ? (
                  <CheckCircle className="h-5 w-5 text-green-500" />
                ) : (
                  <Circle className="h-5 w-5 text-gray-300" />
                )}
              </button>
              <div className="flex-1 min-w-0">
                <p
                  className={`text-sm ${
                    task.completed ? 'text-gray-500 line-through' : 'text-gray-900'
                  }`}
                >
                  {task.title}
                </p>
                <p className="text-xs text-gray-500 mt-1">
                  Due: {formatDate(addHours(parseISO(task.dueDate), 18))}
                </p>
                {task.recurrence && task.recurrence.pattern !== 'none' && (
                  <p className="text-xs text-gray-500 flex items-center gap-1 mt-1">
                    <Repeat className="h-3 w-3" />
                    {task.recurrence.pattern}
                    {task.recurrence.endDate && ` until ${formatDate(task.recurrence.endDate)}`}
                  </p>
                )}
              </div>
              <span
                className={`px-2 py-1 text-xs rounded-full ${
                  task.priority === 'high'
                    ? 'bg-red-100 text-red-800'
                    : task.priority === 'medium'
                    ? 'bg-yellow-100 text-yellow-800'
                    : 'bg-green-100 text-green-800'
                }`}
              >
                {task.priority}
              </span>
              <div className="flex gap-2">
                <button
                  onClick={() => setEditingTask(task)}
                  disabled={state.isLoading}
                  className="text-gray-400 hover:text-gray-600 disabled:opacity-50"
                >
                  <Pencil className="h-5 w-5" />
                </button>
                <button
                  onClick={() => handleDeleteTask(task.id)}
                  disabled={state.isLoading}
                  className="text-gray-400 hover:text-gray-600 disabled:opacity-50"
                >
                  <X className="h-5 w-5" />
                </button>
              </div>
            </div>
          ))
        )}
      </div>

      {editingTask && (
        <EditTaskModal
          task={editingTask}
          onClose={() => setEditingTask(null)}
          onSave={handleEditTask}
        />
      )}
    </div>
  );
}