import React, { useState } from 'react';
import { X, AlertCircle, Calendar } from 'lucide-react';
import type { CalendarEvent } from '../../../types/calendar';
import { useCalendar } from '../../../contexts/CalendarContext';
import { format, parseISO } from 'date-fns';

interface SkipDayModalProps {
  event: CalendarEvent;
  selectedDate: string;
  onClose: () => void;
}

export default function SkipDayModal({ event, selectedDate, onClose }: SkipDayModalProps) {
  const { addSkipDay, validateSkipDay, getSkipDayImpact } = useCalendar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const isValid = validateSkipDay(event.id, selectedDate);
  const impact = isValid ? getSkipDayImpact(event.id, selectedDate) : null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isValid) return;

    setIsSubmitting(true);
    setError(null);

    try {
      await addSkipDay(event.id, selectedDate);
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to add skip day');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-md w-full p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900">Add Skip Day</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-5 w-5" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <h3 className="text-sm font-medium text-gray-900 mb-2">Unit</h3>
            <p className="text-sm text-gray-500">{event.title}</p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-900 mb-2">Selected Date</h3>
            <div className="flex items-center gap-2 text-gray-600">
              <Calendar className="h-5 w-5" />
              <span>{format(parseISO(selectedDate), 'MMMM d, yyyy')}</span>
            </div>
          </div>

          {!isValid && (
            <div className="p-4 bg-red-50 rounded-lg">
              <div className="text-sm text-red-600">
                <div>This date cannot be marked as a skip day. Skip days must be:</div>
                <ul className="list-disc list-inside mt-2">
                  <li>Within the unit's scheduled dates</li>
                  <li>Not on weekends</li>
                  <li>Not on non-school days</li>
                  <li>Not already marked as a skip day</li>
                </ul>
              </div>
            </div>
          )}

          {isValid && impact && (
            <div className="p-4 bg-yellow-50 rounded-lg">
              <div className="flex items-start gap-2">
                <AlertCircle className="h-5 w-5 text-yellow-400 flex-shrink-0" />
                <div>
                  <p className="text-sm text-yellow-800">
                    Adding this skip day will extend the unit by {impact.daysExtended} calendar day(s).
                  </p>
                  <p className="text-sm text-yellow-600 mt-1">
                    New end date will be: {format(new Date(impact.newEndDate), 'MMMM d, yyyy')}
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="pt-4 border-t flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!isValid || isSubmitting}
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
            >
              {isSubmitting ? 'Adding...' : 'Add Skip Day'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}