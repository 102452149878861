import React, { useState, useEffect } from 'react';
import { Calendar, Clock, Filter, Download, Pencil, Trash2, Plus } from 'lucide-react';
import { format, parseISO, isToday, startOfMonth, endOfMonth, startOfWeek, endOfWeek } from 'date-fns';
import type { Student } from '../../types';
import type { AttendanceRecord } from '../../types/attendance';
import { useAttendance } from '../../contexts/AttendanceContext';
import { exportAttendanceReport } from '../../services/attendance';
import AttendanceModal from './AttendanceModal';

interface StudentAttendanceListProps {
  student: Student;
}

type TimeFilter = 'day' | 'week' | 'month' | 'year';
type StatusFilter = 'all' | 'absent' | 'partial';

export default function StudentAttendanceList({ student }: StudentAttendanceListProps) {
  const { state, getStudentAttendance, deleteAttendance } = useAttendance();
  const [timeFilter, setTimeFilter] = useState<TimeFilter>('month');
  const [statusFilter, setStatusFilter] = useState<StatusFilter>('all');
  const [isExporting, setIsExporting] = useState(false);
  const [editingRecord, setEditingRecord] = useState<AttendanceRecord | null>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<string | null>(null);
  const [showAddModal, setShowAddModal] = useState(false);

  useEffect(() => {
    loadAttendanceData();
  }, [student.id, timeFilter]);

  const loadAttendanceData = async () => {
    try {
      const { startDate, endDate } = getDateRangeForFilter(timeFilter);
      console.log('Loading attendance data with:', { startDate, endDate });
      await getStudentAttendance(student.id, startDate, endDate);
      console.log('Current records after load:', state.records);
    } catch (error) {
      console.error('Failed to load attendance data:', error);
    }
  };

  const handleExport = async () => {
    try {
      setIsExporting(true);
      const today = new Date();
      const startDate = format(startOfMonth(today), 'yyyy-MM-dd');
      const endDate = format(endOfMonth(today), 'yyyy-MM-dd');
      
      const blob = await exportAttendanceReport(
        localStorage.getItem('userId') || '',
        startDate,
        endDate,
        student.id
      );

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `attendance-${student.firstName}-${student.lastName}-${format(today, 'yyyy-MM')}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Failed to export attendance:', error);
    } finally {
      setIsExporting(false);
    }
  };

  const handleDelete = async (recordId: string) => {
    try {
      await deleteAttendance(recordId);
      setShowDeleteConfirm(null);
      await loadAttendanceData();
    } catch (error) {
      console.error('Failed to delete attendance record:', error);
    }
  };


  const getDateRangeForFilter = (filterType: TimeFilter) => {
  const today = new Date();
  let startDate, endDate;

  switch (filterType) {
    case 'day':
      startDate = format(today, 'yyyy-MM-dd');
      endDate = startDate;
      break;
    case 'week':
      startDate = format(startOfWeek(today), 'yyyy-MM-dd');
      endDate = format(endOfWeek(today), 'yyyy-MM-dd');
      break;
    case 'month':
      startDate = format(startOfMonth(today), 'yyyy-MM-dd');
      endDate = format(endOfMonth(today), 'yyyy-MM-dd');
      break;
    case 'year':
      startDate = format(new Date(today.getFullYear(), 0, 1), 'yyyy-MM-dd');
      endDate = format(new Date(today.getFullYear(), 11, 31), 'yyyy-MM-dd');
      break;
  }

  return { startDate, endDate };
};

  
  const filteredRecords = state.records
  .filter(record => record.studentId === student.id)
  .filter(record => {
    // Date filter
    const { startDate, endDate } = getDateRangeForFilter(timeFilter);
    const recordDate = parseISO(record.date);
    const start = parseISO(startDate);
    const end = parseISO(endDate);
    return recordDate >= start && recordDate <= end;
  })
  .filter(record => {
    // Status filter
    if (statusFilter === 'all') return true;
    return record.status === statusFilter;
  })
  .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="p-6 border-b border-gray-200">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-lg font-semibold text-gray-900">Attendance History</h2>
          <div className="flex items-center gap-3">
            <button
              onClick={() => setShowAddModal(true)}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md hover:bg-indigo-700"
            >
              <Plus className="h-4 w-4" />
              Add Record
            </button>
            <button
              onClick={handleExport}
              disabled={isExporting}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-indigo-600 hover:text-indigo-800"
            >
              <Download className="h-4 w-4" />
              Export
            </button>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Time Period</label>
            <select
              value={timeFilter}
              onChange={(e) => setTimeFilter(e.target.value as TimeFilter)}
              className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value="day">Today</option>
              <option value="week">This Week</option>
              <option value="month">This Month</option>
              <option value="year">This Year</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">Status</label>
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value as StatusFilter)}
              className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value="all">All Records</option>
              <option value="absent">Absences Only</option>
              <option value="partial">Partial Days Only</option>
            </select>
          </div>
        </div>
      </div>

      <div className="divide-y divide-gray-200">
        {filteredRecords.length === 0 ? (
          <p className="text-center py-8 text-gray-500">No attendance records found</p>
        ) : (
          filteredRecords.map(record => (
            <div key={record.id} className="p-6">
              <div className="flex items-start justify-between">
                <div className="flex items-start gap-4">
                  <Calendar className="h-5 w-5 text-gray-400" />
                  <div>
                    <div className="flex items-center gap-2">
                      <span className="text-sm font-medium text-gray-900">
                        {format(parseISO(record.date), 'MMMM d, yyyy')}
                      </span>
                      <span className={`px-2 py-0.5 text-xs font-medium rounded-full ${
                        record.status === 'absent' 
                          ? 'bg-red-100 text-red-800'
                          : record.status === 'partial'
                          ? 'bg-yellow-100 text-yellow-800'
                          : 'bg-green-100 text-green-800'
                      }`}>
                        {record.status === 'absent' 
                          ? 'Absent'
                          : record.status === 'partial'
                          ? 'Partial Day'
                          : 'Present'}
                      </span>
                      {isToday(parseISO(record.date)) && (
                        <span className="px-2 py-0.5 text-xs font-medium bg-blue-100 text-blue-800 rounded-full">
                          Today
                        </span>
                      )}
                    </div>

                    {record.missedLessons && record.missedLessons.length > 0 && (
                      <div className="mt-2">
                        <p className="text-sm text-gray-600">Missed Lessons:</p>
                        <ul className="mt-1 space-y-1">
                          {record.missedLessons.map((lesson, index) => (
                            <li key={index} className="flex items-center gap-2 text-sm text-gray-600">
                              <Clock className="h-4 w-4 text-gray-400" />
                              <span>{lesson.title}</span>
                              <span className="text-xs text-gray-500">({lesson.period})</span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    )}

                    {record.notes && (
                      <p className="mt-2 text-sm text-gray-600">
                        Notes: {record.notes}
                      </p>
                    )}
                  </div>
                </div>

                <div className="flex items-center gap-2">
                  <button
                    onClick={() => setEditingRecord(record)}
                    className="p-1 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100"
                  >
                    <Pencil className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => setShowDeleteConfirm(record.id)}
                    className="p-1 text-red-400 hover:text-red-600 rounded-full hover:bg-red-50"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                </div>
              </div>

              {showDeleteConfirm === record.id && (
                <div className="mt-4 p-4 bg-red-50 rounded-lg">
                  <p className="text-sm text-red-600">Are you sure you want to delete this attendance record?</p>
                  <div className="mt-4 flex justify-end gap-3">
                    <button
                      onClick={() => setShowDeleteConfirm(null)}
                      className="px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-50"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => handleDelete(record.id)}
                      className="px-3 py-1.5 text-sm font-medium text-white bg-red-600 rounded hover:bg-red-700"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>

      {editingRecord && (
        <AttendanceModal
          students={[student]}
          onClose={() => setEditingRecord(null)}
          date={editingRecord.date}
          editingRecord={editingRecord}
          existingRecord={editingRecord}  // Pass the full record
        />
      )}

      {showAddModal && (
        <AttendanceModal
          students={[student]}
          onClose={() => setShowAddModal(false)}
          date={format(new Date(), 'yyyy-MM-dd')}
        />
      )}
    </div>
  );
}