
import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { useAuth } from './AuthContext';
import * as communicationApi from '../services/communication';
import type { MessageTemplate, Communication } from '../types/communication';

interface CommunicationState {
  templates: MessageTemplate[];
  communications: Communication[];
  isLoading: boolean;
  error: string | null;
  isInitialized: boolean;
}

type CommunicationAction =
  | { type: 'SET_TEMPLATES'; payload: MessageTemplate[] }
  | { type: 'ADD_TEMPLATE'; payload: MessageTemplate }
  | { type: 'UPDATE_TEMPLATE'; payload: MessageTemplate }
  | { type: 'DELETE_TEMPLATE'; payload: string }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'CLEAR_ERROR' }
  | { type: 'SET_INITIALIZED' }
  | { type: 'SET_COMMUNICATIONS'; payload: Communication[] }  // Add this
  | { type: 'ADD_COMMUNICATION'; payload: Communication }     // Add this
  | { type: 'UPDATE_COMMUNICATION'; payload: Partial<Communication> & { id: string } };

interface CommunicationContextType {
  state: CommunicationState;
  createTemplate: (template: Omit<MessageTemplate, 'id' | 'userId' | 'createdAt' | 'lastModified'>) => Promise<void>;
  updateTemplate: (template: MessageTemplate) => Promise<void>;
  deleteTemplate: (id: string) => Promise<void>;
  createCommunication: (communication: Omit<Communication, 'id' | 'userId' | 'createdAt'>) => Promise<Communication>;
  updateCommunication: (communication: Partial<Communication> & { id: string }) => Promise<void>;
  getStudentCommunications: (studentId: string) => Promise<Communication[]>;
  clearError: () => void;
  refreshTemplates: () => Promise<void>;
}

const CommunicationContext = createContext<CommunicationContextType | undefined>(undefined);

const initialState: CommunicationState = {
  templates: [],
  communications: [],
  isLoading: false,
  error: null,
  isInitialized: false,
};

function communicationReducer(state: CommunicationState, action: CommunicationAction): CommunicationState {
  switch (action.type) {
    case 'SET_TEMPLATES':
      return {
        ...state,
        templates: action.payload,
        isLoading: false,
        error: null,
      };
    case 'ADD_TEMPLATE':
      return {
        ...state,
        templates: [...state.templates, action.payload],
        isLoading: false,
        error: null,
      };
    case 'UPDATE_TEMPLATE':
      return {
        ...state,
        templates: state.templates.map(template =>
          template.id === action.payload.id ? action.payload : template
        ),
        isLoading: false,
        error: null,
      };
    case 'DELETE_TEMPLATE':
      return {
        ...state,
        templates: state.templates.filter(template => template.id !== action.payload),
        isLoading: false,
        error: null,
      };
    case 'SET_COMMUNICATIONS':
      return {
        ...state,
        communications: action.payload,
        isLoading: false,
        error: null,
      };
    case 'ADD_COMMUNICATION':
      return {
        ...state,
        communications: [...state.communications, action.payload],
        isLoading: false,
        error: null,
      };
    case 'UPDATE_COMMUNICATION':
      return {
        ...state,
        communications: state.communications.map(comm =>
          comm.id === action.payload.id ? { ...comm, ...action.payload } : comm
        ),
        isLoading: false,
        error: null,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
        error: null,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case 'CLEAR_ERROR':
      return {
        ...state,
        error: null,
      };
    case 'SET_INITIALIZED':
      return {
        ...state,
        isInitialized: true,
      };
    default:
      return state;
  }
}





export function CommunicationProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(communicationReducer, initialState);
  const { state: authState } = useAuth();

  
  
  const loadTemplates = async () => {
    if (!authState.user?.username) {
      console.log('No username available - skipping template load');
      return;
    }

    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const templates = await communicationApi.getMessageTemplates(authState.user.username);
      dispatch({ type: 'SET_TEMPLATES', payload: templates });
    } catch (error) {
      console.error('Error loading templates:', error);
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error instanceof Error ? error.message : 'Failed to load templates' 
      });
    } finally {
      dispatch({ type: 'SET_INITIALIZED' });
    }
  };

  useEffect(() => {
    if (authState.isAuthenticated && authState.user?.username && !state.isInitialized) {
    loadTemplates();
  }
}, [authState.isAuthenticated, authState.user?.username, state.isInitialized]);


  const createTemplate = async (template: Omit<MessageTemplate, 'id' | 'userId' | 'createdAt' | 'lastModified'>) => {
    if (!authState.user?.username) {
      throw new Error('User not authenticated');
    }

    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const newTemplate = await communicationApi.createMessageTemplate(
        authState.user.username,
        template
      );
      dispatch({ type: 'ADD_TEMPLATE', payload: newTemplate });
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error instanceof Error ? error.message : 'Failed to create template' 
      });
      throw error;
    }
  };

  const updateTemplate = async (template: MessageTemplate) => {
    if (!authState.user?.username) {
      throw new Error('User not authenticated');
    }

    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const updatedTemplate = await communicationApi.updateMessageTemplate(
        authState.user.username,
        template
      );
      dispatch({ type: 'UPDATE_TEMPLATE', payload: updatedTemplate });
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error instanceof Error ? error.message : 'Failed to update template' 
      });
      throw error;
    }
  };

  const deleteTemplate = async (id: string) => {
    if (!authState.user?.username) {
      throw new Error('User not authenticated');
    }

    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      await communicationApi.deleteMessageTemplate(authState.user.username, id);
      dispatch({ type: 'DELETE_TEMPLATE', payload: id });
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error instanceof Error ? error.message : 'Failed to delete template' 
      });
      throw error;
    }
  };

  const createCommunication = async (communication: Omit<Communication, 'id' | 'userId' | 'createdAt'>) => {
  if (!authState.user?.username) {
    throw new Error('User not authenticated');
  }

  try {
    const newCommunication = await communicationApi.createCommunication(
      authState.user.username,
      communication
    );
    dispatch({ type: 'ADD_COMMUNICATION', payload: newCommunication });
    return newCommunication;
  } catch (error) {
    dispatch({ 
      type: 'SET_ERROR', 
      payload: error instanceof Error ? error.message : 'Failed to create communication' 
    });
    throw error;
  }
};

  const updateCommunication = async (communication: Partial<Communication> & { id: string }) => {
    if (!authState.user?.username) {
      throw new Error('User not authenticated');
    }

    try {
      await communicationApi.updateCommunication(
        authState.user.username,
        communication
      );
      dispatch({ type: 'UPDATE_COMMUNICATION', payload: communication });
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error instanceof Error ? error.message : 'Failed to update communication' 
      });
      throw error;
    }
  };

  const getStudentCommunications = async (studentId: string) => {
    if (!authState.user?.username) {
      throw new Error('User not authenticated');
    }

    try {
      return await communicationApi.getStudentCommunications(
        authState.user.username,
        studentId
      );
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error instanceof Error ? error.message : 'Failed to fetch communications' 
      });
      throw error;
    }
  };

  const clearError = () => {
    dispatch({ type: 'CLEAR_ERROR' });
  };

  const refreshTemplates = () => loadTemplates();

  return (
    <CommunicationContext.Provider
      value={{
        state,
        createTemplate,
        updateTemplate,
        deleteTemplate,
        createCommunication,
        updateCommunication,
        getStudentCommunications,
        clearError,
        refreshTemplates,
      }}
    >
      {children}
    </CommunicationContext.Provider>
  );
}

export function useCommunication() {
  const context = useContext(CommunicationContext);
  if (context === undefined) {
    throw new Error('useCommunication must be used within a CommunicationProvider');
  }
  return context;
}


