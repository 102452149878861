import React from 'react';
import { X } from 'lucide-react';
import { useStudents } from '../../../contexts/StudentContext';
import type { Student } from '../../../types/student';
import BasicInfoStep from '../AddStudentModal/BasicInfoStep';
import ParentInfoStep from '../AddStudentModal/ParentInfoStep';
import MedicalInfoStep from '../AddStudentModal/MedicalInfoStep';

interface EditStudentModalProps {
  student: Student;
  onClose: () => void;
}

type Step = 'basic' | 'parent' | 'medical';

export default function EditStudentModal({ student, onClose }: EditStudentModalProps) {
  const [currentStep, setCurrentStep] = React.useState<Step>('basic');
  const [studentData, setStudentData] = React.useState(student);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const { updateStudent } = useStudents();

  const handleBasicInfoSubmit = (basicInfo: Partial<Student>) => {
    setStudentData(prev => ({ ...prev, ...basicInfo }));
    setCurrentStep('parent');
  };

  const handleParentInfoSubmit = (parents: Student['parents']) => {
    setStudentData(prev => ({ ...prev, parents }));
    setCurrentStep('medical');
  };

  const handleMedicalInfoSubmit = async (medicalInfo: Partial<Student>) => {
    setIsSubmitting(true);
    setError(null);

    try {
      const finalStudentData = {
        ...studentData,
        ...medicalInfo,
      };

      await updateStudent(finalStudentData);
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update student');
    } finally {
      setIsSubmitting(false);
    }
  };

  const steps = {
    basic: {
      title: 'Basic Information',
      component: BasicInfoStep,
      onSubmit: handleBasicInfoSubmit,
      data: studentData,
    },
    parent: {
      title: 'Parent/Guardian Information',
      component: ParentInfoStep,
      onSubmit: handleParentInfoSubmit,
      data: studentData.parents,
    },
    medical: {
      title: 'Medical & Special Considerations',
      component: MedicalInfoStep,
      onSubmit: handleMedicalInfoSubmit,
      data: studentData,
    },
  };

  const CurrentStepComponent = steps[currentStep].component;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-xl font-semibold text-gray-900">Edit Student</h2>
              <p className="text-sm text-gray-500 mt-1">
                Step {Object.keys(steps).indexOf(currentStep) + 1} of {Object.keys(steps).length}:{' '}
                {steps[currentStep].title}
              </p>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="p-6 overflow-y-auto max-h-[calc(90vh-129px)]">
          {error && (
            <div className="mb-6 p-4 bg-red-50 rounded-lg">
              <p className="text-sm text-red-600">{error}</p>
            </div>
          )}

          <CurrentStepComponent
            onSubmit={steps[currentStep].onSubmit}
            initialData={steps[currentStep].data}
            isSubmitting={isSubmitting}
            onBack={currentStep !== 'basic' ? () => setCurrentStep(
              currentStep === 'medical' ? 'parent' : 'basic'
            ) : undefined}
          />
        </div>
      </div>
    </div>
  );
}