import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Mail, AlertCircle } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

export default function VerifyEmailForm() {
  const [code, setCode] = useState('');
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const { verifyEmail, updateVerificationEmail, resendVerificationCode, state, clearError } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    return clearError;
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!state.unverifiedEmail) return;
    
    try {
      await verifyEmail(state.unverifiedEmail, code);
      navigate('/login');
    } catch (error) {
      // Error handling is done in the context
    }
  };

  const handleUpdateEmail = async (e: React.FormEvent) => {
  e.preventDefault();
  try {
    await updateVerificationEmail(newEmail);
    setIsEditingEmail(false);
    setCode('');
    // Clear any existing error state
    clearError();
  } catch (error) {
    // Error handling is done in the context
    console.error('Failed to update email:', error);
  }
};

  const handleResendCode = async () => {
    if (!state.unverifiedEmail) return;
    try {
      await resendVerificationCode(state.unverifiedEmail);
    } catch (error) {
      // Error handling is done in the context
    }
  };

  if (!state.unverifiedEmail) {
    navigate('/register');
    return null;
  }

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Verify your email
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            We've sent a verification code to {state.unverifiedEmail}
          </p>
          <div className="mt-2 text-center">
            <button
              onClick={() => setIsEditingEmail(!isEditingEmail)}
              className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
            >
              {isEditingEmail ? 'Cancel' : 'Edit email address'}
            </button>
          </div>
        </div>

        {state.error && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <AlertCircle className="h-5 w-5 text-red-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-red-800">{state.error}</p>
              </div>
              <div className="ml-auto pl-3">
                <button
                  onClick={clearError}
                  className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100"
                >
                  <span className="sr-only">Dismiss</span>
                  <AlertCircle className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        )}

        {isEditingEmail ? (
          <form className="mt-8 space-y-6" onSubmit={handleUpdateEmail}>
            <div>
              <label htmlFor="new-email" className="sr-only">
                New Email Address
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="new-email"
                  name="email"
                  type="email"
                  required
                  value={newEmail}
                  onChange={(e) => setNewEmail(e.target.value)}
                  className="appearance-none rounded-lg relative block w-full px-3 py-2 pl-10 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter new email address"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                disabled={state.isLoading}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {state.isLoading ? 'Updating...' : 'Update Email'}
              </button>
            </div>
          </form>
        ) : (
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="verification-code" className="sr-only">
                Verification Code
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="verification-code"
                  name="code"
                  type="text"
                  required
                  value={code}
                  onChange={(e) => setCode(e.target.value)}
                  className="appearance-none rounded-lg relative block w-full px-3 py-2 pl-10 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  placeholder="Enter verification code"
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                disabled={state.isLoading}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {state.isLoading ? 'Verifying...' : 'Verify Email'}
              </button>
            </div>
            <div className="text-center">
              <button
                type="button"
                onClick={handleResendCode}
                disabled={state.isLoading}
                className="text-sm font-medium text-indigo-600 hover:text-indigo-500"
              >
                Resend verification code
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
}