import React, { useState } from 'react';
import { Plus } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import StudentList from './StudentList';
import StudentTable from '../../components/Students/StudentTable';
import ViewToggle from '../../components/Students/ViewToggle';
import BulkActions from '../../components/Students/BulkActions';
import AddStudentModal from './AddStudentModal';
import BehaviorIncidentModal from '../../components/Students/BehaviorIncidentModal';
import AttendanceModal from '../../components/Students/AttendanceModal';
import { useStudents } from '../../contexts/StudentContext';
import { useBehavior } from '../../contexts/BehaviorContext';

export default function Students() {
  const navigate = useNavigate();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showBehaviorModal, setShowBehaviorModal] = useState(false);
  const [showAttendanceModal, setShowAttendanceModal] = useState(false);
  const [view, setView] = useState<'grid' | 'table'>('table');
  const [selectedStudents, setSelectedStudents] = useState<Set<string>>(new Set());
  const { state } = useStudents();
  const { createIncident } = useBehavior();

  const handleCreateIncident = () => {
    setShowBehaviorModal(true);
  };

  const handleSubmitIncident = async (incident: any) => {
    try {
      await createIncident(incident);
      setShowBehaviorModal(false);
    } catch (error) {
      console.error('Failed to create incident:', error);
    }
  };

  const handleCreateAssessment = () => {
    // Coming soon
  };

  const handleSendCommunication = () => {
    navigate('/communication', {
      state: { selectedStudents: Array.from(selectedStudents) }
    });
  };

  const selectedStudentsList = state.students.filter(student => 
    selectedStudents.has(student.id)
  );

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-20">
      <div className="mb-8">
        <div className="flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">Students</h1>
            <p className="text-gray-500">Manage your classroom roster</p>
          </div>
          <div className="flex items-center gap-4">
            <ViewToggle view={view} onViewChange={setView} />
            <button
              onClick={() => setShowAddModal(true)}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
            >
              <Plus className="h-4 w-4" />
              Add Student
            </button>
          </div>
        </div>
      </div>

      {view === 'grid' ? (
        <StudentList
          selectedStudents={selectedStudents}
          onSelectionChange={setSelectedStudents}
        />
      ) : (
        <StudentTable
          students={state.students}
          selectedStudents={selectedStudents}
          onSelectionChange={setSelectedStudents}
        />
      )}

      <BulkActions
        selectedCount={selectedStudents.size}
        onCreateIncident={handleCreateIncident}
        onCreateAssessment={handleCreateAssessment}
        onMarkAttendance={() => setShowAttendanceModal(true)}
        onSendCommunication={handleSendCommunication}
      />

      {showAttendanceModal && (
        <AttendanceModal
          students={selectedStudentsList}
          onClose={() => setShowAttendanceModal(false)}
        />
      )}
      
      {showAddModal && (
        <AddStudentModal onClose={() => setShowAddModal(false)} />
      )}

      {showBehaviorModal && (
        <BehaviorIncidentModal
          students={selectedStudentsList}
          onClose={() => setShowBehaviorModal(false)}
          onSubmit={handleSubmitIncident}
        />
      )}
    </div>
  );
}