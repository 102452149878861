import React, { useState } from 'react';
import { X } from 'lucide-react';
import type { Student } from '../../../types/student';

interface MedicalInfoStepProps {
  onSubmit: (data: Partial<Student>) => void;
  initialData: Partial<Student>;
  isSubmitting?: boolean;
  onBack: () => void;
}

export default function MedicalInfoStep({ onSubmit, initialData, isSubmitting, onBack }: MedicalInfoStepProps) {
  const [formData, setFormData] = useState({
    medicalAlerts: initialData.medicalAlerts?.join('\n') || '',
    allergies: initialData.allergies?.join('\n') || '',
    hasIEP: initialData.hasIEP || false,
    has504: initialData.has504 || false,
  });

  // Check if we're editing by looking for an ID in the initial data
  const isEditing = Boolean(initialData.id);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      medicalAlerts: formData.medicalAlerts.split('\n').filter(Boolean),
      allergies: formData.allergies.split('\n').filter(Boolean),
      hasIEP: formData.hasIEP,
      has504: formData.has504,
    });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Medical Alerts (one per line)
        </label>
        <textarea
          value={formData.medicalAlerts}
          onChange={(e) => setFormData({ ...formData, medicalAlerts: e.target.value })}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder="Enter any medical conditions or alerts..."
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">
          Allergies (one per line)
        </label>
        <textarea
          value={formData.allergies}
          onChange={(e) => setFormData({ ...formData, allergies: e.target.value })}
          rows={3}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder="Enter any allergies..."
        />
      </div>

      <div className="space-y-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            id="hasIEP"
            checked={formData.hasIEP}
            onChange={(e) => setFormData({ ...formData, hasIEP: e.target.checked })}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label htmlFor="hasIEP" className="ml-2 block text-sm text-gray-900">
            Has IEP (Individualized Education Program)
          </label>
        </div>

        <div className="flex items-center">
          <input
            type="checkbox"
            id="has504"
            checked={formData.has504}
            onChange={(e) => setFormData({ ...formData, has504: e.target.checked })}
            className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
          />
          <label htmlFor="has504" className="ml-2 block text-sm text-gray-900">
            Has 504 Plan
          </label>
        </div>
      </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={onBack}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
        >
          Back
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          {isSubmitting ? 'Saving...' : isEditing ? 'Edit Student' : 'Add Student'}
        </button>
      </div>
    </form>
  );
}