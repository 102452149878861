import React, { createContext, useContext, useState, useEffect } from 'react';

interface SecurityState {
  isAuthorized: boolean;
  error: string | null;
}

interface SecurityContextType {
  state: SecurityState;
  verifyAccessCode: (code: string) => void;
  clearError: () => void;
  logout: () => void;
}

const SecurityContext = createContext<SecurityContextType | undefined>(undefined);

// In a real application, this would be stored securely
const VALID_ACCESS_CODE = 'NINJAABC1234';

export function SecurityProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<SecurityState>(() => {
    const stored = localStorage.getItem('security_authorized');
    return {
      isAuthorized: stored === 'true',
      error: null
    };
  });

  useEffect(() => {
    localStorage.setItem('security_authorized', state.isAuthorized.toString());
  }, [state.isAuthorized]);

  const verifyAccessCode = (code: string) => {
    if (code === VALID_ACCESS_CODE) {
      setState({ isAuthorized: true, error: null });
    } else {
      setState(prev => ({
        ...prev,
        error: 'Invalid access code'
      }));
    }
  };

  const clearError = () => {
    setState(prev => ({ ...prev, error: null }));
  };

  const logout = () => {
    setState({ isAuthorized: false, error: null });
    localStorage.removeItem('security_authorized');
  };

  return (
    <SecurityContext.Provider value={{ state, verifyAccessCode, clearError, logout }}>
      {children}
    </SecurityContext.Provider>
  );
}

export function useSecurity() {
  const context = useContext(SecurityContext);
  if (context === undefined) {
    throw new Error('useSecurity must be used within a SecurityProvider');
  }
  return context;
}