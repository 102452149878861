export const formatDate = (dateString: string) => {
  try {
    const date = new Date(dateString);
    
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    });
  } catch (e) {
    console.error('Error formatting date:', e);
    return 'Date not available';
  }
};