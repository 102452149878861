import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { useAuth } from './AuthContext';
import * as studentApi from '../services/students';
import type { Student } from '../types/student';

interface StudentState {
  students: Student[];
  isLoading: boolean;
  error: string | null;
}

type StudentAction =
  | { type: 'SET_LOADING' }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'CLEAR_ERROR' }
  | { type: 'SET_STUDENTS'; payload: Student[] }
  | { type: 'ADD_STUDENT'; payload: Student }
  | { type: 'UPDATE_STUDENT'; payload: Student }
  | { type: 'DELETE_STUDENT'; payload: string };

interface StudentContextType {
  state: StudentState;
  addStudent: (student: Omit<Student, 'id' | 'userId' | 'createdAt' | 'lastModified'>) => Promise<void>;
  updateStudent: (student: Student) => Promise<void>;
  deleteStudent: (id: string) => Promise<void>;
  refreshStudents: () => Promise<void>;
  clearError: () => void;
}

const StudentContext = createContext<StudentContextType | undefined>(undefined);

const initialState: StudentState = {
  students: [],
  isLoading: false,
  error: null,
};

function studentReducer(state: StudentState, action: StudentAction): StudentState {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, isLoading: true, error: null };
    case 'SET_ERROR':
      return { ...state, error: action.payload, isLoading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: null };
    case 'SET_STUDENTS':
      return { ...state, students: action.payload, isLoading: false, error: null };
    case 'ADD_STUDENT':
      return {
        ...state,
        students: [...state.students, action.payload],
        isLoading: false,
        error: null,
      };
    case 'UPDATE_STUDENT':
      return {
        ...state,
        students: state.students.map((student) =>
          student.id === action.payload.id ? action.payload : student
        ),
        isLoading: false,
        error: null,
      };
    case 'DELETE_STUDENT':
      return {
        ...state,
        students: state.students.filter((student) => student.id !== action.payload),
        isLoading: false,
        error: null,
      };
    default:
      return state;
  }
}

export function StudentProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(studentReducer, initialState);
  const { state: authState } = useAuth();

  const refreshStudents = async () => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      const students = await studentApi.getStudents(authState.user.username);
      dispatch({ type: 'SET_STUDENTS', payload: students });
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: error instanceof Error ? error.message : 'Failed to fetch students',
      });
    }
  };

  useEffect(() => {
    if (authState.isAuthenticated && authState.user?.username) {
      refreshStudents();
    }
  }, [authState.isAuthenticated, authState.user?.username]);

  const addStudent = async (
    student: Omit<Student, 'id' | 'userId' | 'createdAt' | 'lastModified'>
  ) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      const newStudent = await studentApi.createStudent(authState.user.username, student);
      dispatch({ type: 'ADD_STUDENT', payload: newStudent });
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: error instanceof Error ? error.message : 'Failed to create student',
      });
      throw error;
    }
  };

  const updateStudent = async (student: Student) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      const updatedStudent = await studentApi.updateStudent(authState.user.username, student);
      dispatch({ type: 'UPDATE_STUDENT', payload: updatedStudent });
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: error instanceof Error ? error.message : 'Failed to update student',
      });
      throw error;
    }
  };

  const deleteStudent = async (id: string) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      await studentApi.deleteStudent(authState.user.username, id);
      dispatch({ type: 'DELETE_STUDENT', payload: id });
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: error instanceof Error ? error.message : 'Failed to delete student',
      });
      throw error;
    }
  };

  const clearError = () => {
    dispatch({ type: 'CLEAR_ERROR' });
  };

  return (
    <StudentContext.Provider
      value={{
        state,
        addStudent,
        updateStudent,
        deleteStudent,
        refreshStudents,
        clearError,
      }}
    >
      {children}
    </StudentContext.Provider>
  );
}

export function useStudents() {
  const context = useContext(StudentContext);
  if (context === undefined) {
    throw new Error('useStudents must be used within a StudentProvider');
  }
  return context;
}