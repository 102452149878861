import React, { useState } from 'react';
import { Plus, X } from 'lucide-react';
import type { ParentGuardian } from '../../../types/student';

interface ParentInfoStepProps {
  onSubmit: (data: ParentGuardian[]) => void;
  initialData: ParentGuardian[];
  isSubmitting?: boolean;
  onBack: () => void;
}

const emptyParent: Omit<ParentGuardian, 'id'> = {
  firstName: '',
  lastName: '',
  relationship: '',
  email: '',
  phone: '',
  address: '',
  isEmergencyContact: false,
  preferredLanguage: 'English',
};

export default function ParentInfoStep({ onSubmit, initialData, isSubmitting, onBack }: ParentInfoStepProps) {
  const [parents, setParents] = useState<(Omit<ParentGuardian, 'id'> & { tempId?: string })[]>(
    initialData.length > 0 ? initialData : [{ ...emptyParent, tempId: Date.now().toString() }]
  );

  const addParent = () => {
    setParents([...parents, { ...emptyParent, tempId: Date.now().toString() }]);
  };

  const removeParent = (index: number) => {
    setParents(parents.filter((_, i) => i !== index));
  };

  const updateParent = (index: number, field: keyof ParentGuardian, value: string | boolean) => {
    const newParents = [...parents];
    newParents[index] = { ...newParents[index], [field]: value };
    setParents(newParents);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Remove tempId before submitting
    const finalParents = parents.map(({ tempId, ...parent }) => ({
      ...parent,
      id: tempId || Math.random().toString(36).substr(2, 9),
    }));
    onSubmit(finalParents);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {parents.map((parent, index) => (
        <div
          key={parent.tempId || index}
          className="p-4 border border-gray-200 rounded-lg space-y-4"
        >
          <div className="flex items-center justify-between">
            <h3 className="text-lg font-medium text-gray-900">
              Parent/Guardian {index + 1}
            </h3>
            {parents.length > 1 && (
              <button
                type="button"
                onClick={() => removeParent(index)}
                className="text-red-600 hover:text-red-800"
              >
                <X className="h-5 w-5" />
              </button>
            )}
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">First Name</label>
              <input
                type="text"
                value={parent.firstName}
                onChange={(e) => updateParent(index, 'firstName', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Last Name</label>
              <input
                type="text"
                value={parent.lastName}
                onChange={(e) => updateParent(index, 'lastName', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Relationship</label>
              <input
                type="text"
                value={parent.relationship}
                onChange={(e) => updateParent(index, 'relationship', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                value={parent.email}
                onChange={(e) => updateParent(index, 'email', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Phone</label>
              <input
                type="tel"
                value={parent.phone}
                onChange={(e) => updateParent(index, 'phone', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Preferred Language
              </label>
              <input
                type="text"
                value={parent.preferredLanguage}
                onChange={(e) => updateParent(index, 'preferredLanguage', e.target.value)}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Address</label>
            <input
              type="text"
              value={parent.address}
              onChange={(e) => updateParent(index, 'address', e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            />
          </div>

          <div className="flex items-center">
            <input
              type="checkbox"
              id={`emergency-${index}`}
              checked={parent.isEmergencyContact}
              onChange={(e) => updateParent(index, 'isEmergencyContact', e.target.checked)}
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <label htmlFor={`emergency-${index}`} className="ml-2 block text-sm text-gray-900">
              Emergency Contact
            </label>
          </div>
        </div>
      ))}

      <div className="flex justify-center">
        <button
          type="button"
          onClick={addParent}
          className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-indigo-600 hover:text-indigo-800"
        >
          <Plus className="h-4 w-4" />
          Add Another Parent/Guardian
        </button>
      </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={onBack}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
        >
          Back
        </button>
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          Next: Medical Information
        </button>
      </div>
    </form>
  );
}