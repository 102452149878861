import React, { useState } from 'react';
import { AlertCircle } from 'lucide-react';
import { useSecurity } from '../../contexts/SecurityContext';

export default function SecurityGate({ children }: { children: React.ReactNode }) {
  const [accessCode, setAccessCode] = useState('');
  const { state, verifyAccessCode, clearError } = useSecurity();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    verifyAccessCode(accessCode.toUpperCase());
  };

  if (state.isAuthorized) {
    return <>{children}</>;
  }

  return (
    <div className="min-h-screen bg-gray-800 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-2xl w-full space-y-8">
        <div className="text-center">
          <div className="mx-auto w-64 h-64 relative mb-8">
            <img
              src="https://ninjaed-content.s3.us-east-2.amazonaws.com/ninjaed-lightlogo.jpeg"
              alt="NinjaED"
              className="w-full h-full object-contain rounded-2xl"
            />
          </div>
          <h2 className="text-3xl text-white font-medium mt-4 mb-10 tracking-wide">
            Teach Smart, Plan Sharp, Get NinjaED
          </h2>
        </div>

        {state.error && (
          <div className="rounded-md bg-red-50 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <AlertCircle className="h-5 w-5 text-red-400" />
              </div>
              <div className="ml-3">
                <p className="text-sm font-medium text-red-800">{state.error}</p>
              </div>
              <div className="ml-auto pl-3">
                <button
                  onClick={clearError}
                  className="inline-flex bg-red-50 rounded-md p-1.5 text-red-500 hover:bg-red-100"
                >
                  <span className="sr-only">Dismiss</span>
                  <AlertCircle className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        )}

        <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
          <div>
            <label htmlFor="access-code" className="sr-only">
              Access Code
            </label>
            <input
              id="access-code"
              name="code"
              type="text"
              required
              value={accessCode}
              onChange={(e) => setAccessCode(e.target.value)}
              className="appearance-none relative block w-full px-4 py-3 border border-gray-700 bg-gray-900 placeholder-gray-400 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent text-lg"
              placeholder="Enter access code"
            />
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-lg font-medium rounded-lg text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 transition-colors"
            >
              Verify Access
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}