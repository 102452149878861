import { Student } from '../types';

const API_ENDPOINT = import.meta.env.VITE_API_DATATABLEDYNAMO_ENDPOINT;

export class StudentsError extends Error {
  constructor(message: string, public statusCode?: number) {
    super(message);
    this.name = 'StudentsError';
  }
}

export async function getStudents(userId: string): Promise<Student[]> {
  try {
    const response = await fetch(`${API_ENDPOINT}/students?userId=${userId}`, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      throw new StudentsError(`HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    return Array.isArray(data?.Items) ? data.Items : [];
  } catch (error) {
    if (error instanceof StudentsError) throw error;
    throw new StudentsError('Failed to fetch students');
  }
}

export async function createStudent(
  userId: string,
  student: Omit<Student, 'id' | 'userId' | 'createdAt' | 'lastModified'>
): Promise<Student> {
  try {
    const response = await fetch(`${API_ENDPOINT}/students`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        userId,
        ...student,
      }),
    });

    if (!response.ok) {
      throw new StudentsError(`HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    if (!data?.Item) {
      throw new StudentsError('Invalid response format from server');
    }

    return data.Item;
  } catch (error) {
    if (error instanceof StudentsError) throw error;
    throw new StudentsError('Failed to create student');
  }
}

export async function updateStudent(userId: string, student: Student): Promise<Student> {
  try {
    // Create a clean copy of the student object without undefined values
    const cleanStudent = Object.fromEntries(
      Object.entries(student).filter(([_, v]) => v !== undefined)
    );

    // Remove id and userId from the update as they're part of the key
    const { id, userId: _, ...updateData } = cleanStudent;

    const response = await fetch(`${API_ENDPOINT}/students`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        userId,
        id: student.id,
        ...updateData,
        lastModified: new Date().toISOString(),
      }),
    });

    if (!response.ok) {
      throw new StudentsError(`HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    if (!data?.Item) {
      throw new StudentsError('Invalid response format from server');
    }

    return data.Item;
  } catch (error) {
    if (error instanceof StudentsError) throw error;
    throw new StudentsError('Failed to update student');
  }
}

export async function deleteStudent(userId: string, studentId: string): Promise<void> {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/students?userId=${userId}&studentId=${studentId}`,
      {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    if (!response.ok) {
      throw new StudentsError(`HTTP error! status: ${response.status}`, response.status);
    }
  } catch (error) {
    if (error instanceof StudentsError) throw error;
    throw new StudentsError('Failed to delete student');
  }
}