import React, { useState } from 'react';
import { X, Calendar, Trash2, BookOpen, Plus, Pause, Clock } from 'lucide-react';
import type { CalendarEvent } from '../../../types/calendar';
import { format, parseISO } from 'date-fns';
import { useCalendar } from '../../../contexts/CalendarContext';
import { useNavigate } from 'react-router-dom';
import { useLessons } from '../../../contexts/LessonContext';
import { getPatternDescription } from '../../../utils/schedulePatterns';
import SchedulePatternSelector from './SchedulePatternSelector';
import SkipDayButton from './SkipDayButton';
import SkipDaysList from './SkipDaysList';

interface EventPopoverProps {
  event: CalendarEvent;
  selectedDate: string | null;
  onClose: () => void;
  onDelete: (id: string) => void;
  onUpdate: (event: CalendarEvent) => void;
  onAddSkipDay: () => void;
  onEditPattern: () => void;
  disabled?: boolean;
}

export default function EventPopover({ 
  event, 
  selectedDate,
  onClose, 
  onDelete, 
  onUpdate,
  onAddSkipDay,
  onEditPattern,
  disabled 
}: EventPopoverProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [startDate, setStartDate] = useState(format(parseISO(event.start), 'yyyy-MM-dd'));
  const { calculateEndDate, deleteSkipDay } = useCalendar();
  const navigate = useNavigate();
  const { state: lessonState } = useLessons();

  const lessonCount = lessonState.lessons[event.unitId]?.length || 0;

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this unit from the calendar?')) {
      onDelete(event.id);
      onClose();
    }
  };

  const handleUpdate = () => {
    const newStartDate = parseISO(startDate);
    const newEndDate = calculateEndDate(newStartDate, event.duration, event);

    onUpdate({
      ...event,
      start: newStartDate.toISOString(),
      end: newEndDate.toISOString(),
    });
    onClose();
  };

  const handleDeleteSkipDay = async (date: string) => {
    if (disabled) return;
    
    try {
      await deleteSkipDay(event.id, date);
      onClose();
    } catch (error) {
      console.error('Failed to delete skip day:', error);
    }
  };

  const handlePlanLessons = () => {
    navigate(`/planning/lessons/${event.unitId}`, {
      state: { event }
    });
    onClose();
  };

  const isSelectedDateSkipDay = selectedDate && event.skipDays?.includes(selectedDate);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-md w-full p-6">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold text-gray-900">{event.title}</h3>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="space-y-4">
          <div className="flex items-center gap-2 text-gray-600">
            <Calendar className="h-5 w-5" />
            {isEditing ? (
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="px-2 py-1 border rounded"
              />
            ) : (
              <span>{format(parseISO(event.start), 'MMM d, yyyy')}</span>
            )}
          </div>

          <div>
            <div className="flex items-center gap-2 text-gray-600">
              <Clock className="h-5 w-5" />
              <span>Schedule Pattern:</span>
              <span className="font-medium">
                {getPatternDescription(event.pattern || { type: 'everyday' })}
              </span>
            </div>
            {!isEditing && (
              <button
                onClick={onEditPattern}
                className="mt-2 text-sm text-indigo-600 hover:text-indigo-800"
              >
                Change Pattern
              </button>
            )}
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <span className="text-sm text-gray-600">Duration:</span>
              <span className="text-sm font-medium">{event.duration} days</span>
            </div>
            {selectedDate && !isSelectedDateSkipDay && (
              <SkipDayButton
                date={selectedDate}
                isSkipDay={false}
                onToggle={onAddSkipDay}
                disabled={disabled}
              />
            )}
          </div>

          {event.skipDays && event.skipDays.length > 0 && (
            <SkipDaysList
              skipDays={event.skipDays}
              onDelete={handleDeleteSkipDay}
              disabled={disabled}
            />
          )}

          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-600">Lessons planned:</span>
            <span className="text-sm font-medium">
              {lessonCount} of {event.duration}
            </span>
          </div>

          <div className="pt-4 flex justify-between border-t">
            <button
              onClick={handleDelete}
              className="flex items-center gap-2 px-3 py-2 text-sm text-red-600 hover:text-red-700"
            >
              <Trash2 className="h-4 w-4" />
              Delete
            </button>
            <div className="flex gap-2">
              {isEditing ? (
                <>
                  <button
                    onClick={() => setIsEditing(false)}
                    className="px-3 py-2 text-sm text-gray-600 hover:text-gray-700"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={handleUpdate}
                    className="px-3 py-2 text-sm text-indigo-600 hover:text-indigo-700"
                  >
                    Save
                  </button>
                </>
              ) : (
                <>
                  <button
                    onClick={() => setIsEditing(true)}
                    className="px-3 py-2 text-sm text-gray-600 hover:text-gray-700"
                  >
                    Change Date
                  </button>
                  <button
                    onClick={handlePlanLessons}
                    className="flex items-center gap-2 px-3 py-2 text-sm text-indigo-600 hover:text-indigo-700"
                  >
                    <BookOpen className="h-4 w-4" />
                    Plan Lessons
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}