import { SchedulePattern } from '../types/calendar';

export function isDateInPattern(date: Date, pattern: SchedulePattern): boolean {
  const dayOfWeek = date.getDay(); // 0 = Sunday, 1 = Monday, etc.

  switch (pattern.type) {
    case 'everyday':
      return true;
    case 'mwf':
      return [1, 3, 5].includes(dayOfWeek);
    case 'tth':
      return [2, 4].includes(dayOfWeek);
    case 'custom':
      return pattern.customDays?.includes(dayOfWeek) ?? false;
    default:
      return true;
  }
}

export function getPatternDescription(pattern: SchedulePattern): string {
  switch (pattern.type) {
    case 'everyday':
      return 'Every Day';
    case 'mwf':
      return 'Monday/Wednesday/Friday';
    case 'tth':
      return 'Tuesday/Thursday';
    case 'custom':
      return 'Custom Pattern';
    default:
      return 'Unknown Pattern';
  }
}

export function getPatternDays(pattern: SchedulePattern): number[] {
  switch (pattern.type) {
    case 'everyday':
      return [1, 2, 3, 4, 5]; // Monday-Friday
    case 'mwf':
      return [1, 3, 5]; // Mon, Wed, Fri
    case 'tth':
      return [2, 4]; // Tue, Thu
    case 'custom':
      return pattern.customDays || [];
    default:
      return [];
  }
}

export function validatePattern(pattern: SchedulePattern): boolean {
  switch (pattern.type) {
    case 'everyday':
    case 'mwf':
    case 'tth':
      return true;
    case 'custom':
      return Array.isArray(pattern.customDays) && 
             pattern.customDays.every(day => Number.isInteger(day) && day >= 0 && day <= 6);
    default:
      return false;
  }
}

export function getNextPatternDay(date: Date, pattern: SchedulePattern): Date {
  const patternDays = getPatternDays(pattern);
  let nextDate = new Date(date);
  let attempts = 0;
  const maxAttempts = 7; // Prevent infinite loop

  while (!patternDays.includes(nextDate.getDay()) && attempts < maxAttempts) {
    nextDate.setDate(nextDate.getDate() + 1);
    attempts++;
  }

  return nextDate;
}

export function getPatternFrequency(pattern: SchedulePattern): number {
  return getPatternDays(pattern).length;
}

export function adjustDurationForPattern(
  baseDuration: number, 
  pattern: SchedulePattern
): number {
  const frequency = getPatternFrequency(pattern);
  if (pattern.type === 'everyday' || frequency === 0) return baseDuration;
  
  // Adjust duration based on pattern frequency
  // For example, if a unit needs 10 days but runs MWF (3 days/week),
  // it will need more calendar days to complete
  return Math.ceil(baseDuration * (5 / frequency));
}