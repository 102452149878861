import React, { useState, useCallback } from 'react';
import { addDays, parseISO } from 'date-fns'; // Include addDays here
import { useLessons } from '../../../contexts/LessonContext';
import type { Lesson, SavedUnit } from '../../../types';
import { fetchAuthSession } from 'aws-amplify/auth'; // for the lambda function AI request


interface LessonFormProps {
  unit: SavedUnit;
  onClose: () => void;
  schoolDays: Date[];
  existingLessons: Lesson[];
  editingLesson?: Lesson;
}

interface FormErrors {
  title?: string;
  standards?: string;
  learningTargets?: string;
}

export default function LessonForm({ unit, onClose, schoolDays, existingLessons, editingLesson }: LessonFormProps) {
  const { addLesson, updateLesson } = useLessons();
  const nextOrder = editingLesson ? editingLesson.order : existingLessons.length;
  const [isResourceModalOpen, setIsResourceModalOpen] = useState(false);
  const [resourceModalInput, setResourceModalInput] = useState('');
  const [isEnhancingResources, setIsEnhancingResources] = useState(false);


  // Calculate the default date
  const calculateDefaultDate = (): string => {
  if (!unit.scheduledStart) {
    return '2024-01-01'; // Default to 1/1/24 if no unit is scheduled
  }

  // Extract the maximum scheduled date from existing lessons
  const maxScheduledDate = existingLessons.reduce((latest, lesson) => {
    const lessonDate = lesson.date ? new Date(lesson.date) : null;
    return lessonDate && (!latest || lessonDate > latest) ? lessonDate : latest;
  }, null);

  // Start searching from the next day after the maximum scheduled date
  let nextDate = maxScheduledDate
    ? new Date(Date.UTC(maxScheduledDate.getUTCFullYear(), maxScheduledDate.getUTCMonth(), maxScheduledDate.getUTCDate() + 1))
    : new Date(Date.UTC(new Date(unit.scheduledStart).getUTCFullYear(), new Date(unit.scheduledStart).getUTCMonth(), new Date(unit.scheduledStart).getUTCDate()));

  console.log('Starting nextDate:', nextDate.toISOString());
  console.log('Day of week for starting nextDate (UTC):', nextDate.getUTCDay());

  // Ensure the next date is not a weekend
  while (nextDate.getUTCDay() === 0 || nextDate.getUTCDay() === 6) { // 0 = Sunday, 6 = Saturday
    console.log(`Current nextDate (UTC): ${nextDate.toISOString()} - Skipping weekend`);
    nextDate.setUTCDate(nextDate.getUTCDate() + 1); // Move to the next day in UTC
    console.log(`Moved to nextDate (UTC): ${nextDate.toISOString()} - Day of week: ${nextDate.getUTCDay()}`);
  }

  // Return the next valid date in 'yyyy-MM-dd' format
  const nextValidDate = nextDate.toISOString().split('T')[0];
  console.log('Final selected nextDate (after skipping weekends, UTC):', nextValidDate);

  return nextValidDate;
};












  
  const [formData, setFormData] = useState<Partial<Lesson>>({
    title: editingLesson?.title || '',
    description: editingLesson?.description || '',
    standards: editingLesson?.standards || [],
    learningTargets: editingLesson?.learningTargets || [],
    resources: editingLesson?.resources || '',
    order: nextOrder,
    date: editingLesson?.date || calculateDefaultDate(), // Use the calculated next available date
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEnhancing, setIsEnhancing] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({});

  const validateForm = useCallback((): boolean => {
    const newErrors: FormErrors = {};
    
    if (!formData.title?.trim()) {
      newErrors.title = 'Title is required';
    }
    if (!formData.standards?.length) {
      newErrors.standards = 'At least one standard must be selected';
    }
    if (!formData.learningTargets?.length) {
      newErrors.learningTargets = 'At least one learning target must be selected';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formData]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    try {
      const lessonData = {
        ...formData,
        unitId: unit.id,
      } as Lesson;

      if (editingLesson) {
        await updateLesson({
          ...editingLesson,
          ...lessonData,
        });
      } else {
        await addLesson(unit.id, lessonData);
      }
      onClose();
    } catch (error) {
      console.error('Error saving lesson:', error);
      setErrors(prev => ({
        ...prev,
        submit: error instanceof Error ? error.message : 'Failed to save lesson'
      }));
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStandardsChange = (standardId: string, checked: boolean) => {
    setFormData(prev => {
      const standards = prev.standards || [];
      if (checked) {
        return {
          ...prev,
          standards: [...standards, standardId]
        };
      }
      return {
        ...prev,
        standards: standards.filter(s => s !== standardId)
      };
    });
  };

  const handleTargetsChange = (targetId: string, checked: boolean) => {
    setFormData(prev => {
      const targets = prev.learningTargets || [];
      if (checked) {
        return {
          ...prev,
          learningTargets: [...targets, targetId]
        };
      }
      return {
        ...prev,
        learningTargets: targets.filter(t => t !== targetId)
      };
    });
  };


const handleEnhanceResources = async (additionalInput: string) => {
  setIsEnhancingResources(true);
  try {
    const { tokens } = await fetchAuthSession();
    const idToken = tokens.idToken.toString();
    const standardsWithDescriptions = (formData.standards || []).map(standardId => {
      const standard = unit.standardsCovered?.find(s => s.standard === standardId);
      return {
        id: standardId,
        description: standard?.description || "Description not available"
      };
    });
    const response = await fetch('https://xjkejr4zmxsaohd2567yy7wqke0kjqex.lambda-url.us-east-2.on.aws/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`
      },
      body: JSON.stringify({
        unitName: unit.unitName,
        lessonTitle: formData.title,
        lessonDescription: formData.description,
        standards: standardsWithDescriptions,
        learningTargets: formData.learningTargets,
        currentResources: formData.resources,
        additionalInput: additionalInput,
        lessonId: editingLesson?.id,
        subject: unit.subject,
        unitId: unit.id
      }),
    });
    if (!response.ok) {
      const errorText = await response.text();
      console.error('Error response:', errorText);
      throw new Error(errorText || 'Failed to enhance resources');
    }
    const data = await response.json();
    console.log('Response data:', data);
    
    // Create new resources string
    const currentResources = formData.resources || '';
    const updatedResources = data.resources || '';
    const newResourceLink = data.resourceLink;
    
    let newResources = currentResources;
    
    if (updatedResources && updatedResources !== currentResources) {
      newResources = updatedResources;
    }
    
    if (newResourceLink) {
        newResources = newResources 
          ? `${newResources}\n\nGenerated Resource: <a href="${newResourceLink}" target="_blank" class="text-blue-600 hover:text-blue-800 underline">${newResourceLink.split('/').pop()}</a>`
          : `Generated Resource: <a href="${newResourceLink}" target="_blank" class="text-blue-600 hover:text-blue-800 underline">${newResourceLink.split('/').pop()}</a>`;
    }

    // Update form data with new resources
    const updatedFormData = {
      ...formData,
      resources: newResources
    };

    // Set the form data and wait for the update
    setFormData(updatedFormData);

    // Give React time to update the UI
    setTimeout(async () => {
      // Submit the form with updated data
      const lessonData = {
        ...updatedFormData,
        unitId: unit.id,
      } as Lesson;

      if (editingLesson) {
        await updateLesson({
          ...editingLesson,
          ...lessonData,
        });
      } else {
        await addLesson(unit.id, lessonData);
      }
      
      // Close both modal and form
      setIsResourceModalOpen(false);
      onClose();
    }, 100);
    
  } catch (error) {
    console.error('Error enhancing resources:', error);
  } finally {
    setIsEnhancingResources(false);
  }
};











  
const handleEnhanceLesson = async () => {
    setIsEnhancing(true);
    try {
      const { tokens } = await fetchAuthSession();
      const idToken = tokens.idToken.toString();

      const response = await fetch('https://by5ipoaoj75xtfx6qca4nuzy3a0bbrxj.lambda-url.us-east-2.on.aws/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({
          unitName: unit.unitName,
          lessonTitle: formData.title,
          standards: formData.standards?.map(standardId => {
            const standard = unit.standardsCovered?.find(s => s.standard === standardId);
            return standard ? `${standard.standard}: ${standard.description}` : standardId;
          }),
          learningTargets: formData.learningTargets,
          currentDescription: formData.description,
          resources: formData.resources,
        }),
      });

      console.log('Response status:', response.status);
      
      if (!response.ok) {
        const errorText = await response.text();
        console.error('Error response:', errorText);
        throw new Error(errorText || 'Failed to enhance lesson');
      }
      
      const data = await response.json();
      setFormData(prev => ({
        ...prev,
        description: data.updatedDescription || prev.description,
      }));
    } catch (error) {
      console.error('Error enhancing lesson:', error);
    } finally {
      setIsEnhancing(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label htmlFor="title" className="block text-sm font-medium text-gray-700 mb-1">
          Lesson Title
        </label>
        <input
          type="text"
          id="title"
          value={formData.title}
          onChange={(e) => setFormData({ ...formData, title: e.target.value })}
          className={`w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 
            ${errors.title ? 'border-red-500' : ''}`}
          required
        />
        {errors.title && (
          <p className="mt-1 text-sm text-red-600">{errors.title}</p>
        )}
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Standards Covered
        </label>
        <div className="space-y-2">
          {unit.standardsCovered.map((standard) => (
            <label key={standard.standard} className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.standards?.includes(standard.standard)}
                onChange={(e) => handleStandardsChange(standard.standard, e.target.checked)}
                className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <span className="text-sm text-gray-900">
                {standard.standard}: {standard.description}
              </span>
            </label>
          ))}
          {errors.standards && (
            <p className="text-sm text-red-600">{errors.standards}</p>
          )}
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Learning Targets
        </label>
        <div className="space-y-2">
          {unit.learningTargets.map((target, index) => (
            <label key={index} className="flex items-center gap-2">
              <input
                type="checkbox"
                checked={formData.learningTargets?.includes(target)}
                onChange={(e) => handleTargetsChange(target, e.target.checked)}
                className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
              <span className="text-sm text-gray-900">{target}</span>
            </label>
          ))}
          {errors.learningTargets && (
            <p className="text-sm text-red-600">{errors.learningTargets}</p>
          )}
        </div>
      </div>

      <div>
        <div className="flex items-center justify-between mb-1">
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            Lesson Description
          </label>
          <button
            type="button"
            onClick={handleEnhanceLesson}
            disabled={isEnhancing}
            className="flex items-center gap-2 text-sm text-indigo-600 hover:text-indigo-800 disabled:opacity-50"
          >
            <span>{isEnhancing ? 'Enhancing...' : 'Ninja It!'}</span>
            <span className="text-lg">⚔️</span>
          </button>
        </div>
        <textarea
          id="description"
          value={formData.description}
          onChange={(e) => setFormData({ ...formData, description: e.target.value })}
          rows={4}
          className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        />
      </div>

      <div>
        <div className="flex items-center justify-between mb-1">
          <label htmlFor="resources" className="block text-sm font-medium text-gray-700">
            Resources
          </label>
          <button
            type="button"
            onClick={() => setIsResourceModalOpen(true)}
            disabled={isEnhancingResources}
            className="flex items-center gap-2 text-sm text-indigo-600 hover:text-indigo-800 disabled:opacity-50"
          >
            <span>{isEnhancingResources ? 'Enhancing...' : 'Ninja It!'}</span>
            <span className="text-lg">⚔️</span>
          </button>
        </div>
        <textarea
          id="resources"
          value={formData.resources}
          onChange={(e) => setFormData({ ...formData, resources: e.target.value })}
          rows={2}
          className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          placeholder="List any resources needed for this lesson"
        />
      </div>

{/* Modal */}
{isResourceModalOpen && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
    <div className="bg-white rounded-xl max-w-md w-full p-6">
      <div className="text-center">
        <div className="mx-auto w-12 h-12 bg-indigo-100 rounded-full flex items-center justify-center mb-4">
          <span className="text-2xl">⚔️</span>
        </div>
        <h2 className="text-xl font-semibold text-gray-900 mb-2">
          Ninja Resources
        </h2>
        <p className="text-gray-500 mb-6">
          Add additional information to help generate better worksheets for your lesson. # of questions, etc.
        </p>

        <textarea
          value={resourceModalInput}
          onChange={(e) => setResourceModalInput(e.target.value)}
          rows={4}
          className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 mb-6"
          placeholder="Enter additional information to help generate resources..."
        />

        <div className="flex justify-end gap-3">
          <button
            type="button"
            onClick={() => setIsResourceModalOpen(false)}
            className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
          >
            Cancel
          </button>
          <button
            type="button"
            onClick={() => handleEnhanceResources(resourceModalInput)}
            disabled={isEnhancingResources}
            className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
          >
            {isEnhancingResources ? (
              <>
                <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent" />
                Enhancing...
              </>
            ) : (
              <>
                <span className="text-lg">⚔️</span>
                Ninja It!
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  </div>
)}

      <div className="flex justify-end gap-3">
        <button
          type="button"
          onClick={onClose}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
          disabled={isSubmitting}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Saving...' : editingLesson ? 'Save Changes' : 'Add Lesson'}
        </button>
      </div>
    </form>
  );
}