import React, { useEffect, useRef } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { 
  format, 
  startOfMonth, 
  addMonths,
  subMonths,
  eachDayOfInterval, 
  endOfMonth, 
  isWeekend,
  startOfWeek,
  endOfWeek,
  isSameMonth,
  parseISO,
  isSameDay,
  isToday
} from 'date-fns';
import { useCalendar } from '../../../contexts/CalendarContext';
import EventPopover from './EventPopover';
import NonSchoolDayPopover from './NonSchoolDayPopover';
import SkipDayModal from './SkipDayModal';
import SchedulePatternModal from './SchedulePatternModal';
import { useAuth } from '../../../contexts/AuthContext';
import { updateUnitSchedule } from '../../../services/units';
import { subjectColors } from '../../../utils/constants';
import { isDateInPattern } from '../../../utils/schedulePatterns';

const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

interface CalendarGridProps {
  disabled?: boolean;
}

export default function CalendarGrid({ disabled = false }: CalendarGridProps) {
  const currentMonthRef = useRef<HTMLDivElement>(null);
  const [selectedEvent, setSelectedEvent] = React.useState<string | null>(null);
  const [selectedDate, setSelectedDate] = React.useState<string | null>(null);
  const [selectedNonSchoolDay, setSelectedNonSchoolDay] = React.useState<NonSchoolDay | null>(null);
  const [showSkipDayModal, setShowSkipDayModal] = React.useState(false);
  const [showPatternModal, setShowPatternModal] = React.useState(false);
  const { state, updateEvent, deleteEvent, deleteNonSchoolDay, isCalculating } = useCalendar();
  const { state: authState } = useAuth();
  const currentMonth = new Date().toISOString().slice(0, 7); // YYYY-MM format

  const months = React.useMemo(() => [
    ...Array.from({ length: 6 }, (_, i) => 
      startOfMonth(subMonths(new Date(), 6 - i))
    ),
    ...Array.from({ length: 12 }, (_, i) => 
      startOfMonth(addMonths(new Date(), i))
    ),
  ], []);

  useEffect(() => {
    const monthElement = document.querySelector(`[data-month="${currentMonth}"]`);
    if (monthElement) {
      monthElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [currentMonth]);

  const handleEventUpdate = async (event: CalendarEvent) => {
    if (disabled || isCalculating) return;
    
    try {
      updateEvent(event);
      if (authState.user?.username) {
        await updateUnitSchedule(
          authState.user.username,
          event.unitId,
          event.start,
          event.end,
          event.skipDays,
          event.pattern
        );
      }
    } catch (error) {
      console.error('Failed to update event:', error);
    }
  };

  const handleEventDelete = async (eventId: string) => {
    if (disabled || isCalculating) return;

    const event = state.events.find(e => e.id === eventId);
    if (event && authState.user?.username) {
      try {
        deleteEvent(eventId);
        await updateUnitSchedule(
          authState.user.username,
          event.unitId,
          null,
          null
        );
      } catch (error) {
        console.error('Failed to delete event:', error);
      }
    }
  };

  const handleDeleteNonSchoolDay = async (nonSchoolDay: NonSchoolDay) => {
    if (disabled || isCalculating) return;

    if (confirm('Are you sure you want to delete this non-school day?')) {
      try {
        await deleteNonSchoolDay(nonSchoolDay.id);
      } catch (error) {
        console.error('Failed to delete non-school day:', error);
      }
    }
  };

  const renderDay = (date: Date, monthStart: Date) => {
    const dateStr = format(date, 'yyyy-MM-dd');
    const { setNodeRef } = useDroppable({
      id: dateStr,
      disabled: disabled || isCalculating || isWeekend(date)
    });

    const isWeekendDay = isWeekend(date);
    const nonSchoolDayInfo = state.nonSchoolDays.find(day => 
      isSameDay(parseISO(day.date), date)
    );
    const isNonSchoolDay = Boolean(nonSchoolDayInfo);
    const isCurrentMonth = isSameMonth(date, monthStart);
    const isCurrentDay = isToday(date);

    const skipDayEvents = state.events.filter(event => {
        const eventStart = parseISO(event.start);
        const eventEnd = parseISO(event.end);
        return date >= eventStart && 
               date <= eventEnd && 
               event.skipDays?.includes(dateStr);
      });

    const isSkipDay = skipDayEvents.length > 0;

    const dayEvents = state.events.filter(event => {
      const eventStart = parseISO(event.start);
      const eventEnd = parseISO(event.end);
      const isPatternDay = isDateInPattern(date, event.pattern || { type: 'everyday' });
      
      return date >= eventStart && date <= eventEnd 
        && (!isWeekend(date)) 
        && (!isNonSchoolDay)
        && !isSkipDay
        && isPatternDay;
    });



    const dayClassNames = [
      'min-h-[100px] p-2 border-r border-b',
      !isCurrentMonth ? 'bg-gray-50/50 text-gray-400' :
      isWeekendDay || isNonSchoolDay ? 'bg-gray-50' : 'bg-white',
      (disabled || isCalculating) ? 'cursor-not-allowed' : '',
      isSameDay(date, parseISO(selectedDate || '')) ? 'ring-2 ring-indigo-500' : ''
    ].filter(Boolean).join(' ');

    return (
      <div
        key={`day-${dateStr}`}
        ref={setNodeRef}
        className={dayClassNames}
        onClick={() => setSelectedDate(dateStr)}
      >
        <div className="flex items-start justify-between mb-2">
          <span className={`text-sm ${
            !isCurrentMonth ? 'text-gray-400' :
            isWeekendDay || isNonSchoolDay ? 'text-gray-400' : 
            isCurrentDay ? 'text-indigo-600 font-bold' : 'text-gray-600'
          }`}>
            {format(date, 'd')}
            {isCurrentDay && (
              <span className="ml-1 text-xs font-medium bg-indigo-100 text-indigo-600 px-1.5 py-0.5 rounded">
                Today
              </span>
            )}
          </span>
        </div>

        
        {nonSchoolDayInfo && isCurrentMonth && (
          <div
            onClick={(e) => {
              e.stopPropagation();
              !disabled && !isCalculating && setSelectedNonSchoolDay(nonSchoolDayInfo);
            }}
            className={`mt-1 px-2 py-1 text-xs bg-gray-100 text-gray-800 rounded 
              ${!disabled && !isCalculating ? 'cursor-pointer hover:bg-gray-200' : 'cursor-not-allowed'}`}
          >
            <div className="font-medium capitalize">{nonSchoolDayInfo.type}</div>
            {nonSchoolDayInfo.description && (
              <div className="text-gray-600 truncate">{nonSchoolDayInfo.description}</div>
            )}
          </div>
        )}

        {isCurrentMonth && dayEvents.map(event => (
          <div
            key={`event-${event.id}-${dateStr}`}
            onClick={(e) => {
              e.stopPropagation();
              if (!disabled && !isCalculating) {
                setSelectedEvent(event.id);
                setSelectedDate(dateStr);
              }
            }}
            className={`mt-1 px-2 py-1 text-xs rounded transition-colors border border-gray-200
              ${!disabled && !isCalculating ? 'cursor-pointer hover:brightness-95' : 'cursor-not-allowed'}
              ${event.backgroundColor || 'bg-gray-200'}
              ${event.textColor || 'text-gray-800'}`}
          >
            <div className="flex items-center justify-between">
              <span>{event.title}</span>
              {event.pattern?.type !== 'everyday' && (
                <span className="text-xs opacity-75">
                  {event.pattern?.type === 'mwf' ? 'MWF' :
                   event.pattern?.type === 'tth' ? 'TTh' :
                   'Custom'}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={`h-full overflow-y-auto ${disabled || isCalculating ? 'pointer-events-none' : ''}`}>
      {months.map(month => {
        const monthKey = format(month, 'yyyy-MM');
        const monthStart = startOfMonth(month);
        const monthEnd = endOfMonth(month);
        const calendarStart = startOfWeek(monthStart);
        const calendarEnd = endOfWeek(monthEnd);

        const daysInMonth = eachDayOfInterval({
          start: calendarStart,
          end: calendarEnd,
        });

        return (
          <div
            key={monthKey}
            className="mb-8"
            data-month={monthKey}
            ref={monthKey === currentMonth ? currentMonthRef : null}
          >
            <h3 className="text-lg font-semibold text-gray-900 mb-4 px-4">
              {format(month, 'MMMM yyyy')}
            </h3>
            <div className="grid grid-cols-7 border-l border-t">
              {WEEKDAYS.map(day => (
                <div 
                  key={`${monthKey}-header-${day}`}
                  className="py-2 text-center text-sm font-medium text-gray-600 border-r border-b bg-gray-50"
                >
                  {day}
                </div>
              ))}
              {daysInMonth.map(date => renderDay(date, monthStart))}
            </div>
          </div>
        );
      })}

      {selectedEvent && (
        <EventPopover
          event={state.events.find(e => e.id === selectedEvent)!}
          selectedDate={selectedDate}
          onClose={() => {
            setSelectedEvent(null);
            setSelectedDate(null);
          }}
          onDelete={handleEventDelete}
          onUpdate={handleEventUpdate}
          onAddSkipDay={() => setShowSkipDayModal(true)}
          onEditPattern={() => setShowPatternModal(true)}
          disabled={disabled || isCalculating}
        />
      )}

      {selectedNonSchoolDay && (
        <NonSchoolDayPopover
          nonSchoolDay={selectedNonSchoolDay}
          onClose={() => setSelectedNonSchoolDay(null)}
          onDelete={handleDeleteNonSchoolDay}
          disabled={disabled || isCalculating}
        />
      )}

      {showSkipDayModal && selectedEvent && selectedDate && (
        <SkipDayModal
          event={state.events.find(e => e.id === selectedEvent)!}
          selectedDate={selectedDate}
          onClose={() => setShowSkipDayModal(false)}
        />
      )}

      {showPatternModal && selectedEvent && (
        <SchedulePatternModal
          event={state.events.find(e => e.id === selectedEvent)!}
          onClose={() => setShowPatternModal(false)}
        />
      )}
    </div>
  );
}