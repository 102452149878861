import React, { useState } from 'react';
import { X, Calendar, Trash2 } from 'lucide-react';
import type { NonSchoolDay } from '../../../types';
import { format, parseISO } from 'date-fns';
import { useCalendar } from '../../../contexts/CalendarContext';
import DeleteConfirmationModal from './DeleteConfirmationModal';

interface NonSchoolDayPopoverProps {
  nonSchoolDay: NonSchoolDay;
  onClose: () => void;
}

export default function NonSchoolDayPopover({ nonSchoolDay, onClose }: NonSchoolDayPopoverProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [formData, setFormData] = useState({
    date: format(parseISO(nonSchoolDay.date), 'yyyy-MM-dd'),
    type: nonSchoolDay.type,
    description: nonSchoolDay.description
  });
  const { deleteNonSchoolDay, updateNonSchoolDay } = useCalendar();
  const [isUpdating, setIsUpdating] = useState(false);

  const handleDelete = async () => {
    try {
      await deleteNonSchoolDay(nonSchoolDay.id);
      onClose();
    } catch (error) {
      console.error('Failed to delete non-school day:', error);
    }
  };

  const handleUpdate = async () => {
    try {
      setIsUpdating(true);
      // Construct complete non-school day object for update
      const updatedNonSchoolDay: NonSchoolDay = {
        ...nonSchoolDay,
        date: formData.date,
        type: formData.type,
        description: formData.description,
        lastModified: new Date().toISOString()
      };

      await updateNonSchoolDay(updatedNonSchoolDay);
      onClose();
    } catch (error) {
      console.error('Failed to update non-school day:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-xl max-w-md w-full p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold text-gray-900">Non-School Day</h3>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-5 w-5" />
            </button>
          </div>

          <div className="space-y-4">
            {isEditing ? (
              <>
                <div>
                  <label htmlFor="date" className="block text-sm font-medium text-gray-700 mb-1">
                    Date
                  </label>
                  <input
                    type="date"
                    id="date"
                    value={formData.date}
                    onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                    className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>

                <div>
                  <label htmlFor="type" className="block text-sm font-medium text-gray-700 mb-1">
                    Type
                  </label>
                  <select
                    id="type"
                    value={formData.type}
                    onChange={(e) => setFormData({ ...formData, type: e.target.value as NonSchoolDay['type'] })}
                    className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  >
                    <option value="holiday">Holiday</option>
                    <option value="break">Break</option>
                    <option value="other">Other</option>
                  </select>
                </div>

                <div>
                  <label htmlFor="description" className="block text-sm font-medium text-gray-700 mb-1">
                    Description
                  </label>
                  <input
                    type="text"
                    id="description"
                    value={formData.description}
                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>
              </>
            ) : (
              <>
                <div className="flex items-center gap-2 text-gray-600">
                  <Calendar className="h-5 w-5" />
                  <span>{format(parseISO(nonSchoolDay.date), 'MMMM d, yyyy')}</span>
                </div>

                <div className="flex items-center gap-2">
                  <span className="text-sm text-gray-600">Type:</span>
                  <span className="text-sm font-medium capitalize">{nonSchoolDay.type}</span>
                </div>

                {nonSchoolDay.description && (
                  <div className="flex items-center gap-2">
                    <span className="text-sm text-gray-600">Description:</span>
                    <span className="text-sm font-medium">{nonSchoolDay.description}</span>
                  </div>
                )}
              </>
            )}

            <div className="pt-4 flex justify-between border-t">
              <button
                onClick={() => setShowDeleteConfirmation(true)}
                className="flex items-center gap-2 px-3 py-2 text-sm text-red-600 hover:text-red-700"
              >
                <Trash2 className="h-4 w-4" />
                Delete
              </button>
              <div className="flex gap-2">
                {isEditing ? (
                  <>
                    <button
                      onClick={() => setIsEditing(false)}
                      className="px-3 py-2 text-sm text-gray-600 hover:text-gray-700"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleUpdate}
                      disabled={isUpdating}
                      className="px-3 py-2 text-sm text-indigo-600 hover:text-indigo-700 disabled:opacity-50"
                    >
                      {isUpdating ? 'Saving...' : 'Save'}
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => setIsEditing(true)}
                    className="px-3 py-2 text-sm text-indigo-600 hover:text-indigo-700"
                  >
                    Edit
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showDeleteConfirmation && (
        <DeleteConfirmationModal
          type={nonSchoolDay.type}
          description={nonSchoolDay.description}
          date={format(parseISO(nonSchoolDay.date), 'MMMM d, yyyy')}
          onConfirm={handleDelete}
          onCancel={() => setShowDeleteConfirmation(false)}
        />
      )}
    </>
  );
}