import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { parseISO, format, addDays } from 'date-fns';
import type { CalendarEvent, NonSchoolDay, CalendarState } from '../types';
import { useAuth } from './AuthContext';
import { useLessons } from './LessonContext';
import * as calendarService from '../services/calendar';
import { updateUnitSchedule } from '../services/units';
import { updateLessonDates } from '../utils/lessonDateUtils';
import { 
  calculateWorkingDays,
  findSubjectConflicts,
  recalculateUnitDates,
  validateUnitPlacement,
  SchedulePattern
} from '../utils/scheduleUtils';
import {
  validateSkipDay as validateSkipDayUtil,
  addSkipDay as addSkipDayToEvent,
  removeSkipDay as removeSkipDayFromEvent,
  getSkipDayImpact as getSkipDayEventImpact
} from '../utils/skipDayUtils';

interface CalendarContextType {
  state: CalendarState;
  isCalculating: boolean;
  calculationProgress: number;
  addEvent: (event: CalendarEvent) => Promise<void>;
  updateEvent: (event: CalendarEvent) => Promise<void>;
  deleteEvent: (id: string) => Promise<void>;
  addNonSchoolDay: (day: NonSchoolDay) => Promise<void>;
  addMultipleNonSchoolDays: (days: Omit<NonSchoolDay, 'id'>[]) => Promise<void>;
  updateNonSchoolDay: (day: NonSchoolDay) => Promise<void>;
  deleteNonSchoolDay: (id: string) => Promise<void>;
  setSchoolYear: (start: string, end: string) => void;
  calculateEndDate: (startDate: Date, duration: number, event?: CalendarEvent) => Date;
  hasOverlap: (start: Date, end: Date, subject: string, excludeEventId?: string) => boolean;
  loadEvents: (events: CalendarEvent[]) => void;
  recalculateAllEventDates: () => Promise<void>;
  addSkipDay: (eventId: string, date: string) => Promise<void>;
  deleteSkipDay: (eventId: string, date: string) => Promise<void>;
  validateSkipDay: (eventId: string, date: string) => boolean;
  getSkipDayImpact: (eventId: string, date: string) => { newEndDate: string; daysExtended: number };
  updateEventPattern: (eventId: string, pattern: SchedulePattern) => Promise<void>;
}

const CalendarContext = createContext<CalendarContextType | undefined>(undefined);

const initialState: CalendarState = {
  events: [],
  nonSchoolDays: [],
  schoolYear: {
    start: new Date(new Date().getFullYear(), 7, 1).toISOString(),
    end: new Date(new Date().getFullYear() + 1, 5, 30).toISOString(),
  },
  isCalculating: false,
  calculationProgress: 0,
};

type CalendarAction =
  | { type: 'ADD_EVENT'; payload: CalendarEvent }
  | { type: 'UPDATE_EVENT'; payload: CalendarEvent }
  | { type: 'DELETE_EVENT'; payload: string }
  | { type: 'ADD_NON_SCHOOL_DAY'; payload: NonSchoolDay }
  | { type: 'UPDATE_NON_SCHOOL_DAY'; payload: NonSchoolDay }
  | { type: 'DELETE_NON_SCHOOL_DAY'; payload: string }
  | { type: 'SET_SCHOOL_YEAR'; payload: { start: string; end: string } }
  | { type: 'SET_NON_SCHOOL_DAYS'; payload: NonSchoolDay[] }
  | { type: 'LOAD_EVENTS'; payload: CalendarEvent[] }
  | { type: 'SET_EVENTS'; payload: CalendarEvent[] }
  | { type: 'SET_CALCULATING'; payload: boolean }
  | { type: 'SET_CALCULATION_PROGRESS'; payload: number }
  | { type: 'ADD_SKIP_DAY'; payload: { eventId: string; date: string } }
  | { type: 'DELETE_SKIP_DAY'; payload: { eventId: string; date: string } }
  | { type: 'UPDATE_EVENT_PATTERN'; payload: { eventId: string; pattern: SchedulePattern } };

function calendarReducer(state: CalendarState, action: CalendarAction): CalendarState {
  switch (action.type) {
    case 'ADD_EVENT':
      return {
        ...state,
        events: [...state.events, action.payload],
      };
    case 'UPDATE_EVENT':
      return {
        ...state,
        events: state.events.map((event) =>
          event.id === action.payload.id ? action.payload : event
        ),
      };
    case 'DELETE_EVENT':
      return {
        ...state,
        events: state.events.filter((event) => event.id !== action.payload),
      };
    case 'ADD_NON_SCHOOL_DAY':
      return {
        ...state,
        nonSchoolDays: [...state.nonSchoolDays, action.payload],
      };
    case 'UPDATE_NON_SCHOOL_DAY':
      return {
        ...state,
        nonSchoolDays: state.nonSchoolDays.map((day) =>
          day.id === action.payload.id ? action.payload : day
        ),
      };
    case 'DELETE_NON_SCHOOL_DAY':
      return {
        ...state,
        nonSchoolDays: state.nonSchoolDays.filter((day) => day.id !== action.payload),
      };
    case 'SET_SCHOOL_YEAR':
      return {
        ...state,
        schoolYear: action.payload,
      };
    case 'SET_NON_SCHOOL_DAYS':
      return {
        ...state,
        nonSchoolDays: action.payload,
      };
    case 'LOAD_EVENTS':
    case 'SET_EVENTS':
      return {
        ...state,
        events: action.payload,
      };
    case 'SET_CALCULATING':
      return {
        ...state,
        isCalculating: action.payload,
        calculationProgress: action.payload ? 0 : 100,
      };
    case 'SET_CALCULATION_PROGRESS':
      return {
        ...state,
        calculationProgress: action.payload,
      };
    case 'ADD_SKIP_DAY':
      return {
        ...state,
        events: state.events.map(event =>
          event.id === action.payload.eventId
            ? addSkipDayToEvent(event, action.payload.date, state.nonSchoolDays.map(d => d.date))
            : event
        ),
      };
    case 'DELETE_SKIP_DAY':
      return {
        ...state,
        events: state.events.map(event =>
          event.id === action.payload.eventId
            ? removeSkipDayFromEvent(event, action.payload.date, state.nonSchoolDays.map(d => d.date))
            : event
        ),
      };
    case 'UPDATE_EVENT_PATTERN':
      return {
        ...state,
        events: state.events.map(event => {
          if (event.id === action.payload.eventId) {
            const { endDate } = calculateWorkingDays(
              parseISO(event.start),
              event.duration,
              state.nonSchoolDays,
              event.skipDays,
              action.payload.pattern
            );
            return {
              ...event,
              pattern: action.payload.pattern,
              end: endDate.toISOString()
            };
          }
          return event;
        }),
      };
    default:
      return state;
  }
}

export function CalendarProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(calendarReducer, initialState);
  const { state: authState } = useAuth();
  const { updateLesson, loadLessons, state: lessonState } = useLessons();

  useEffect(() => {
    if (authState.user?.username) {
      loadNonSchoolDays();
    }
  }, [authState.user?.username]);

  const loadNonSchoolDays = async () => {
    if (!authState.user?.username) return;
    try {
      const days = await calendarService.getNonSchoolDays(authState.user.username);
      dispatch({ type: 'SET_NON_SCHOOL_DAYS', payload: days });
    } catch (error) {
      console.error('Failed to load non-school days:', error);
    }
  };

  const calculateEndDate = (startDate: Date, duration: number, event?: CalendarEvent): Date => {
    const { endDate } = calculateWorkingDays(
      startDate,
      duration,
      state.nonSchoolDays,
      event?.skipDays,
      event?.pattern
    );
    return endDate;
  };

  const hasOverlap = (start: Date, end: Date, subject: string, excludeEventId?: string): boolean => {
    const conflicts = findSubjectConflicts(start, end, subject, state.events, excludeEventId);
    return conflicts.length > 0;
  };

  const addEvent = async (event: CalendarEvent) => {
    const validation = validateUnitPlacement(
      parseISO(event.start),
      event.duration,
      event.subject,
      state.events,
      state.nonSchoolDays,
      event.pattern
    );

    if (!validation.valid) {
      throw new Error('Invalid unit placement: Subject conflict detected');
    }

    dispatch({ type: 'ADD_EVENT', payload: event });
    await updateLessonsForEvent(event);
  };

  const updateEvent = async (event: CalendarEvent) => {
    dispatch({ type: 'UPDATE_EVENT', payload: event });
    await updateLessonsForEvent(event);
  };

  const deleteEvent = async (id: string) => {
    const event = state.events.find(e => e.id === id);
    if (event) {
      dispatch({ type: 'DELETE_EVENT', payload: id });
      await resetLessonsForEvent(event);
    }
  };

  const addSkipDay = async (eventId: string, date: string) => {
  const event = state.events.find(e => e.id === eventId);
  if (!event || !validateSkipDay(eventId, date)) return;

  try {
    // Calculate new end date first
    const { endDate } = calculateWorkingDays(
      parseISO(event.start),
      event.duration,
      state.nonSchoolDays,
      [...(event.skipDays || []), date],
      event.pattern
    );

    // Update backend
    const updatedUnit = await updateUnitSchedule(
      authState.user!.username, 
      event.unitId,
      event.start,
      endDate.toISOString(),  // Use calculated end date
      [...(event.skipDays || []), date],
      event.pattern
    );

    // Update local state with backend response
    const updatedEvent = {
      ...event,
      skipDays: updatedUnit.skipDays || [],
      start: updatedUnit.scheduledStart || event.start,
      end: updatedUnit.scheduledEnd || endDate.toISOString()
    };

    dispatch({ type: 'UPDATE_EVENT', payload: updatedEvent });
    
    // Update lessons after backend is updated
    await updateLessonsForEvent(updatedEvent);
    await updateOverlappingUnits(updatedEvent);
  } catch (error) {
    console.error('Failed to add skip day:', error);
    throw error;
  }
};

  const deleteSkipDay = async (eventId: string, date: string) => {
  const event = state.events.find(e => e.id === eventId);
  if (!event) return;

  try {
    // Calculate new end date first
    const { endDate } = calculateWorkingDays(
      parseISO(event.start),
      event.duration,
      state.nonSchoolDays,
      (event.skipDays || []).filter(d => d !== date),
      event.pattern
    );

    // Update backend with recalculated end date
    const updatedUnit = await updateUnitSchedule(
      authState.user!.username,
      event.unitId,
      event.start,
      endDate.toISOString(),  // Use the recalculated end date
      (event.skipDays || []).filter(d => d !== date),
      event.pattern
    );

    // Create updated event with the backend response
    const updatedEvent = {
      ...event,
      skipDays: updatedUnit.skipDays || [],
      start: updatedUnit.scheduledStart || event.start,
      end: updatedUnit.scheduledEnd || endDate.toISOString()
    };

    // Update local state with the full updated event
    dispatch({ type: 'UPDATE_EVENT', payload: updatedEvent });
    
    // Update lessons
    await updateLessonsForEvent(updatedEvent);
    await updateOverlappingUnits(updatedEvent);
  } catch (error) {
    console.error('Failed to delete skip day:', error);
    throw error;
  }
};

  const updateOverlappingUnits = async (changedEvent: CalendarEvent) => {
  // Get all events of the same subject that start after this one
  const affectedEvents = state.events.filter(event => 
    event.subject === changedEvent.subject &&
    event.id !== changedEvent.id &&
    parseISO(event.start) >= parseISO(changedEvent.start)
  ).sort((a, b) => parseISO(a.start).getTime() - parseISO(b.start).getTime());

  let lastEndDate = parseISO(changedEvent.end);

  for (const event of affectedEvents) {
    const currentStartDate = parseISO(event.start);
    
    // Check if this event needs to be moved
    if (currentStartDate <= lastEndDate) {
      // Calculate new dates
      const newStartDate = addDays(lastEndDate, 1);
      const { endDate } = calculateWorkingDays(
        newStartDate,
        event.duration,
        state.nonSchoolDays,
        event.skipDays,
        event.pattern
      );

      // Update in backend
      const updatedUnit = await updateUnitSchedule(
        authState.user!.username,
        event.unitId,
        newStartDate.toISOString(),
        endDate.toISOString(),
        event.skipDays,
        event.pattern
      );

      // Update in local state
      const updatedEvent = {
        ...event,
        start: updatedUnit.scheduledStart || newStartDate.toISOString(),
        end: updatedUnit.scheduledEnd || endDate.toISOString()
      };

      dispatch({ type: 'UPDATE_EVENT', payload: updatedEvent });
      await updateLessonsForEvent(updatedEvent);

      lastEndDate = parseISO(updatedEvent.end);
    } else {
      // If this event doesn't need to be moved, use its end date for next comparison
      lastEndDate = parseISO(event.end);
    }
  }
};
  
  const updateEventPattern = async (eventId: string, pattern: SchedulePattern) => {
  const event = state.events.find(e => e.id === eventId);
  if (!event) return;

  try {
    // Calculate new end date with the new pattern
    const { endDate } = calculateWorkingDays(
      parseISO(event.start),
      event.duration,
      state.nonSchoolDays,
      event.skipDays,
      pattern
    );

    // Update backend
    const updatedUnit = await updateUnitSchedule(
      authState.user!.username,
      event.unitId,
      event.start,
      endDate.toISOString(),
      event.skipDays,
      pattern  // Include the pattern in the backend update
    );

    // Update local state with backend response
    const updatedEvent = {
      ...event,
      pattern: pattern,
      end: endDate.toISOString()
    };

    dispatch({ type: 'UPDATE_EVENT', payload: updatedEvent });
    
    // Update lessons
    await updateLessonsForEvent(updatedEvent);
  } catch (error) {
    console.error('Failed to update event pattern:', error);
    throw error;
  }
};

  const updateLessonsForEvent = async (event: CalendarEvent) => {
    await loadLessons(event.unitId);
    const unitLessons = lessonState.lessons[event.unitId] || [];
    await updateLessonDates(
      event.unitId,
      event.start,
      event.end,
      updateLesson,
      state.nonSchoolDays.map(day => day.date),
      event.skipDays,
      unitLessons
    );
  };

  const resetLessonsForEvent = async (event: CalendarEvent) => {
    await loadLessons(event.unitId);
    const unitLessons = lessonState.lessons[event.unitId] || [];
    await Promise.all(unitLessons.map(lesson =>
      updateLesson({
        ...lesson,
        date: '2024-01-01'
      })
    ));
  };


  const loadEvents = (events: CalendarEvent[]) => {
  // Process events to ensure required fields exist
  const processedEvents = events.map(event => ({
    ...event,
    skipDays: event.skipDays || [], // Ensure skipDays exists
    pattern: event.pattern || { type: 'everyday' }
  }));
  
  // Update the state with processed events
  dispatch({ type: 'LOAD_EVENTS', payload: processedEvents });
};
  
  const recalculateAllEventDates = async () => {
    dispatch({ type: 'SET_CALCULATING', payload: true });
    try {
      const updatedEvents = recalculateUnitDates(state.events, state.nonSchoolDays);
      dispatch({ type: 'SET_EVENTS', payload: updatedEvents });

      for (const event of updatedEvents) {
        await updateLessonsForEvent(event);
      }
    } finally {
      dispatch({ type: 'SET_CALCULATING', payload: false });
    }
  };

  const validateSkipDay = (eventId: string, date: string): boolean => {
    const event = state.events.find(e => e.id === eventId);
    if (!event) {
      console.error('Event not found for validation:', eventId);
      return false;
    }
    return validateSkipDayUtil(event, date, state.nonSchoolDays.map(d => d.date));
  };

  return (
  <CalendarContext.Provider
    value={{
      state,
      isCalculating: state.isCalculating,
      calculationProgress: state.calculationProgress,
      addEvent,
      updateEvent,
      deleteEvent,
      addNonSchoolDay: async (day) => {
        const savedDay = await calendarService.createNonSchoolDay(
          authState.user!.username,
          day
        );
        dispatch({ type: 'ADD_NON_SCHOOL_DAY', payload: savedDay });
        
        // Update all units that might be affected
        for (const event of state.events) {
          const { endDate } = calculateWorkingDays(
            parseISO(event.start),
            event.duration,
            [...state.nonSchoolDays, savedDay],
            event.skipDays,
            event.pattern
          );
          
          if (format(endDate, 'yyyy-MM-dd') !== format(parseISO(event.end), 'yyyy-MM-dd')) {
            const updatedEvent = { ...event, end: endDate.toISOString() };
            await updateUnitSchedule(
              authState.user!.username,
              event.unitId,
              event.start,
              endDate.toISOString(),
              event.skipDays,
              event.pattern
            );
            dispatch({ type: 'UPDATE_EVENT', payload: updatedEvent });
            await updateOverlappingUnits(updatedEvent);
          }
        }
      },
      addMultipleNonSchoolDays: async (days) => {
        dispatch({ type: 'SET_CALCULATING', payload: true });
        try {
          const savedDays = await Promise.all(
            days.map(day => calendarService.createNonSchoolDay(
              authState.user!.username,
              day
            ))
          );
          dispatch({ type: 'SET_NON_SCHOOL_DAYS', payload: savedDays });
      
          // Update all units that might be affected
          for (const event of state.events) {
            const { endDate } = calculateWorkingDays(
              parseISO(event.start),
              event.duration,
              [...state.nonSchoolDays, ...savedDays],
              event.skipDays,
              event.pattern
            );
      
            if (format(endDate, 'yyyy-MM-dd') !== format(parseISO(event.end), 'yyyy-MM-dd')) {
              const updatedEvent = { ...event, end: endDate.toISOString() };
              await updateUnitSchedule(
                authState.user!.username,
                event.unitId,
                event.start,
                endDate.toISOString(),
                event.skipDays,
                event.pattern
              );
              dispatch({ type: 'UPDATE_EVENT', payload: updatedEvent });
              await updateOverlappingUnits(updatedEvent);
            }
          }
        } finally {
          dispatch({ type: 'SET_CALCULATING', payload: false });
        }
      },
      
      updateNonSchoolDay: async (day) => {
        const updatedDay = await calendarService.updateNonSchoolDay(
          authState.user!.username,
          day
        );
        dispatch({ type: 'UPDATE_NON_SCHOOL_DAY', payload: updatedDay });
      
        // Update all units that might be affected
        for (const event of state.events) {
          const { endDate } = calculateWorkingDays(
            parseISO(event.start),
            event.duration,
            state.nonSchoolDays.map(d => d.id === updatedDay.id ? updatedDay : d),
            event.skipDays,
            event.pattern
          );
      
          if (format(endDate, 'yyyy-MM-dd') !== format(parseISO(event.end), 'yyyy-MM-dd')) {
            const updatedEvent = { ...event, end: endDate.toISOString() };
            await updateUnitSchedule(
              authState.user!.username,
              event.unitId,
              event.start,
              endDate.toISOString(),
              event.skipDays,
              event.pattern
            );
            dispatch({ type: 'UPDATE_EVENT', payload: updatedEvent });
            await updateOverlappingUnits(updatedEvent);
          }
        }
      },
      
      deleteNonSchoolDay: async (id) => {
        await calendarService.deleteNonSchoolDay(authState.user!.username, id);
        dispatch({ type: 'DELETE_NON_SCHOOL_DAY', payload: id });
      
        // Get updated non-school days list
        const updatedNonSchoolDays = state.nonSchoolDays.filter(day => day.id !== id);
        
        // Sort events by start date to process them in chronological order
        const sortedEvents = [...state.events].sort((a, b) => 
          parseISO(a.start).getTime() - parseISO(b.start).getTime()
        );
      
        let lastEndDateBySubject: { [key: string]: Date } = {};
      
        // Process each event
        for (const event of sortedEvents) {
          const previousEnd = parseISO(event.end);
          
          // Calculate new end date without the deleted non-school day
          const { endDate } = calculateWorkingDays(
            parseISO(event.start),
            event.duration,
            updatedNonSchoolDays,
            event.skipDays,
            event.pattern
          );
      
          // Check if we need to move the start date earlier due to the removed non-school day
          let newStartDate = parseISO(event.start);
          if (lastEndDateBySubject[event.subject]) {
            newStartDate = addDays(lastEndDateBySubject[event.subject], 1);
          }
      
          // Recalculate end date if start date changed
          const finalEndDate = newStartDate !== parseISO(event.start) 
            ? calculateWorkingDays(
                newStartDate,
                event.duration,
                updatedNonSchoolDays,
                event.skipDays,
                event.pattern
              ).endDate
            : endDate;
      
          if (format(finalEndDate, 'yyyy-MM-dd') !== format(previousEnd, 'yyyy-MM-dd') ||
              format(newStartDate, 'yyyy-MM-dd') !== format(parseISO(event.start), 'yyyy-MM-dd')) {
            // Update the unit in the backend
            const updatedUnit = await updateUnitSchedule(
              authState.user!.username,
              event.unitId,
              newStartDate.toISOString(),
              finalEndDate.toISOString(),
              event.skipDays,
              event.pattern
            );
      
            // Update local state
            const updatedEvent = {
              ...event,
              start: updatedUnit.scheduledStart || newStartDate.toISOString(),
              end: updatedUnit.scheduledEnd || finalEndDate.toISOString()
            };
            dispatch({ type: 'UPDATE_EVENT', payload: updatedEvent });
            await updateLessonsForEvent(updatedEvent);
            await updateOverlappingUnits(updatedEvent);
            
            // Update the last end date for this subject
            lastEndDateBySubject[event.subject] = parseISO(updatedEvent.end);
          } else {
            lastEndDateBySubject[event.subject] = previousEnd;
          }
        }
      },



      
      setSchoolYear: (start, end) => {
        dispatch({ type: 'SET_SCHOOL_YEAR', payload: { start, end } });
      },
      calculateEndDate,
      hasOverlap,
      loadEvents: (events) => {
        const processedEvents = events.map(event => ({
          ...event,
          skipDays: event.skipDays || [],
          pattern: event.pattern || { type: 'everyday' }
        }));
        dispatch({ type: 'LOAD_EVENTS', payload: processedEvents });
      },
      recalculateAllEventDates,
      addSkipDay,
      deleteSkipDay,
      validateSkipDay,
      getSkipDayImpact: (eventId, date) => {
        const event = state.events.find(e => e.id === eventId);
        return event 
          ? getSkipDayEventImpact(event, date, state.nonSchoolDays.map(d => d.date))
          : { newEndDate: '', daysExtended: 0 };
      },
      updateEventPattern,
    }}
  >
    {children}
  </CalendarContext.Provider>
);
}
  
  export function useCalendar() {
    const context = useContext(CalendarContext);
    if (context === undefined) {
      throw new Error('useCalendar must be used within a CalendarProvider');
    }
    return context;
  }