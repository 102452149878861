import React, { createContext, useContext, useReducer } from 'react';
import type { SubjectsState, CustomSubject } from '../types';

type SubjectsAction =
  | { type: 'ADD_SUBJECT'; payload: CustomSubject }
  | { type: 'DELETE_SUBJECT'; payload: string };

interface SubjectsContextType {
  state: SubjectsState;
  addSubject: (name: string) => void;
  deleteSubject: (id: string) => void;
}

const SubjectsContext = createContext<SubjectsContextType | undefined>(undefined);

const initialState: SubjectsState = {
  defaultSubjects: ['Math', 'Science', 'English', 'Social Studies', 'Other'],
  customSubjects: []
};

function subjectsReducer(state: SubjectsState, action: SubjectsAction): SubjectsState {
  switch (action.type) {
    case 'ADD_SUBJECT':
      return {
        ...state,
        customSubjects: [...state.customSubjects, action.payload]
      };
    case 'DELETE_SUBJECT':
      return {
        ...state,
        customSubjects: state.customSubjects.filter(subject => subject.id !== action.payload)
      };
    default:
      return state;
  }
}

export function SubjectsProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(subjectsReducer, initialState);

  const addSubject = (name: string) => {
    const newSubject: CustomSubject = {
      id: Math.random().toString(36).substr(2, 9),
      name,
      createdAt: new Date().toISOString()
    };
    dispatch({ type: 'ADD_SUBJECT', payload: newSubject });
  };

  const deleteSubject = (id: string) => {
    dispatch({ type: 'DELETE_SUBJECT', payload: id });
  };

  return (
    <SubjectsContext.Provider value={{ state, addSubject, deleteSubject }}>
      {children}
    </SubjectsContext.Provider>
  );
}

export function useSubjects() {
  const context = useContext(SubjectsContext);
  if (context === undefined) {
    throw new Error('useSubjects must be used within a SubjectsProvider');
  }
  return context;
}