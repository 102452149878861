
import { Student } from '../types/student';
import { useAuth } from '../contexts/AuthContext';

interface EmailTemplate {
  subject: string;
  message: string;
}

interface TeacherInfo {
  name: string;
  position?: string;
  school?: string;
}

export function processEmailTemplate(
  template: EmailTemplate,
  student: Student,
  teacher: TeacherInfo
): { subject: string; body: string } {
  let subject = template.subject;
  let body = template.message;

  // Replace student placeholders
  const studentName = `${student.firstName} ${student.lastName}`;
  body = body.replace(/\[Student's Name\]/g, studentName);

  // Replace parent placeholders
  if (student.parents && student.parents.length > 0) {
    const parent = student.parents[0];
    const parentName = `${parent.firstName} ${parent.lastName}`;
    body = body.replace(/\[Parent's Name\]/g, parentName);
  }

  // Replace teacher placeholders
  body = body.replace(/\[Your Name\]/g, teacher.name);
  body = body.replace(/\[Your Position\]/g, teacher.position || 'Teacher');
  body = body.replace(/\[Your School\]/g, teacher.school || '');

  return { subject, body };
}

export function useTeacherInfo(): TeacherInfo {
  const { state } = useAuth();
  const userProfile = state.user?.attributes?.profile 
    ? JSON.parse(state.user.attributes.profile)
    : {};

  return {
    name: state.user?.attributes?.name || '',
    position: 'Teacher',
    school: userProfile.schoolName || '',
  };
}

export function validateEmailTemplate(template: EmailTemplate): string | null {
  if (!template.subject?.trim()) {
    return 'Subject is required';
  }

  if (!template.message?.trim()) {
    return 'Message content is required';
  }

  const requiredPlaceholders = [
    "[Student's Name]",
    "[Parent's Name]",
    "[Your Name]",
    "[Your Position]",
    "[Your School]"
  ];

  const missingPlaceholders = requiredPlaceholders.filter(
    placeholder => !template.message.includes(placeholder)
  );

  if (missingPlaceholders.length > 0) {
    return `Missing required placeholders: ${missingPlaceholders.join(', ')}`;
  }

  return null;
}
