
import React, { useState, useEffect } from 'react';
import { DndContext, DragEndEvent } from '@dnd-kit/core';
import { useCalendar } from '../../../contexts/CalendarContext';
import { useSavedUnits } from '../../../contexts/SavedUnitsContext';
import CalendarGrid from '../../../components/Planning/Calendar/CalendarGrid';
import UnitSidebar from '../../../components/Planning/Calendar/UnitSidebar';
import NonSchoolDayModal from '../../../components/Planning/Calendar/NonSchoolDayModal';
import { Plus, RefreshCw } from 'lucide-react';
import { useAuth } from '../../../contexts/AuthContext';
import { updateUnitSchedule } from '../../../services/units';
import { subjectColors } from '../../../utils/constants';
import { parseISO } from 'date-fns';

export default function Calendar() {
  const [showNonSchoolDayModal, setShowNonSchoolDayModal] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const { state: calendarState, addEvent, calculateEndDate, deleteEvent, hasOverlap, loadEvents, isCalculating } = useCalendar();
  const { state: unitsState, refreshUnits } = useSavedUnits();
  const { state: authState } = useAuth();

  const handleRefresh = async () => {
    if (isCalculating || isRefreshing) return;
    
    setIsRefreshing(true);
    setError(null);
    
    try {
      // Refresh units first
      await refreshUnits();
      
      // Then update calendar events based on the fresh unit data
      const scheduledUnits = unitsState.units.filter(unit => unit.scheduledStart && unit.scheduledEnd);
      const events = scheduledUnits.map(unit => ({
        id: unit.id,
        unitId: unit.id,
        title: `Unit ${unit.unitNumber}: ${unit.unitName}`,
        start: unit.scheduledStart!,
        end: unit.scheduledEnd!,
        duration: parseInt(unit.duration),
        backgroundColor: subjectColors[unit.subject]?.bg || subjectColors.Other.bg,
        textColor: subjectColors[unit.subject]?.text || subjectColors.Other.text,
        subject: unit.subject,
        skipDays: unit.skipDays || [],  // Changed from restDays to skipDays
        pattern: unit.pattern || { type: 'everyday' }  // Add pattern if not already included
      }));
      
      loadEvents(events);
    } catch (error) {
      console.error('Failed to refresh calendar:', error);
      setError('Failed to refresh calendar data. Please try again.');
    } finally {
      setIsRefreshing(false);
    }
  };

  // Load scheduled units on mount and when units change
  useEffect(() => {
    console.log('Loading units effect running', unitsState.units);
    const currentUnits = unitsState.units.filter(unit => unit.scheduledStart && unit.scheduledEnd);
    
    const events = currentUnits.map(unit => ({
      id: unit.id,
      unitId: unit.id,
      title: `Unit ${unit.unitNumber}: ${unit.unitName}`,
      start: unit.scheduledStart!,
      end: unit.scheduledEnd!,
      duration: parseInt(unit.duration),
      backgroundColor: subjectColors[unit.subject]?.bg || subjectColors.Other.bg,
      textColor: subjectColors[unit.subject]?.text || subjectColors.Other.text,
      subject: unit.subject,
      skipDays: unit.skipDays || [],  // Changed from restDays to skipDays
      pattern: unit.pattern || { type: 'everyday' }  // Add pattern if not already included
    }));

    loadEvents(events);
  }, [unitsState.units]);

  const handleDragEnd = async (event: DragEndEvent) => {
    console.log('Drag end event:', event);
    try {
      const { active, over } = event;

      if (!over || !authState.user?.username) {
        console.log('No over target or no username');
        return;
      }

      const unitId = active.id.toString().replace('unit-', '');
      const unit = unitsState.units.find(u => u.id === unitId);
      const dateString = over.id.toString().replace('calendar-', '');
      
      console.log('Processing drag:', { unitId, unit, dateString });

      if (!unit || !dateString) {
        console.log('Missing unit or date string');
        return;
      }

      const duration = parseInt(unit.duration);
      if (isNaN(duration)) {
        console.log('Invalid duration');
        return;
      }

      // Check if this unit is already scheduled
      const existingEvent = calendarState.events.find(event => event.unitId === unitId);
      if (existingEvent) {
        setError('This unit is already scheduled. Please remove the existing schedule first.');
        setTimeout(() => setError(null), 3000);
        return;
      }

      const startDate = parseISO(dateString);
      const endDate = calculateEndDate(startDate, duration);

      // Check for overlap with other units
      if (hasOverlap(startDate, endDate, unit.subject)) {
        setError(`Cannot place unit here - overlaps with another ${unit.subject} unit`);
        setTimeout(() => setError(null), 3000);
        return;
      }

      // Update unit's scheduled dates in DynamoDB
      await updateUnitSchedule(
        authState.user.username,
        unitId,
        startDate.toISOString(),
        endDate.toISOString()
      );

      // Add event to calendar state
      const newEvent = {
        id: unitId,
        unitId,
        title: `Unit ${unit.unitNumber}: ${unit.unitName}`,
        start: startDate.toISOString(),
        end: endDate.toISOString(),
        duration,
        backgroundColor: subjectColors[unit.subject]?.bg || subjectColors.Other.bg,
        textColor: subjectColors[unit.subject]?.text || subjectColors.Other.text,
        subject: unit.subject,
        skipDays: unit.skipDays || [],  // Changed from restDays to skipDays
        pattern: unit.pattern || { type: 'everyday' }  // Add pattern if not already included
      };

      addEvent(newEvent);

    } catch (error) {
      console.error('Error in handleDragEnd:', error);
      setError('Failed to schedule unit. Please try again.');
      setTimeout(() => setError(null), 3000);
    }
  };

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <div className="h-[calc(100vh-theme(spacing.32))]">
        <div className="mb-6 flex items-center justify-between">
          <div>
            <h1 className="text-2xl font-bold text-gray-900">School Year Calendar</h1>
            <p className="text-gray-500">Schedule your units and manage non-school days</p>
          </div>
          <div className="flex items-center gap-3">
            <button
              onClick={handleRefresh}
              disabled={isCalculating || isRefreshing}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-900 disabled:opacity-50"
              title="Refresh calendar"
            >
              <RefreshCw className={`h-4 w-4 ${isRefreshing ? 'animate-spin' : ''}`} />
              {isRefreshing ? 'Refreshing...' : 'Refresh'}
            </button>
            <button
              onClick={() => setShowNonSchoolDayModal(true)}
              disabled={isCalculating}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
            >
              <Plus className="h-4 w-4" />
              Add Non-School Day
            </button>
          </div>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-lg">
            <p className="text-sm text-red-600">{error}</p>
          </div>
        )}

        <div className="flex gap-6 h-full">
          <UnitSidebar units={unitsState.units} />
          <div className="flex-1 bg-white rounded-xl shadow-sm overflow-hidden">
            <CalendarGrid disabled={isCalculating} />
          </div>
        </div>

        {showNonSchoolDayModal && (
          <NonSchoolDayModal onClose={() => setShowNonSchoolDayModal(false)} />
        )}
      </div>
    </DndContext>
  );
}
