
import React from 'react';
import { Grid, List } from 'lucide-react';

interface ViewToggleProps {
  view: 'grid' | 'table';
  onViewChange: (view: 'grid' | 'table') => void;
}

export default function ViewToggle({ view, onViewChange }: ViewToggleProps) {
  return (
    <div className="flex items-center gap-2 bg-white rounded-lg border border-gray-200 p-1">
      <button
        onClick={() => onViewChange('grid')}
        className={`p-2 rounded ${
          view === 'grid'
            ? 'bg-indigo-100 text-indigo-600'
            : 'text-gray-500 hover:text-gray-700'
        }`}
        title="Grid view"
      >
        <Grid className="h-4 w-4" />
      </button>
      <button
        onClick={() => onViewChange('table')}
        className={`p-2 rounded ${
          view === 'table'
            ? 'bg-indigo-100 text-indigo-600'
            : 'text-gray-500 hover:text-gray-700'
        }`}
        title="Table view"
      >
        <List className="h-4 w-4" />
      </button>
    </div>
  );
}
