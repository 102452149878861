import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { useAuth } from './AuthContext';
import * as alertsApi from '../services/alerts';
import type { Alert } from '../types';

interface AlertState {
  alerts: Alert[];
  isLoading: boolean;
  error: string | null;
}

type AlertAction =
  | { type: 'SET_ALERTS'; payload: Alert[] }
  | { type: 'ADD_ALERT'; payload: Alert }
  | { type: 'REMOVE_ALERT'; payload: string }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'CLEAR_ERROR' };

interface AlertContextType {
  state: AlertState;
  addAlert: (alert: Omit<Alert, 'id'>) => Promise<void>;
  removeAlert: (id: string) => Promise<void>;
  markAsRead: (id: string) => Promise<void>;
  clearError: () => void;
}

const AlertContext = createContext<AlertContextType | undefined>(undefined);

const initialState: AlertState = {
  alerts: [],
  isLoading: false,
  error: null,
};

function alertReducer(state: AlertState, action: AlertAction): AlertState {
  switch (action.type) {
    case 'SET_ALERTS':
      return {
        ...state,
        alerts: action.payload,
        isLoading: false,
      };
    case 'ADD_ALERT':
      return {
        ...state,
        alerts: [action.payload, ...state.alerts],
        error: null,
      };
    case 'REMOVE_ALERT':
      return {
        ...state,
        alerts: state.alerts.filter((alert) => alert.id !== action.payload),
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case 'CLEAR_ERROR':
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
}

export function AlertProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(alertReducer, initialState);
  const { state: authState } = useAuth();

  useEffect(() => {
    if (authState.isAuthenticated && authState.user?.username) {
      loadAlerts();
    }
  }, [authState.isAuthenticated, authState.user?.username]);

  const loadAlerts = async () => {
    if (!authState.user?.username) return;

    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const alerts = await alertsApi.getAlerts(authState.user.username);
      dispatch({ type: 'SET_ALERTS', payload: alerts });
    } catch (error) {
      console.error('Failed to load alerts:', error);
      dispatch({ type: 'SET_ERROR', payload: 'Failed to load alerts' });
    }
  };

  const addAlert = async (alert: Omit<Alert, 'id'>) => {
    if (!authState.user?.username) return;

    try {
      const newAlert = await alertsApi.createAlert(authState.user.username, alert);
      dispatch({ type: 'ADD_ALERT', payload: newAlert });
    } catch (error) {
      console.error('Failed to create alert:', error);
      dispatch({ type: 'SET_ERROR', payload: 'Failed to create alert' });
    }
  };

  const removeAlert = async (id: string) => {
    if (!authState.user?.username) return;

    try {
      await alertsApi.deleteAlert(authState.user.username, id);
      dispatch({ type: 'REMOVE_ALERT', payload: id });
    } catch (error) {
      console.error('Failed to delete alert:', error);
      dispatch({ type: 'SET_ERROR', payload: 'Failed to delete alert' });
    }
  };

  // Alias for removeAlert to maintain backward compatibility
  const markAsRead = removeAlert;

  const clearError = () => {
    dispatch({ type: 'CLEAR_ERROR' });
  };

  return (
    <AlertContext.Provider
      value={{
        state,
        addAlert,
        removeAlert,
        markAsRead,
        clearError,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
}

export function useAlerts() {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error('useAlerts must be used within an AlertProvider');
  }
  return context;
}