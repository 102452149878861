
import React from 'react';
import { User } from 'lucide-react';
import type { Student } from '../../types/student';

interface RecipientListProps {
  students: Student[];
}

export default function RecipientList({ students }: RecipientListProps) {
  return (
    <div className="mb-6">
      <h2 className="text-sm font-medium text-gray-700 mb-2">Recipients</h2>
      <div className="flex flex-wrap gap-2">
        {students.map(student => (
          <div 
            key={student.id}
            className="flex items-center gap-2 px-3 py-2 bg-gray-100 rounded-lg"
          >
            <div className="w-6 h-6 rounded-full bg-gray-200 flex items-center justify-center overflow-hidden">
              {student.profilePicture ? (
                <img
                  src={student.profilePicture}
                  alt={`${student.firstName} ${student.lastName}`}
                  className="w-full h-full object-cover"
                />
              ) : (
                <User className="w-3 h-3 text-gray-400" />
              )}
            </div>
            <span className="text-sm text-gray-900">
              {student.firstName} {student.lastName}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}
