import React, { useState, useEffect } from 'react';
import { X, Check } from 'lucide-react';
import { useSubscription } from '../../contexts/SubscriptionContext';
import type { SubscriptionPlan, UserSubscription } from '../../types/subscription';


interface UpgradeModalProps {
  onClose: () => void;
}

interface PlanButtonProps {
  plan: SubscriptionPlan;
  isLoading: boolean;
  selectedPlan: string | null;
  subscription: UserSubscription | null;
  handleSelectPlan: (plan: SubscriptionPlan) => Promise<void>;
}

const PlanButton = ({ plan, isLoading, selectedPlan, subscription, handleSelectPlan }: PlanButtonProps) => {
  // If subscription is null, isCurrentPlan should be false
  const isCurrentPlan = subscription ? subscription.priceId === plan.id : false;

  const buttonContent = isLoading && selectedPlan === plan.id ? (
    <div className="flex items-center justify-center gap-2">
      <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent" />
      <span>Processing...</span>
    </div>
  ) : isCurrentPlan ? (
    "Current Plan"
  ) : (
    "Select Plan"
  );

  return (
    <button
      onClick={() => handleSelectPlan(plan)}
      disabled={isLoading || isCurrentPlan || selectedPlan === plan.id}
      className={`mt-8 w-full px-4 py-2 text-sm font-medium rounded-lg flex items-center justify-center gap-2 ${
        isCurrentPlan
          ? 'bg-gray-100 text-gray-600 cursor-not-allowed'
          : 'text-white bg-indigo-600 hover:bg-indigo-700'
      } disabled:opacity-50 disabled:cursor-not-allowed`}
    >
      {buttonContent}
    </button>
  );
};

export default function UpgradeModal({ onClose }: UpgradeModalProps) {
  const { state, checkoutSubscription } = useSubscription();
  const { plans, subscription, isLoading, error } = state;
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);


    useEffect(() => {
    console.log('Plans here they are:', plans);
  }, [plans]);
  
  const handleSelectPlan = async (plan: SubscriptionPlan) => {
    if (isLoading || selectedPlan) return;
    try {
      setSelectedPlan(plan.id);
      await checkoutSubscription(plan.id);
    } catch (error) {
      console.error('Failed to checkout:', error);
      setSelectedPlan(null);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-4xl w-full p-6">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h2 className="text-2xl font-semibold text-gray-900">Upgrade Your Plan</h2>
            <p className="text-gray-500 mt-1">
              {subscription ? 'Choose a new plan to upgrade' : 'Select a plan to continue using NinjaED'}
            </p>
          </div>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-5 w-5" />
          </button>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-50 rounded-lg text-sm text-red-600">
            {error}
          </div>
        )}

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {plans.map((plan, index) => (
            <div
              key={`${plan.id}-${index}`}
              className={`border-2 rounded-lg p-6 transition-colors ${
                selectedPlan === plan.id
                  ? 'border-indigo-500 bg-indigo-50'
                  : 'border-gray-200 hover:border-indigo-500'
              }`}
            >
              <h3 className="text-lg font-semibold text-gray-900">{plan.name}</h3>
              <p className="text-gray-500 text-sm mt-1">{plan.description}</p>
              
             <div className="mt-4">
                <span className="text-3xl font-bold text-gray-900">
                  ${plan.priceUSD ? plan.priceUSD.toFixed(2) : '0.00'}
                </span>
                <span className="text-gray-500">/{plan.interval}</span>
              </div>

              <ul className="mt-6 space-y-4">
                {plan.features.map((feature, featureIndex) => (
                  <li key={`${plan.id}-feature-${featureIndex}`} className="flex items-start gap-2">
                    <Check className="h-5 w-5 text-green-500 flex-shrink-0" />
                    <span className="text-sm text-gray-600">{feature}</span>
                  </li>
                ))}
              </ul>

              <PlanButton
                plan={plan}
                isLoading={isLoading}
                selectedPlan={selectedPlan}
                subscription={subscription}
                handleSelectPlan={handleSelectPlan}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}