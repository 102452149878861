export const subjectColors = {
  Math: { bg: 'bg-blue-200', text: 'text-blue-800' },
  Science: { bg: 'bg-green-200', text: 'text-green-800' },
  English: { bg: 'bg-yellow-200', text: 'text-yellow-800' },
  'Social Studies': { bg: 'bg-pink-200', text: 'text-pink-800' },
  Other: { bg: 'bg-purple-200', text: 'text-purple-800' },
  // Custom subject colors will be assigned from this list
  Custom1: { bg: 'bg-orange-200', text: 'text-orange-800' },
  Custom2: { bg: 'bg-teal-200', text: 'text-teal-800' },
  Custom3: { bg: 'bg-red-200', text: 'text-red-800' },
  Custom4: { bg: 'bg-indigo-200', text: 'text-indigo-800' },
  Custom5: { bg: 'bg-lime-200', text: 'text-lime-800' },
} as const;