import React, { createContext, useContext, useReducer } from 'react';
import { useAuth } from './AuthContext';
import * as plansApi from '../services/plans';
import type { PlanRequest } from '../types';

interface PlanRequestState {
  requests: PlanRequest[];
  hiddenRequests: PlanRequest[];
  isLoading: boolean;
  error?: string;
  isInitialized: boolean;
}

type PlanRequestAction = 
  | { type: 'ADD_REQUEST'; payload: PlanRequest }
  | { type: 'SET_REQUESTS'; payload: { active: PlanRequest[]; hidden: PlanRequest[] } }
  | { type: 'HIDE_REQUEST'; payload: string }
  | { type: 'UNHIDE_REQUEST'; payload: string }
  | { type: 'CLEAR_REQUESTS' }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'SET_INITIALIZED' };

interface PlanRequestContextType {
  state: PlanRequestState;
  addRequest: (request: Omit<PlanRequest, 'id' | 'userId' | 'timestamp'>) => Promise<PlanRequest>;
  hidePlan: (requestId: string) => Promise<void>;
  unhidePlan: (requestId: string) => Promise<void>;
  clearRequests: () => void;
  loadPlans: () => Promise<void>;
}

const PlanRequestContext = createContext<PlanRequestContextType | undefined>(undefined);

const initialState: PlanRequestState = {
  requests: [],
  hiddenRequests: [],
  isLoading: false,
  error: undefined,
  isInitialized: false,
};

function planRequestReducer(state: PlanRequestState, action: PlanRequestAction): PlanRequestState {
  switch (action.type) {
    case 'ADD_REQUEST':
      return {
        ...state,
        requests: [action.payload, ...state.requests],
        error: undefined,
      };
    case 'SET_REQUESTS':
      return {
        ...state,
        requests: action.payload.active,
        hiddenRequests: action.payload.hidden,
        error: undefined,
      };
    case 'HIDE_REQUEST':
      const planToHide = state.requests.find(req => req.id === action.payload);
      return {
        ...state,
        requests: state.requests.filter(req => req.id !== action.payload),
        hiddenRequests: planToHide 
          ? [planToHide, ...state.hiddenRequests]
          : state.hiddenRequests,
      };
    case 'UNHIDE_REQUEST':
      const planToUnhide = state.hiddenRequests.find(req => req.id === action.payload);
      return {
        ...state,
        hiddenRequests: state.hiddenRequests.filter(req => req.id !== action.payload),
        requests: planToUnhide 
          ? [planToUnhide, ...state.requests]
          : state.requests,
      };
    case 'CLEAR_REQUESTS':
      return {
        ...state,
        requests: [],
        hiddenRequests: [],
        error: undefined,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'SET_INITIALIZED':
      return {
        ...state,
        isInitialized: true,
      };
    default:
      return state;
  }
}

export function PlanRequestProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(planRequestReducer, initialState);
  const { state: authState } = useAuth();

  const loadPlans = async () => {
    if (!authState.user?.username) {
      throw new Error('User not authenticated');
    }

    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      
      // Fetch both active and hidden plans
      const [activeResponse, hiddenResponse] = await Promise.all([
        plansApi.getPlans(authState.user.username, false),
        plansApi.getPlans(authState.user.username, true)
      ]);
      
      if (!activeResponse || !hiddenResponse || typeof activeResponse !== 'object' || typeof hiddenResponse !== 'object') {
        throw new Error('Invalid response format');
      }

      dispatch({ 
        type: 'SET_REQUESTS', 
        payload: { 
          active: activeResponse.Items || [], 
          hidden: hiddenResponse.Items || [] 
        }
      });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to load plans';
      console.error('Failed to load plans:', error);
      dispatch({ type: 'SET_ERROR', payload: errorMessage });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
      dispatch({ type: 'SET_INITIALIZED' });
    }
  };

  const addRequest = async (request: Omit<PlanRequest, 'id' | 'userId' | 'timestamp'>): Promise<PlanRequest> => {
    if (!authState.user?.username) {
      throw new Error('User not authenticated');
    }

    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      const response = await plansApi.createPlan(authState.user.username, request);
      
      if (!response?.Item) {
        throw new Error('Invalid response from create plan');
      }

      dispatch({ type: 'ADD_REQUEST', payload: response.Item });
      return response.Item;
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to save plan';
      console.error('Failed to save plan:', error);
      dispatch({ type: 'SET_ERROR', payload: errorMessage });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const hidePlan = async (requestId: string) => {
    if (!authState.user?.username) return;

    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      await plansApi.hidePlan(authState.user.username, requestId);
      dispatch({ type: 'HIDE_REQUEST', payload: requestId });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to hide plan';
      dispatch({ type: 'SET_ERROR', payload: errorMessage });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const unhidePlan = async (requestId: string) => {
    if (!authState.user?.username) return;

    try {
      dispatch({ type: 'SET_LOADING', payload: true });
      await plansApi.unhidePlan(authState.user.username, requestId);
      dispatch({ type: 'UNHIDE_REQUEST', payload: requestId });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Failed to unhide plan';
      dispatch({ type: 'SET_ERROR', payload: errorMessage });
      throw error;
    } finally {
      dispatch({ type: 'SET_LOADING', payload: false });
    }
  };

  const clearRequests = () => {
    dispatch({ type: 'CLEAR_REQUESTS' });
  };

  return (
    <PlanRequestContext.Provider value={{ 
      state, 
      addRequest, 
      hidePlan,
      unhidePlan,
      clearRequests, 
      loadPlans 
    }}>
      {children}
    </PlanRequestContext.Provider>
  );
}

export function usePlanRequests() {
  const context = useContext(PlanRequestContext);
  if (context === undefined) {
    throw new Error('usePlanRequests must be used within a PlanRequestProvider');
  }
  return context;
}