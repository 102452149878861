
import { Communication, MessageTemplate } from '../types/communication';

const API_ENDPOINT = import.meta.env.VITE_API_DATATABLEDYNAMO_ENDPOINT;

export class CommunicationError extends Error {
  constructor(message: string, public statusCode: number = 500) {
    super(message);
    this.name = 'CommunicationError';
  }
}

export async function getMessageTemplates(userId: string): Promise<MessageTemplate[]> {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new CommunicationError('Authentication token not found', 401);
    }

    const response = await fetch(`${API_ENDPOINT}/messageTemplate?userId=${userId}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.status === 404) {
      return [];
    }

    if (!response.ok) {
      throw new CommunicationError(`HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    return data.Items || [];
  } catch (error) {
    console.error('Error fetching message templates:', error);
    if (error instanceof CommunicationError) throw error;
    throw new CommunicationError('Failed to fetch message templates');
  }
}

export async function createMessageTemplate(
  userId: string,
  template: Omit<MessageTemplate, 'id' | 'userId' | 'createdAt' | 'lastModified'>
): Promise<MessageTemplate> {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new CommunicationError('Authentication token not found', 401);
    }

    const response = await fetch(`${API_ENDPOINT}/messageTemplate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        ...template,
      }),
    });

    if (!response.ok) {
      throw new CommunicationError(`HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    return data.Item;
  } catch (error) {
    console.error('Error creating message template:', error);
    if (error instanceof CommunicationError) throw error;
    throw new CommunicationError('Failed to create message template');
  }
}

export async function updateMessageTemplate(
  userId: string,
  template: MessageTemplate
): Promise<MessageTemplate> {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new CommunicationError('Authentication token not found', 401);
    }

    const response = await fetch(`${API_ENDPOINT}/messageTemplate`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        ...template,
      }),
    });

    if (!response.ok) {
      throw new CommunicationError(`HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    return data.Item;
  } catch (error) {
    console.error('Error updating message template:', error);
    if (error instanceof CommunicationError) throw error;
    throw new CommunicationError('Failed to update message template');
  }
}

export async function deleteMessageTemplate(userId: string, templateId: string): Promise<void> {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new CommunicationError('Authentication token not found', 401);
    }

    const response = await fetch(
      `${API_ENDPOINT}/messageTemplate?userId=${userId}&templateId=${templateId}`,
      {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      throw new CommunicationError(`HTTP error! status: ${response.status}`, response.status);
    }
  } catch (error) {
    console.error('Error deleting message template:', error);
    if (error instanceof CommunicationError) throw error;
    throw new CommunicationError('Failed to delete message template');
  }
}

export async function createCommunication(
  userId: string,
  communication: Omit<Communication, 'id' | 'userId' | 'createdAt'>
): Promise<Communication> {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new CommunicationError('Authentication token not found', 401);
    }

    const response = await fetch(`${API_ENDPOINT}/communication`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        ...communication,
      }),
    });

    if (!response.ok) {
      throw new CommunicationError(`HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    return data.Item;
  } catch (error) {
    console.error('Error creating communication:', error);
    if (error instanceof CommunicationError) throw error;
    throw new CommunicationError('Failed to create communication');
  }
}

export async function getStudentCommunications(
  userId: string,
  studentId: string
): Promise<Communication[]> {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new CommunicationError('Authentication token not found', 401);
    }

    const response = await fetch(
      `${API_ENDPOINT}/communication?userId=${userId}&studentId=${studentId}`,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 404) {
      return [];
    }

    if (!response.ok) {
      throw new CommunicationError(`HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    return data.Items || [];
  } catch (error) {
    console.error('Error fetching student communications:', error);
    if (error instanceof CommunicationError) throw error;
    throw new CommunicationError('Failed to fetch student communications');
  }
}

export async function updateCommunication(
  userId: string,
  data: {
    id: string;
    studentId: string;
    status: 'draft' | 'sent';
    sentDate?: string;
  }
): Promise<void> {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new CommunicationError('Authentication token not found', 401);
    }

    const response = await fetch(`${API_ENDPOINT}/communication`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        ...data,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error' }));
      throw new CommunicationError(
        errorData.message || `HTTP error! status: ${response.status}`,
        response.status
      );
    }
  } catch (error) {
    console.error('Error updating communication:', error);
    if (error instanceof CommunicationError) throw error;
    throw new CommunicationError('Failed to update communication');
  }
}
