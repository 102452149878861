import React from 'react';
import { ArrowUpDown } from 'lucide-react';

export type SortField = 'priority' | 'dueDate' | 'createdAt';
export type SortDirection = 'asc' | 'desc';

interface TaskSortProps {
  field: SortField;
  direction: SortDirection;
  onSort: (field: SortField, direction: SortDirection) => void;
}

export default function TaskSort({ field, direction, onSort }: TaskSortProps) {
  const handleSort = (newField: SortField) => {
    const newDirection = field === newField && direction === 'asc' ? 'desc' : 'asc';
    onSort(newField, newDirection);
  };

  const getSortIndicator = (sortField: SortField) => {
    if (field !== sortField) return null;
    return <span className="ml-1">{direction === 'asc' ? '↑' : '↓'}</span>;
  };

  return (
    <div className="flex items-center gap-2 text-sm">
      <ArrowUpDown className="h-4 w-4 text-gray-400" />
      <span className="text-gray-500">Sort by:</span>
      <button
        onClick={() => handleSort('priority')}
        className={`px-2 py-1 rounded hover:bg-gray-100 ${
          field === 'priority' ? 'font-medium text-indigo-600' : 'text-gray-600'
        }`}
      >
        Priority{getSortIndicator('priority')}
      </button>
      <button
        onClick={() => handleSort('dueDate')}
        className={`px-2 py-1 rounded hover:bg-gray-100 ${
          field === 'dueDate' ? 'font-medium text-indigo-600' : 'text-gray-600'
        }`}
      >
        Due Date{getSortIndicator('dueDate')}
      </button>
      <button
        onClick={() => handleSort('createdAt')}
        className={`px-2 py-1 rounded hover:bg-gray-100 ${
          field === 'createdAt' ? 'font-medium text-indigo-600' : 'text-gray-600'
        }`}
      >
        Created{getSortIndicator('createdAt')}
      </button>
    </div>
  );
}