import React, { useState } from 'react';
import { ChevronDown, ChevronUp, User, ArrowRight, MessageCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import type { Student } from '../../types/student';
import { useCommunication } from '../../contexts/CommunicationContext';

interface StudentTableProps {
  students: Student[];
  selectedStudents: Set<string>;
  onSelectionChange: (studentIds: Set<string>) => void;
}

interface Column {
  key: keyof Student | 'fullName' | 'messageCount';
  label: string;
  sortable?: boolean;
  render?: (student: Student) => React.ReactNode;
  renderHeader?: () => React.ReactNode;
}

export default function StudentTable({ students, selectedStudents, onSelectionChange }: StudentTableProps) {
  const navigate = useNavigate();
  const { getStudentCommunications } = useCommunication();
  const [sortConfig, setSortConfig] = useState<{
    key: string;
    direction: 'asc' | 'desc';
  } | null>(null);
  const [lastSelected, setLastSelected] = useState<string | null>(null);
  const [communicationCounts, setCommunicationCounts] = useState<Record<string, number>>({});

  React.useEffect(() => {
    const loadCommunicationCounts = async () => {
      const counts: Record<string, number> = {};
      for (const student of students) {
        try {
          const communications = await getStudentCommunications(student.id);
          counts[student.id] = communications.filter(c => c.status === 'sent').length;
        } catch (error) {
          console.error(`Error loading communications for student ${student.id}:`, error);
          counts[student.id] = 0;
        }
      }
      setCommunicationCounts(counts);
    };
    
    loadCommunicationCounts();
  }, [students]);

  const columns: Column[] = [
    {
      key: 'fullName',
      label: 'Name',
      sortable: true,
      render: (student) => (
        <div className="flex items-center gap-3">
          <div className="w-8 h-8 rounded-full bg-gray-100 flex items-center justify-center overflow-hidden">
            {student.profilePicture ? (
              <img
                src={student.profilePicture}
                alt={`${student.firstName} ${student.lastName}`}
                className="w-full h-full object-cover"
              />
            ) : (
              <User className="w-4 h-4 text-gray-400" />
            )}
          </div>
          <div>
            <div className="font-medium text-gray-900">
              {student.firstName} {student.lastName}
            </div>
            <div className="text-sm text-gray-500">
              Grade {student.grade}
            </div>
          </div>
        </div>
      ),
      renderHeader: () => (
        <div className="flex items-center gap-3">
          <input
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
            checked={selectedStudents.size === students.length}
            onChange={(e) => {
              e.stopPropagation();
              handleSelectAll(e);
            }}
            onClick={(e) => e.stopPropagation()}
          />
          <span>Name</span>
        </div>
      )
    },
    {
      key: 'class',
      label: 'Class',
      sortable: true,
    },
    {
      key: 'messageCount',
      label: 'Messages',
      render: (student) => communicationCounts[student.id] > 0 ? (
        <div className="flex items-center gap-1 text-indigo-600">
          <MessageCircle className="h-4 w-4" />
          <span>{communicationCounts[student.id]}</span>
        </div>
      ) : null
    }
  ];

  const handleSort = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig?.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedStudents = React.useMemo(() => {
    if (!sortConfig) return students;

    return [...students].sort((a, b) => {
      if (sortConfig.key === 'fullName') {
        const aName = `${a.firstName} ${a.lastName}`;
        const bName = `${b.firstName} ${b.lastName}`;
        return sortConfig.direction === 'asc' 
          ? aName.localeCompare(bName)
          : bName.localeCompare(aName);
      }

      const aValue = a[sortConfig.key as keyof Student];
      const bValue = b[sortConfig.key as keyof Student];

      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [students, sortConfig]);

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      onSelectionChange(new Set(students.map(s => s.id)));
    } else {
      onSelectionChange(new Set());
    }
  };

  const handleRowClick = (studentId: string, e: React.MouseEvent) => {
    if ((e.target as HTMLElement).closest('.view-button')) {
      return;
    }

    if (e.shiftKey && lastSelected) {
      const currentIndex = sortedStudents.findIndex(s => s.id === studentId);
      const lastIndex = sortedStudents.findIndex(s => s.id === lastSelected);
      const [start, end] = [currentIndex, lastIndex].sort((a, b) => a - b);
      const rangeIds = sortedStudents
        .slice(start, end + 1)
        .map(student => student.id);
      
      const newSelection = new Set([...selectedStudents, ...rangeIds]);
      onSelectionChange(newSelection);
      setLastSelected(studentId);
      return;
    }

    if (e.ctrlKey || e.metaKey) {
      const newSelection = new Set(selectedStudents);
      if (newSelection.has(studentId)) {
        newSelection.delete(studentId);
      } else {
        newSelection.add(studentId);
      }
      onSelectionChange(newSelection);
      setLastSelected(studentId);
      return;
    }

    const newSelection = new Set(selectedStudents);
    if (newSelection.has(studentId)) {
      newSelection.delete(studentId);
    } else {
      newSelection.add(studentId);
    }
    onSelectionChange(newSelection);
    setLastSelected(studentId);
  };

  const handleViewStudent = (studentId: string) => {
    navigate(`/students/${studentId}`);
  };

  if (!students.length) {
    return (
      <div className="text-center py-12 bg-white rounded-lg shadow-sm">
        <p className="text-gray-500">No students added yet</p>
      </div>
    );
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            {columns.map((column) => (
              <th
                key={column.key}
                scope="col"
                className={`px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider ${
                  column.sortable ? 'cursor-pointer select-none' : ''
                }`}
                onClick={() => column.sortable && handleSort(column.key)}
              >
                <div className="flex items-center gap-2">
                  {column.renderHeader ? (
                    column.renderHeader()
                  ) : (
                    <>
                      {column.label}
                      {column.sortable && sortConfig?.key === column.key && (
                        sortConfig.direction === 'asc' ? 
                          <ChevronUp className="h-4 w-4" /> : 
                          <ChevronDown className="h-4 w-4" />
                      )}
                    </>
                  )}
                </div>
              </th>
            ))}
            <th scope="col" className="relative px-6 py-3">
              <span className="sr-only">Actions</span>
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {sortedStudents.map((student) => (
            <tr
              key={student.id}
              onClick={(e) => handleRowClick(student.id, e)}
              className={`${
                selectedStudents.has(student.id)
                  ? 'bg-indigo-50'
                  : 'hover:bg-gray-50'
              } cursor-pointer select-none`}
            >
              {columns.map((column) => (
                <td key={column.key} className="px-6 py-4 whitespace-nowrap">
                  {column.render ? column.render(student) : (
                    <div className="text-sm text-gray-900">
                      {student[column.key as keyof Student]}
                    </div>
                  )}
                </td>
              ))}
              <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleViewStudent(student.id);
                  }}
                  className="view-button text-indigo-600 hover:text-indigo-900 flex items-center gap-1"
                >
                  View
                  <ArrowRight className="h-4 w-4" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}