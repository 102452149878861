import React, { createContext, useContext, useReducer } from 'react';
import { useAuth } from './AuthContext';
import * as lessonsApi from '../services/lessons';
import type { Lesson } from '../types';

interface LessonState {
  lessons: Record<string, Lesson[]>;
  lessonsByUnit: Record<string, Lesson[]>;
  lessonsByDate: Record<string, Lesson[]>;
  optimisticUpdates: Record<string, Lesson>;
  isLoading: boolean;
  error: string | null;
}

type LessonAction =
  | { type: 'SET_LOADING' }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'CLEAR_ERROR' }
  | { type: 'SET_LESSONS'; payload: { unitId: string; lessons: Lesson[] } }
  | { type: 'ADD_LESSON'; payload: Lesson }
  | { type: 'UPDATE_LESSON'; payload: Lesson }
  | { type: 'DELETE_LESSON'; payload: { unitId: string; lessonId: string } }
  | { type: 'REORDER_LESSONS'; payload: { unitId: string; lessons: Lesson[] } }
  | { type: 'SET_OPTIMISTIC_UPDATE'; payload: { lessonId: string; lesson: Lesson } }
  | { type: 'SET_BATCH_OPTIMISTIC_UPDATES'; payload: Record<string, Lesson> }
  | { type: 'CLEAR_OPTIMISTIC_UPDATES' };

interface LessonContextType {
  state: LessonState;
  loadLessons: (unitId: string) => Promise<void>;
  loadLessonsByDate: (date: string) => Promise<void>;
  addLesson: (unitId: string, lesson: Omit<Lesson, 'id' | 'createdAt'>) => Promise<void>;
  updateLesson: (lesson: Lesson, optimistic?: boolean) => Promise<void>;
  deleteLesson: (unitId: string, lessonId: string) => Promise<void>;
  reorderLessons: (unitId: string, lessons: Lesson[]) => Promise<void>;
  clearError: () => void;
  setOptimisticUpdate: (lessonId: string, lesson: Lesson) => void;
  setBatchOptimisticUpdates: (updates: Record<string, Lesson>) => void;
  clearOptimisticUpdates: () => void;
}

const LessonContext = createContext<LessonContextType | undefined>(undefined);

const initialState: LessonState = {
  lessons: {},
  lessonsByUnit: {},
  lessonsByDate: {},
  optimisticUpdates: {},
  isLoading: false,
  error: null,
};

function lessonReducer(state: LessonState, action: LessonAction): LessonState {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case 'CLEAR_ERROR':
      return {
        ...state,
        error: null,
      };
    case 'SET_LESSONS': {
      const isDateQuery = /^\d{4}-\d{2}-\d{2}$/.test(action.payload.unitId);
      return {
        ...state,
        lessons: {
          ...state.lessons,
          [action.payload.unitId]: action.payload.lessons || [],
        },
        ...(isDateQuery
          ? {
              lessonsByDate: {
                ...state.lessonsByDate,
                [action.payload.unitId]: action.payload.lessons || [],
              }
            }
          : {
              lessonsByUnit: {
                ...state.lessonsByUnit,
                [action.payload.unitId]: action.payload.lessons || [],
              }
            }
        ),
        isLoading: false,
        error: null,
      };
    }
    case 'ADD_LESSON': {
      const isDateQuery = /^\d{4}-\d{2}-\d{2}$/.test(action.payload.unitId);
      return {
        ...state,
        lessons: {
          ...state.lessons,
          [action.payload.unitId]: [
            ...(state.lessons[action.payload.unitId] || []),
            action.payload,
          ],
        },
        ...(isDateQuery
          ? {
              lessonsByDate: {
                ...state.lessonsByDate,
                [action.payload.unitId]: [
                  ...(state.lessonsByDate[action.payload.unitId] || []),
                  action.payload,
                ],
              }
            }
          : {
              lessonsByUnit: {
                ...state.lessonsByUnit,
                [action.payload.unitId]: [
                  ...(state.lessonsByUnit[action.payload.unitId] || []),
                  action.payload,
                ],
              }
            }
        ),
        isLoading: false,
        error: null,
      };
    }
    case 'UPDATE_LESSON': {
      // Find the old unitId and determine if it was date-based
      let oldUnitId = '';
      let wasDateBased = false;
      
      // Check in lessonsByUnit
      for (const [unitId, lessons] of Object.entries(state.lessonsByUnit)) {
        if (lessons.some(l => l.id === action.payload.id)) {
          oldUnitId = unitId;
          wasDateBased = false;
          break;
        }
      }
      
      // Check in lessonsByDate if not found
      if (!oldUnitId) {
        for (const [dateId, lessons] of Object.entries(state.lessonsByDate)) {
          if (lessons.some(l => l.id === action.payload.id)) {
            oldUnitId = dateId;
            wasDateBased = true;
            break;
          }
        }
      }

      const { [action.payload.id]: _, ...remainingOptimisticUpdates } = state.optimisticUpdates;
      const newUnitId = action.payload.unitId;
      const isNewDateBased = /^\d{4}-\d{2}-\d{2}$/.test(newUnitId);

      const newState = {
        ...state,
        lessons: {
          ...state.lessons,
          ...(oldUnitId && oldUnitId !== newUnitId && {
            [oldUnitId]: state.lessons[oldUnitId]?.filter(
              lesson => lesson.id !== action.payload.id
            ) || []
          }),
          [newUnitId]: [
            ...(state.lessons[newUnitId] || []).filter(
              lesson => lesson.id !== action.payload.id
            ),
            action.payload
          ]
        },
        lessonsByUnit: {
          ...state.lessonsByUnit,
          ...((!wasDateBased && oldUnitId && oldUnitId !== newUnitId) && {
            [oldUnitId]: state.lessonsByUnit[oldUnitId]?.filter(
              lesson => lesson.id !== action.payload.id
            ) || []
          }),
          ...(!isNewDateBased && {
            [newUnitId]: [
              ...(state.lessonsByUnit[newUnitId] || []).filter(
                lesson => lesson.id !== action.payload.id
              ),
              action.payload
            ]
          })
        },
        lessonsByDate: {
          ...state.lessonsByDate,
          ...(wasDateBased && oldUnitId && oldUnitId !== newUnitId && {
            [oldUnitId]: state.lessonsByDate[oldUnitId]?.filter(
              lesson => lesson.id !== action.payload.id
            ) || []
          }),
          ...(isNewDateBased && {
            [newUnitId]: [
              ...(state.lessonsByDate[newUnitId] || []).filter(
                lesson => lesson.id !== action.payload.id
              ),
              action.payload
            ]
          })
        },
        optimisticUpdates: remainingOptimisticUpdates,
        isLoading: false,
        error: null,
      };

      // Maintain lesson order
      if (newState.lessons[newUnitId]) {
        newState.lessons[newUnitId].sort((a, b) => a.order - b.order);
      }
      if (isNewDateBased && newState.lessonsByDate[newUnitId]) {
        newState.lessonsByDate[newUnitId].sort((a, b) => a.order - b.order);
      }
      if (!isNewDateBased && newState.lessonsByUnit[newUnitId]) {
        newState.lessonsByUnit[newUnitId].sort((a, b) => a.order - b.order);
      }

      return newState;
    }
    case 'DELETE_LESSON': {
      const isDateQuery = /^\d{4}-\d{2}-\d{2}$/.test(action.payload.unitId);
      return {
        ...state,
        lessons: {
          ...state.lessons,
          [action.payload.unitId]: (state.lessons[action.payload.unitId] || []).filter(
            (lesson) => lesson.id !== action.payload.lessonId
          ),
        },
        ...(isDateQuery
          ? {
              lessonsByDate: {
                ...state.lessonsByDate,
                [action.payload.unitId]: (state.lessonsByDate[action.payload.unitId] || []).filter(
                  (lesson) => lesson.id !== action.payload.lessonId
                ),
              }
            }
          : {
              lessonsByUnit: {
                ...state.lessonsByUnit,
                [action.payload.unitId]: (state.lessonsByUnit[action.payload.unitId] || []).filter(
                  (lesson) => lesson.id !== action.payload.lessonId
                ),
              }
            }
        ),
        isLoading: false,
        error: null,
      };
    }
    case 'REORDER_LESSONS': {
      const isDateQuery = /^\d{4}-\d{2}-\d{2}$/.test(action.payload.unitId);
      return {
        ...state,
        lessons: {
          ...state.lessons,
          [action.payload.unitId]: action.payload.lessons,
        },
        ...(isDateQuery
          ? {
              lessonsByDate: {
                ...state.lessonsByDate,
                [action.payload.unitId]: action.payload.lessons,
              }
            }
          : {
              lessonsByUnit: {
                ...state.lessonsByUnit,
                [action.payload.unitId]: action.payload.lessons,
              }
            }
        ),
        optimisticUpdates: {},
        isLoading: false,
        error: null,
      };
    }
    case 'SET_OPTIMISTIC_UPDATE':
      return {
        ...state,
        optimisticUpdates: {
          ...state.optimisticUpdates,
          [action.payload.lessonId]: action.payload.lesson,
        },
      };
    case 'SET_BATCH_OPTIMISTIC_UPDATES':
      return {
        ...state,
        optimisticUpdates: {
          ...state.optimisticUpdates,
          ...action.payload,
        },
      };
    case 'CLEAR_OPTIMISTIC_UPDATES':
      return {
        ...state,
        optimisticUpdates: {},
      };
    default:
      return state;
  }
}

export function LessonProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(lessonReducer, initialState);
  const { state: authState } = useAuth();

  const setOptimisticUpdate = (lessonId: string, lesson: Lesson) => {
    dispatch({ type: 'SET_OPTIMISTIC_UPDATE', payload: { lessonId, lesson } });
  };

  const setBatchOptimisticUpdates = (updates: Record<string, Lesson>) => {
    dispatch({ type: 'SET_BATCH_OPTIMISTIC_UPDATES', payload: updates });
  };

  const clearOptimisticUpdates = () => {
    dispatch({ type: 'CLEAR_OPTIMISTIC_UPDATES' });
  };

  const loadLessons = async (unitId: string) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      const lessons = await lessonsApi.getLessons(authState.user.username, unitId);
      
      // Sort lessons by order
      const sortedLessons = lessons.sort((a, b) => a.order - b.order);
      
      dispatch({ type: 'SET_LESSONS', payload: { unitId, lessons: sortedLessons } });
    } catch (error) {
      const message = error instanceof lessonsApi.LessonsError ? error.message : 'Failed to fetch lessons';
      dispatch({ type: 'SET_ERROR', payload: message });
    }
  };

  const loadLessonsByDate = async (date: string) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      const lessons = await lessonsApi.getLessonsByDate(authState.user.username, date);
      dispatch({ type: 'SET_LESSONS', payload: { unitId: date, lessons } });
    } catch (error) {
      const message = error instanceof lessonsApi.LessonsError ? error.message : 'Failed to fetch lessons';
      dispatch({ type: 'SET_ERROR', payload: message });
    }
  };

  const addLesson = async (unitId: string, lesson: Omit<Lesson, 'id' | 'createdAt'>) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      const newLesson = await lessonsApi.createLesson(authState.user.username, {
        ...lesson,
        unitId,
      });
      dispatch({ type: 'ADD_LESSON', payload: newLesson });
    } catch (error) {
      const message = error instanceof lessonsApi.LessonsError ? error.message : 'Failed to create lesson';
      dispatch({ type: 'SET_ERROR', payload: message });
      throw error;
    }
  };

  const updateLesson = async (lesson: Lesson, optimistic = true) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    if (optimistic) {
      setOptimisticUpdate(lesson.id, lesson);
    }

    try {
      const updatedLesson = await lessonsApi.updateLesson(authState.user.username, lesson);
      dispatch({ type: 'UPDATE_LESSON', payload: updatedLesson });
      return updatedLesson;
    } catch (error) {
      const message = error instanceof lessonsApi.LessonsError ? error.message : 'Failed to update lesson';
      dispatch({ type: 'SET_ERROR', payload: message });
      if (optimistic) {
        clearOptimisticUpdates();
      }
      throw error;
    }
  };

  const deleteLesson = async (unitId: string, lessonId: string) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      await lessonsApi.deleteLesson(authState.user.username, lessonId);
      dispatch({ type: 'DELETE_LESSON', payload: { unitId, lessonId } });
    } catch (error) {
      const message = error instanceof lessonsApi.LessonsError ? error.message : 'Failed to delete lesson';
      dispatch({ type: 'SET_ERROR', payload: message });
      throw error;
    }
  };

  const reorderLessons = async (unitId: string, lessons: Lesson[]) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    // Apply optimistic updates for all lessons being reordered
    const optimisticUpdates = lessons.reduce((acc, lesson) => {
      acc[lesson.id] = lesson;
      return acc;
    }, {} as Record<string, Lesson>);
    
    setBatchOptimisticUpdates(optimisticUpdates);

    try {
      // Update one lesson at a time
      for (const lesson of lessons) {
        await lessonsApi.updateLesson(authState.user.username, lesson);
      }
      dispatch({ type: 'REORDER_LESSONS', payload: { unitId, lessons } });
    } catch (error) {
      const message = error instanceof lessonsApi.LessonsError ? error.message : 'Failed to reorder lessons';
      dispatch({ type: 'SET_ERROR', payload: message });
      clearOptimisticUpdates();
      throw error;
    }
  };

  const clearError = () => {
    dispatch({ type: 'CLEAR_ERROR' });
  };

  return (
    <LessonContext.Provider
      value={{
        state,
        loadLessons,
        loadLessonsByDate,
        addLesson,
        updateLesson,
        deleteLesson,
        reorderLessons,
        clearError,
        setOptimisticUpdate,
        setBatchOptimisticUpdates,
        clearOptimisticUpdates,
      }}
    >
      {children}
    </LessonContext.Provider>
  );
}

export function useLessons() {
  const context = useContext(LessonContext);
  if (context === undefined) {
    throw new Error('useLessons must be used within a LessonProvider');
  }
  return context;
}