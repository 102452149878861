import React, { useState } from 'react';
import { Camera } from 'lucide-react';
import type { Student } from '../../../types/student';
import { useStudents } from '../../../contexts/StudentContext';

interface BasicInfoStepProps {
  onSubmit: (data: Partial<Student>) => void;
  initialData: Partial<Student>;
  isSubmitting?: boolean;
  onBack?: () => void;
}

export default function BasicInfoStep({ onSubmit, initialData, isSubmitting }: BasicInfoStepProps) {
  const { state: studentState } = useStudents();
  const [formData, setFormData] = useState({
    firstName: initialData.firstName || '',
    lastName: initialData.lastName || '',
    grade: initialData.grade || 1,
    class: initialData.class || '',
    dateOfBirth: initialData.dateOfBirth || '',
    gender: initialData.gender || 'preferNotToSay',
    talkLevel: initialData.talkLevel || 5,
    behaviorLevel: initialData.behaviorLevel || 1,
    interests: initialData.interests?.join(', ') || '',
    languagePreference: initialData.languagePreference || 'English',
    doNotSeatWith: initialData.doNotSeatWith || [],
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit({
      ...formData,
      interests: formData.interests.split(',').map(i => i.trim()).filter(Boolean),
    });
  };

  const handleStudentSelect = (studentId: string) => {
    setFormData(prev => ({
      ...prev,
      doNotSeatWith: prev.doNotSeatWith.includes(studentId)
        ? prev.doNotSeatWith.filter(id => id !== studentId)
        : [...prev.doNotSeatWith, studentId]
    }));
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div className="flex justify-center">
        <div className="relative">
          <div className="w-32 h-32 rounded-full bg-gray-100 flex items-center justify-center">
            <Camera className="h-8 w-8 text-gray-400" />
          </div>
          <button
            type="button"
            className="absolute bottom-0 right-0 rounded-full bg-indigo-600 p-2 text-white hover:bg-indigo-700"
          >
            <Camera className="h-4 w-4" />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
            First Name
          </label>
          <input
            type="text"
            id="firstName"
            value={formData.firstName}
            onChange={(e) => setFormData({ ...formData, firstName: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            required
          />
        </div>
        <div>
          <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
            Last Name
          </label>
          <input
            type="text"
            id="lastName"
            value={formData.lastName}
            onChange={(e) => setFormData({ ...formData, lastName: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            required
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="grade" className="block text-sm font-medium text-gray-700">
            Grade
          </label>
          <select
            id="grade"
            value={formData.grade}
            onChange={(e) => setFormData({ ...formData, grade: Number(e.target.value) })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          >
            {Array.from({ length: 12 }, (_, i) => (
              <option key={i + 1} value={i + 1}>
                Grade {i + 1}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label htmlFor="class" className="block text-sm font-medium text-gray-700">
            Class/Section
          </label>
          <input
            type="text"
            id="class"
            value={formData.class}
            onChange={(e) => setFormData({ ...formData, class: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            required
          />
        </div>
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div>
          <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700">
            Date of Birth
          </label>
          <input
            type="date"
            id="dateOfBirth"
            value={formData.dateOfBirth}
            onChange={(e) => setFormData({ ...formData, dateOfBirth: e.target.value })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            required
          />
        </div>
        <div>
          <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
            Gender
          </label>
          <select
            id="gender"
            value={formData.gender}
            onChange={(e) => setFormData({ ...formData, gender: e.target.value as Student['gender'] })}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          >
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
            <option value="preferNotToSay">Prefer not to say</option>
          </select>
        </div>
      </div>

      <div>
        <label htmlFor="talkLevel" className="block text-sm font-medium text-gray-700">
          Talk Level (1-10)
        </label>
        <input
          type="range"
          id="talkLevel"
          min="1"
          max="10"
          value={formData.talkLevel}
          onChange={(e) => setFormData({ ...formData, talkLevel: Number(e.target.value) })}
          className="mt-1 block w-full"
        />
        <div className="flex justify-between text-xs text-gray-500">
          <span>Quiet</span>
          <span>Talkative</span>
        </div>
      </div>

      <div>
        <label htmlFor="behaviorLevel" className="block text-sm font-medium text-gray-700">
          Behavior Level (1-10)
        </label>
        <input
          type="range"
          id="behaviorLevel"
          min="1"
          max="10"
          value={formData.behaviorLevel}
          onChange={(e) => setFormData({ ...formData, behaviorLevel: Number(e.target.value) })}
          className="mt-1 block w-full"
        />
        <div className="flex justify-between text-xs text-gray-500">
          <span>Perfect Behavior</span>
          <span>Poor Behavior</span>
        </div>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Do Not Seat With
        </label>
        <div className="border rounded-lg p-4 max-h-48 overflow-y-auto">
          {studentState.students.length === 0 ? (
            <p className="text-sm text-gray-500">No other students available</p>
          ) : (
            <div className="space-y-2">
              {studentState.students
                .filter(student => student.id !== initialData.id)
                .map(student => (
                  <label key={student.id} className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={formData.doNotSeatWith.includes(student.id)}
                      onChange={() => handleStudentSelect(student.id)}
                      className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <span className="text-sm text-gray-900">
                      {student.firstName} {student.lastName}
                    </span>
                  </label>
                ))}
            </div>
          )}
        </div>
      </div>

      <div>
        <label htmlFor="interests" className="block text-sm font-medium text-gray-700">
          Interests (comma-separated)
        </label>
        <input
          type="text"
          id="interests"
          value={formData.interests}
          onChange={(e) => setFormData({ ...formData, interests: e.target.value })}
          placeholder="e.g., Reading, Math, Sports"
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        />
      </div>

      <div>
        <label htmlFor="languagePreference" className="block text-sm font-medium text-gray-700">
          Language Preference
        </label>
        <input
          type="text"
          id="languagePreference"
          value={formData.languagePreference}
          onChange={(e) => setFormData({ ...formData, languagePreference: e.target.value })}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        />
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          disabled={isSubmitting}
          className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
        >
          Next: Parent Information
        </button>
      </div>
    </form>
  );
}