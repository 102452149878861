// components/common/DatePicker.tsx

import React, { useState, useEffect, useRef } from 'react';
import { 
  format, 
  startOfWeek, 
  endOfWeek, 
  addDays, 
  isSameDay,
  addMonths,
  startOfMonth,
  eachWeekOfInterval,
  endOfMonth
} from 'date-fns';
import { ChevronLeft, ChevronRight } from 'lucide-react';

interface DatePickerProps {
  selectedDate: Date;
  onChange: (date: Date) => void;
  viewMode: 'day' | 'week' | 'month';
}

export function DatePicker({ selectedDate, onChange, viewMode }: DatePickerProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(selectedDate);
  const pickerRef = useRef<HTMLDivElement>(null);
  
  const weekStart = startOfWeek(selectedDate, { weekStartsOn: 0 });
  const weekEnd = endOfWeek(selectedDate, { weekStartsOn: 0 });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const formatButtonText = () => {
    switch (viewMode) {
      case 'day':
        return format(selectedDate, 'MMMM d, yyyy');
      case 'week':
        return `${format(weekStart, 'MMM d')} - ${format(weekEnd, 'MMM d, yyyy')}`;
      case 'month':
        return format(selectedDate, 'MMMM yyyy');
    }
  };

  const handleDateClick = (date: Date) => {
    if (viewMode === 'month') {
      const currentStart = startOfMonth(date);
      onChange(currentStart);
    } else if (viewMode === 'week') {
      const weekStart = startOfWeek(date, { weekStartsOn: 0 });
      onChange(weekStart);
    } else {
      onChange(date);
    }
    setIsOpen(false);
  };

  const generateCalendarDays = () => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(currentMonth);
    const weeks = eachWeekOfInterval(
      { start: monthStart, end: monthEnd },
      { weekStartsOn: 0 }
    );

    return weeks.map(week => {
      const days = [];
      const weekStart = startOfWeek(week, { weekStartsOn: 0 });
      
      for (let i = 0; i < 7; i++) {
        days.push(addDays(weekStart, i));  // Fixed line
      }
      
      return days;
    });
  };

  const isDateSelected = (date: Date) => {
    switch (viewMode) {
      case 'day':
        return isSameDay(date, selectedDate);
      case 'week':
        return date >= weekStart && date <= weekEnd;
      case 'month':
        return date.getMonth() === selectedDate.getMonth() && 
               date.getYear() === selectedDate.getYear();
    }
  };

  return (
    <div className="relative" ref={pickerRef}>
      <button
  onClick={() => setIsOpen(!isOpen)}
  className="block w-[200px] rounded-lg border border-gray-300 bg-white px-4 py-2 text-base shadow-sm hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500"
>
  {formatButtonText()}
</button>

      {isOpen && (
        <div className="absolute mt-1 bg-white border rounded-md shadow-lg z-10 p-4 min-w-[280px]">
          <div className="flex items-center justify-between mb-4">
            <button
              onClick={() => setCurrentMonth(prev => addMonths(prev, -1))}
              className="p-1 hover:bg-gray-100 rounded-md"
            >
              <ChevronLeft className="h-5 w-5" />
            </button>
            
            <div className="text-sm font-medium">
              {format(currentMonth, 'MMMM yyyy')}
            </div>
            
            <button
              onClick={() => setCurrentMonth(prev => addMonths(prev, 1))}
              className="p-1 hover:bg-gray-100 rounded-md"
            >
              <ChevronRight className="h-5 w-5" />
            </button>
          </div>

          <div className="grid grid-cols-7 gap-1">
            {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
              <div key={day} className="text-xs text-center text-gray-500 p-2">
                {day}
              </div>
            ))}
            
            {generateCalendarDays().map((week, weekIndex) => (
              <React.Fragment key={weekIndex}>
                {week.map((day, dayIndex) => {
                  const isSelected = isDateSelected(day);
                  const isDifferentMonth = day.getMonth() !== currentMonth.getMonth();

                  return (
                    <button
                      key={dayIndex}
                      onClick={() => handleDateClick(day)}
                      className={`
                        p-2 text-sm rounded-md relative
                        ${isSelected ? 'bg-blue-500 text-white' : ''}
                        ${isDifferentMonth ? 'text-gray-300' : ''}
                        ${!isSelected && !isDifferentMonth ? 'hover:bg-gray-100' : ''}
                      `}
                    >
                      {format(day, 'd')}
                    </button>
                  );
                })}
              </React.Fragment>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}