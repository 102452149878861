import React from 'react';
import { AlertTriangle, MessageCircle, Calendar, FileText } from 'lucide-react';

interface BulkActionsProps {
  selectedCount: number;
  onCreateIncident: () => void;
  onCreateAssessment: () => void;
  onMarkAttendance: () => void;
  onSendCommunication: () => void;
}

export default function BulkActions({
  selectedCount,
  onCreateIncident,
  onCreateAssessment,
  onMarkAttendance,
  onSendCommunication
}: BulkActionsProps) {
  if (selectedCount === 0) return null;

  return (
    <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-white rounded-lg shadow-lg border border-gray-200 p-2 flex items-center gap-2">
      <span className="px-3 py-1 bg-indigo-100 text-indigo-800 rounded-full text-sm font-medium">
        {selectedCount} selected
      </span>
      <div className="h-6 border-l border-gray-200" />
      
      {/* Behavior Incident - Now Enabled */}
      <button
        onClick={onCreateIncident}
        className="flex items-center gap-2 px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded"
      >
        <AlertTriangle className="h-4 w-4" />
        Behavior Incident
      </button>

      {/* Assessment - Disabled */}
      <div className="relative group">
        <button
          disabled
          className="flex items-center gap-2 px-3 py-1 text-sm font-medium text-gray-400 hover:bg-gray-100 rounded cursor-not-allowed"
        >
          <FileText className="h-4 w-4" />
          Assessment
        </button>
        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-900 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity whitespace-nowrap pointer-events-none">
          Coming Soon
        </div>
      </div>

      {/* Attendance */}
      <button
        onClick={onMarkAttendance}
        className="flex items-center gap-2 px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded"
      >
        <Calendar className="h-4 w-4" />
        Attendance
      </button>

      {/* Communication */}
      <button
        onClick={onSendCommunication}
        className="flex items-center gap-2 px-3 py-1 text-sm font-medium text-gray-700 hover:bg-gray-100 rounded"
      >
        <MessageCircle className="h-4 w-4" />
        Communication
      </button>
    </div>
  );
}