import { createContext, useContext, useEffect, useState } from 'react';
import { 
  signIn, 
  signUp, 
  signOut, 
  getCurrentUser, 
  confirmSignUp, 
  AuthUser, 
  fetchUserAttributes, 
  fetchAuthSession, 
  resetPassword,
  confirmResetPassword,
  updateUserAttributes as amplifyUpdateUserAttributes 
} from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';

interface AuthState {
  user: AuthUser | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  error: string | null;
  unverifiedEmail: string | null;
}

interface AuthContextType {
  state: AuthState;
  setState: React.Dispatch<React.SetStateAction<AuthState>>;
  login: (email: string, password: string) => Promise<void>;
  register: (email: string, password: string, name: string, schoolName: string, zipCode: string) => Promise<void>;
  verifyEmail: (email: string, code: string) => Promise<void>;
  logout: () => Promise<void>;
  clearError: () => void;
  updateUserAttributes: (attributes: Record<string, string>) => Promise<void>;
  updateVerificationEmail: (newEmail: string) => Promise<void>;
  resendVerificationCode: (email: string) => Promise<void>;
  forgotPassword: (email: string) => Promise<string | undefined>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [state, setState] = useState<AuthState>({
    user: null,
    isAuthenticated: false,
    isLoading: true,
    error: null,
    unverifiedEmail: null
  });

  useEffect(() => {
    let mounted = true;
    
    const initialize = async () => {
      try {
        // Try to get the current session first
        const session = await fetchAuthSession();
        if (!mounted) return;
        
        if (session?.tokens?.idToken) {
          const token = session.tokens.idToken.toString();
          localStorage.setItem('token', token);
          
          // If we have a valid session, get the user details
          const user = await getCurrentUser();
          const attributes = await fetchUserAttributes();
          
          if (mounted) {
            setState(prev => ({
              ...prev,
              user: { ...user, attributes },
              isAuthenticated: true,
              isLoading: false,
            }));
            localStorage.setItem('userId', user.username);
          }
        } else {
          if (mounted) {
            setState(prev => ({
              ...prev,
              isLoading: false,
              isAuthenticated: false,
            }));
          }
        }
      } catch (error) {
        if (mounted) {
          console.log('Initial auth check failed:', error);
          setState(prev => ({
            ...prev,
            isLoading: false,
            isAuthenticated: false,
          }));
          // Clean up any stale auth state
          try {
            await signOut({ global: true });
          } catch (signOutError) {
            console.error('Error during cleanup signout:', signOutError);
          }
          localStorage.removeItem('userId');
          localStorage.removeItem('token');
        }
      }
    };

    initialize();
    setupAuthListener();

    return () => {
      mounted = false;
    };
  }, []);


  async function forgotPassword(email: string) {
  try {
    setState(prev => ({ ...prev, isLoading: true, error: null }));
    
    try {
      await resetPassword({ username: email });
      return undefined; // Normal password reset flow
    } catch (error: any) {
      console.log('Password reset error:', error.name, error.message);
      
      if (error.message.includes('no registered/verified email') || 
          error.name === 'UserNotConfirmedException') {
        setState(prev => ({
          ...prev,
          unverifiedEmail: email,
        }));
        
        try {
          await signUp({
            username: email,
            password: 'temporaryPassword',
            options: {
              userAttributes: {
                email: email,
              },
            },
          });
        } catch (signUpError: any) {
          if (signUpError.name === 'UsernameExistsException') {
            setState(prev => ({
              ...prev,
              unverifiedEmail: email,
              error: 'Your account needs to be verified. A new verification code has been sent to your email.',
            }));
            return '/verify-email';
          }
        }
      }
      throw error;
    }
  } finally {
    setState(prev => ({ ...prev, isLoading: false }));
  }
}


  async function updateVerificationEmail(newEmail: string) {
  try {
    setState(prev => ({ 
      ...prev, 
      isLoading: true, 
      error: null 
    }));
    
    await signUp({
      username: newEmail,
      password: 'tempPassword',
      options: {
        userAttributes: {
          email: newEmail,
        },
      },
    });
    
  } catch (error: any) {
    // If the error is UsernameExistsException, that's what we want
    if (error.name === 'UsernameExistsException') {
      setState(prev => ({
        ...prev,
        unverifiedEmail: newEmail,
        isLoading: false,
        error: null // Explicitly clear any error
      }));
      return; // Return here to prevent throwing the error
    }
    
    // For any other error, set the error state and throw
    setState(prev => ({
      ...prev,
      error: error instanceof Error ? error.message : 'Failed to update email',
      isLoading: false
    }));
    throw error;
  }
  
  // Make sure we set loading to false if we somehow get here
  setState(prev => ({
    ...prev,
    isLoading: false
  }));
}

async function resendVerificationCode(email: string) {
  try {
    setState(prev => ({ ...prev, isLoading: true, error: null }));
    
    try {
      // For v6, we'll use confirmSignUp with an invalid code to trigger a resend
      await confirmSignUp({
        username: email,
        confirmationCode: '000000'
      });
    } catch (error: any) {
      // If we get CodeMismatchException, it means the user exists and a new code was sent
      if (error.name === 'CodeMismatchException') {
        setState(prev => ({
          ...prev,
          unverifiedEmail: email,
          error: 'A new verification code has been sent to your email.'
        }));
        return;
      }
      throw error;
    }
  } catch (error: any) {
    console.log('Resend code error:', error);
    setState(prev => ({
      ...prev,
      error: error instanceof Error ? error.message : 'Failed to resend code'
    }));
    throw error;
  } finally {
    setState(prev => ({ ...prev, isLoading: false }));
  }
}

  
  async function getAndStoreToken(throwError = false) {
    try {
      const session = await fetchAuthSession();
      
      if (session?.tokens?.idToken?.toString()) {
        const token = session.tokens.idToken.toString();
        localStorage.setItem('token', token);
        return token;
      } else {
        localStorage.removeItem('token');
        if (throwError) {
          throw new Error('No valid token found in session');
        }
        return null;
      }
    } catch (error) {
      localStorage.removeItem('token');
      if (throwError) {
        throw error;
      }
      return null;
    }
  }

  function setupAuthListener() {
    Hub.listen('auth', async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signedIn':
          try {
            const attributes = await fetchUserAttributes();
            setState(prev => ({
              ...prev,
              user: { ...data, attributes },
              isAuthenticated: true,
              error: null,
            }));
            await getAndStoreToken();
            localStorage.setItem('userId', data.username);
          } catch (error) {
            console.error('Error handling signIn event:', error);
          }
          break;
        case 'signedOut':
          setState(prev => ({
            ...prev,
            user: null,
            isAuthenticated: false,
            error: null,
          }));
          localStorage.removeItem('userId');
          localStorage.removeItem('token');
          break;
        case 'tokenRefresh':
          getAndStoreToken().catch(console.error);
          break;
      }
    });
  }

  async function login(email: string, password: string) {
    try {
      // First check if we're already authenticated
      const currentSession = await fetchAuthSession();
      if (currentSession?.tokens?.idToken) {
        // We're already logged in, just refresh the state
        const user = await getCurrentUser();
        const attributes = await fetchUserAttributes();
        setState(prev => ({
          ...prev,
          user: { ...user, attributes },
          isAuthenticated: true,
          isLoading: false,
          error: null,
        }));
        return;
      }

      setState(prev => ({ ...prev, isLoading: true, error: null }));
      
      const { isSignedIn, nextStep } = await signIn({ username: email, password });
      
      if (isSignedIn) {
        const user = await getCurrentUser();
        const attributes = await fetchUserAttributes();
        const token = await getAndStoreToken(true);
        
        setState(prev => ({
          ...prev,
          user: { ...user, attributes },
          isAuthenticated: true,
          isLoading: false,
        }));
        
        localStorage.setItem('userId', user.username);
      } else {
        throw new Error(`Authentication incomplete: ${nextStep.signInStep}`);
      }
    } catch (error) {
      localStorage.removeItem('token');
      setState(prev => ({
        ...prev,
        error: error instanceof Error ? error.message : 'Login failed',
        isLoading: false,
      }));
      throw error;
    }
  }

  async function register(email: string, password: string, name: string, schoolName: string, zipCode: string) {
    try {
      setState(prev => ({ ...prev, isLoading: true, error: null }));
      await signUp({
        username: email,
        password,
        options: {
          userAttributes: {
            email,
            name,
            profile: JSON.stringify({ schoolName, zipCode }),
          },
          autoSignIn: true
        },
      });
      setState(prev => ({
        ...prev,
        isLoading: false,
        unverifiedEmail: email,
      }));
    } catch (error) {
      setState(prev => ({
        ...prev,
        error: error instanceof Error ? error.message : 'Registration failed',
        isLoading: false,
      }));
      throw error;
    }
  }

  async function verifyEmail(email: string, code: string) {
    try {
      setState(prev => ({ ...prev, isLoading: true, error: null }));
      await confirmSignUp({ username: email, confirmationCode: code });
      setState(prev => ({
        ...prev,
        isLoading: false,
        unverifiedEmail: null,
      }));
    } catch (error) {
      setState(prev => ({
        ...prev,
        error: error instanceof Error ? error.message : 'Verification failed',
        isLoading: false,
      }));
      throw error;
    }
  }

  async function updateUserAttributes(attributes: Record<string, string>) {
    try {
      setState(prev => ({ ...prev, isLoading: true, error: null }));
      
      await amplifyUpdateUserAttributes({ userAttributes: attributes });
      const updatedAttributes = await fetchUserAttributes();
      const currentUser = await getCurrentUser();
      
      setState(prev => ({
        ...prev,
        user: {
          ...currentUser,
          attributes: updatedAttributes
        },
        isLoading: false,
      }));
    } catch (error) {
      console.error('Error updating profile:', error);
      setState(prev => ({
        ...prev,
        error: error instanceof Error ? error.message : 'Failed to update profile',
        isLoading: false,
      }));
      throw error;
    }
  }

  async function logout() {
    try {
      setState(prev => ({ ...prev, isLoading: true }));
      await signOut({ global: true });
      setState(prev => ({
        ...prev,
        user: null,
        isAuthenticated: false,
        error: null,
        isLoading: false,
      }));
      localStorage.removeItem('userId');
      localStorage.removeItem('token');
    } catch (error) {
      setState(prev => ({
        ...prev,
        error: error instanceof Error ? error.message : 'Logout failed',
        isLoading: false,
      }));
    }
  }

  function clearError() {
    setState(prev => ({ ...prev, error: null }));
  }

  return (
    <AuthContext.Provider
      value={{
        state,
        setState,
        login,
        register,
        logout,
        clearError,
        verifyEmail,
        updateUserAttributes,
        updateVerificationEmail,    // Add this
        resendVerificationCode, 
        forgotPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}