import React, { useState, useRef, useEffect } from 'react';
import {
  Bell,
  User,
  X,
  LogOut,
  Settings,
} from 'lucide-react';
import { useAlerts } from '../contexts/AlertContext';
import { useAuth } from '../contexts/AuthContext';
import { useSecurity } from '../contexts/SecurityContext';
import { formatDate } from '../utils/dateFormatter';
import { useNavigate } from 'react-router-dom';
import { getProfilePictureUrl } from '../utils/imageUtils';
import { getDailyQuote } from '../utils/quotes';

export default function Header() {
  const { state: alertState, markAsRead } = useAlerts();
  const { state: authState, logout } = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const profileRef = useRef<HTMLDivElement>(null);
  const unreadCount = alertState.alerts.length;
  const navigate = useNavigate();
  const [profilePicUrl, setProfilePicUrl] = useState<string | null>(null);
  const dailyQuote = getDailyQuote();

  const handleViewAll = () => {
    setIsDropdownOpen(false);
    navigate('/notifications');
  };

  const handleAlertClick = (alert: Alert) => {
    if (alert.metadata?.type === 'plan_ready' && alert.metadata.path) {
      navigate(alert.metadata.path);
      setIsDropdownOpen(false);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handleNavigateToAccount = () => {
    setIsProfileOpen(false);
    navigate('/account');
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
      if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
        setIsProfileOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const refreshProfilePicture = async () => {
      if (authState.user?.attributes?.picture) {
        try {
          const url = await getProfilePictureUrl(authState.user.attributes.picture);
          if (url) {
            setProfilePicUrl(url);
          }
        } catch (error) {
          console.error('Failed to refresh profile picture:', error);
        }
      }
    };

    refreshProfilePicture();
    const interval = setInterval(refreshProfilePicture, 30 * 60 * 1000);
    return () => clearInterval(interval);
  }, [authState.user?.attributes?.picture]);

  const getAlertColor = (type: 'urgent' | 'warning' | 'info') => {
    switch (type) {
      case 'urgent':
        return 'bg-red-50 border-red-100';
      case 'warning':
        return 'bg-yellow-50 border-yellow-100';
      case 'info':
        return 'bg-blue-50 border-blue-100';
    }
  };

  return (
    <header className="bg-white border-b border-gray-200 px-6 py-4">
      <div className="flex items-center justify-between">
        <div className="flex-1 flex items-center min-w-0">
          <div className="hidden md:block text-sm text-gray-500 italic pr-8 max-w-xl">
            <div className="line-clamp-2">
              "{dailyQuote.text}" – {dailyQuote.author}
            </div>
          </div>
        </div>

        <div className="flex items-center gap-4">
          <div className="relative" ref={dropdownRef}>
            <button 
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              className="relative p-2 text-gray-600 hover:bg-gray-100 rounded-full"
            >
              <Bell className="h-6 w-6" />
              {unreadCount > 0 && (
                <span className="absolute top-1 right-1 h-4 w-4 bg-red-500 rounded-full text-xs text-white flex items-center justify-center">
                  {unreadCount}
                </span>
              )}
            </button>

            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-96 bg-white rounded-lg shadow-lg border border-gray-200 z-50">
                <div className="p-4 border-b border-gray-200">
                  <div className="flex items-center justify-between">
                    <h3 className="font-semibold text-gray-900">Notifications</h3>
                    <span className="text-sm text-gray-500">
                      {unreadCount} unread
                    </span>
                  </div>
                </div>
                <div className="max-h-96 overflow-y-auto">
                  {alertState.alerts.length === 0 ? (
                    <div className="p-4 text-center text-gray-500">
                      No new notifications
                    </div>
                  ) : (
                    <div className="divide-y divide-gray-100">
                      {alertState.alerts.map((alert) => (
                        <div
                          key={alert.id}
                          onClick={() => handleAlertClick(alert)}
                          className={`p-4 ${getAlertColor(alert.type)} hover:bg-gray-50 transition-colors ${
                            alert.metadata?.type === 'plan_ready' ? 'cursor-pointer' : ''
                          }`}
                        >
                          <div className="flex items-start justify-between">
                            <div className="flex-1 pr-4">
                              <p className="text-sm text-gray-900">{alert.message}</p>
                              <p className="text-xs text-gray-500 mt-1">
                                {formatDate(alert.date)}
                              </p>
                              {alert.metadata?.type === 'plan_ready' && (
                                <p className="text-xs text-indigo-600 mt-1 hover:text-indigo-800">
                                  Click to view plan
                                </p>
                              )}
                            </div>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                markAsRead(alert.id);
                              }}
                              className="p-1 hover:bg-gray-200 rounded-full transition-colors"
                            >
                              <X className="h-4 w-4 text-gray-400" />
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="p-4 border-t border-gray-200">
                  <button 
                    className="text-sm text-indigo-600 hover:text-indigo-800 w-full text-center"
                    onClick={handleViewAll}
                  >
                    View all notifications
                  </button>
                </div>
              </div>
            )}
          </div>
          
          <div className="relative" ref={profileRef}>
            <button
              onClick={() => setIsProfileOpen(!isProfileOpen)}
              className="flex items-center gap-3 pl-4 border-l border-gray-200"
            >
              <div className="text-right">
                <p className="text-sm font-medium text-gray-900">
                  {authState.user?.attributes?.name || 'User'}
                </p>
                <p className="text-xs text-gray-500">
                  {authState.user?.attributes?.email}
                </p>
              </div>
              <div className="h-10 w-10 rounded-full bg-indigo-100 flex items-center justify-center overflow-hidden">
                {authState.user?.attributes?.picture ? (
                  <img 
                    src={profilePicUrl || authState.user.attributes.picture}
                    alt="Profile" 
                    className="w-full h-full object-cover"
                    onError={(e) => {
                      const target = e.target as HTMLImageElement;
                      target.onerror = null;
                      if (authState.user?.attributes?.picture) {
                        getProfilePictureUrl(authState.user.attributes.picture)
                          .then(url => {
                            if (url) {
                              setProfilePicUrl(url);
                              target.src = url;
                            }
                          });
                      }
                    }}
                  />
                ) : (
                  <User className="h-6 w-6 text-indigo-600" />
                )}
              </div>
            </button>

            {isProfileOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white rounded-lg shadow-lg border border-gray-200 z-50">
                <div className="p-2 space-y-1">
                  <button
                    onClick={handleNavigateToAccount}
                    className="w-full flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-lg"
                  >
                    <Settings className="h-4 w-4" />
                    My Account
                  </button>
                  <button
                    onClick={handleLogout}
                    className="w-full flex items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 rounded-lg"
                  >
                    <LogOut className="h-4 w-4" />
                    Sign out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
}