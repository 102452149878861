import React, { useState, useEffect } from 'react';
import { X, Clock, AlertCircle } from 'lucide-react';
import type { Student, Lesson } from '../../types';
import type { AttendanceStatus, MissedLesson } from '../../types/attendance';
import { useAttendance } from '../../contexts/AttendanceContext';
import { useLessons } from '../../contexts/LessonContext';
import { format, parseISO } from 'date-fns';
import { useAuth } from '../../contexts/AuthContext';
import { useTasks } from '../../contexts/TaskContext';

interface AttendanceModalProps {
  students: Student[];
  onClose: () => void;
  date?: string;
  existingRecord?: AttendanceRecord;  // Add this
  existingAttendance?: Record<string, StudentAttendance>;
}

interface StudentAttendance {
  status: AttendanceStatus;
  missedLessons: Set<string>;
  notes: string;
}

export default function AttendanceModal({ 
  students, 
  onClose, 
  date: initialDate, 
  existingAttendance = {} 
}: AttendanceModalProps) {
  const { markAttendance, updateAttendance, getStudentAttendance } = useAttendance();
  const { state: lessonState, loadLessonsByDate } = useLessons();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { state: authState } = useAuth();
  const { addTask } = useTasks();
  const [date, setDate] = useState(initialDate || format(new Date(), 'yyyy-MM-dd'));
  const [studentAttendance, setStudentAttendance] = useState<Record<string, StudentAttendance>>(() => {
  if (existingRecord) {
    return {
      [existingRecord.studentId]: {
        status: existingRecord.status,
        missedLessons: new Set(existingRecord.missedLessons?.map(l => l.lessonId) || []),
        notes: existingRecord.notes || ''
      }
    };
  }
  return students.reduce((acc, student) => ({
    ...acc,
    [student.id]: {
      status: 'present',
      missedLessons: new Set<string>(),
      notes: ''
    }
  }), {});
});

  // Load lessons for the selected date
  useEffect(() => {
    loadLessonsByDate(date);
  }, [date]);

  const lessons = lessonState.lessonsByDate[date] || [];

 /* const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      for (const student of students) {
        const attendance = studentAttendance[student.id];
        if (attendance.status !== 'present') {
          const missedLessonDetails: MissedLesson[] = Array.from(attendance.missedLessons).map(lessonId => {
            const lesson = lessons.find(l => l.id === lessonId);
            return {
              lessonId,
              title: lesson?.title || 'Unknown Lesson',
              period: lesson?.order < lessons.length / 2 ? 'morning' : 'afternoon'
            };
          });

          await markAttendance(
            [student.id],
            date,
            attendance.status,
            attendance.status === 'partial' ? missedLessonDetails : undefined,
            attendance.notes
          );

          const lessonsToProcess = attendance.status === 'absent' 
            ? lessons 
            : lessons.filter(lesson => attendance.missedLessons.has(lesson.id));

          for (const lesson of lessonsToProcess) {
            const nextBusinessDay = new Date(date);
            nextBusinessDay.setDate(nextBusinessDay.getDate() + 2);
            while (nextBusinessDay.getDay() === 0 || nextBusinessDay.getDay() === 6) {
              nextBusinessDay.setDate(nextBusinessDay.getDate() + 1);
            }

            const task = {
              userId: authState.user.username, 
              title: `Catch up ${student.firstName} ${student.lastName} on ${lesson.title}`,
              dueDate: format(nextBusinessDay, 'yyyy-MM-dd'),
              completed: false,
              priority: 'high',
              source: 'system'
            };

            await addTask(task);
          }
        }
      }

      onClose();
    } catch (err) {
      console.error('Error submitting attendance:', err);
      setError(err instanceof Error ? err.message : 'Failed to mark attendance');
    } finally {
      setIsSubmitting(false);
    }
  };
*/
  
const handleSubmit = async (e: React.FormEvent) => {
  e.preventDefault();
  setIsSubmitting(true);
  setError(null);

  try {
    // Check for existing records first
    const existingRecords = await checkExistingRecords();
    
    // Track which students would be duplicates
    const duplicateStudents = students
      .filter(s => existingRecords[s.id] && !existingAttendance[s.id])
      .map(s => `${s.firstName} ${s.lastName}`);

    if (duplicateStudents.length > 0) {
      setError(`Attendance already exists for: ${duplicateStudents.join(', ')}`);
      setIsSubmitting(false);
      return;
    }

    for (const student of students) {
      const attendance = studentAttendance[student.id];
      
      const missedLessonDetails: MissedLesson[] = Array.from(attendance.missedLessons)
        .map(lessonId => {
          const lesson = lessons.find(l => l.id === lessonId);
          return {
            lessonId,
            title: lesson?.title || 'Unknown Lesson',
            period: lesson?.order < lessons.length / 2 ? 'morning' : 'afternoon'
          };
        });

      if (existingAttendance[student.id]) {
        // Update existing record
        await updateAttendance({
          ...existingAttendance[student.id],
          status: attendance.status,
          missedLessons: attendance.status === 'partial' ? missedLessonDetails : [],
          notes: attendance.notes
        });
      } else {
        // Create new record only if one doesn't exist
        await markAttendance(
          [student.id],
          date,
          attendance.status,
          attendance.status === 'partial' ? missedLessonDetails : undefined,
          attendance.notes
        );
      }
    }

    onClose();
  } catch (err) {
    console.error('Error submitting attendance:', err);
    setError(err instanceof Error ? err.message : 'Failed to mark attendance');
  } finally {
    setIsSubmitting(false);
  }
};

  const checkExistingRecords = async () => {
    const existingRecords = await Promise.all(
      students.map(student => 
        getStudentAttendance(student.id, date, date)
      )
    );
    
    // Return map of studentId to existing record
    return students.reduce((acc, student, index) => {
      if (existingRecords[index]?.length > 0) {
        acc[student.id] = existingRecords[index][0];
      }
      return acc;
    }, {} as Record<string, any>);
  };

  const updateStudentAttendance = (
    studentId: string,
    field: keyof StudentAttendance,
    value: any
  ) => {
    setStudentAttendance(prev => ({
      ...prev,
      [studentId]: {
        ...prev[studentId],
        [field]: value
      }
    }));
  };

  const toggleLessonForStudent = (studentId: string, lessonId: string) => {
    setStudentAttendance(prev => {
      const student = prev[studentId];
      const newMissedLessons = new Set(student.missedLessons);
      
      if (newMissedLessons.has(lessonId)) {
        newMissedLessons.delete(lessonId);
      } else {
        newMissedLessons.add(lessonId);
      }

      return {
        ...prev,
        [studentId]: {
          ...student,
          missedLessons: newMissedLessons
        }
      };
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-xl font-semibold text-gray-900">Mark Attendance</h2>
              <div className="mt-2">
                <input
                  type="date"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  className="rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        <form onSubmit={handleSubmit} className="p-6 overflow-y-auto max-h-[calc(90vh-129px)]">
          {error && (
            <div className="mb-6 p-4 bg-red-50 rounded-lg flex items-center gap-2">
              <AlertCircle className="h-5 w-5 text-red-400" />
              <p className="text-sm text-red-600">{error}</p>
            </div>
          )}

          <div className="space-y-8">
            {students.map(student => (
              <div key={student.id} className="border-b border-gray-200 last:border-0 pb-6 last:pb-0">
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                  {student.firstName} {student.lastName}
                </h3>

                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Attendance Status
                    </label>
                    <div className="flex gap-4">
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          value="present"
                          checked={studentAttendance[student.id].status === 'present'}
                          onChange={(e) => updateStudentAttendance(
                            student.id,
                            'status',
                            e.target.value as AttendanceStatus
                          )}
                          className="text-indigo-600 focus:ring-indigo-500"
                        />
                        <span className="text-sm text-gray-900">Present</span>
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          value="absent"
                          checked={studentAttendance[student.id].status === 'absent'}
                          onChange={(e) => updateStudentAttendance(
                            student.id,
                            'status',
                            e.target.value as AttendanceStatus
                          )}
                          className="text-indigo-600 focus:ring-indigo-500"
                        />
                        <span className="text-sm text-gray-900">Full Day Absence</span>
                      </label>
                      <label className="flex items-center gap-2">
                        <input
                          type="radio"
                          value="partial"
                          checked={studentAttendance[student.id].status === 'partial'}
                          onChange={(e) => updateStudentAttendance(
                            student.id,
                            'status',
                            e.target.value as AttendanceStatus
                          )}
                          className="text-indigo-600 focus:ring-indigo-500"
                        />
                        <span className="text-sm text-gray-900">Partial Day</span>
                      </label>
                    </div>
                  </div>

                  {studentAttendance[student.id].status === 'partial' && lessons.length > 0 && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Select Missed Lessons
                      </label>
                      <div className="border rounded-lg p-4 space-y-2">
                        {lessons.map((lesson, index) => (
                          <label key={lesson.id} className="flex items-center gap-2">
                            <input
                              type="checkbox"
                              checked={studentAttendance[student.id].missedLessons.has(lesson.id)}
                              onChange={() => toggleLessonForStudent(student.id, lesson.id)}
                              className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                            />
                            <div>
                              <span className="text-sm text-gray-900">{lesson.title}</span>
                              <span className="ml-2 text-xs text-gray-500">
                                <Clock className="inline-block h-3 w-3 mr-1" />
                                {index < lessons.length / 2 ? 'Morning' : 'Afternoon'}
                              </span>
                            </div>
                          </label>
                        ))}
                      </div>
                    </div>
                  )}

                  {studentAttendance[student.id].status !== 'present' && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Notes (Optional)
                      </label>
                      <textarea
                        value={studentAttendance[student.id].notes}
                        onChange={(e) => updateStudentAttendance(student.id, 'notes', e.target.value)}
                        rows={2}
                        className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        placeholder="Add any additional notes..."
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className="flex justify-end gap-3 mt-6">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
            >
              {isSubmitting ? 'Saving...' : 'Mark Attendance'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
