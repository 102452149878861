import React, { useState, useEffect } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft, Plus, BookOpen } from 'lucide-react';
import { useLessons } from '../../../contexts/LessonContext';
import { useSavedUnits } from '../../../contexts/SavedUnitsContext';
import { useCalendar } from '../../../contexts/CalendarContext';
import type { SavedUnit } from '../../../types';
import LessonList from '../../../components/Planning/Lessons/LessonList';
import LessonForm from '../../../components/Planning/Lessons/LessonForm';
import NinjaLessonsModal from '../../../components/Planning/NinjaLessonsModal';
import { eachDayOfInterval, parseISO, isWeekend, isSameDay, addDays, startOfDay } from 'date-fns';

export default function LessonPlanner() {
  const { unitId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { state: unitsState } = useSavedUnits();
  const { state: lessonState, loadLessons } = useLessons();
  const { state: calendarState } = useCalendar();
  const [unit, setUnit] = useState<SavedUnit | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [showNinjaModal, setShowNinjaModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const loadData = async () => {
      if (unitId) {
        const foundUnit = unitsState.units.find(u => u.id === unitId);
        if (foundUnit) {
          setUnit(foundUnit);
          await loadLessons(unitId);
        }
        setIsLoading(false);
      }
    };
    loadData();
  }, [unitId, unitsState.units]);

  const handleViewUnitDetails = () => {
    navigate('/planning/saved-units', { 
      state: { 
        expandUnitId: unit?.id,
        scrollToUnit: true 
      }
    });
  };

  if (isLoading) {
    return (
      <div className="max-w-4xl mx-auto">
        <div className="mb-8">
          <h1 className="text-2xl font-bold text-gray-900">Lesson Planning</h1>
          <p className="text-gray-500">Loading unit details...</p>
        </div>
        <div className="flex items-center justify-center h-64">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
        </div>
      </div>
    );
  }

  if (!unit) {
    return (
      <div className="max-w-4xl mx-auto">
        <button
          onClick={() => navigate('/planning/lessons')}
          className="flex items-center gap-2 text-gray-600 hover:text-gray-900 mb-6"
        >
          <ArrowLeft className="h-4 w-4" />
          Back to Lesson Planning
        </button>
        
        <div className="bg-white rounded-xl shadow-sm p-8 text-center">
          <h1 className="text-xl font-semibold text-gray-900 mb-2">Unit Not Found</h1>
          <p className="text-gray-500 mb-6">The unit you're looking for doesn't exist.</p>
          <button
            onClick={() => navigate('/planning/lessons')}
            className="px-4 py-2 text-sm font-medium text-indigo-600 hover:text-indigo-700"
          >
            Return to Lesson Planning
          </button>
        </div>
      </div>
    );
  }

  // Get all weekdays for the unit's duration
  const durationDays = parseInt(unit.duration);
  // Convert UTC date to local time at start of day
  const startDate = unit.scheduledStart 
    ? startOfDay(new Date(unit.scheduledStart)) 
    : startOfDay(new Date());
  const schoolDays = Array.from({ length: durationDays }, (_, i) => addDays(startDate, i));

  const lessons = lessonState.lessons[unitId] || [];

  return (
    <div className="max-w-4xl mx-auto">
      <div className="flex items-center justify-between mb-6">
        <button
          onClick={() => navigate('/planning/lessons')}
          className="flex items-center gap-2 text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="h-4 w-4" />
          Back to Lesson Planning
        </button>
        <button
          onClick={handleViewUnitDetails}
          className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-indigo-600 hover:text-indigo-700 border border-indigo-600 rounded-lg"
        >
          <BookOpen className="h-4 w-4" />
          View Unit Details
        </button>
      </div>

      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">
          Lesson Planning: {unit.unitName}
        </h1>
        <p className="text-gray-500">
          Plan daily lessons for Unit {unit.unitNumber}
        </p>
      </div>

      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex items-center justify-between mb-6">
          <div>
            <h2 className="text-lg font-semibold text-gray-900">Lessons</h2>
            <p className="text-sm text-gray-500">
              {lessons.length} of {durationDays} lessons planned
            </p>
          </div>
          <div className="flex gap-3">
            <button
              onClick={() => setShowNinjaModal(true)}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
            >
              <span className="text-lg">⚔️</span>
              Ninja It!
            </button>
            <button
              onClick={() => setShowForm(true)}
              className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-indigo-600 border border-indigo-600 rounded-lg hover:bg-indigo-50"
            >
              <Plus className="h-4 w-4" />
              Add Lesson
            </button>
          </div>
        </div>

        <LessonList
          lessons={lessons}
          unit={unit}
          schoolDays={schoolDays}
          event={location.state?.event}
        />

        {showForm && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-hidden">
              <div className="p-6 border-b border-gray-200">
                <h2 className="text-xl font-semibold text-gray-900">Add Lesson</h2>
              </div>
              <div className="p-6 overflow-y-auto max-h-[calc(90vh-129px)]">
                <LessonForm
                  unit={unit}
                  onClose={() => setShowForm(false)}
                  schoolDays={schoolDays}
                  existingLessons={lessons}
                />
              </div>
            </div>
          </div>
        )}

        {showNinjaModal && (
          <NinjaLessonsModal
            unit={unit}
            onClose={() => setShowNinjaModal(false)}
          />
        )}
      </div>
    </div>
  );
}