import { loadStripe } from '@stripe/stripe-js';
import type { StripeCheckoutSession, UserSubscription } from '../types/subscription';

const STRIPE_PUBLIC_KEY = import.meta.env.VITE_STRIPE_PUBLIC_KEY;
const API_URL = import.meta.env.VITE_API_URL;

if (!STRIPE_PUBLIC_KEY) {
  console.error('Stripe configuration error: Missing public key');
  throw new Error('Missing Stripe public key');
}

if (!API_URL) {
  console.error('API configuration error: Missing API URL');
  throw new Error('Missing API URL');
}

export const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export class StripeError extends Error {
  constructor(message: string, public statusCode?: number, public details?: any) {
    super(message);
    this.name = 'StripeError';
  }
}

export async function createCheckoutSession(priceId: string): Promise<StripeCheckoutSession> {
  const token = localStorage.getItem('token');
  const userId = localStorage.getItem('userId');

  if (!token) {
    console.error('Authentication error: No token found');
    throw new StripeError('Authentication required');
  }

  if (!userId) {
    console.error('Authentication error: No user ID found');
    throw new StripeError('User ID not found');
  }

  try {
    console.log('Initiating checkout session:', { priceId, userId });

    const response = await fetch(`${API_URL}/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ 
        priceId,
        userId 
      }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error occurred' }));
      console.error('Checkout session creation failed:', {
        status: response.status,
        error: errorData
      });
      throw new StripeError(
        errorData.message || 'Failed to create checkout session',
        response.status,
        errorData
      );
    }

    const data = await response.json();
    
    if (!data.url) {
      console.error('Invalid checkout session response:', data);
      throw new StripeError('Invalid checkout session response');
    }

    console.log('Checkout session created successfully');
    return data;
  } catch (error) {
    if (error instanceof StripeError) {
      throw error;
    }
    console.error('Stripe API error:', error);
    throw new StripeError(
      error instanceof Error ? error.message : 'Failed to create checkout session'
    );
  }
}

export async function createPortalSession(): Promise<{ url: string }> {
  const token = localStorage.getItem('token');

  if (!token) {
    console.error('Authentication error: No token found');
    throw new StripeError('Authentication required');
  }

  try {
    const response = await fetch(`${API_URL}/create-portal-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error occurred' }));
      console.error('Portal session creation failed:', {
        status: response.status,
        error: errorData
      });
      throw new StripeError(
        errorData.message || 'Failed to create portal session',
        response.status,
        errorData
      );
    }

    const data = await response.json();
    
    if (!data.url) {
      console.error('Invalid portal session response:', data);
      throw new StripeError('Invalid portal session response');
    }

    return data;
  } catch (error) {
    if (error instanceof StripeError) {
      throw error;
    }
    console.error('Stripe API error:', error);
    throw new StripeError(
      error instanceof Error ? error.message : 'Failed to create portal session'
    );
  }
}

export async function getSubscription(): Promise<UserSubscription | null> {
  const token = localStorage.getItem('token');

  if (!token) {
    console.error('Authentication error: No token found');
    throw new StripeError('Authentication required');
  }

  try {
    const response = await fetch(`${API_URL}/subscription`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (response.status === 404) {
      return null;
    }

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error occurred' }));
      console.error('Subscription fetch failed:', {
        status: response.status,
        error: errorData
      });
      throw new StripeError(
        errorData.message || 'Failed to fetch subscription',
        response.status,
        errorData
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    if (error instanceof StripeError) {
      throw error;
    }
    console.error('Stripe API error:', error);
    throw new StripeError(
      error instanceof Error ? error.message : 'Failed to fetch subscription'
    );
  }
}
