import { SavedUnit } from '../types';

const API_ENDPOINT = import.meta.env.VITE_API_DATATABLEDYNAMO_ENDPOINT;

export class UnitsError extends Error {
  constructor(message: string, public statusCode?: number) {
    super(message);
    this.name = 'UnitsError';
  }
}

export async function updateUnitSchedule(
  userId: string, 
  unitId: string, 
  startDate: string | null, 
  endDate: string | null,
  skipDays?: string[],
  pattern?: any
): Promise<SavedUnit> {
  try {
    console.log('Updating unit schedule:', {
      userId,
      unitId,
      startDate,
      endDate,
      skipDays,
      pattern
    });

    const token = localStorage.getItem('token');
    if (!token) {
      throw new UnitsError('Authentication token not found');
    }

    // Create update data with only the fields we want to update
    const updateData = {
      userId,
      id: unitId,
      scheduledStart: startDate,
      scheduledEnd: endDate,
      skipDays: skipDays || [],
      pattern: pattern || { type: 'everyday' },
      lastModified: new Date().toISOString(),
    };

    const response = await fetch(`${API_ENDPOINT}/units`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(updateData),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error' }));
      console.error('Failed to update unit schedule:', {
        status: response.status,
        error: errorData
      });
      throw new UnitsError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    if (!data?.Item) {
      throw new UnitsError('Invalid response format from server');
    }

    return data.Item;
  } catch (error) {
    console.error('Error updating unit schedule:', error);
    if (error instanceof UnitsError) throw error;
    throw new UnitsError('Failed to update unit schedule');
  }
}

export async function getUnits(userId: string): Promise<SavedUnit[]> {
  try {
    console.log('Starting getUnits request for userId:', userId);
    console.log('Using API endpoint:', API_ENDPOINT);
    
    const token = localStorage.getItem('token');
    if (!token) {
      console.error('No token found in localStorage');
      throw new UnitsError('Authentication token not found');
    }
    console.log('Token found:', token.substring(0, 20) + '...');

    const url = `${API_ENDPOINT}/units?userId=${userId}`;
    console.log('Making request to:', url);

    const response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    
    console.log('Response status:', response.status);
    console.log('Response headers:', Object.fromEntries(response.headers.entries()));

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error' }));
      console.error('Failed to fetch units:', {
        status: response.status,
        error: errorData
      });
      throw new UnitsError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
    }
    
    const data = await response.json();
    console.log('Raw response data:', data);

    if (!data?.Items) {
      console.warn('No Items array in response:', data);
      return [];
    }

    // Transform the data to ensure consistent field names
    const transformedUnits = data.Items.map((unit: any) => ({
      ...unit,
      unitName: unit.unitName || unit.unit_name, // Handle both field names
      scheduledStart: unit.scheduledStart || null,
      scheduledEnd: unit.scheduledEnd || null,
      skipDays: Array.isArray(unit.skipDays) ? unit.skipDays : [], // Ensure skipDays is always an array
    }));

    console.log('Transformed units:', transformedUnits);
    return transformedUnits;
  } catch (error) {
    console.error('Error in getUnits:', error);
    if (error instanceof UnitsError) throw error;
    throw new UnitsError(error instanceof Error ? error.message : 'Failed to fetch units');
  }
}

export async function createUnit(userId: string, unit: SavedUnit): Promise<SavedUnit> {
  try {
    console.log('Creating unit:', { userId, unit });
    const token = localStorage.getItem('token');
    if (!token) {
      throw new UnitsError('Authentication token not found');
    }

    // Ensure consistent field naming
    const unitData = {
      ...unit,
      unitName: unit.unitName || unit.unit_name,
      scheduledStart: unit.scheduledStart || null,
      scheduledEnd: unit.scheduledEnd || null,
    };

    const response = await fetch(`${API_ENDPOINT}/units`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        ...unitData,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error' }));
      console.error('Failed to create unit:', {
        status: response.status,
        error: errorData
      });
      throw new UnitsError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    console.log('Unit created successfully:', data);
    
    if (!data?.Item) {
      throw new UnitsError('Invalid response format from server');
    }

    // Ensure consistent field naming in the response
    const transformedUnit = {
      ...data.Item,
      unitName: data.Item.unitName || data.Item.unit_name,
      scheduledStart: data.Item.scheduledStart || null,
      scheduledEnd: data.Item.scheduledEnd || null,
    };

    return transformedUnit;
  } catch (error) {
    console.error('Error creating unit:', error);
    if (error instanceof UnitsError) throw error;
    throw new UnitsError('Failed to create unit');
  }
}

export async function updateUnit(userId: string, unit: SavedUnit): Promise<SavedUnit> {
  try {
    console.log('Updating unit:', { userId, unit });
    const token = localStorage.getItem('token');
    if (!token) {
      throw new UnitsError('Authentication token not found');
    }

    // Ensure consistent field naming
    const unitData = {
      ...unit,
      unitName: unit.unitName || unit.unit_name,
      scheduledStart: unit.scheduledStart || null,
      scheduledEnd: unit.scheduledEnd || null,
    };

    const response = await fetch(`${API_ENDPOINT}/units`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        ...unitData,
      }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error' }));
      console.error('Failed to update unit:', {
        status: response.status,
        error: errorData
      });
      throw new UnitsError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
    }

    const data = await response.json();
    console.log('Unit updated successfully:', data);
    
    if (!data?.Item) {
      throw new UnitsError('Invalid response format from server');
    }

    // Ensure consistent field naming in the response
    const transformedUnit = {
      ...data.Item,
      unitName: data.Item.unitName || data.Item.unit_name,
      scheduledStart: data.Item.scheduledStart || null,
      scheduledEnd: data.Item.scheduledEnd || null,
    };

    return transformedUnit;
  } catch (error) {
    console.error('Error updating unit:', error);
    if (error instanceof UnitsError) throw error;
    throw new UnitsError('Failed to update unit');
  }
}


export async function deleteUnit(userId: string, unitId: string): Promise<void> {
  try {
    console.log('Deleting unit:', { userId, unitId });
    const token = localStorage.getItem('token');
    if (!token) {
      throw new UnitsError('Authentication token not found');
    }

    const response = await fetch(`${API_ENDPOINT}/units?userId=${userId}&unitId=${unitId}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error' }));
      console.error('Failed to delete unit:', {
        status: response.status,
        error: errorData
      });
      throw new UnitsError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
    }
    
    console.log('Unit deleted successfully');
  } catch (error) {
    console.error('Error deleting unit:', error);
    if (error instanceof UnitsError) throw error;
    throw new UnitsError('Failed to delete unit');
  }
}