import { startOfWeek, addWeeks, isWeekend, addDays, parseISO, isSameDay } from 'date-fns';

export function getNextBusinessWeek(): Date {
  const today = new Date();
  // Set weekStartsOn to 1 (Monday)
  const startOfCurrentWeek = startOfWeek(today, { weekStartsOn: 1 });

  // If today is a weekend, return the start of next week
  if (isWeekend(today)) {
    return addWeeks(startOfCurrentWeek, 1);
  }

  return startOfCurrentWeek;
}

export function calculateEndDate(
  startDate: Date,
  duration: number,
  nonSchoolDays: string[] = []
): Date {
  let currentDate = startDate;
  let schoolDaysCount = 0;

  while (schoolDaysCount < duration) {
    const isNonSchoolDay = nonSchoolDays.some(day => 
      isSameDay(parseISO(day), currentDate)
    );

    if (!isWeekend(currentDate) && !isNonSchoolDay) {
      schoolDaysCount++;
    }

    if (schoolDaysCount < duration) {
      currentDate = addDays(currentDate, 1);
    }
  }

  return currentDate;
}