import { getUrl } from 'aws-amplify/storage';

export const getProfilePictureUrl = async (pictureUrl: string) => {
  try {
    // Extract the path without query parameters
    const urlWithoutParams = pictureUrl.split('?')[0];
    
    // Get the key after 'amazonaws.com/' and remove 'public/' prefix since it's handled by accessLevel
    let key = urlWithoutParams.includes('amazonaws.com') 
      ? urlWithoutParams.split('amazonaws.com/')[1]
      : urlWithoutParams;

    // Remove 'public/' prefix if it exists since accessLevel: 'public' adds it automatically
    key = key.replace('public/', '');

    console.log('Final key for getUrl:', key); // For debugging

    const { url } = await getUrl({
      key,
      options: {
        accessLevel: 'public',
        validateObjectExistence: true,
        expires: 7200
      }
    });
    return url.toString();
  } catch (error) {
    console.error('Error getting profile picture URL:', error);
    return pictureUrl; // Fall back to original URL if refresh fails
  }
};