import { NonSchoolDay } from '../types';

const API_ENDPOINT = import.meta.env.VITE_API_DATATABLEDYNAMO_ENDPOINT;

export class CalendarError extends Error {
  constructor(message: string, public statusCode?: number) {
    super(message);
    this.name = 'CalendarError';
  }
}

export async function createNonSchoolDay(userId: string, day: Omit<NonSchoolDay, 'id'>): Promise<NonSchoolDay> {
  try {
    const response = await fetch(`${API_ENDPOINT}/calendar`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        userId,
        ...day
      }),
    });

    if (!response.ok) {
      throw new Error('Failed to create non-school day');
    }

    const data = await response.json();
    return data.Item;
  } catch (error) {
    throw new CalendarError('Failed to create non-school day');
  }
}

export async function getNonSchoolDays(userId: string): Promise<NonSchoolDay[]> {
  try {
    const response = await fetch(`${API_ENDPOINT}/calendar?userId=${userId}`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (!response.ok) {
      if (response.status === 404) {
        // Return empty array if no records found
        return [];
      }
      throw new Error('Failed to fetch non-school days');
    }

    const data = await response.json();
    return data.Items || [];
  } catch (error) {
    if (error instanceof CalendarError) throw error;
    // If there's a network error or other issue, return empty array
    console.error('Error fetching non-school days:', error);
    return [];
  }
}

export async function deleteNonSchoolDay(userId: string, dayId: string): Promise<void> {
  try {
    const response = await fetch(
      `${API_ENDPOINT}/calendar?userId=${userId}&dayId=${dayId}`,
      {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );

    if (!response.ok) {
      throw new Error('Failed to delete non-school day');
    }
  } catch (error) {
    throw new CalendarError('Failed to delete non-school day');
  }
}

export async function updateNonSchoolDay(userId: string, day: NonSchoolDay): Promise<NonSchoolDay> {
  try {
    console.log('Updating non-school day:', { userId, day });
    
    const response = await fetch(`${API_ENDPOINT}/calendar`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        userId,
        ...day
      }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => null);
      console.error('Update failed:', { status: response.status, error: errorData });
      throw new Error('Failed to update non-school day');
    }

    const data = await response.json();
    console.log('Update response:', data);
    
    if (!data?.Item) {
      throw new Error('Invalid response format');
    }

    return data.Item;
  } catch (error) {
    console.error('Error in updateNonSchoolDay:', error);
    throw new CalendarError('Failed to update non-school day');
  }
}
