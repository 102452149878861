import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Pencil } from 'lucide-react';
import type { Lesson } from '../../types';
import { subjectColors } from '../../utils/constants';
import { useSavedUnits } from '../../contexts/SavedUnitsContext';

interface LessonCardProps {
  lesson: Lesson;
  onClick: () => void;
  isDragging?: boolean;
}

export default function LessonCard({ lesson, onClick, isDragging }: LessonCardProps) {
  const { state: unitsState } = useSavedUnits();
  
  // Use sortable instead of draggable to support both dragging and sorting
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging: isDraggingCard
  } = useSortable({
    id: lesson.id,
  });

  const unit = unitsState.units.find(u => u.id === lesson.unitId);
  const isOtherActivity = lesson.unitId === 'other';
  const colors = unit ? subjectColors[unit.subject] || subjectColors.Other : subjectColors.Other;
  
  // For other activities, extract the type from the description
  const activityType = isOtherActivity ? lesson.description?.match(/^Type: (.*?)\n/)?.[1] || 'Other' : null;

  const style = transform ? {
    transform: CSS.Transform.toString(transform),
    transition,
    width: isDraggingCard ? '250px' : undefined,
    height: '80px',
    opacity: isDraggingCard ? 0 : 1,
  } : { 
    height: '80px',
    transition 
  };

  const handleEditClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <div className="relative group">
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className={`${colors.bg} p-3 rounded-lg select-none touch-none ${
          isDragging || isDraggingCard ? 'shadow-lg cursor-grabbing' : 'hover:shadow-md cursor-grab active:cursor-grabbing'
        }`}
      >
        <div className="flex flex-col gap-1">
          <div className="flex items-center justify-between">
            {isOtherActivity ? (
              <span className={`text-xs font-medium ${colors.text}`}>
                {activityType}
              </span>
            ) : (
              <>
                <span className={`text-xs font-medium ${colors.text}`}>
                  {unit?.subject || 'Other'}
                </span>
                <span className="text-xs text-gray-500">
                  Unit {unit?.unitNumber}
                </span>
              </>
            )}
          </div>
          <h4 className="text-sm font-medium text-gray-900 line-clamp-1">
            {lesson.title}
          </h4>
          {!isOtherActivity && unit && (
            <p className="text-xs text-gray-500 line-clamp-1">
              {unit.unit_name}
            </p>
          )}
        </div>
      </div>
      {/* Edit Button - Positioned outside the draggable area */}
      <div 
        className={`absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity
          ${isDraggingCard ? 'pointer-events-none' : ''}`}
        onClick={handleEditClick}
        onMouseDown={(e) => e.stopPropagation()}
        onTouchStart={(e) => e.stopPropagation()}
      >
        <button
          type="button"
          className="p-1 bg-white rounded-lg shadow-sm hover:bg-gray-50 transition-colors"
          aria-label="Edit lesson"
        >
          <Pencil className="h-4 w-4 text-gray-600" />
        </button>
      </div>
    </div>
  );
}