import React, { useMemo } from 'react';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { useNavigate } from 'react-router-dom';
import type { SavedUnit } from '../../../types';
import { useCalendar } from '../../../contexts/CalendarContext';
import { subjectColors } from '../../../utils/constants';

interface UnitSidebarProps {
  units: SavedUnit[];
}

function DraggableUnit({ unit }: { unit: SavedUnit }) {
  const navigate = useNavigate();
  const { state: { events } } = useCalendar();
  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: `unit-${unit.id}`,
    data: {
      type: 'unit',
      unit: unit
    }
  });
  const style = transform ? {
    transform: CSS.Transform.toString(transform),
  } : undefined;
  const isPlaced = useMemo(() => 
    events.some(event => event.unitId === unit.id),
    [events, unit.id]
  );
  const colors = subjectColors[unit.subject] || subjectColors.Other;
  const handleEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const node = setNodeRef(null);
    navigate('/planning/saved-units', { 
      state: { editUnitId: unit.id }
    });
    setNodeRef(node);
  };
  if (isPlaced) {
    return null;
  }
  return (
    <div className="relative w-full"> {/* Added w-full */}
      <div
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        className="group p-4 w-full bg-white rounded-lg shadow-sm border border-gray-200 cursor-move hover:shadow-md transition-shadow"
      >
        <div className="flex items-center gap-2 mb-2">
          <span className="text-sm font-medium text-gray-900 truncate">
            Unit {unit.unitNumber}
          </span>
          <span className={`px-2 py-0.5 text-xs rounded-full flex-shrink-0 ${colors.bg} ${colors.text}`}>
            {unit.subject}
          </span>
        </div>
        <h4 className="text-sm text-gray-700 line-clamp-2 mb-2 break-words">{unit.unitName}</h4>
        <div className="flex flex-col gap-1 text-xs text-gray-500">
          <div className="truncate">{unit.standardsCovered?.length || 0} standards • {unit.learningTargets?.length || 0} targets</div>
          <div className="truncate">{unit.duration} days</div>
        </div>
      </div>
      
      {!isDragging && (
        <button
          onClick={handleEdit}
          className="absolute top-2 right-2 w-6 h-6 flex items-center justify-center
                     bg-white rounded-full shadow-sm border border-gray-200 
                     text-gray-500 hover:text-gray-700 hover:bg-gray-50
                     transition-all flex-shrink-0"
          title="Edit unit"
        >
          ✎
        </button>
      )}
    </div>
  );
}

export default function UnitSidebar({ units }: UnitSidebarProps) {
  const sortedUnits = [...units].sort((a, b) => a.unitNumber - b.unitNumber);
  const availableUnits = sortedUnits.filter(unit => !unit.scheduledStart);
  
  return (
    <div className="w-80 flex-shrink-0 flex flex-col bg-white rounded-xl shadow-sm">
      {/* Fixed Header */}
      <div className="p-4 border-b border-gray-200">
        <h3 className="text-lg font-semibold text-gray-900 truncate">Available Units</h3>
        <p className="text-sm text-gray-500 mt-1 truncate">
          {availableUnits.length} units available for scheduling
        </p>
      </div>
      {/* Scrollable Content */}
      <div className="flex-1 overflow-y-auto overflow-x-hidden p-4 space-y-3 min-h-0">
        {availableUnits.length === 0 ? (
          <div className="text-center py-6 text-gray-500">
            <p className="truncate">No units available</p>
            <p className="text-sm mt-1 truncate">Create units in the Planning section</p>
          </div>
        ) : (
          availableUnits.map(unit => (
            <DraggableUnit key={unit.id} unit={unit} />
          ))
        )}
      </div>
    </div>
  );
}