import React, { forwardRef } from 'react';
import { Copy, Check } from 'lucide-react';

interface GeneratedEmail {
  to: string[];
  subject: string;
  body: string;
  copied: boolean;
  studentId: string;
  communicationId: string;
}

interface EmailGeneratorProps {
  emails: GeneratedEmail[];
  onCopy: (index: number, studentId: string, communicationId: string) => void;
}

const EmailGenerator = forwardRef<HTMLDivElement, EmailGeneratorProps>(
  ({ emails, onCopy }, ref) => {
    if (!emails.length) return null;

    return (
      <div ref={ref} className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Generated Emails</h2>
        <div className="space-y-6">
          {emails.map((email, index) => (
            <div key={index} className="border rounded-lg p-4">
              <div className="flex items-center justify-between mb-2">
                <div>
                  <p className="text-sm text-gray-600">To: {email.to.join(', ')}</p>
                  <p className="font-medium">{email.subject}</p>
                </div>
                <button
                  onClick={() => onCopy(index, email.studentId, email.communicationId)}
                  className={`flex items-center gap-2 px-3 py-1.5 text-sm font-medium rounded transition-colors ${
                    email.copied
                      ? 'bg-green-50 text-green-600 border border-green-600'
                      : 'text-indigo-600 hover:text-indigo-800 border border-indigo-600'
                  }`}
                >
                  {email.copied ? (
                    <>
                      <Check className="h-4 w-4" />
                      Saved & Copied
                    </>
                  ) : (
                    <>
                      <Copy className="h-4 w-4" />
                      Copy & Save Email as Sent
                    </>
                  )}
                </button>
              </div>
              <div className="mt-2 p-4 bg-gray-50 rounded text-sm whitespace-pre-wrap font-mono">
                {email.body}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
);

EmailGenerator.displayName = 'EmailGenerator';

export default EmailGenerator;