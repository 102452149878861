import { PlanRequest } from '../types';

const API_ENDPOINT = import.meta.env.VITE_API_DATATABLEDYNAMO_ENDPOINT;

export class PlansError extends Error {
  constructor(message: string, public statusCode?: number) {
    super(message);
    this.name = 'PlansError';
  }
}

interface DynamoDBResponse<T> {
  Items?: T[];
  Item?: T;
  Count?: number;
  ScannedCount?: number;
}

export async function getPlans(userId: string, hidden: boolean = false): Promise<DynamoDBResponse<PlanRequest>> {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new PlansError('Authentication token not found');
    }
    const url = `${API_ENDPOINT}/plans?userId=${userId}&hidden=${hidden}`;
    const response = await fetch(url, {
      headers: {
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error' }));
      throw new PlansError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    if (error instanceof PlansError) throw error;
    throw new PlansError('Failed to fetch plans');
  }
}

export async function createPlan(userId: string, plan: Omit<PlanRequest, 'id' | 'userId' | 'timestamp'>): Promise<DynamoDBResponse<PlanRequest>> {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new PlansError('Authentication token not found');
    }
    console.log('Creating plan:', { userId, plan });
    const response = await fetch(`${API_ENDPOINT}/plans`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify({
        userId,
        isHidden: false,
        ...plan,
      }),
    });
    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error' }));
      console.error('Failed to create plan:', {
        status: response.status,
        error: errorData,
        requestData: { userId, plan }
      });
      throw new PlansError(errorData.message || `HTTP error! status: ${response.status}`, response.status);
    }
    const data = await response.json();
    console.log('Plan created successfully:', data);
    
    if (!data?.Item) {
      console.error('Invalid response format:', data);
      throw new PlansError('Invalid response format from server');
    }
    return data;
  } catch (error) {
    console.error('Error creating plan:', error);
    if (error instanceof PlansError) throw error;
    throw new PlansError('Failed to create plan');
  }
}

export async function hidePlan(userId: string, planId: string): Promise<void> {
  try {
    const response = await fetch(`${API_ENDPOINT}/plans/${planId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        userId,
        isHidden: true
      }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error' }));
      throw new PlansError(errorData.message || `HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    if (error instanceof PlansError) throw error;
    throw new PlansError('Failed to hide plan');
  }
}

export async function unhidePlan(userId: string, planId: string): Promise<void> {
  try {
    const response = await fetch(`${API_ENDPOINT}/plans/${planId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
      body: JSON.stringify({
        userId,
        isHidden: false
      }),
    });

    if (!response.ok) {
      const errorData = await response.json().catch(() => ({ message: 'Unknown error' }));
      throw new PlansError(errorData.message || `HTTP error! status: ${response.status}`);
    }
  } catch (error) {
    if (error instanceof PlansError) throw error;
    throw new PlansError('Failed to unhide plan');
  }
}
