import React, { createContext, useContext, useReducer } from 'react';
import { useAuth } from './AuthContext';
import * as behaviorApi from '../services/behavior';
import type { BehaviorIncident } from '../types/behavior';

interface BehaviorState {
  incidents: BehaviorIncident[];
  isLoading: boolean;
  error: string | null;
}

type BehaviorAction =
  | { type: 'SET_LOADING' }
  | { type: 'SET_ERROR'; payload: string }
  | { type: 'CLEAR_ERROR' }
  | { type: 'SET_INCIDENTS'; payload: BehaviorIncident[] }
  | { type: 'ADD_INCIDENT'; payload: BehaviorIncident }
  | { type: 'UPDATE_INCIDENT'; payload: BehaviorIncident };

interface BehaviorContextType {
  state: BehaviorState;
  createIncident: (incident: Omit<BehaviorIncident, 'id' | 'userId' | 'createdAt' | 'lastModified'>) => Promise<void>;
  updateIncident: (incident: BehaviorIncident) => Promise<void>;
  loadIncidents: (studentId: string) => Promise<void>;
  clearError: () => void;
}

const BehaviorContext = createContext<BehaviorContextType | undefined>(undefined);

const initialState: BehaviorState = {
  incidents: [],
  isLoading: false,
  error: null,
};

function behaviorReducer(state: BehaviorState, action: BehaviorAction): BehaviorState {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, isLoading: true, error: null };
    case 'SET_ERROR':
      return { ...state, error: action.payload, isLoading: false };
    case 'CLEAR_ERROR':
      return { ...state, error: null };
    case 'SET_INCIDENTS':
      return { ...state, incidents: action.payload, isLoading: false, error: null };
    case 'ADD_INCIDENT':
      return {
        ...state,
        incidents: [action.payload, ...state.incidents],
        isLoading: false,
        error: null,
      };
    case 'UPDATE_INCIDENT':
      return {
        ...state,
        incidents: state.incidents.map(incident =>
          incident.id === action.payload.id ? action.payload : incident
        ),
        isLoading: false,
        error: null,
      };
    default:
      return state;
  }
}

export function BehaviorProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(behaviorReducer, initialState);
  const { state: authState } = useAuth();

  const createIncident = async (
    incident: Omit<BehaviorIncident, 'id' | 'userId' | 'createdAt' | 'lastModified'>
  ) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      const newIncident = await behaviorApi.createBehaviorIncident(
        authState.user.username,
        incident
      );
      dispatch({ type: 'ADD_INCIDENT', payload: newIncident });
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: error instanceof Error ? error.message : 'Failed to create incident',
      });
      throw error;
    }
  };

  const updateIncident = async (incident: BehaviorIncident) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      const updatedIncident = await behaviorApi.updateBehaviorIncident(
        authState.user.username,
        incident
      );
      dispatch({ type: 'UPDATE_INCIDENT', payload: updatedIncident });
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: error instanceof Error ? error.message : 'Failed to update incident',
      });
      throw error;
    }
  };

  const loadIncidents = async (studentId: string) => {
    if (!authState.user?.username) {
      dispatch({ type: 'SET_ERROR', payload: 'User not authenticated' });
      return;
    }

    dispatch({ type: 'SET_LOADING' });
    try {
      const incidents = await behaviorApi.getStudentBehaviorIncidents(
        authState.user.username,
        studentId
      );
      dispatch({ type: 'SET_INCIDENTS', payload: incidents });
    } catch (error) {
      dispatch({
        type: 'SET_ERROR',
        payload: error instanceof Error ? error.message : 'Failed to fetch incidents',
      });
    }
  };

  const clearError = () => {
    dispatch({ type: 'CLEAR_ERROR' });
  };

  return (
    <BehaviorContext.Provider
      value={{
        state,
        createIncident,
        updateIncident,
        loadIncidents,
        clearError,
      }}
    >
      {children}
    </BehaviorContext.Provider>
  );
}

export function useBehavior() {
  const context = useContext(BehaviorContext);
  if (context === undefined) {
    throw new Error('useBehavior must be used within a BehaviorProvider');
  }
  return context;
}