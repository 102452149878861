import React, { useEffect } from 'react';
import { DndContext, DragEndEvent, DragOverlay, closestCenter, DragStartEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { addDays, format, isSameDay, startOfWeek, parseISO, isToday } from 'date-fns';
import DayColumn from './DayColumn';
import LessonCard from './LessonCard';
import { useCalendar } from '../../contexts/CalendarContext';
import { useLessons } from '../../contexts/LessonContext';
import { useAuth } from '../../contexts/AuthContext';
import type { Lesson } from '../../types';

interface WeekViewProps {
  currentWeek: Date;
  selectedDate: Date | null;
  onDaySelect: (date: Date) => void;
}

export default function WeekView({ currentWeek, selectedDate, onDaySelect }: WeekViewProps) {
  const { state: calendarState } = useCalendar();
  const { 
    state: lessonState, 
    loadLessonsByDate, 
    updateLesson,
    reorderLessons,
    setOptimisticUpdate,
    setBatchOptimisticUpdates,
    clearOptimisticUpdates 
  } = useLessons();
  const { state: authState } = useAuth();
  const [activeLesson, setActiveLesson] = React.useState<Lesson | null>(null);

  // Get Monday of the week
  const monday = startOfWeek(currentWeek, { weekStartsOn: 1 });

  // Generate weekdays (Monday to Friday)
  const weekDays = Array.from({ length: 5 }, (_, i) => addDays(monday, i));

  // Get all lessons for the current week, including optimistic updates
// Get all lessons for the current week, including optimistic updates
const weekLessons = React.useMemo(() => {
  const seenLessonIds = new Set<string>();
  
  // First, get lessons from date-based queries
  const dateBasedLessons = weekDays
    .map(day => format(day, 'yyyy-MM-dd'))
    .flatMap(dateStr => lessonState.lessonsByDate[dateStr] || [])
    .filter(lesson => {
      if (!lesson.date || seenLessonIds.has(lesson.id)) return false;
      seenLessonIds.add(lesson.id);
      return true;
    });

  // Then, get any additional lessons from units that fall within the week
  const unitBasedLessons = Object.values(lessonState.lessonsByUnit)
    .flat()
    .filter(lesson => {
      if (!lesson.date || seenLessonIds.has(lesson.id)) return false;
      const lessonDate = parseISO(lesson.date);
      if (!weekDays.some(day => isSameDay(day, lessonDate))) return false;
      seenLessonIds.add(lesson.id);
      return true;
    });

  // Fallback to original lessons structure for backward compatibility
  const legacyLessons = Object.values(lessonState.lessons)
    .flat()
    .filter(lesson => {
      if (!lesson.date || seenLessonIds.has(lesson.id)) return false;
      const lessonDate = parseISO(lesson.date);
      if (!weekDays.some(day => isSameDay(day, lessonDate))) return false;
      seenLessonIds.add(lesson.id);
      return true;
    });

  // Combine all lessons and apply optimistic updates
  return [...dateBasedLessons, ...unitBasedLessons, ...legacyLessons]
    .map(lesson => lessonState.optimisticUpdates[lesson.id] || lesson)
    .sort((a, b) => a.order - b.order);
}, [
  lessonState.lessonsByDate,
  lessonState.lessonsByUnit,
  lessonState.lessons,
  lessonState.optimisticUpdates,
  weekDays
]);

const getLessonsByDay = (date: Date): Lesson[] => {
  return weekLessons
    .filter(lesson => lesson.date && isSameDay(parseISO(lesson.date), date))
    .sort((a, b) => a.order - b.order);
};

  // Load lessons for the current week
  useEffect(() => {
    async function loadWeekLessons() {
      if (!authState.user?.username) return;

      try {
        const loadPromises = weekDays.map(day => {
          const dateStr = format(day, 'yyyy-MM-dd');
          return loadLessonsByDate(dateStr);
        });
        
        await Promise.all(loadPromises);
      } catch (error) {
        console.error('Error loading lessons for week:', error);
      }
    }

    loadWeekLessons();
  }, [currentWeek, authState.user?.username]);

  // Set default selection to today if it's in the current week view
  useEffect(() => {
    const today = new Date();
    const todayInWeek = weekDays.find(day => isSameDay(day, today));
    if (!selectedDate && todayInWeek) {
      onDaySelect(todayInWeek);
    }
  }, [currentWeek]); // Only run when the week changes

  const handleDragStart = (event: DragStartEvent) => {
    const lesson = weekLessons.find(l => l.id === event.active.id);
    if (lesson) {
      setActiveLesson(lesson);
    }
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    setActiveLesson(null);
    
    if (!over) return;

    const lessonId = active.id as string;
    const targetId = over.id as string;
    const lesson = weekLessons.find(l => l.id === lessonId);

    if (!lesson) return;

    try {
      const targetLesson = weekLessons.find(l => l.id === targetId);
      
      if (targetLesson) {
        const targetDate = format(parseISO(targetLesson.date), 'yyyy-MM-dd');
        const sourceDate = format(parseISO(lesson.date), 'yyyy-MM-dd');
        
        // Get all lessons for the target day
        const lessonsOnTargetDay = weekLessons
          .filter(l => format(parseISO(l.date), 'yyyy-MM-dd') === targetDate)
          .sort((a, b) => a.order - b.order);

        if (sourceDate === targetDate) {
          // Same day reordering
          const oldIndex = lessonsOnTargetDay.findIndex(l => l.id === lesson.id);
          const newIndex = lessonsOnTargetDay.findIndex(l => l.id === targetLesson.id);
          
          if (oldIndex === newIndex) return;

          // Create new array with updated order
          const reorderedLessons = [...lessonsOnTargetDay];
          reorderedLessons.splice(oldIndex, 1);
          reorderedLessons.splice(newIndex, 0, lesson);

          // Create optimistic updates for all affected lessons
          const updates: Record<string, Lesson> = {};
          reorderedLessons.forEach((l, index) => {
            updates[l.id] = { ...l, order: index };
          });

          // Apply optimistic updates
          setBatchOptimisticUpdates(updates);

          // Update backend
          await reorderLessons(targetDate, reorderedLessons.map((l, index) => ({ ...l, order: index })));
        } else {
          // Moving to different day
          const newOrder = targetLesson.order;
          
          // Prepare the updated lesson
          const updatedLesson = {
            ...lesson,
            date: targetDate,
            order: newOrder
          };

          // Update orders for all lessons in the target day
          const updatedTargetLessons = lessonsOnTargetDay
            .filter(l => l.id !== lesson.id)
            .map(l => {
              if (l.order >= newOrder) {
                return { ...l, order: l.order + 1 };
              }
              return l;
            });

          // Create batch updates
          const updates = {
            [lesson.id]: updatedLesson,
            ...updatedTargetLessons.reduce((acc, l) => ({
              ...acc,
              [l.id]: l
            }), {})
          };

          // Apply optimistic updates
          setBatchOptimisticUpdates(updates);

          // Update backend
          await Promise.all([
            updateLesson(updatedLesson, false),
            ...updatedTargetLessons.map(l => updateLesson(l, false))
          ]);
        }
      } else {
        // Dropping on empty day column
        const targetDate = targetId;
        const lessonsOnTargetDay = weekLessons
          .filter(l => format(parseISO(l.date), 'yyyy-MM-dd') === targetDate);

        const updatedLesson = {
          ...lesson,
          date: targetDate,
          order: lessonsOnTargetDay.length // Place at the end
        };

        // Apply optimistic update
        setOptimisticUpdate(lesson.id, updatedLesson);

        // Update backend
        await updateLesson(updatedLesson, false);
      }
    } catch (error) {
      console.error('Error updating lesson position:', error);
      clearOptimisticUpdates();
      
      // Refresh affected dates
      const refreshDates = new Set([
        format(parseISO(lesson.date), 'yyyy-MM-dd'),
        typeof targetId === 'string' ? targetId : format(parseISO(targetId), 'yyyy-MM-dd')
      ]);
      
      await Promise.all(
        Array.from(refreshDates).map(date => loadLessonsByDate(date))
      );
    }
  };

  return (
    <DndContext 
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div className="grid grid-cols-5 gap-4 bg-white rounded-xl shadow-sm p-4">
        {weekDays.map((day) => {
          const isNonSchoolDay = calendarState.nonSchoolDays.some(
            nonSchoolDay => isSameDay(parseISO(nonSchoolDay.date), day)
          );
          const dayLessons = getLessonsByDay(day);
          const dateStr = format(day, 'yyyy-MM-dd');
          const isCurrentDay = isToday(day);

          return (
            <SortableContext 
              items={dayLessons.map(l => l.id)}
              strategy={verticalListSortingStrategy}
              key={dateStr}
            >
              <DayColumn
                date={day}
                lessons={dayLessons}
                isNonSchoolDay={isNonSchoolDay}
                onDaySelect={onDaySelect}
                isSelected={selectedDate ? isSameDay(day, selectedDate) : false}
                isCurrentDay={isCurrentDay}
              />
            </SortableContext>
          );
        })}
      </div>
      <DragOverlay>
        {activeLesson && (
          <div className="w-[250px]">
            <LessonCard 
              lesson={activeLesson} 
              onClick={() => {}} 
              isDragging 
            />
          </div>
        )}
      </DragOverlay>
    </DndContext>
  );
}