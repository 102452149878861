import { parseISO, format, isWeekend, addDays } from 'date-fns';
import type { CalendarEvent } from '../types/calendar';
import { calculateWorkingDays } from './scheduleUtils';

export function validateSkipDay(
  event: CalendarEvent,
  skipDate: string,
  nonSchoolDays: string[]
): boolean {
  try {
    // Ensure consistent date formatting
    const skipDateFormatted = format(parseISO(skipDate), 'yyyy-MM-dd');
    const startDate = parseISO(event.start);
    const endDate = parseISO(event.end);
    
    // Use formatted date for comparisons
    if (parseISO(skipDateFormatted) < startDate || parseISO(skipDateFormatted) > endDate) {
      console.log('Skip day outside unit date range');
      return false;
    }
    
    // Use formatted date for checks
    if (isWeekend(parseISO(skipDateFormatted))) {
      console.log('Skip day falls on weekend');
      return false;
    }
    
    if (nonSchoolDays.includes(skipDateFormatted)) {
      console.log('Skip day falls on non-school day');
      return false;
    }
    
    if (event.skipDays?.includes(skipDateFormatted)) {
      console.log('Day already marked as skip day');
      return false;
    }
    
    return true;
  } catch (err) {
    console.error('Error validating skip day:', err);
    return false;
  }
}

export function addSkipDay(
  event: CalendarEvent,
  skipDate: string,
  nonSchoolDays: string[]
): CalendarEvent {
  if (!event || !skipDate) {
    throw new Error('Event and skip date are required');
  }
  
  // Ensure consistent date formatting
  const formattedSkipDate = format(parseISO(skipDate), 'yyyy-MM-dd');
  const newSkipDays = [...(event.skipDays || []), formattedSkipDate].sort();
  
  const { endDate } = calculateWorkingDays(
    parseISO(event.start),
    event.duration,
    nonSchoolDays.map(date => ({ 
      id: '', 
      userId: '', 
      date, 
      type: 'other', 
      description: '', 
      createdAt: '' 
    })),
    newSkipDays,
    event.pattern
  );
  
  return {
    ...event,
    skipDays: newSkipDays,
    end: format(endDate, 'yyyy-MM-dd')
  };
}

export function removeSkipDay(
  event: CalendarEvent,
  skipDate: string,
  nonSchoolDays: string[]
): CalendarEvent {
  if (!event || !skipDate) {
    throw new Error('Event and skip date are required');
  }

  const formattedSkipDate = format(new Date(skipDate), 'yyyy-MM-dd');
  const newSkipDays = (event.skipDays || []).filter(date => date !== formattedSkipDate);

  const { endDate } = calculateWorkingDays(
    new Date(event.start),
    event.duration,
    nonSchoolDays.map(date => ({ 
      id: '', 
      userId: '', 
      date, 
      type: 'other', 
      description: '', 
      createdAt: '' 
    })),
    newSkipDays,
    event.pattern
  );

  return {
    ...event,
    skipDays: newSkipDays,
    end: endDate.toISOString()
  };
}

export function getSkipDayImpact(
  event: CalendarEvent,
  skipDate: string,
  nonSchoolDays: string[]
): { 
  newEndDate: string;
  daysExtended: number;
} {
  if (!event || !skipDate) {
    throw new Error('Event and skip date are required');
  }

  const currentEndDate = parseISO(event.end);
  const formattedSkipDate = format(parseISO(skipDate), 'yyyy-MM-dd');
  
  const { endDate } = calculateWorkingDays(
    parseISO(event.start),
    event.duration,
    nonSchoolDays.map(date => ({ 
      id: '', 
      userId: '', 
      date, 
      type: 'other', 
      description: '', 
      createdAt: '' 
    })),
    [...(event.skipDays || []), formattedSkipDate],
    event.pattern
  );
  
  // Add one day to match the display with the actual end date
  const displayEndDate = addDays(endDate, 1);

  const daysExtended = Math.round(
    (displayEndDate.getTime() - currentEndDate.getTime()) / (1000 * 60 * 60 * 24)
  );

  return {
    newEndDate: format(displayEndDate, 'yyyy-MM-dd'),
    daysExtended
  };
}