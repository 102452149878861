import React, { useState } from 'react';
import { X, AlertCircle } from 'lucide-react';
import type { CalendarEvent, SchedulePattern } from '../../../types/calendar';
import { useCalendar } from '../../../contexts/CalendarContext';
import { getPatternDescription } from '../../../utils/schedulePatterns';
import SchedulePatternSelector from './SchedulePatternSelector';

interface SchedulePatternModalProps {
  event: CalendarEvent;
  onClose: () => void;
}

export default function SchedulePatternModal({ event, onClose }: SchedulePatternModalProps) {
  const { updateEventPattern, isCalculating } = useCalendar();
  const [tempPattern, setTempPattern] = useState<SchedulePattern>(event.pattern || { type: 'everyday' });
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setIsSubmitting(true);

    try {
      await updateEventPattern(event.id, tempPattern);
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update schedule pattern');
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-md w-full p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold text-gray-900">Update Schedule Pattern</h2>
          <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
            <X className="h-5 w-5" />
          </button>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-50 rounded-lg flex items-start gap-2">
            <AlertCircle className="h-5 w-5 text-red-400 flex-shrink-0" />
            <p className="text-sm text-red-600">{error}</p>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <h3 className="text-sm font-medium text-gray-900 mb-2">Current Unit</h3>
            <p className="text-sm text-gray-500">{event.title}</p>
            <p className="text-sm text-gray-500 mt-1">
              Current pattern: {getPatternDescription(event.pattern || { type: 'everyday' })}
            </p>
          </div>

          <SchedulePatternSelector
            value={tempPattern}
            onChange={setTempPattern}
            disabled={isCalculating || isSubmitting}
            showImpact
          />

          <div className="pt-4 border-t flex justify-end gap-3">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={isCalculating || isSubmitting}
              className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700 disabled:opacity-50"
            >
              {isSubmitting ? 'Updating...' : 'Update Pattern'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}