import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Pencil, User, MessageCircle, Info, AlertTriangle, MessageSquare, CheckSquare } from 'lucide-react';
import { useStudents } from '../../contexts/StudentContext';
import { useCommunication } from '../../contexts/CommunicationContext';
import { useBehavior } from '../../contexts/BehaviorContext';
import { parseISO, format } from 'date-fns';
import EditStudentModal from './EditStudentModal';
import CommunicationHistory from '../../components/Students/CommunicationHistory';
import BehaviorIncidentList from '../../components/Students/BehaviorIncidentList';
import type { Communication } from '../../types/communication';
import StudentAttendanceList from '../../components/Students/StudentAttendanceList';

export default function StudentProfile() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useStudents();
  const { getStudentCommunications } = useCommunication();
  const { loadIncidents, state: behaviorState } = useBehavior();
  const [showEditModal, setShowEditModal] = useState(false);
  const [communications, setCommunications] = useState<Communication[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Refs for scroll targets
  const basicInfoRef = useRef<HTMLDivElement>(null);
  const attendanceRef = useRef<HTMLDivElement>(null);
  const behaviorRef = useRef<HTMLDivElement>(null);
  const communicationRef = useRef<HTMLDivElement>(null);

  const student = state.students.find(s => s.id === id);

  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    const loadData = async () => {
      if (student?.id) {
        setIsLoading(true);
        try {
          const [comms] = await Promise.all([
            getStudentCommunications(student.id),
            loadIncidents(student.id)
          ]);
          setCommunications(comms);
        } catch (err) {
          setError(err instanceof Error ? err.message : 'Failed to load student data');
        } finally {
          setIsLoading(false);
        }
      }
    };

    loadData();
  }, [student?.id]);

  if (!student) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-500">Student not found</p>
        <button
          onClick={() => navigate('/students')}
          className="mt-4 text-indigo-600 hover:text-indigo-800"
        >
          Back to Students
        </button>
      </div>
    );
  }

  // Find students that this student should not be seated with
  const doNotSeatWithStudents = student.doNotSeatWith?.map(id => 
    state.students.find(s => s.id === id)
  ).filter(Boolean) || [];

  const navigationLinks = [
    { label: 'Basic Info', icon: Info, ref: basicInfoRef },
    { label: 'Attendance', icon: CheckSquare, ref: attendanceRef },
    { label: 'Behavior', icon: AlertTriangle, ref: behaviorRef },
    { label: 'Communication', icon: MessageSquare, ref: communicationRef },
  ];

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="mb-8">
        <button
          onClick={() => navigate('/students')}
          className="flex items-center gap-2 text-gray-600 hover:text-gray-900 mb-4"
        >
          <ArrowLeft className="h-4 w-4" />
          Back to Students
        </button>

        <div className="flex flex-col gap-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-4">
              <div className="w-16 h-16 rounded-full bg-gray-100 flex items-center justify-center overflow-hidden">
                {student.profilePicture ? (
                  <img
                    src={student.profilePicture}
                    alt={`${student.firstName} ${student.lastName}`}
                    className="w-full h-full object-cover"
                  />
                ) : (
                  <User className="w-8 h-8 text-gray-400" />
                )}
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  {student.firstName} {student.lastName}
                </h1>
                <p className="text-gray-500">Grade {student.grade} • {student.class}</p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <button
                onClick={() => navigate('/reply', { state: { studentId: student.id } })}
                className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-indigo-600 border border-indigo-600 rounded-lg hover:bg-indigo-50"
              >
                <MessageCircle className="h-4 w-4" />
                Reply
              </button>
              <button
                onClick={() => setShowEditModal(true)}
                className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
              >
                <Pencil className="h-4 w-4" />
                Edit Student
              </button>
            </div>
          </div>

          {/* Quick Navigation Links */}
          <div className="flex gap-4 border-b border-gray-200 pb-4">
            {navigationLinks.map(({ label, icon: Icon, ref }) => (
              <button
                key={label}
                onClick={() => scrollToSection(ref)}
                className="flex items-center gap-2 px-4 py-2 text-sm font-medium text-gray-600 hover:text-indigo-600 hover:bg-indigo-50 rounded-lg transition-colors"
              >
                <Icon className="h-4 w-4" />
                {label}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="space-y-8">
        {/* Basic Information Section */}
        <div ref={basicInfoRef} className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Basic Information</h2>
            <dl className="space-y-4">
              <div>
                <dt className="text-sm font-medium text-gray-500">Date of Birth</dt>
                <dd className="text-sm text-gray-900">{format(parseISO(student.dateOfBirth), 'MMMM d, yyyy')}</dd>
              </div>
              <div>
                <dt className="text-sm font-medium text-gray-500">Gender</dt>
                <dd className="text-sm text-gray-900 capitalize">{student.gender}</dd>
              </div>
              <div>
                <dt className="text-sm font-medium text-gray-500">Language Preference</dt>
                <dd className="text-sm text-gray-900">{student.languagePreference}</dd>
              </div>
              <div>
                <dt className="text-sm font-medium text-gray-500">Interests</dt>
                <dd className="mt-1 flex flex-wrap gap-2">
                  {student.interests.map((interest, index) => (
                    <span
                      key={index}
                      className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                    >
                      {interest}
                    </span>
                  ))}
                </dd>
              </div>
              {doNotSeatWithStudents.length > 0 && (
                <div>
                  <dt className="text-sm font-medium text-gray-500">Do Not Seat With</dt>
                  <dd className="mt-2">
                    <ul className="space-y-2">
                      {doNotSeatWithStudents.map(otherStudent => otherStudent && (
                        <li 
                          key={otherStudent.id}
                          className="flex items-center gap-2 text-sm text-gray-900"
                        >
                          <div className="w-6 h-6 rounded-full bg-gray-100 flex items-center justify-center overflow-hidden flex-shrink-0">
                            {otherStudent.profilePicture ? (
                              <img
                                src={otherStudent.profilePicture}
                                alt={`${otherStudent.firstName} ${otherStudent.lastName}`}
                                className="w-full h-full object-cover"
                              />
                            ) : (
                              <User className="w-3 h-3 text-gray-400" />
                            )}
                          </div>
                          {otherStudent.firstName} {otherStudent.lastName}
                        </li>
                      ))}
                    </ul>
                  </dd>
                </div>
              )}
            </dl>
          </div>

          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Medical & Special Considerations</h2>
            <dl className="space-y-4">
              {student.medicalAlerts.length > 0 && (
                <div>
                  <dt className="text-sm font-medium text-gray-500">Medical Alerts</dt>
                  <dd className="mt-1">
                    <ul className="space-y-1">
                      {student.medicalAlerts.map((alert, index) => (
                        <li key={index} className="text-sm text-red-600">
                          • {alert}
                        </li>
                      ))}
                    </ul>
                  </dd>
                </div>
              )}
              {student.allergies.length > 0 && (
                <div>
                  <dt className="text-sm font-medium text-gray-500">Allergies</dt>
                  <dd className="mt-1">
                    <ul className="space-y-1">
                      {student.allergies.map((allergy, index) => (
                        <li key={index} className="text-sm text-orange-600">
                          • {allergy}
                        </li>
                      ))}
                    </ul>
                  </dd>
                </div>
              )}
              <div>
                <dt className="text-sm font-medium text-gray-500">Special Education</dt>
                <dd className="mt-1 flex gap-2">
                  {student.hasIEP && (
                    <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                      IEP
                    </span>
                  )}
                  {student.has504 && (
                    <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                      504 Plan
                    </span>
                  )}
                  {!student.hasIEP && !student.has504 && (
                    <span className="text-sm text-gray-500">None</span>
                  )}
                </dd>
              </div>
            </dl>
          </div>

          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Parents/Guardians</h2>
            <div className="space-y-6">
              {student.parents.map((parent) => (
                <div key={parent.id} className="border-b border-gray-200 last:border-0 pb-4 last:pb-0">
                  <div className="flex items-center justify-between mb-2">
                    <h3 className="text-sm font-medium text-gray-900">
                      {parent.firstName} {parent.lastName}
                    </h3>
                    {parent.isEmergencyContact && (
                      <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-red-100 text-red-800">
                        Emergency Contact
                      </span>
                    )}
                  </div>
                  <dl className="grid grid-cols-2 gap-4 text-sm">
                    <div>
                      <dt className="text-gray-500">Relationship</dt>
                      <dd className="text-gray-900">{parent.relationship}</dd>
                    </div>
                    <div>
                      <dt className="text-gray-500">Phone</dt>
                      <dd className="text-gray-900">{parent.phone}</dd>
                    </div>
                    <div>
                      <dt className="text-gray-500">Email</dt>
                      <dd className="text-gray-900">{parent.email}</dd>
                    </div>
                    <div>
                      <dt className="text-gray-500">Language</dt>
                      <dd className="text-gray-900">{parent.preferredLanguage}</dd>
                    </div>
                    {parent.address && (
                      <div className="col-span-2">
                        <dt className="text-gray-500">Address</dt>
                        <dd className="text-gray-900">{parent.address}</dd>
                      </div>
                    )}
                  </dl>
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Record Information</h2>
            <dl className="space-y-4">
              <div>
                <dt className="text-sm font-medium text-gray-500">Created</dt>
                <dd className="text-sm text-gray-900">{format(new Date(student.createdAt), 'MMMM d, yyyy')}</dd>
              </div>
              <div>
                <dt className="text-sm font-medium text-gray-500">Last Modified</dt>
                <dd className="text-sm text-gray-900">{format(new Date(student.lastModified), 'MMMM d, yyyy')}</dd>
              </div>
            </dl>
          </div>
        </div>
        
        <div ref={attendanceRef} className="md:col-span-2">
 			 <StudentAttendanceList student={student} />
		</div>

        {/* Behavior Incidents Section */}
        <div ref={behaviorRef} className="md:col-span-2">
          <BehaviorIncidentList incidents={behaviorState.incidents} />
        </div>

        {/* Communications History Section */}
        <div ref={communicationRef} className="md:col-span-2">
          {isLoading ? (
            <div className="bg-white rounded-lg shadow-sm p-6">
              <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-4 py-1">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="space-y-2">
                    <div className="h-4 bg-gray-200 rounded"></div>
                    <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                  </div>
                </div>
              </div>
            </div>
          ) : error ? (
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-lg font-semibold text-gray-900 mb-4">Communications</h2>
              <p className="text-red-600">{error}</p>
            </div>
          ) : (
            <CommunicationHistory communications={communications} />
          )}
        </div>
      </div>

      {showEditModal && (
        <EditStudentModal
          student={student}
          onClose={() => setShowEditModal(false)}
        />
      )}
    </div>
  );
}