import React, { useState } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { format } from 'date-fns';
import LessonCard from './LessonCard';
import type { Lesson } from '../../types';
import LessonDetailsModal from './LessonDetailsModal';

interface DayColumnProps {
  date: Date;
  lessons: Lesson[];
  isNonSchoolDay: boolean;
  onDaySelect: (date: Date) => void;
  isSelected: boolean;
  isCurrentDay: boolean;
}

export default function DayColumn({ 
  date, 
  lessons, 
  isNonSchoolDay, 
  onDaySelect, 
  isSelected,
  isCurrentDay
}: DayColumnProps) {
  const [selectedLesson, setSelectedLesson] = useState<Lesson | null>(null);
  const dateStr = format(date, 'yyyy-MM-dd');
  
  const { setNodeRef, isOver } = useDroppable({
    id: dateStr,
  });

  const handleDaySelect = () => {
    onDaySelect(date);
    // Find the day details section and scroll to it
    const dayDetails = document.getElementById('day-details');
    if (dayDetails) {
      dayDetails.scrollIntoView({ 
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  return (
    <>
      <div
        ref={setNodeRef}
        className={`min-h-[600px] p-4 rounded-lg transition-colors ${
          isNonSchoolDay ? 'bg-gray-50' : 'bg-gray-50/30'
        } ${isOver ? 'ring-2 ring-indigo-400 ring-inset bg-indigo-50/50' : ''} 
        ${isSelected ? 'ring-2 ring-indigo-500' : ''}`}
      >
        <button
          onClick={handleDaySelect}
          className={`w-full text-center pb-4 border-b border-gray-200 hover:bg-gray-50 rounded-t-lg transition-colors ${
            isSelected ? 'bg-indigo-50 hover:bg-indigo-100' : 
            isCurrentDay ? 'bg-indigo-50/50' : ''
          }`}
        >
          <p className={`font-medium ${
            isSelected ? 'text-indigo-900' : 
            isCurrentDay ? 'text-indigo-700 font-semibold' : 
            'text-gray-900'
          }`}>
            {format(date, 'EEEE')}
          </p>
          <p className={`text-sm ${
            isSelected 
              ? 'text-indigo-600' 
              : isCurrentDay 
                ? 'text-indigo-600 font-bold' 
                : 'text-gray-500'
          }`}>
            {format(date, 'MMM d')}
            {isCurrentDay && <span className="ml-1 text-xs font-medium bg-indigo-100 px-1.5 py-0.5 rounded">Today</span>}
          </p>
        </button>
        
        {isNonSchoolDay ? (
          <div className="flex items-center justify-center h-32">
            <p className="text-sm text-gray-500">No School</p>
          </div>
        ) : (
          <SortableContext 
            items={lessons.map(l => l.id)}
            strategy={verticalListSortingStrategy}
          >
            <div className="space-y-2 mt-4">
              {lessons.map((lesson) => (
                <LessonCard
                  key={lesson.id}
                  lesson={lesson}
                  onClick={() => setSelectedLesson(lesson)}
                />
              ))}
            </div>
          </SortableContext>
        )}
      </div>

      {selectedLesson && (
        <LessonDetailsModal
          lesson={selectedLesson}
          onClose={() => setSelectedLesson(null)}
        />
      )}
    </>
  );
}