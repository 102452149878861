import { addDays, isWeekend, isSameDay, parseISO, format } from 'date-fns';
import type { CalendarEvent, NonSchoolDay, SchedulePattern, ScheduleConflict } from '../types/calendar';

export function calculateWorkingDays(
  startDate: Date,
  duration: number,
  nonSchoolDays: NonSchoolDay[],
  skipDays: string[] = [],
  pattern: SchedulePattern = { type: 'everyday' }
): { endDate: Date; workingDays: Date[] } {
  let currentDate = startDate;
  let workingDaysCount = 0;
  const workingDays: Date[] = [];

  while (workingDaysCount < duration) {
    const dateStr = format(currentDate, 'yyyy-MM-dd');
    const isNonSchoolDay = nonSchoolDays.some(day => 
      isSameDay(parseISO(day.date), currentDate)
    );
    const isSkipDay = skipDays.includes(dateStr);
    const isPatternDay = isDateInPattern(currentDate, pattern);

    if (!isWeekend(currentDate) && !isNonSchoolDay && !isSkipDay && isPatternDay) {
      workingDaysCount++;
      workingDays.push(currentDate);
    }
    currentDate = addDays(currentDate, 1);
  }

  // Return the last working day as the end date, not the next day
  return {
    endDate: workingDays[workingDays.length - 1] || currentDate,
    workingDays
  };
}

export function isDateInPattern(date: Date, pattern: SchedulePattern): boolean {
  const dayOfWeek = date.getDay(); // 0 = Sunday, 1 = Monday, etc.

  switch (pattern.type) {
    case 'everyday':
      return true;
    case 'mwf':
      return [1, 3, 5].includes(dayOfWeek);
    case 'tth':
      return [2, 4].includes(dayOfWeek);
    case 'custom':
      return pattern.customDays?.includes(dayOfWeek) ?? false;
    default:
      return true;
  }
}

export function findSubjectConflicts(
  startDate: Date,
  endDate: Date,
  subject: string,
  existingEvents: CalendarEvent[],
  excludeEventId?: string
): ScheduleConflict[] {
  const conflicts: ScheduleConflict[] = [];
  let currentDate = startDate;

  while (currentDate <= endDate) {
    const conflictingEvent = existingEvents.find(event => {
      if (excludeEventId && event.id === excludeEventId) return false;
      if (event.subject !== subject) return false;

      const eventStart = parseISO(event.start);
      const eventEnd = parseISO(event.end);
      return currentDate >= eventStart && currentDate <= eventEnd;
    });

    if (conflictingEvent) {
      conflicts.push({
        date: currentDate,
        conflictingUnitId: conflictingEvent.id,
        type: 'subject'
      });
    }

    currentDate = addDays(currentDate, 1);
  }

  return conflicts;
}

export function findNextAvailableSlot(
  startDate: Date,
  duration: number,
  subject: string,
  existingEvents: CalendarEvent[],
  nonSchoolDays: NonSchoolDay[],
  pattern: SchedulePattern = { type: 'everyday' }
): Date {
  let testDate = startDate;
  let foundSlot = false;

  while (!foundSlot) {
    const { endDate } = calculateWorkingDays(testDate, duration, nonSchoolDays, [], pattern);
    const conflicts = findSubjectConflicts(testDate, endDate, subject, existingEvents);

    if (conflicts.length === 0) {
      foundSlot = true;
    } else {
      // Move to the day after the last conflict
      testDate = addDays(conflicts[conflicts.length - 1].date, 1);
    }
  }

  return testDate;
}

export function recalculateUnitDates(
  events: CalendarEvent[],
  nonSchoolDays: NonSchoolDay[]
): CalendarEvent[] {
  // Sort events by start date
  const sortedEvents = [...events].sort((a, b) => 
    parseISO(a.start).getTime() - parseISO(b.start).getTime()
  );

  const updatedEvents: CalendarEvent[] = [];
  const processedSubjects = new Map<string, Date>();

  for (const event of sortedEvents) {
    let startDate = parseISO(event.start);
    const lastEndDate = processedSubjects.get(event.subject);

    // If there's a previous unit of the same subject, ensure no overlap
    if (lastEndDate && startDate <= lastEndDate) {
      startDate = addDays(lastEndDate, 1);
    }

    // Calculate new end date based on duration and constraints
    const { endDate } = calculateWorkingDays(
      startDate,
      event.duration,
      nonSchoolDays,
      event.skipDays,
      event.pattern
    );

    updatedEvents.push({
      ...event,
      start: startDate.toISOString(),
      end: endDate.toISOString()
    });

    processedSubjects.set(event.subject, endDate);
  }

  return updatedEvents;
}

export function validateUnitPlacement(
  startDate: Date,
  duration: number,
  subject: string,
  existingEvents: CalendarEvent[],
  nonSchoolDays: NonSchoolDay[],
  pattern: SchedulePattern = { type: 'everyday' }
): { valid: boolean; conflicts: ScheduleConflict[]; suggestedStart?: Date } {
  const { endDate } = calculateWorkingDays(startDate, duration, nonSchoolDays, [], pattern);
  const conflicts = findSubjectConflicts(startDate, endDate, subject, existingEvents);

  if (conflicts.length === 0) {
    return { valid: true, conflicts: [] };
  }

  const suggestedStart = findNextAvailableSlot(
    startDate,
    duration,
    subject,
    existingEvents,
    nonSchoolDays,
    pattern
  );

  return {
    valid: false,
    conflicts,
    suggestedStart
  };
}