import React, { useState } from 'react';
import { format } from 'date-fns';
import { AlertTriangle, Pencil } from 'lucide-react';
import type { BehaviorIncident } from '../../types/behavior';
import { useBehavior } from '../../contexts/BehaviorContext';
import BehaviorIncidentModal from './BehaviorIncidentModal';

interface BehaviorIncidentListProps {
  incidents: BehaviorIncident[];
}

export default function BehaviorIncidentList({ incidents }: BehaviorIncidentListProps) {
  const [editingIncident, setEditingIncident] = useState<BehaviorIncident | null>(null);
  const { updateIncident } = useBehavior();

  const handleUpdateIncident = async (updatedData: Omit<BehaviorIncident, 'id' | 'userId' | 'createdAt' | 'lastModified'>) => {
    if (!editingIncident) return;
    
    try {
      await updateIncident({
        ...editingIncident,
        ...updatedData,
      });
      setEditingIncident(null);
    } catch (error) {
      console.error('Failed to update incident:', error);
    }
  };

  if (incidents.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Behavior Incidents</h2>
        <p className="text-center text-gray-500 py-4">No behavior incidents recorded</p>
      </div>
    );
  }

  const getSeverityColor = (severity: BehaviorIncident['severity']) => {
    switch (severity) {
      case 'minor':
        return 'bg-yellow-100 text-yellow-800';
      case 'moderate':
        return 'bg-orange-100 text-orange-800';
      case 'major':
        return 'bg-red-100 text-red-800';
    }
  };

  return (
    <>
      <div className="bg-white rounded-lg shadow-sm p-6">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Behavior Incidents</h2>
        <div className="space-y-6">
          {incidents.map((incident) => (
            <div
              key={incident.id}
              className="border-b border-gray-200 last:border-0 pb-6 last:pb-0"
            >
              <div className="flex items-start justify-between mb-4">
                <div>
                  <div className="flex items-center gap-3">
                    <AlertTriangle className={`h-5 w-5 ${
                      incident.severity === 'major' ? 'text-red-500' :
                      incident.severity === 'moderate' ? 'text-orange-500' :
                      'text-yellow-500'
                    }`} />
                    <h3 className="text-lg font-medium text-gray-900">
                      {format(new Date(`${incident.date}T${incident.time}`), 'MMMM d, yyyy h:mm a')}
                    </h3>
                  </div>
                  <div className="flex items-center gap-2 mt-2">
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${getSeverityColor(incident.severity)}`}>
                      {incident.severity.charAt(0).toUpperCase() + incident.severity.slice(1)}
                    </span>
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                      incident.status === 'open' ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'
                    }`}>
                      {incident.status.charAt(0).toUpperCase() + incident.status.slice(1)}
                    </span>
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <span className="text-sm text-gray-500">
                    {incident.location}
                  </span>
                  <button
                    onClick={() => setEditingIncident(incident)}
                    className="p-1 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100"
                  >
                    <Pencil className="h-4 w-4" />
                  </button>
                </div>
              </div>

              <div className="space-y-4">
                <div>
                  <h4 className="text-sm font-medium text-gray-700">Description</h4>
                  <p className="mt-1 text-sm text-gray-600">{incident.description}</p>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                  <div>
                    <h4 className="text-sm font-medium text-gray-700">Antecedent</h4>
                    <p className="mt-1 text-sm text-gray-600">{incident.antecedent}</p>
                  </div>
                  <div>
                    <h4 className="text-sm font-medium text-gray-700">Behavior</h4>
                    <p className="mt-1 text-sm text-gray-600">{incident.behavior}</p>
                  </div>
                  <div>
                    <h4 className="text-sm font-medium text-gray-700">Consequence</h4>
                    <p className="mt-1 text-sm text-gray-600">{incident.consequence}</p>
                  </div>
                </div>

                <div>
                  <h4 className="text-sm font-medium text-gray-700">Intervention(s) Tried</h4>
                  <p className="mt-1 text-sm text-gray-600">{incident.interventionTried}</p>
                </div>

                <div>
                  <h4 className="text-sm font-medium text-gray-700">Next Steps</h4>
                  <p className="mt-1 text-sm text-gray-600">{incident.nextSteps}</p>
                </div>

                {incident.witnesses && incident.witnesses.length > 0 && (
                  <div>
                    <h4 className="text-sm font-medium text-gray-700">Witnesses</h4>
                    <p className="mt-1 text-sm text-gray-600">{incident.witnesses.join(', ')}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {editingIncident && (
        <BehaviorIncidentModal
          students={[]} // Not needed for editing
          onClose={() => setEditingIncident(null)}
          onSubmit={handleUpdateIncident}
          editingIncident={editingIncident}
        />
      )}
    </>
  );
}