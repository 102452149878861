import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeft, AlertCircle, Plus, Pencil } from 'lucide-react';
import { useStudents } from '../../contexts/StudentContext';
import { useCommunication } from '../../contexts/CommunicationContext';
import { useAuth } from '../../contexts/AuthContext';
import { useTeacherInfo } from '../../utils/emailUtils';
import CreateTemplateModal from '../../components/Communication/CreateTemplateModal';
import RecipientList from '../../components/Communication/RecipientList';
import MessageForm from '../../components/Communication/MessageForm';
import EmailGenerator from '../../components/Communication/EmailGenerator';
import type { MessageTemplate } from '../../types/communication';
import { fetchAuthSession } from 'aws-amplify/auth';

interface GeneratedEmail {
  to: string[];
  subject: string;
  body: string;
  copied: boolean;
  studentId?: string;
  communicationId?: string;
}

interface CommunicationResponse {
  id: string;
  studentId: string;
  type: string;
  subject: string;
  content: string;
  recipients: string[];
  sentDate: string;
  status: string;
  templateId?: string;
}

interface EmailResponse {
  to?: string[];
  subject?: string;
  body?: string;
  message?: string;
}

export default function Communication() {
  const location = useLocation();
  const navigate = useNavigate();
  const { state: { students } } = useStudents();
  const { state: commState, createCommunication, updateCommunication } = useCommunication();
  const { state: authState } = useAuth();
  const selectedIds = location.state?.selectedStudents as string[] || [];
  const teacherInfo = useTeacherInfo();
  const emailContainerRef = useRef<HTMLDivElement>(null);
  
  // Original states
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [additionalInstructions, setAdditionalInstructions] = useState('');
  const [generatedEmails, setGeneratedEmails] = useState<GeneratedEmail[]>([]);
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [generationProgress, setGenerationProgress] = useState(0);
  const [editingTemplate, setEditingTemplate] = useState<MessageTemplate | undefined>();

  useEffect(() => {
    if (selectedIds.length === 0) {
      navigate('/students');
    }

    return () => {
      const timeouts = generatedEmails
        .filter(email => email.copied)
        .map((_, index) => setTimeout(() => {}, 2000));
      timeouts.forEach(clearTimeout);
    };
  }, [selectedIds, navigate, generatedEmails]);

  const selectedStudents = students.filter(student => 
    selectedIds.includes(student.id)
  );

  const studentsWithoutContacts = selectedStudents.filter(
    student => !student.parents?.length
  );

  const handleEditTemplate = (template: MessageTemplate) => {
    setEditingTemplate(template);
    setShowTemplateModal(true);
  };

  const handleTemplateChange = (templateId: string) => {
    setSelectedTemplate(templateId);
    if (templateId) {
      const template = commState.templates.find(t => t.id === templateId);
      if (template) {
        setSubject(template.name || '');
        setMessage(template.sample || '');
        setAdditionalInstructions(template.description || '');
      }
    } else {
      setSubject('');
      setMessage('');
      setAdditionalInstructions('');
    }
  };

  const TemplateSelector = ({ 
    selectedTemplate, 
    onTemplateChange,
    onEditTemplate,
    onCreateTemplate
  }: {
    selectedTemplate: string;
    onTemplateChange: (id: string) => void;
    onEditTemplate: (template: MessageTemplate) => void;
    onCreateTemplate: () => void;
  }) => {
    const { state: commState } = useCommunication();
    
    return (
      <div className="mb-6">
        <div className="flex items-center justify-between mb-2">
          <label className="block text-sm font-medium text-gray-700">
            Message Template
          </label>
          <button
            onClick={onCreateTemplate}
            className="flex items-center gap-2 text-sm text-indigo-600 hover:text-indigo-800"
          >
            <Plus className="h-4 w-4" />
            Create Template
          </button>
        </div>
        <select
          value={selectedTemplate}
          onChange={(e) => onTemplateChange(e.target.value)}
          className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        >
          <option value="">Write message directly</option>
          {commState.templates.map(template => (
            <option key={template.id} value={template.id}>
              {template.name}
            </option>
          ))}
        </select>
        {selectedTemplate && (
          <div className="mt-2 flex justify-end gap-2">
            <button
              onClick={() => {
                const template = commState.templates.find(t => t.id === selectedTemplate);
                if (template) onEditTemplate(template);
              }}
              className="flex items-center gap-1 text-sm text-gray-600 hover:text-gray-800"
            >
              <Pencil className="h-4 w-4" />
              Edit Template
            </button>
          </div>
        )}
      </div>
    );
  };

  const handleGenerateEmails = async () => {
  if (!authState.user?.username) {
    setError('User not authenticated');
    return;
  }

  setIsGenerating(true);
  setError(null);
  setGeneratedEmails([]);
  setGenerationProgress(0);

  try {
    // Get the auth token using Amplify
    const { tokens } = await fetchAuthSession();
    const idToken = tokens.idToken.toString();

    const template = selectedTemplate 
      ? commState.templates.find(t => t.id === selectedTemplate)
      : null;

    let allEmails: GeneratedEmail[] = [];
    let hasScrolled = false;

    for (let i = 0; i < selectedStudents.length; i++) {
      const student = selectedStudents[i];
      
      try {
        const response = await fetch('https://7rjk7ypanwvuibu6vfzbexbedy0fctol.lambda-url.us-east-2.on.aws/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${idToken}` // Using the Amplify token
          },
          body: JSON.stringify({
            students: [{
              name: `${student.firstName} ${student.lastName}`,
              parents: student.parents.map(p => ({
                name: `${p.firstName} ${p.lastName}`,
                email: p.email,
                preferredLanguage: p.preferredLanguage
              }))
            }],
            template: template?.sample || '',
            subject,
            message,
            additionalInstructions,
            teacher: teacherInfo
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || `Failed to generate email for ${student.firstName} ${student.lastName}`);
        }

        const data = await response.json();
        if (!data || (typeof data !== 'object')) {
          throw new Error('Invalid response format');
        }

        const emailData = Array.isArray(data.emails) 
          ? data.emails 
          : data.email 
            ? [data.email]
            : [data];

        const processedEmails = emailData.map((email: EmailResponse) => ({
          to: email.to || student.parents.map(p => p.email),
          subject: email.subject || subject,
          body: email.body || email.message || '',
          copied: false,
          studentId: student.id
        }));
        
        allEmails = [...allEmails, ...processedEmails];
        
        setGenerationProgress(Math.round(((i + 1) / selectedStudents.length) * 100));
        setGeneratedEmails(allEmails);

        if (!hasScrolled && allEmails.length > 0) {
          setTimeout(() => {
            emailContainerRef.current?.scrollIntoView({ 
              behavior: 'smooth',
              block: 'start'
            });
          }, 100);
          hasScrolled = true;
        }

        if (data.remainingTokens !== undefined) {
          console.log(`Remaining tokens: ${data.remainingTokens}`);
        }

      } catch (error) {
        console.error('Error generating email:', error);
        throw error;
      }
    }

    // Create communications one at a time
    for (const student of selectedStudents) {
      try {
        const studentEmails = allEmails.filter(email => 
          student.parents.some(p => email.to.includes(p.email))
        );
        
        const generatedEmail = studentEmails[0];
        
        const communication = await createCommunication({
          studentId: student.id,
          type: 'email',
          subject: generatedEmail.subject,
          content: generatedEmail.body,
          recipients: student.parents.map(p => p.email),
          sentDate: new Date().toISOString(),
          status: 'draft',
          templateId: selectedTemplate || undefined
        });
    
        studentEmails.forEach(email => {
          email.communicationId = communication?.id || '';
          email.studentId = student.id;
        });
    
      } catch (commError) {
        console.error(`Failed to create communication for student ${student.id}:`, commError);
        
        const studentEmails = allEmails.filter(email => 
          student.parents.some(p => email.to.includes(p.email))
        );
        
        studentEmails.forEach(email => {
          email.studentId = student.id;
          email.communicationId = '';
        });
      }
    }

    setGeneratedEmails([...allEmails]);

  } catch (error) {
    console.error('Error generating emails:', error);
    setError(
      error instanceof Error 
        ? error.message 
        : 'Failed to generate emails. Please try again.'
    );
  } finally {
    setIsGenerating(false);
    setGenerationProgress(0);
  }
};

  const handleCopyEmail = async (index: number, studentId: string, communicationId: string) => {
    if (!authState.user?.username) {
      setError('User not authenticated');
      return;
    }

    const email = generatedEmails[index];
    const text = `To: ${email.to.join(', ')}\nSubject: ${email.subject}\n\n${email.body}`;
    
    try {
      await navigator.clipboard.writeText(text);
      
      if (communicationId) {
        try {
          await updateCommunication({
            id: communicationId,
            studentId,
            status: 'sent',
            sentDate: new Date().toISOString()
          });
        } catch (updateError) {
          console.error('Failed to update communication status:', updateError);
        }
      }

      const newEmails = [...generatedEmails];
      newEmails[index] = { ...email, copied: true };
      setGeneratedEmails(newEmails);

      setTimeout(() => {
        const resetEmails = [...generatedEmails];
        resetEmails[index] = { ...email, copied: false };
        setGeneratedEmails(resetEmails);
      }, 2000);

    } catch (err) {
      console.error('Failed to copy to clipboard:', err);
      setError('Failed to copy email to clipboard. Please try selecting and copying manually.');
    }
  };
  
  return (
    <div className="max-w-4xl mx-auto px-4 pb-12">
      <button
        onClick={() => navigate('/students')}
        className="flex items-center gap-2 text-gray-600 hover:text-gray-900 mb-6"
      >
        <ArrowLeft className="h-4 w-4" />
        Back to Students
      </button>

      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Send Communication</h1>
        <p className="text-gray-500">
          Send a message to {selectedStudents.length} selected student{selectedStudents.length !== 1 ? 's' : ''}
        </p>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-50 rounded-lg flex items-start gap-3">
          <AlertCircle className="h-5 w-5 text-red-400 flex-shrink-0 mt-0.5" />
          <div className="flex-1">
            <p className="text-sm font-medium text-red-800">{error}</p>
            {studentsWithoutContacts.length > 0 && (
              <p className="text-sm text-red-600 mt-1">
                Please add parent contact information for these students before sending communications.
              </p>
            )}
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 gap-6">
        <div className="bg-white rounded-lg shadow-sm p-6">
          <RecipientList students={selectedStudents} />
          
          <TemplateSelector
            selectedTemplate={selectedTemplate}
            onTemplateChange={handleTemplateChange}
            onEditTemplate={handleEditTemplate}
            onCreateTemplate={() => {
              setEditingTemplate(undefined);
              setShowTemplateModal(true);
            }}
          />

          <MessageForm
            subject={subject}
            message={message}
            additionalInstructions={additionalInstructions}
            isGenerating={isGenerating}
            generationProgress={generationProgress}
            onSubjectChange={setSubject}
            onMessageChange={setMessage}
            onInstructionsChange={setAdditionalInstructions}
            onSubmit={handleGenerateEmails}
          />
        </div>

        <EmailGenerator 
          ref={emailContainerRef}
          emails={generatedEmails}
          onCopy={handleCopyEmail}
        />
      </div>

      {showTemplateModal && (
        <CreateTemplateModal 
          onClose={() => {
            setShowTemplateModal(false);
            setEditingTemplate(undefined);
          }}
          editingTemplate={editingTemplate}
        />
      )}
    </div>
  );
}