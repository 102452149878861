import React from 'react';
import { X, Pencil } from 'lucide-react';
import type { Lesson } from '../../types';
import { useSavedUnits } from '../../contexts/SavedUnitsContext';
import LessonForm from '../../components/Planning/Lessons/LessonForm';
import OtherScheduleForm from './OtherScheduleForm';
import { subjectColors } from '../../utils/constants';

interface LessonDetailsModalProps {
  lesson: Lesson;
  onClose: () => void;
}

export default function LessonDetailsModal({ lesson, onClose }: LessonDetailsModalProps) {
  const { state: unitsState } = useSavedUnits();
  const [isEditing, setIsEditing] = React.useState(false);
  const unit = unitsState.units.find(u => u.id === lesson.unitId);
  const isOtherActivity = lesson.unitId === 'other';

  const colors = unit ? subjectColors[unit.subject] || subjectColors.Other : subjectColors.Other;
  const activityType = isOtherActivity ? lesson.description?.match(/^Type: (.*?)\n/)?.[1] || 'Other' : null;
  const activityTime = isOtherActivity ? lesson.description?.match(/Time: (.*?)\n/)?.[1] : null;
  const description = isOtherActivity 
    ? lesson.description?.replace(/^Type: .*\n/, '').replace(/^Time: .*\n/, '')
    : lesson.description;

  if (isEditing) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] flex flex-col">
          <div className="p-6 border-b border-gray-200">
            <div className="flex items-center justify-between">
              <h2 className="text-xl font-semibold text-gray-900">
                Edit {isOtherActivity ? 'Activity' : 'Lesson'}
              </h2>
              <button
                onClick={() => {
                  setIsEditing(false);
                  onClose();
                }}
                className="text-gray-400 hover:text-gray-600"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
          </div>

          <div className="flex-1 overflow-y-auto p-6">
            {isOtherActivity ? (
              <OtherScheduleForm
                weekDays={[new Date(lesson.date)]}
                onClose={() => {
                  setIsEditing(false);
                  onClose();
                }}
                editingLesson={lesson}
              />
            ) : unit && (
              <LessonForm
                unit={unit}
                onClose={() => {
                  setIsEditing(false);
                  onClose();
                }}
                schoolDays={[new Date(lesson.date)]}
                existingLessons={[]}
                editingLesson={lesson}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] flex flex-col">
        {/* Fixed Header */}
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <div className="flex-1">
              <div className="flex items-center gap-2 mb-2">
                <span className={`px-2 py-1 rounded-full text-xs font-medium ${colors.bg} ${colors.text}`}>
                  {isOtherActivity ? activityType : unit?.subject}
                </span>
                {!isOtherActivity && unit && (
                  <span className="text-sm text-gray-500">
                    Unit {unit.unitNumber}
                  </span>
                )}
              </div>
              <h2 className="text-xl font-semibold text-gray-900">{lesson.title}</h2>
              {!isOtherActivity && unit && (
                <p className="text-sm text-gray-500 mt-1">{unit.unitName}</p>
              )}
            </div>
            <div className="flex items-center gap-2">
              <button
                onClick={() => setIsEditing(true)}
                className="flex items-center gap-2 px-3 py-2 text-sm font-medium text-indigo-600 hover:text-indigo-700 rounded-lg hover:bg-indigo-50"
              >
                <Pencil className="h-4 w-4" />
                Edit {isOtherActivity ? 'Activity' : 'Lesson'}
              </button>
              <button
                onClick={onClose}
                className="p-2 text-gray-400 hover:text-gray-600 rounded-full hover:bg-gray-100"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>

        {/* Scrollable Content */}
        <div className="flex-1 overflow-y-auto p-6">
          <div className="space-y-6">
            {isOtherActivity ? (
              <>
                {activityTime && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-700 mb-2">Time</h3>
                    <p className="text-sm text-gray-600">{activityTime}</p>
                  </div>
                )}
                {description && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-700 mb-2">Description</h3>
                    <p className="text-sm text-gray-600">{description}</p>
                  </div>
                )}
              </>
            ) : unit && (
              <>
                {lesson.standards?.length > 0 && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-700 mb-2">Standards Covered</h3>
                    <ul className="space-y-2">
                      {lesson.standards.map((standardId) => {
                        const standard = unit.standardsCovered?.find(
                          (s) => s.standard === standardId
                        );
                        return standard ? (
                          <li key={standardId} className="text-sm text-gray-600">
                            <span className="font-medium">{standard.standard}:</span>{' '}
                            {standard.description}
                          </li>
                        ) : null;
                      })}
                    </ul>
                  </div>
                )}

                {lesson.learningTargets?.length > 0 && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-700 mb-2">Learning Targets</h3>
                    <ul className="space-y-2">
                      {lesson.learningTargets.map((targetId) => {
                        const target = unit.learningTargets?.[parseInt(targetId)];
                        return target ? (
                          <li key={targetId} className="text-sm text-gray-600">
                            {target}
                          </li>
                        ) : null;
                      })}
                    </ul>
                  </div>
                )}

                {description && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-700 mb-2">Description</h3>
                    <p className="text-sm text-gray-600">{description}</p>
                  </div>
                )}

                {lesson.resources && (
                  <div>
                    <h3 className="text-sm font-medium text-gray-700 mb-2">Resources</h3>
                    <p className="text-sm text-gray-600">{lesson.resources}</p>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}