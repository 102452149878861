import React, { useState } from 'react';
import { Plus, X } from 'lucide-react';
import { useSubjects } from '../../contexts/SubjectsContext';
import type { SubjectType } from '../../types';

interface SubjectSelectProps {
  value: SubjectType;
  onChange: (value: SubjectType) => void;
}

export default function SubjectSelect({ value, onChange }: SubjectSelectProps) {
  const { state, addSubject, deleteSubject } = useSubjects();
  const [showAddForm, setShowAddForm] = useState(false);
  const [newSubject, setNewSubject] = useState('');

  const handleAddSubject = () => {
    if (newSubject.trim()) {
      addSubject(newSubject.trim());
      setNewSubject('');
      setShowAddForm(false);
    }
  };

  const handleDeleteSubject = (id: string, subjectName: string) => {
    if (value === subjectName) {
      onChange(state.defaultSubjects[0]);
    }
    deleteSubject(id);
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2">
        <select
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="flex-1 rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
        >
          {state.defaultSubjects.map((subject) => (
            <option key={subject} value={subject}>
              {subject}
            </option>
          ))}
          {state.customSubjects.map((subject) => (
            <option key={subject.id} value={subject.name}>
              {subject.name}
            </option>
          ))}
        </select>
        <button
          type="button"
          onClick={() => setShowAddForm(true)}
          className="flex items-center gap-1 px-3 py-2 text-sm text-indigo-600 hover:text-indigo-800"
        >
          <Plus className="h-4 w-4" />
          Add Subject
        </button>
      </div>

      {showAddForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-xl p-6 max-w-md w-full">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-semibold text-gray-900">Add New Subject</h3>
              <button
                onClick={() => setShowAddForm(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="h-5 w-5" />
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label htmlFor="newSubject" className="block text-sm font-medium text-gray-700 mb-1">
                  Subject Name
                </label>
                <input
                  type="text"
                  id="newSubject"
                  value={newSubject}
                  onChange={(e) => setNewSubject(e.target.value)}
                  className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  placeholder="Enter subject name"
                />
              </div>

              <div className="flex justify-end gap-3">
                <button
                  type="button"
                  onClick={() => setShowAddForm(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleAddSubject}
                  className="px-4 py-2 text-sm font-medium text-white bg-indigo-600 rounded-lg hover:bg-indigo-700"
                >
                  Add Subject
                </button>
              </div>
            </div>

            {state.customSubjects.length > 0 && (
              <div className="mt-6">
                <h4 className="text-sm font-medium text-gray-700 mb-2">Custom Subjects</h4>
                <div className="space-y-2">
                  {state.customSubjects.map((subject) => (
                    <div
                      key={subject.id}
                      className="flex items-center justify-between py-2 px-3 bg-gray-50 rounded-lg"
                    >
                      <span className="text-sm text-gray-900">{subject.name}</span>
                      <button
                        onClick={() => handleDeleteSubject(subject.id, subject.name)}
                        className="text-red-400 hover:text-red-600"
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}