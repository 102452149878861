import { parseISO, addDays, isWeekend, format, startOfDay } from 'date-fns';
import type { Lesson } from '../types';

export async function updateLessonDates(
  unitId: string,
  startDate: string | null,
  endDate: string | null,
  updateLesson: (lesson: Lesson) => Promise<void>,
  nonSchoolDays: string[] = [],
  restDays: string[] = [],
  unitLessons: Lesson[] = []
) {
  if (!startDate || !endDate) {
    console.log('No dates provided, skipping lesson date updates');
    return;
  }

  try {
    const sortedLessons = [...unitLessons].sort((a, b) => a.order - b.order);
    
    // Convert UTC date to local timezone at start of day
    let currentDate = startOfDay(new Date(startDate));
    
    console.log('Starting update of lesson dates:', {
      startDate,
      localStartDate: format(currentDate, 'yyyy-MM-dd'),
      endDate,
      lessonCount: sortedLessons.length,
      nonSchoolDays,
      restDays
    });

    for (const lesson of sortedLessons) {
      while (true) {
        const dateStr = format(currentDate, 'yyyy-MM-dd');
        const shouldSkip = 
          isWeekend(currentDate) ||
          nonSchoolDays.includes(dateStr) ||
          restDays.includes(dateStr);

        console.log('Checking date:', {
          date: dateStr,
          isWeekend: isWeekend(currentDate),
          isNonSchool: nonSchoolDays.includes(dateStr),
          isRestDay: restDays.includes(dateStr),
          shouldSkip
        });

        if (!shouldSkip) break;
        currentDate = addDays(currentDate, 1);
      }

      const newDate = format(currentDate, 'yyyy-MM-dd');
      
      if (lesson.date !== newDate) {
        console.log(`Updating lesson "${lesson.title}" from ${lesson.date} to ${newDate}`);
        await updateLesson({
          ...lesson,
          date: newDate,
          isRestDay: false
        });
      }
      
      currentDate = addDays(currentDate, 1);
    }
  } catch (error) {
    console.error('Error updating lesson dates:', error);
    throw error;
  }
}