import React, { useState } from 'react';
import { X } from 'lucide-react';
import { useStudents } from '../../../contexts/StudentContext';
import BasicInfoStep from './BasicInfoStep';
import ParentInfoStep from './ParentInfoStep';
import MedicalInfoStep from './MedicalInfoStep';
import type { Student, ParentGuardian } from '../../../types/student';

interface AddStudentModalProps {
  onClose: () => void;
}

type Step = 'basic' | 'parent' | 'medical';

const initialStudentData: Omit<Student, 'id' | 'userId' | 'createdAt' | 'lastModified'> = {
  firstName: '',
  lastName: '',
  grade: 1,
  class: '',
  profilePicture: undefined,
  talkLevel: 5,
  interests: [],
  parents: [],
  dateOfBirth: '',
  gender: 'preferNotToSay',
  medicalAlerts: [],
  allergies: [],
  hasIEP: false,
  has504: false,
  languagePreference: 'English',
};

export default function AddStudentModal({ onClose }: AddStudentModalProps) {
  const [currentStep, setCurrentStep] = useState<Step>('basic');
  const [studentData, setStudentData] = useState(initialStudentData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { addStudent } = useStudents();

  const handleBasicInfoSubmit = (basicInfo: Partial<Student>) => {
    setStudentData(prev => ({ ...prev, ...basicInfo }));
    setCurrentStep('parent');
  };

  const handleParentInfoSubmit = (parents: ParentGuardian[]) => {
    setStudentData(prev => ({ ...prev, parents }));
    setCurrentStep('medical');
  };

  const handleMedicalInfoSubmit = async (medicalInfo: Partial<Student>) => {
    setIsSubmitting(true);
    setError(null);

    try {
      const finalStudentData = {
        ...studentData,
        ...medicalInfo,
      };

      await addStudent(finalStudentData);
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to add student');
    } finally {
      setIsSubmitting(false);
    }
  };

  const steps = {
    basic: {
      title: 'Basic Information',
      component: BasicInfoStep,
      onSubmit: handleBasicInfoSubmit,
      data: studentData,
    },
    parent: {
      title: 'Parent/Guardian Information',
      component: ParentInfoStep,
      onSubmit: handleParentInfoSubmit,
      data: studentData.parents,
    },
    medical: {
      title: 'Medical & Special Considerations',
      component: MedicalInfoStep,
      onSubmit: handleMedicalInfoSubmit,
      data: studentData,
    },
  };

  const CurrentStepComponent = steps[currentStep].component;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl max-w-2xl w-full max-h-[90vh] overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-xl font-semibold text-gray-900">Add New Student</h2>
              <p className="text-sm text-gray-500 mt-1">Step {Object.keys(steps).indexOf(currentStep) + 1} of {Object.keys(steps).length}: {steps[currentStep].title}</p>
            </div>
            <button onClick={onClose} className="text-gray-400 hover:text-gray-500">
              <X className="h-5 w-5" />
            </button>
          </div>
        </div>

        <div className="p-6 overflow-y-auto max-h-[calc(90vh-129px)]">
          {error && (
            <div className="mb-6 p-4 bg-red-50 rounded-lg">
              <p className="text-sm text-red-600">{error}</p>
            </div>
          )}

          <CurrentStepComponent
            onSubmit={steps[currentStep].onSubmit}
            initialData={steps[currentStep].data}
            isSubmitting={isSubmitting}
            onBack={currentStep !== 'basic' ? () => setCurrentStep(
              currentStep === 'medical' ? 'parent' : 'basic'
            ) : undefined}
          />
        </div>
      </div>
    </div>
  );
}