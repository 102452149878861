import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { fetchAuthSession } from 'aws-amplify/auth';

interface UserSettings {
  id: string;
  userId: string;
  theme: 'light' | 'dark';
  notifications: {
    email: boolean;
    browser: boolean;
  };
  sessionTimeout: number;
  schoolYear: string;
  lastModified: string;
}

interface SettingsState {
  settings: UserSettings | null;
  isLoading: boolean;
  error: string | null;
}

type SettingsAction =
  | { type: 'SET_SETTINGS'; payload: UserSettings }
  | { type: 'SET_LOADING'; payload: boolean }
  | { type: 'SET_ERROR'; payload: string | null };

interface SettingsContextType {
  state: SettingsState;
  updateSettings: (settings: Partial<UserSettings>) => Promise<void>;
}

const SettingsContext = createContext<SettingsContextType | undefined>(undefined);

const initialState: SettingsState = {
  settings: null,
  isLoading: true, // Start with loading true
  error: null,
};

function settingsReducer(state: SettingsState, action: SettingsAction): SettingsState {
  switch (action.type) {
    case 'SET_SETTINGS':
      return {
        ...state,
        settings: action.payload,
        isLoading: false,
        error: null,
      };
    case 'SET_LOADING':
      return {
        ...state,
        isLoading: action.payload,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
}

export function SettingsProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(settingsReducer, initialState);
  const { state: authState } = useAuth();

  const createDefaultSettings = async (userId: string): Promise<UserSettings> => {
    const defaultSettings: UserSettings = {
      id: Math.random().toString(36).substring(2, 9),
      userId,
      theme: 'light',
      notifications: {
        email: true,
        browser: true,
      },
      sessionTimeout: 60,
      schoolYear: '2023-2024',
      lastModified: new Date().toISOString(),
    };

    const { tokens } = await fetchAuthSession();
    const response = await fetch(
      `${import.meta.env.VITE_API_DATATABLEDYNAMO_ENDPOINT}/user-settings`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${tokens.idToken.toString()}`,
        },
        body: JSON.stringify(defaultSettings),
      }
    );

    if (!response.ok) {
      throw new Error('Failed to create default settings');
    }

    const data = await response.json();
    return data.Item;
  };

  const fetchSettings = async () => {
    if (!authState.user?.username) return;

    try {
      const { tokens } = await fetchAuthSession();
      const response = await fetch(
        `${import.meta.env.VITE_API_DATATABLEDYNAMO_ENDPOINT}/user-settings?userId=${authState.user.username}`,
        {
          headers: {
            'Authorization': `Bearer ${tokens.idToken.toString()}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error('Failed to fetch settings');
      }

      const data = await response.json();
      
      if (data.Item) {
        dispatch({ type: 'SET_SETTINGS', payload: data.Item });
      } else {
        // Create and save default settings if none exist
        const defaultSettings = await createDefaultSettings(authState.user.username);
        dispatch({ type: 'SET_SETTINGS', payload: defaultSettings });
      }
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error instanceof Error ? error.message : 'Failed to fetch settings' 
      });
    }
  };

  const updateSettings = async (newSettings: Partial<UserSettings>) => {
    if (!authState.user?.username) return;

    dispatch({ type: 'SET_LOADING', payload: true });
    try {
      const { tokens } = await fetchAuthSession();
      
      // If no settings exist yet, create default settings first
      const currentSettings = state.settings || await createDefaultSettings(authState.user.username);
      
      const updatedSettings = {
        ...currentSettings,
        ...newSettings,
        lastModified: new Date().toISOString(),
      };

      const response = await fetch(
        `${import.meta.env.VITE_API_DATATABLEDYNAMO_ENDPOINT}/user-settings`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokens.idToken.toString()}`,
          },
          body: JSON.stringify(updatedSettings),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update settings');
      }

      const data = await response.json();
      dispatch({ type: 'SET_SETTINGS', payload: data.Item });
    } catch (error) {
      dispatch({ 
        type: 'SET_ERROR', 
        payload: error instanceof Error ? error.message : 'Failed to update settings' 
      });
    }
  };

  useEffect(() => {
    if (authState.isAuthenticated && authState.user?.username) {
      fetchSettings();
    }
  }, [authState.isAuthenticated, authState.user?.username]);

  return (
    <SettingsContext.Provider value={{ state, updateSettings }}>
      {children}
    </SettingsContext.Provider>
  );
}

export function useSettings() {
  const context = useContext(SettingsContext);
  if (context === undefined) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
}