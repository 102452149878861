import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import Header from './components/Header';
import Dashboard from './pages/Dashboard';
import Notifications from './pages/Notifications';
import Planning from './pages/Planning';
import Schedule from './pages/Schedule';
import Account from './pages/Account';
import Attendance from './pages/Attendance';
import Settings from './pages/Settings';
import Students from './pages/Students';
import StudentProfile from './pages/Students/StudentProfile';
import FullYearPlan from './pages/Planning/FullYearPlan';
import HiddenPlans from './pages/Planning/HiddenPlans';
import SavedUnits from './pages/Planning/SavedUnits';
import Calendar from './pages/Planning/Calendar';
import LessonPlanner from './pages/Planning/LessonPlanner';
import LessonPlanning from './pages/Planning/LessonPlanning';
import Communication from './pages/Communication';
import Reply from './pages/Reply';
import { AlertProvider } from './contexts/AlertContext';
import { TaskProvider } from './contexts/TaskContext';
import { PlanRequestProvider } from './contexts/PlanRequestContext';
import { SavedUnitsProvider } from './contexts/SavedUnitsContext';
import { CalendarProvider } from './contexts/CalendarContext';
import { LessonProvider } from './contexts/LessonContext';
import { SubjectsProvider } from './contexts/SubjectsContext';
import { AuthProvider } from './contexts/AuthContext';
import { SecurityProvider } from './contexts/SecurityContext';
import { SubscriptionProvider } from './contexts/SubscriptionContext';
import { StudentProvider } from './contexts/StudentContext';
import { CommunicationProvider } from './contexts/CommunicationContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { BehaviorProvider } from './contexts/BehaviorContext';
import { AttendanceProvider } from './contexts/AttendanceContext';
import SecurityGate from './components/Security/SecurityGate';
import LoginForm from './components/Auth/LoginForm';
import RegisterForm from './components/Auth/RegisterForm';
import VerifyEmailForm from './components/Auth/VerifyEmailForm';
import ForgotPassword from './components/Auth/ForgotPassword';
import PrivateRoute from './components/Auth/PrivateRoute';
import { initGA, trackPageView, initFacebookPixel, trackFacebookPixelEvent } from './utils/analytics';

function AnalyticsWrapper({ children }: { children: React.ReactNode }) {
    const location = useLocation();

    useEffect(() => {
        initGA();
        initFacebookPixel(); // Initialize Facebook Pixel
    }, []);

    useEffect(() => {
        trackPageView(location.pathname + location.search); // Google Analytics
        trackFacebookPixelEvent('PageView'); // Facebook Pixel
    }, [location]);

    return <>{children}</>;
}

function MainLayout() {
  return (
    <div className="flex min-h-screen bg-gray-50">
      <Sidebar />
      <div className="flex-1">
        <Header />
        <main className="p-6">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/notifications" element={<Notifications />} />
            <Route path="/planning" element={<Planning />} />
            <Route path="/schedule" element={<Schedule />} />
            <Route path="/account" element={<Account />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/students" element={<Students />} />
            <Route path="/students/:id" element={<StudentProfile />} />
            <Route path="/attendance" element={<Attendance />} />
            <Route path="/planning/full-year" element={<FullYearPlan />} />
            <Route path="/planning/hidden-plans" element={<HiddenPlans />} />
            <Route path="/planning/saved-units" element={<SavedUnits />} />
            <Route path="/planning/calendar" element={<Calendar />} />
            <Route path="/planning/lessons" element={<LessonPlanning />} />
            <Route path="/planning/lessons/:unitId" element={<LessonPlanner />} />
            <Route path="/communication" element={<Communication />} />
            <Route path="/reply" element={<Reply />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}

export default function App() {
  return (
    <Router>
      <AnalyticsWrapper>
        <SecurityProvider>
          <SecurityGate>
            <AuthProvider>
              <SubscriptionProvider>
                <AlertProvider>
                  <TaskProvider>
                    <PlanRequestProvider>
                      <SubjectsProvider>
                        <SavedUnitsProvider>
                          <LessonProvider>
                            <CalendarProvider>
                              <StudentProvider>
                                <CommunicationProvider>
                                  <SettingsProvider>
                                    <BehaviorProvider>
                                      <AttendanceProvider>
                                        <Routes>
                                          <Route path="/login" element={<LoginForm />} />
                                          <Route path="/register" element={<RegisterForm />} />
                                          <Route path="/verify-email" element={<VerifyEmailForm />} />
                                          <Route path="/forgot-password" element={<ForgotPassword />} />
                                          <Route path="/*" element={
                                            <PrivateRoute>
                                              <MainLayout />
                                            </PrivateRoute>
                                          } />
                                        </Routes>
                                      </AttendanceProvider>
                                    </BehaviorProvider>
                                  </SettingsProvider>
                                </CommunicationProvider>
                              </StudentProvider>
                            </CalendarProvider>
                          </LessonProvider>
                        </SavedUnitsProvider>
                      </SubjectsProvider>
                    </PlanRequestProvider>
                  </TaskProvider>
                </AlertProvider>
              </SubscriptionProvider>
            </AuthProvider>
          </SecurityGate>
        </SecurityProvider>
      </AnalyticsWrapper>
    </Router>
  );
}