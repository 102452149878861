import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAlerts } from '../contexts/AlertContext';
import { AlertTriangle, Info, X } from 'lucide-react';
import { formatDate } from '../utils/dateFormatter';
import type { Alert } from '../types';

const getAlertIcon = (type: Alert['type']) => {
  switch (type) {
    case 'urgent':
      return <AlertTriangle className="h-5 w-5 text-red-500" />;
    case 'warning':
      return <AlertTriangle className="h-5 w-5 text-yellow-500" />;
    case 'info':
      return <Info className="h-5 w-5 text-blue-500" />;
  }
};

const getAlertStyles = (type: Alert['type']) => {
  switch (type) {
    case 'urgent':
      return 'bg-red-50 border-red-100';
    case 'warning':
      return 'bg-yellow-50 border-yellow-100';
    case 'info':
      return 'bg-blue-50 border-blue-100';
  }
};

export default function Notifications() {
  const { state, markAsRead } = useAlerts();
  const navigate = useNavigate();

  const handleAlertClick = (alert: Alert) => {
    if (alert.metadata?.type === 'plan_ready' && alert.metadata.path) {
      navigate(alert.metadata.path);
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-gray-900">All Notifications</h1>
        <p className="text-gray-500">View and manage all your notifications</p>
      </div>

      <div className="space-y-4">
        {state.alerts.length === 0 ? (
          <div className="text-center py-12 bg-white rounded-lg shadow-sm">
            <p className="text-gray-500">No notifications to display</p>
          </div>
        ) : (
          state.alerts.map((alert) => (
            <div
              key={alert.id}
              onClick={() => handleAlertClick(alert)}
              className={`flex items-start gap-4 p-4 rounded-lg border ${getAlertStyles(
                alert.type
              )} transition-all hover:shadow-sm ${
                alert.metadata?.type === 'plan_ready' ? 'cursor-pointer' : ''
              }`}
            >
              {getAlertIcon(alert.type)}
              <div className="flex-1">
                <p className="text-gray-900">{alert.message}</p>
                <p className="text-sm text-gray-500 mt-1">
                  {formatDate(alert.date)}
                </p>
                {alert.metadata?.type === 'plan_ready' && (
                  <p className="text-sm text-indigo-600 mt-1 hover:text-indigo-800">
                    Click to view plan
                  </p>
                )}
              </div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  markAsRead(alert.id);
                }}
                className="p-1 hover:bg-gray-200 rounded-full transition-colors"
                title="Mark as read"
              >
                <X className="h-4 w-4 text-gray-400" />
              </button>
            </div>
          ))
        )}
      </div>
    </div>
  );
}