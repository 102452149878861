import React from 'react';
import { Clock, AlertCircle } from 'lucide-react';
import type { SchedulePattern } from '../../../types/calendar';
import { getPatternDescription } from '../../../utils/schedulePatterns';

interface SchedulePatternSelectorProps {
  value: SchedulePattern;
  onChange: (pattern: SchedulePattern) => void;
  disabled?: boolean;
  showImpact?: boolean;
  daysImpact?: number;
}

export default function SchedulePatternSelector({
  value,
  onChange,
  disabled,
  showImpact,
  daysImpact
}: SchedulePatternSelectorProps) {
  const handlePatternChange = (type: SchedulePattern['type']) => {
    if (disabled) return;

    if (type === 'custom') {
      onChange({
        type: 'custom',
        customDays: [1, 3, 5] // Default to MWF for custom pattern
      });
    } else {
      onChange({ type });
    }
  };

  const handleCustomDayToggle = (day: number, e: React.MouseEvent) => {
    e.preventDefault();  // Add this
    if (disabled || value.type !== 'custom') return;
    const currentDays = value.customDays || [];
    const newDays = currentDays.includes(day)
      ? currentDays.filter(d => d !== day)
      : [...currentDays, day].sort();
    onChange({
      type: 'custom',
      customDays: newDays
    });
  };

  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">
          <div className="flex items-center gap-2">
            <Clock className="h-4 w-4" />
            Schedule Pattern
          </div>
        </label>
        <select
          value={value.type}
          onChange={(e) => handlePatternChange(e.target.value as SchedulePattern['type'])}
          disabled={disabled}
          className="w-full rounded-lg border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:opacity-50"
        >
          <option value="everyday">Every Day</option>
          <option value="mwf">Monday/Wednesday/Friday</option>
          <option value="tth">Tuesday/Thursday</option>
          <option value="custom">Custom Pattern</option>
        </select>
        <p className="mt-1 text-sm text-gray-500">
          {getPatternDescription(value)}
        </p>
      </div>

      {value.type === 'custom' && (
        <div>
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Select Days
          </label>
          <div className="flex gap-2">
            {['M', 'T', 'W', 'T', 'F'].map((day, index) => {
              const dayNumber = index + 1;
              const isSelected = value.customDays?.includes(dayNumber);
              
              return (
                <button
                  key={dayNumber}
                  onClick={(e) => handleCustomDayToggle(dayNumber, e)}  // Pass the event
                  type="button"  // Add this to prevent form submission
                  disabled={disabled}
                  className={`
                    w-8 h-8 rounded-full flex items-center justify-center text-sm font-medium
                    transition-colors
                    ${isSelected
                      ? 'bg-indigo-100 text-indigo-700'
                      : 'bg-gray-100 text-gray-600'
                    }
                    ${disabled
                      ? 'opacity-50 cursor-not-allowed'
                      : 'hover:bg-indigo-50'
                    }
                  `}
                >
                  {day}
                </button>
              );
            })}
          </div>
        </div>
      )}

      {showImpact && daysImpact !== undefined && daysImpact > 0 && (
        <div className="flex items-start gap-2 p-3 bg-yellow-50 rounded-lg">
          <AlertCircle className="h-5 w-5 text-yellow-400 flex-shrink-0" />
          <div className="text-sm text-yellow-800">
            <p>This pattern will extend the unit duration by {daysImpact} calendar days.</p>
            <p className="mt-1 text-yellow-600">
              The unit will still have the same number of instructional days, but spread over a longer period.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}